@import "src/design/standard.scss";
@import "src/design/pattern.scss";


.cke_toolbox {
    display: flex !important;
    flex-wrap: wrap !important;
}


.cke_top, .cke_bottom{
    background: $c-cream !important;
}

.cke_top{
    padding: 8px 8px 4px !important;
}


a.cke_button_off:hover, a.cke_button_off:focus, a.cke_button_off:active{
    background: white !important;
    border: 1px #bcbcbc solid;
    border-radius: 3px;
    padding: 3px 5px;
}

.cke_button_on{
    border-radius: 3px !important; 
}

.cke_button{
    margin: 0 3px !important;
}

.ck-editor__inline {
    min-height: 500px;
  }

.ck-off:hover{
    background: white !important;
}

.ck-on{
    background: $c-blue !important;
    .ck-icon, .ck-button__label{
        color: white !important;
    }
}

.cke_1_contents{
    min-height: 400px !important;
}

:host ::ng-deep .cke_1_contents {
    min-height: 400px !important;
}


.ck-onlyRead{
    .cke_top, .cke_1_bottom, .cke_1_path{
        display: none !important;
    }

    .ck-editor {
        min-height: 500px !important;
    }

    .cke_contents{
        min-height: 400px !important;
    }

    .cke_path_item{
        display: none !important;
    }
   
}


