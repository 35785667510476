@import "src/design/standard.scss";
@import "src/design/pattern.scss";


.ngx-slider{
    .ngx-slider-bar{
        height: 3px !important;
    }

    .ngx-slider-pointer{
        width: 12px !important;
        height: 12px !important;
        top: -5px !important;
        background-color: $c-blue !important;

        &::after{
            content: none !important;
        }
    }

    .ngx-slider-selection{
        background-color: $c-blue !important;
    }

    .ngx-slider-bubble{
        color: $c-black !important;
        font-size: 14px !important;
    }
}