// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

// Imports

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

// BASIC
// Reset
@import "src/design/reset.scss";
// Normilize
@import "src/design/normalize.scss";
// Standard
@import "src/design/standard.scss";
// Patterns
@import "src/design/pattern.scss";

// ADDITIONAL
// Date Time Picker
@import "src/design/additionalElemnts/ng-pick-datetime.scss";
// Ng Select
@import "src/design/additionalElemnts/ng-select.scss";
// Cke Editor
@import "src/design/additionalElemnts/cke-editor.scss";
// Spinner
@import "src/design/additionalElemnts/spinner.scss";
// Cropper
@import "src/design/additionalElemnts/cropper.scss";
// Google Map
@import "src/design/additionalElemnts/google-map.scss";
// Full Calendar
@import "src/design/additionalElemnts/full-calendar.scss";
// Slider Input
@import "src/design/additionalElemnts/ngx-slider.scss";

/* 
+ + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + 

DESIGN  ver 1.0

1. Elements
    1.1. Buttons
    1.2. Dropdown
    1.3. Pagination
    1.4. Headers
    1.5. Boxs
        1.5.1. Modern Box
        1.6.2. Classic Box
    1.6  Cards
        1.6.1. Offer Card
        1.6.2. Collection Card
        1.6.3. Category Card
        1.6.4  Category Small Card
        1.6.5. User Card
        1.6.6. Offer-agreement Card
        1.6.7. Tag Card 
    1.7. Notification
2. Layout
    2.1. Navigation
    2.2. Notification
    2.3. Footer
3. Pages
    2.1. Offer
    2.2. Offer home
    2.3. Categories
    2.4. Categories home
    2.5. Collections
    2.6. Collections home
    2.7. Roadshow
    2.8. Offer details
    2.9. Offer new
    2.10. Offer agreements
    2.11. Login
    2.12. Register
    2.13 Banner
    2.14 Suppliers Pending
4. Form
5. Table
6. Modals
7. No items
8. Other

+ + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + 
*/

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

// 1. Elements

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

// 1.1 Btn
// pozadina/ slova
@mixin btn($color-first, $color-second) {
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.25rem;
    border-radius: $radius-btn;
    @include font-basic($color-first, $fs-basic);
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    min-width: 110px;
    height: 35px;

    background-color: $color-second;

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken($color-second, 10%);
        transition: all 0.2s;
        border-radius: $radius-btn;
        z-index: -1;
    }

    &:hover {
        color: #fff;

        &:before {
            width: 100%;
        }
    }

    // @include padding(6px 20px);
    // padding-top: 10px;
    // @include font-basic($color-first, $fs-basic);
    // background-color: $color-second;
    // border: 1px solid $color-first;
    // border-radius: $radius-btn;
    // cursor: pointer;

    // &:hover {
    //     background-color: $color-first;
    //     border: 1px solid $color-second;

    //     @if($color-second==transparent) {
    //         color: $c-white;
    //     }

    //     @else {
    //         color: $color-second;
    //     }
    // }
    // &:disabled{
    //     opacity: 0.8;
    //     cursor: not-allowed;
    // }
}

@mixin btn-hover() {
    border: none;
    border-radius: $radius-btn;
    cursor: pointer;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: rgba(173, 173, 173, 0.158);
        transition: all 0.2s;
        border-radius: $radius-btn;
        z-index: -1;
    }

    &:hover {
        &:before {
            width: 100%;
        }
    }
}

.btn {
    @include btn($c-white, $c-blue);
}

.btn-blue {
    @include btn($c-white, $c-blue);
}

.btn-red {
    @include btn($c-white, $c-red);
}

.btn-green {
    @include btn($c-white, $c-green);
}

.btn-green-c {
    @include btn($c-green, transparent);
}

.btn-blue-c {
    @include btn($c-blue, transparent);
}

.btn-red-c {
    @include btn($c-red, transparent);
}

.btn-white {
    @include btn($c-blue, $c-white);
}

.btn-white-c {
    @include btn($c-blue, transparent);
}

.btn-transparent {
    @include btn($c-white, transparent);
    border: 1px solid $c-white;
}

.btn-grey {
    @include btn($c-grey, #e2e2e2);
}

.btn-share {
    @include image($icon-btn-share-0, auto, auto, auto, 17px, center right 15px);
    @include padding(6px 40px 6px 20px);
    padding-top: 10px;
    @include font-basic($c-black-light, $fs-basic);
    background-color: #e2e2e2;
    border-radius: $radius-btn;
    border: none;
    cursor: pointer;

    &:hover {
        @include image($icon-btn-share-1, auto, auto, auto, 17px, center right 15px);
        color: $c-blue;
        background-color: $c-cream;
    }
}

.btn-share-modern {
    @include image($icon-btn-share-0, auto, auto, auto, 17px, center right 15px);
    @include padding(0 40px 0 1.25rem);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    @include font-basic($c-black-light, $fs-basic);
    background-color: #e2e2e2;
    border-radius: $radius-btn;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    .share-options {
        background-color: #d0d0d0;
        position: absolute;
        overflow: hidden;
        width: 100%;
        left: -160px;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $radius-btn;
        transition-duration: 0.2s;

        .share-fb {
            @include image($icon-share-fb, 20px, 20px, auto, 19px, center);
            margin: 0 5px;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }

        .share-twiter {
            @include image($icon-share-twiter, 20px, 20px, auto, 19px, center);
            margin: 0 5px;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }

        .share-mail {
            @include image($icon-share-mail, 20px, 20px, auto, 19px, center);
            margin: 0 5px;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    &:hover {
        .share-options {
            -webkit-transform: translateX(160px);
            transform: translateX(160px);
            transition-duration: 0.2s;
        }
    }
}

.btn-qr {
    @include image($icon-qr-0, auto, auto, auto, 17px, center right 15px);
    @include padding(0 40px 0 1.25rem);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    @include font-basic($c-black-light, $fs-basic);
    background-color: #e2e2e2;
    @include btn-hover();
}

.btn-favourites {
    @include image($icon-btn-favourites-0, auto, auto, auto, 17px, center right 15px);
    @include padding(0 40px 0 1.25rem);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    @include font-basic($c-black-light, $fs-basic);
    background-color: #e2e2e2;
    @include btn-hover();
}

.btn-favourites-active {
    @include image($icon-btn-favourites-1, auto, auto, auto, 17px, center right 15px);
    display: inline-flex;
    background-color: #e2e2e2;
}

.btn-delete {
    @include image($icon-reject-1, 17px, 17px, auto, 30px, center);
    cursor: pointer;
}

.btn-filter {
    @include image($icon-btn-filter-0, auto, auto, auto, 17px, center right 15px);
    @include padding(0 40px 0 20px);
    @include font-basic($c-black-light, $fs-basic);
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid #e2e2e2;
    background-color: #e2e2e2;
    border-radius: $radius-btn;
    cursor: pointer;

    .btn-filter-counter {
        display: block;
        position: absolute;
        padding: 1px;
        background-color: $c-blue;
        @include font-basic-semi-bold($c-white, 0.7rem);
        line-height: 20px;
        width: 20px;
        height: 20px;
        text-align: center;
        right: 0;
        top: -9px;
        border-radius: 12px;
    }

    &:hover {
        @include image($icon-btn-filter-1, auto, auto, auto, 17px, center right 15px);
        color: $c-white;
        background-color: $c-blue;

        .btn-filter-counter {
            display: none;
        }
    }
}

.btn-filter-active {
    @include image($icon-btn-filter-1, auto, auto, auto, 17px, center right 15px);
    display: inline-flex;
    color: $c-white;
    background-color: $c-blue;
}

// 1.2. Dropdown

@mixin dropdownn($position, $position-px, $margin-top-px) {
    display: block;
    position: relative;

    >.dropdown-btn {
        @include image($icon-arrow-down, auto, auto, auto, 8px, center right 17px);
        @include padding(0 40px 0 20px);
        @include font-basic($c-grey-light, $fs-basic);
        background-color: #e2e2e2;
        border-radius: $radius-btn;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        cursor: pointer;

        .default {
            @include font-basic($c-grey-light, $fs-basic);
        }

        .value {
            @include font-basic($c-black, $fs-basic);

            b {
                @include font-basic-bold($c-black, $fs-basic);
            }
        }
    }

    >.dropdown-btn-blue {
        @include image($icon-arrow-down, auto, auto, auto, 8px, center right 17px);
        @include btn($c-white, $c-blue);
        @include padding(0 40px 0 20px);
        border-radius: $radius-btn;
        align-items: center;
        height: 35px;
        min-width: 30px;
        cursor: pointer;
    }

    >.dropdown-btn-active {
        @include image($icon-arrow-up, auto, auto, auto, 8px, center right 17px);
        display: inline-flex;
        background-color: #e2e2e2;
        height: 35px;
    }

    >.dropdown-btn-blue-active {
        @include image($icon-arrow-up, auto, auto, auto, 8px, center right 17px);
        display: inline-flex;
        background-color: #0047ba;
        height: 35px;
    }

    >.dropdown-list {
        position: absolute;
        z-index: 20;
        top: $margin-top-px;
        background-color: $c-white;
        box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
        @include padding(0 25px);
        border-radius: 10px;

        @if ($position==left) {
            left: $position-px;
        }

        @else {
            right: $position-px;
        }

        &::after {
            position: absolute;
            content: "";
            top: -8px;
            width: 0;
            height: 0;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-bottom: 8px solid white;
            z-index: 0;

            @if ($position==left) {
                left: 27px;
            }

            @else {
                right: 27px;
            }
        }

        &::before {
            position: absolute;
            content: "";
            width: 12px;
            height: 12px;
            background-color: white;
            top: -7px;
            transform: rotate(45deg);
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
            z-index: -1;

            @if ($position==left) {
                left: 30px;
            }

            @else {
                right: 30px;
            }
        }

        >.dropdown-item {
            @include font-basic($c-black, $fs-basic);
            padding: 12px 5px 12px 5px;
            border-bottom: 1px solid $c-grey-line;
            width: auto;
            white-space: nowrap;
            background-color: $c-white;
            cursor: pointer;

            b {
                @include font-basic-bold($c-black, $fs-basic);
                margin-right: 5px;
            }

            &:hover {
                color: $c-blue;

                b {
                    color: $c-blue;
                }
            }

            &:last-of-type {
                border: none;
            }
        }
    }
}

@mixin dropup($position, $position-px, $margin-top-px) {
    display: inline-block;
    position: relative;

    >.dropdown-list {
        position: absolute;
        z-index: 20;
        top: $margin-top-px;
        background-color: $c-white;
        box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
        @include padding(0 25px);
        border-radius: 10px;

        @if ($position==left) {
            left: $position-px;
        }

        @else {
            right: $position-px;
        }

        >.dropdown-item {
            @include font-basic($c-black, $fs-basic);
            @include padding(15px 5px);
            border-bottom: 1px solid $c-grey-line;
            width: auto;
            white-space: nowrap;
            background-color: $c-white;
            cursor: pointer;

            b {
                @include font-basic-bold($c-black, $fs-basic);
                margin-right: 5px;
            }

            &:hover {
                color: $c-blue;

                b {
                    color: $c-blue;
                }
            }

            &:last-of-type {
                border: none;
            }
        }
    }
}

.dropdown {
    @include dropdownn(right, 0, 45px);
}

// 1.3. Pagination
.pagination-container {
    @include margin-top(60px);
    text-align: center;

    .pagination {
        display: inline-flex;
        @include font-basic($c-black-light, 1rem);

        >* {
            @include margin(0 10px);
        }

        >.first {
            @include image($icon-pagination-firstPage-0, 17px, 15px, auto, 14px, center);
            cursor: pointer;

            &:hover {
                @include image($icon-pagination-firstPage-1, 17px, 15px, auto, 14px, center);
            }
        }

        >.prev {
            @include image($icon-pagination-prevPage-0, 12px, 15px, auto, 14px, center);
            cursor: pointer;

            &:hover {
                @include image($icon-pagination-prevPage-1, 12px, 15px, auto, 14px, center);
            }
        }

        >.counter {
            display: inline-flex;

            >.currentPage {
                color: $c-blue;
                @include margin(0 10px 0 0);
            }

            >.totalPage {
                @include margin(0 0 0 10px);
            }
        }

        >.next {
            @include image($icon-pagination-nextPage-0, 12px, 15px, auto, 14px, center);
            cursor: pointer;

            &:hover {
                @include image($icon-pagination-nextPage-1, 12px, 15px, auto, 14px, center);
            }
        }

        >.last {
            @include image($icon-pagination-lastPage-0, 17px, 15px, auto, 14px, center);
            cursor: pointer;

            &:hover {
                @include image($icon-pagination-lastPage-1, 17px, 15px, auto, 14px, center);
            }
        }
    }
}

// 1.4. Header
@mixin header {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 50px 0;

        @include respSpecif(1000) {
            flex-direction: column;
            align-items: flex-start;
            margin: 0 0 30px 0;

            .title-box {
                margin-bottom: 20px;
            }
        }

        >.title-box {
            >.back {
                display: inline-block;
                @include margin(0 0 7px 0);
                @include image($icon-back, 52px, 14px, auto, 11px, center left);
                @include font-basic($c-grey-light, $fs-basic);
                text-align: right;
                cursor: pointer;
            }

            >.title {
                @include font-basic-bold($c-black-light, $fs-section-title);
                text-transform: capitalize;
                display: flex;
                align-items: center;
            }

            >.title--section {
                @include font-basic-bold($c-black-light, 1.75rem);
            }

            >.sub-title {
                @include margin(5px 0 0 0);
                @include font-basic($c-grey-light, $fs-section-subtitle);

                >* {
                    margin-right: 10px;
                }

                >.bold {
                    @include font-basic-bold($c-black-light, $fs-section-subtitle);
                }
            }
        }

        >.tools-box {
            display: flex;
            justify-content: flex-end;

            >* {
                margin: 0 0 0 10px;

                @include respSpecif(1000) {
                    margin: 0 10px 0 0;
                }
            }

            @include respSpecif(800) {
                >* {
                    margin: 0 10px 0 0;
                }
            }
        }
    }
}

@mixin header-home {
    .header {
        @include margin(0 0 40px 0);

        >.title {
            text-align: left;
            @include font-basic-bold($c-black-light, $fs-section-title);
        }

        >.sub-title {
            @include margin(5px 0 0 0);
            text-align: left;
            @include font-basic($c-grey-light, $fs-section-subtitle);
        }
    }
}

@mixin header-section {
    >.section-header {
        @include margin(20px 0 20px 0);
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respSpecif(1000) {
            flex-direction: column;
            align-items: flex-start;
            margin: 15px 0 15px 0;

            .tools {
                margin-top: 20px;

                >* {
                    margin-left: 0;
                    margin: 0 10px 0 0 !important;
                }
            }
        }

        >.title {
            @include font-basic-bold($c-black-light, 1.75rem);
            display: flex;
            align-items: flex-end;
            position: relative;
            padding-top: 5px;

            &.req {
                &::after {
                    @include font-basic-bold($c-blue, 1rem);
                    content: "*";
                    position: absolute;
                    right: -6px;
                    top: 3px;
                }
            }
        }

        >.tools {
            display: flex;

            button {
                margin-left: 10px;
            }
        }
    }
}

// 1.5. Box & Section
@mixin box-section($max-height) {
    >.box {
        background-color: $c-white;
        border-radius: 10px;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
        @include padding(0 20px 20px 20px);

        >.tabs {
            @include padding(20px 0 30px 0);

            >.tab {
                display: inline-block;
                @include margin(0 40px 0 0);
                @include font-basic($c-black-light, 1rem);
                line-height: 30px;
                border-bottom: 4px solid transparent;
                cursor: pointer;

                &:hover {
                    color: $c-blue;
                }
            }

            >.tab-active {
                border-bottom: 4px solid $c-blue;
                @include font-basic-semi-bold($c-blue, 1rem);
            }
        }

        >.box-content {
            @include font-basic($c-grey, $fs-basic);
            line-height: 1.3rem;
            max-height: $max-height;
            padding-right: 5px;
            overflow-y: auto;
            @include scroll-vertical();

            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @include padding(30px 0 0 0);

            .group {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                >.group-title {
                    @include font-basic-bold($c-black, 1rem);
                    @include margin(0 0 20px 0);
                }
            }

            .col-1 {
                width: 100%;
            }

            .col-2 {
                width: calc(100% / 2 - 50px);
            }

            .col-3 {
                width: calc(100% / 3 - 75px);
            }

            .col-4 {
                width: calc(100% / 3 - 75px);
            }
        }
    }
}


.tooltip {
    position: absolute;
    background-color: lightgray;
    left: 200px;
    font-size: 16px;
    padding: 5px;
    border-radius: 5px;

    &-vauchers {
        left: 290px
    }
}

.section-modern,
.section-classic {
    @include margin(0 0 50px 0);
    @include header-section();

    // resize
    >.section-header {
        >.title {
            position: relative;

            >.resize {
                position: absolute;
                right: -25px;
                bottom: 6px;
                @include image($icon-section-resize-1, 17px, 17px, auto, 16px, center);
                cursor: pointer;
            }
        }
    }

    >.section-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        >* {
            width: 100%;
            word-break: break-word;
        }
    }

    .group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include margin(0 0 20px);

        >* {
            width: 100%;
        }

        >.gruop-title {
            @include font-basic-bold($c-black, 1rem);
            @include margin(0 0 20px 0);
        }
    }

    .col-2 {
        width: calc(100% / 2 - 50px);

        @include resp(S) {
            width: 100%;
        }
    }

    .col-3 {
        width: calc(100% / 3 - 75px);
    }

    .col-4 {
        width: calc(100% / 3 - 75px);
    }

    .col-button {
        width: calc(100% / 2 - -20px);
    }

    .attachments {
        margin-right: auto;

        >.attachment {
            display: flex;
            align-items: center;
            gap: 1rem;

            .file-name {
                @include font-basic-semi-bold($c-black-light, 0.95rem);
                cursor: pointer;

                &:hover {
                    color: $c-blue;
                }
            }

            .delete {
                @include image($icon-delete, 12px, 15px, auto, 14px, center);
                margin-left: 6px;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}

.section-modern-collapse,
.section-classic-collapse {
    @include margin(0 0 0 0);
    border-bottom: 1px solid $c-line-grey;

    // resize
    >.section-header {
        >.title {
            >.resize {
                cursor: pointer;
                margin-left: 7px;
                @include image($icon-section-resize-0, 17px, 17px, auto, 16px, center);
            }
        }

        >.tools {
            display: none;
        }
    }

    >.section-content {
        display: none;
    }
}

.section-modern {
    .section-content {
        background-color: $c-white;
        border-radius: 10px;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
        @include padding(20px 20px 10px 20px);

        .tabs {
            @include padding(0 0 30px 0);
            @include margin-top(-10px);

            >.tab {
                display: inline-block;
                @include margin(0 40px 0 0);
                @include font-basic($c-black-light, 1rem);
                line-height: 30px;
                border-bottom: 4px solid transparent;
                cursor: pointer;

                &:hover {
                    color: $c-blue;
                }
            }

            >.tab-active {
                border-bottom: 4px solid $c-blue;
                @include font-basic-semi-bold($c-blue, 1rem);
            }
        }

        .section-content-part-title {
            @include font-basic-bold($c-black, 1rem);
            @include margin(0 0 20px 0);
        }
    }
}

// Footer
@mixin footer-home {
    @include margin(40px 0 0 0, 30px 0 0 0, 20px 0 0 0);
    text-align: center;
}

@mixin footer {
    >.footer {
        @include margin(30px 0 0 0);

        >.tools-box {
            display: flex;
            justify-content: flex-end;

            >* {
                @include margin(0 0 0 10px);
            }
        }
    }
}

// 1.6. Cards
@mixin offer-card {
    .offer-card {
        position: relative;

        >.cover {
            position: relative;
            // box-shadow: 0 0 0 1px #dbdbdb;
            border-radius: 10px;

            >.img-cover {
                // position: absolute;
                top: 0;
                left: 0;
                cursor: pointer;
                border-radius: 10px;
                width: 100%;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);

                &:hover {
                    opacity: 0.8;
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
                }
            }

            >.img-placeholder {
                cursor: pointer;
                border-radius: 10px;
                width: 100%;
            }

            >.status {
                position: absolute;
                z-index: 2;
                left: 0;
                right: 0;
                bottom: 0;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include padding(0px 15px);
                background-color: $c-grey-light;
                @include font-basic-semi-bold($c-white, 1rem);
                text-align: center;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                background-color: #00000045;
            }

            >.checked {
                position: absolute;
                right: 10px;
                top: 6px;
                @include image($icon-checker-1, 17px, 17px, auto, 16px, center);
            }
        }

        >img {
            width: 100%;
            border-radius: 10px;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
                transition: 1s ease;
            }
        }

        >.data {
            @include padding(10px 10px 0 10px);

            >.company-name {
                @include font-basic-semi-bold($c-black-light, 1.125rem);
                line-height: 1.4rem;
                cursor: pointer;
                margin-top: 5px;

                &:hover {
                    color: $c-blue;
                }
            }

            >.title {
                @include font-basic(#6e6e6e, 1rem);
                line-height: 1.3rem;
                cursor: pointer;
                margin-top: 4px;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;

                &:hover {
                    color: $c-blue;
                }
            }

            >.sub-title {
                display: flex;
                justify-content: space-between;

                >.category {
                    @include font-basic($c-blue, $fs-basic);
                    cursor: pointer;
                    line-height: 17px;

                    &:hover {
                        opacity: 0.8;
                    }
                }

                >.location {
                    @include image($icon-location, auto, 12px, auto, 11px, center left);
                    padding-left: 12px;
                    @include font-basic($c-grey, $fs-basic);
                }

                >.locations {
                    @include image($icon-locations, auto, 12px, auto, 11px, center left);
                    padding-left: 12px;
                    @include font-basic($c-grey, $fs-basic);
                }
            }

            >.description {
                @include margin(10px 0 0 0);
                @include font-basic($c-grey, $fs-basic);
                line-height: 18px;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                height: 36px;
                word-break: break-word;
            }

            >.price {
                display: inline-flex;
                align-items: center;
                margin-top: 10px;
                @include font-basic-semi-bold($c-black-light, 1rem);
                @include padding(3px 5px 3px 5px);
                border-radius: 3px;
                background-color: #e6e6e6;

                .line-through {
                    margin-right: 5px;
                    @include font-basic-semi-bold($c-grey-light, 1rem);
                    text-decoration: line-through;
                }

                .bold {
                    color: $c-black;
                }

                .discount {
                    @include font-basic-semi-bold($c-black-light, 1rem);
                }
            }

            >.time {
                @include margin(15px 0 0 0);

                >.date {
                    @include margin(0 10px 0 0);
                    @include font-basic-semi-bold($c-black-light, 1.125rem);
                }

                >.hours {
                    @include font-basic($c-black-light, 1.125rem);
                }
            }

            >.rating {
                @include margin(10px 0 0 0);

                >.value {
                    display: inline-block;
                    @include font-basic($c-blue, $fs-basic);
                }

                >.stars {
                    position: relative;
                    display: inline-block;
                    margin: 0 10px;
                    @include image($icon-stars-empty, 78px, 14px, auto, 13px, center left);

                    >.stars-positive {
                        position: absolute;
                        @include image($icon-stars-full, 0%, 14px, auto, 13px, center left);
                    }
                }

                >.voted {
                    display: inline-block;
                    @include font-basic($c-grey, $fs-basic);
                }
            }
        }

        >.tag {
            position: absolute;
            top: 0;
            left: 0;
            @include padding(7px 15px);
            background-color: $c-blue;
            @include font-basic-semi-bold($c-white, $fs-basic);
            border-top-left-radius: 10px;
            border-bottom-right-radius: 8px;
        }

        >.status {
            position: absolute;
            top: 0;
            right: 0;
            @include padding(7px 15px);
            background-color: $c-grey-light;
            @include font-basic-semi-bold($c-white, $fs-basic);
            border-top-right-radius: 10px;
            border-bottom-left-radius: 8px;
        }
    }
}

@mixin offer-roadshow-card {
    .offer-roadshow-card {
        position: relative;

        >.cover {
            position: relative;
            border-radius: 10px;
            height: 12.2rem;

            >.img-cover {
                // position: absolute;
                top: 0;
                left: 0;
                cursor: pointer;
                border-radius: 10px;
                width: 100%;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
                height: 100%;

                &:hover {
                    opacity: 0.8;
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
                }
            }

            >.img-placeholder {
                cursor: pointer;
                border-radius: 10px;
                width: 100%;
            }

            >.details {
                position: absolute;
                z-index: 2;
                left: 0;
                right: 0;
                bottom: 0;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include padding(0px 15px);
                background-color: $c-grey-light;
                @include font-basic-semi-bold($c-white, 1rem);
                text-align: center;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                background-color: #00000045;

                .roadshow-title {
                    display: none;
                }
            }

            &:hover {
                .duration {
                    display: none;
                }

                .roadshow-title {
                    display: inline-block;
                }
            }

            >.checked {
                position: absolute;
                right: 10px;
                top: 6px;
                @include image($icon-checker-1, 17px, 17px, auto, 16px, center);
            }
        }

        >img {
            width: 100%;
            border-radius: 10px;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }

        >.data {
            @include padding(10px 10px 0 10px);

            >.title {
                display: inline-block;
                @include font-basic-semi-bold($c-black-light, 1.125rem);
                word-break: break-word;
                cursor: pointer;

                &:hover {
                    color: $c-blue;
                }
            }

            >.sub-title {
                @include margin(10px 0 0 0);
                display: flex;
                justify-content: space-between;

                >.category {
                    @include font-basic($c-blue, $fs-basic);
                    cursor: pointer;
                }

                >.location {
                    @include image($icon-location, auto, 12px, auto, 11px, center left);
                    padding-left: 12px;
                    @include font-basic($c-grey, $fs-basic);
                    line-height: 17px;
                }

                >.locations {
                    @include image($icon-locations, auto, 12px, auto, 11px, center left);
                    padding-left: 12px;
                    @include font-basic($c-grey, $fs-basic);
                    line-height: 17px;
                }

                >.proposals {
                    @include image($icon-proposals-0, auto, 12px, auto, 11px, center left);
                    padding-left: 19px;
                    @include font-basic($c-grey, $fs-basic);
                    line-height: 12px;
                    cursor: pointer;

                    &:hover {
                        @include image($icon-proposals-1, auto, 12px, auto, 11px, center left);
                        color: $c-blue;
                    }
                }
            }

            >.description {
                @include margin(10px 0 0 0);
                @include font-basic($c-grey, $fs-basic);
                line-height: 18px;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                height: 36px;
                word-break: break-word;
            }

            >.price {
                display: flex;
                align-items: center;
                @include margin(15px 0 0 0);
                @include font-basic-semi-bold($c-black-light, 1rem);

                .line-through {
                    margin-right: 5px;
                    @include font-basic-semi-bold($c-grey-light, 1rem);
                    text-decoration: line-through;
                }

                .bold {
                    color: $c-black;
                }

                .discount {
                    @include font-basic-semi-bold($c-black-light, 1rem);
                    @include margin(0 5px);
                    @include padding(7px 5px 3px 5px);
                    border-radius: 3px;
                    background-color: $c-cream;
                }
            }
        }

        >.tag {
            position: absolute;
            top: 0;
            left: 0;
            @include padding(7px 15px);
            background-color: $c-blue;
            @include font-basic-semi-bold($c-white, $fs-basic);
            border-top-left-radius: 10px;
            border-bottom-right-radius: 8px;
        }

        &:hover {
            >.settings {
                display: flex;
            }
        }

        >.settings {
            top: -15px;
            right: -15px;
            position: absolute;
            display: none;
            background-color: $c-white;
            box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
            @include padding(0px 5px);
            border-radius: 5px;
            z-index: 1;
            cursor: initial;

            >.edit {
                @include image($icon-edit, 15px, 15px, auto, 14px, center);
                @include margin(7px);
                cursor: pointer;
            }

            >.delete {
                @include image($icon-delete, 12px, 15px, auto, 14px, center);
                @include margin(7px);
                cursor: pointer;
            }
        }
    }
}

@mixin collection-card {
    .collection-card {
        position: relative;
        cursor: pointer;

        >img {
            width: 100%;
            height: auto;
            border-radius: 15px;
        }

        >.data {
            position: absolute;
            bottom: 10px;
            left: 10px;
            width: calc(100% - 60px);
            padding: 20px;
            background-color: rgba(255, 255, 255, 0.9);
            border-radius: 20px;

            @include respSpecif(1000) {
                padding: 10px 20px;
            }

            >.title {
                @include font-basic-bold($c-black-light, 1.25rem);

                @include respSpecif(1000) {
                    font-size: 0.9rem;
                }
            }

            >.description {
                @include margin(10px 0 0 0);
                @include font-basic($c-black-light, $fs-basic);
                line-height: 1.3rem;
                @include hide-X(1000);
            }

            >.footer {
                @include margin(10px 0 0 0);
                text-align: right;
                @include hide-X(1000);
            }
        }

        &:hover {
            >.settings {
                display: flex;
            }
        }

        >.settings {
            top: -15px;
            right: -15px;
            position: absolute;
            display: none;
            background-color: $c-white;
            box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
            @include padding(0px 5px);
            border-radius: 5px;
            z-index: 1;
            cursor: initial;

            >.edit {
                @include image($icon-edit, 15px, 15px, auto, 14px, center);
                @include margin(7px);
                cursor: pointer;
            }

            >.delete {
                @include image($icon-delete, 12px, 15px, auto, 14px, center);
                @include margin(7px);
                cursor: pointer;
            }
        }
    }
}

@mixin banner-card($inline) {
    .banner-card {
        cursor: pointer;
        display: flex;
        border-radius: calc(1.2vw / #{$inline});
        overflow: hidden;
        position: relative;

        >.text-section {
            background-color: #3f6285;
            border-right: 4px solid white;
            width: 20%;
            position: relative;

            >.banner__tag {
                position: absolute;
                left: 0;
                top: 0;
                padding: calc(1vw / #{$inline}) calc(1vw / #{$inline}) calc(0.6vw / #{$inline}) calc(1vw / #{$inline});
                font-family: "ADNOC Sans Medium";
                color: #fff;
                font-size: calc(1vw / #{$inline});
                background-color: $c-blue;
                border-bottom-right-radius: calc(1vw / #{$inline});

                @include respSpecif(1200) {
                    font-size: calc(1.2vw / #{$inline});
                    padding: calc(1.4vw / #{$inline}) calc(1.2vw / #{$inline}) calc(0.8vw / #{$inline}) calc(1.2vw / #{$inline});
                }

                @include respSpecif(800) {
                    display: none;
                }
            }

            >.banner__content {
                position: absolute;
                left: calc(1vw / #{$inline});
                top: calc(4vw / #{$inline});
                width: calc(100% - (2vw / #{$inline}));

                >.banner__content__title {
                    margin-top: calc(0.8vw / #{$inline});
                    font-family: "ADNOC Sans Bold";
                    color: #fff;
                    font-size: calc(1.8vw / #{$inline});
                    line-height: calc(2.3vw / #{$inline});
                    word-break: all;
                }

                >.banner__content__discount {
                    font-family: "ADNOC Sans Bold";
                    color: #fff;
                    font-size: calc(1.1vw / #{$inline});
                    line-height: calc(1.6vw / #{$inline});

                    .discount--new {
                        .old {
                            text-decoration: line-through;
                        }

                        .new {
                            margin-left: 5px;
                        }
                    }
                }

                >.banner__content__company {
                    margin-top: calc(1.2vw / #{$inline});
                    font-family: "ADNOC Sans Medium";
                    color: #fff;
                    font-size: calc(1.2vw / #{$inline});
                    line-height: calc(1.5vw / #{$inline});
                }
            }

            >.banner__btn_c {
                position: absolute;
                bottom: calc(1.5vw / #{$inline});
                width: 100%;
                display: flex;
                justify-content: center;

                >.banner__btn {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    padding: calc(0.5vw / #{$inline}) calc(1.3vw / #{$inline});
                    font-family: "ADNOC Sans Regular";
                    color: #fff;
                    font-size: calc(0.8vw / #{$inline});
                    border: 1px solid #fff;
                    border-radius: calc(1vw / #{$inline});
                    cursor: pointer;

                    &:hover {
                        background-color: rgba(255, 255, 255, 0.1);
                    }
                }
            }
        }

        >.big-imgs-section {
            width: calc((100% - 20%) / 2);
            position: relative;
            border-right: 4px solid white;

            >img {
                width: 100%;
            }

            .img {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        >.small-imgs-section {
            display: inline-block;
            width: calc((100% - 20% - 3px) / 2);
            display: flex;
            flex-wrap: wrap;

            .small-img {
                width: calc(100% / 2 - 3px);
                position: relative;

                &:nth-child(1) {
                    border-right: 4px solid white;
                }

                &:nth-child(3) {
                    border-top: 4px solid white;
                    border-right: 4px solid white;
                }

                &:nth-child(4) {
                    border-top: 4px solid white;
                }

                >img {
                    width: 100%;
                }

                .img {
                    position: absolute;
                    left: 0;
                }
            }
        }

        .website-btn {
            @include image($icon-website-0, 30px, 30px, auto, 25px, center);
            position: absolute;
            right: 14px;
            top: 14px;
            z-index: 10;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}




@mixin category-card {
    .category-card {
        position: relative;
        display: flex;
        height: 140px;

        >img {
            margin: 0 auto;
            width: 66px;
            display: block;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }

        >.data {
            position: absolute;
            width: 100%;

            >.title {
                @include margin(10px 0 0 0);
                text-align: center;
                @include font-basic-bold($c-black-light, 1rem);
                line-height: 1.2rem;
                word-break: break-word;
                display: flex;
                height: 35px;
                align-items: center;
                justify-content: center
            }

            >.counter {
                @include margin(5px 0 0 0);
                text-align: center;
                @include font-basic($c-grey-light, $fs-basic);
            }
        }

        &:hover {
            >.settings {
                display: flex;
            }
        }

        >.settings {
            top: -15px;
            right: -15px;
            position: absolute;
            display: none;
            background-color: $c-white;
            box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
            @include padding(0px 5px);
            border-radius: 5px;
            z-index: 1;
            cursor: initial;

            >.edit {
                @include image($icon-edit, 15px, 15px, auto, 14px, center);
                @include margin(7px);
                cursor: pointer;
            }

            >.delete {
                @include image($icon-delete, 12px, 15px, auto, 14px, center);
                @include margin(7px);
                cursor: pointer;
            }
        }
    }
}

@mixin user-card {
    .user-card {
        position: relative;
        display: flex;
        text-align: center;

        >img {
            width: 70%;
            border-radius: 50%;
            border: 1px solid $c-grey-line;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }

        >.data {
            position: absolute;
            width: 100%;

            >.name {
                @include margin(15px 0 0 0);
                text-align: center;
                @include font-basic-semi-bold($c-black-light, 1rem);
                line-height: 1.2rem;
            }

            >.title {
                @include margin(8px 0 0 0);
                text-align: center;
                @include font-basic($c-blue, $fs-basic);
            }

            >.mail {
                @include margin(7px 0 0 0);
                text-align: center;
                width: 100%;
                @include font-basic($c-grey, $fs-basic);
                line-height: 1.2rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                cursor: copy;

                &:hover {
                    color: $c-blue;
                }
            }
        }

        &:hover {
            >.settings {
                display: flex;
            }
        }

        >.settings {
            top: 0px;
            right: 7px;
            position: absolute;
            display: none;
            background-color: $c-white;
            box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
            @include padding(0px 5px);
            border-radius: 5px;
            z-index: 1;
            cursor: initial;

            >.edit {
                @include image($icon-edit, 15px, 15px, auto, 14px, center);
                @include margin(7px);
                cursor: pointer;
            }

            >.delete {
                @include image($icon-delete, 12px, 15px, auto, 14px, center);
                @include margin(7px);
                cursor: pointer;
            }
        }
    }

    .user-card-inactive {
        >img {
            opacity: 0.5;
        }
    }
}

@mixin supplier-card {
    .user-card {
        position: relative;
        display: flex;

        >img {
            width: 100%;
            border-radius: 50%;
            border: 1px solid $c-grey-line;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }

        >.data {
            position: absolute;
            width: 100%;

            >.name {
                @include margin(15px 0 0 0);
                text-align: center;
                @include font-basic-semi-bold($c-black-light, 1rem);
            }

            >.title {
                @include margin(8px 0 0 0);
                text-align: center;
                @include font-basic($c-blue, $fs-basic);
            }

            >.mail {
                @include margin(7px 0 0 0);
                text-align: center;
                @include font-basic($c-grey, $fs-basic);
            }
        }

        &:hover {
            >.settings {
                display: flex;
            }
        }

        >.settings {
            top: -15px;
            right: -15px;
            position: absolute;
            display: none;
            background-color: $c-white;
            box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
            @include padding(0px 5px);
            border-radius: 5px;
            z-index: 1;
            cursor: initial;

            >.edit {
                @include image($icon-edit, 15px, 15px, auto, 14px, center);
                @include margin(7px);
                cursor: pointer;
            }

            >.delete {
                @include image($icon-delete, 12px, 15px, auto, 14px, center);
                @include margin(7px);
                cursor: pointer;
            }
        }
    }

    .user-card-inactive {
        >img {
            opacity: 0.5;
        }
    }
}

@mixin offer-agreement-card {
    .offer-agreement-card {
        display: flex;

        >.card-content {
            cursor: pointer;
            position: relative;
            background-color: #f7fbfc;
            border: 1px solid $c-grey-line;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
            border-radius: 15px;
            width: calc(100% - 30px);
            padding: 15px;
            height: 165px;

            &:hover {
                border-color: $c-blue;
            }

            >.id {
                @include font-basic-bold($c-blue, 1.5rem);
                @include margin(0 0 15px 0);
            }

            >.date {
                @include font-basic-bold($c-black, 1rem);
                line-height: 1.2rem;
                @include margin(5px 0 0 0);
            }

            >.details {
                @include margin(20px 0 0 0);
                @include font-basic-semi-bold($c-black-light, $fs-basic);

                >* {
                    @include margin(5px 0 0 0);
                }
            }

            >.status {
                position: absolute;
                right: 15px;
                top: 15px;
                background-color: $c-blue;
                border-radius: 3px;
                @include padding(5px 10px 5px 10px);
                @include font-basic-semi-bold($c-white, 1rem);
            }

            >.status-draft {
                background-color: $c-status-draft;
            }

            >.status-review {
                background-color: $c-status-review;
            }

            >.status-approved {
                background-color: $c-status-approved;
            }

            >.status-rejected {
                background-color: $c-status-blocked;
            }

            >.status-pendingApproval {
                background-color: $c-status-renegotiation;
            }

            >.status-expired {
                background-color: $c-status-expired;
            }

            >.status-blocked {
                background-color: $c-status-blocked;
            }

            >.status-cancelled {
                background-color: $c-status-blocked;
            }
        }
    }
}

@mixin survey-card {
    .survey-card {
        display: flex;

        >.card-content {
            cursor: pointer;
            position: relative;
            background-color: #f7fbfc;
            border: 1px solid $c-grey-line;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
            border-radius: 15px;
            width: calc(100% - 30px);
            padding: 15px;
            height: 145px;

            &:hover {
                border-color: $c-blue;
            }

            >.id {
                @include font-basic-bold($c-blue, 1.5rem);
                @include margin(0 0 15px 0);
            }

            >.date {
                @include font-basic-bold($c-black, 1rem);
                line-height: 1.2rem;
                @include margin(5px 0 0 0);
                word-break: break-word;
            }

            >.details {
                @include margin(20px 0 0 0);
                @include font-basic-semi-bold($c-black-light, $fs-basic);
                top: 60%;
                position: absolute;

                >* {
                    @include margin(5px 0 0 0);
                }
            }

            >.status {
                position: absolute;
                right: 15px;
                top: 15px;
                background-color: $c-blue;
                border-radius: 3px;
                @include padding(5px 10px 5px 10px);
                @include font-basic-semi-bold($c-white, 1rem);
            }

            >.status-draft {
                background-color: $c-status-draft;
            }

            >.status-published {
                background-color: $c-status-published;
            }

            >.status-responses {
                background-color: $c-status-responses;
            }

            >.status-expired {
                background-color: $c-status-expired;
            }
        }
    }
}

@mixin roadshow-planning-card {
    .roadshow-planning-card {
        display: flex;

        >.card-content {
            cursor: pointer;
            position: relative;
            background-color: $c-white;
            border: 1px solid $c-grey-line;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
            border-radius: 15px;
            width: calc(100% - 30px);
            padding: 15px;
            height: 7rem;

            &:hover {
                box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.12);
            }

            >.title {
                @include font-basic-bold($c-black, 1.3rem);
                line-height: 1.4rem;
                @include margin-top(20px);
                height: 40px;
                display: flex;
                align-items: center;
                word-break: break-word;
            }

            >.date {
                @include font-basic-bold($c-black, 0.9rem);
                @include margin-top(10px);
            }

            >.locations {
                display: flex;
                align-items: baseline;
                @include font-basic-bold($c-blue, 0.9rem);
                @include margin-top(5px);

                >.location {
                    margin-right: 5px;

                    &.ellipsis {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }

                >.more {
                    @include font-basic-bold($c-grey, 0.8rem);
                }
            }

            >.invitations {
                @include margin(15px 0 0 0);
                @include font-basic-semi-bold($c-black, $fs-basic);

                >.invited {
                    @include margin(0 0 6px 0);
                }

                >.review-renegotiation {
                    @include margin(0 0 6px 0);

                    >.review {
                        display: inline-block;
                    }

                    >.renegotiation {
                        display: inline-block;
                        @include margin(0 0 0 15px);
                    }
                }

                >.approved-rejected {
                    >.approved {
                        display: inline-block;
                        color: $c-blue;
                    }

                    >.rejected {
                        @include margin(0 0 0 15px);
                        display: inline-block;
                        color: $c-red;
                    }
                }
            }

            >.offers {
                @include margin(15px 0 0 0);
                @include font-basic-semi-bold($c-black, $fs-basic);

                >.count {
                    @include font-basic-bold($c-black, $fs-basic);
                }
            }

            >.status {
                position: absolute;
                top: -1px;
                left: -1px;
                @include padding(8px 15px 5px 15px);
                background-color: $c-blue;
                @include font-basic-semi-bold($c-white, $fs-basic);
                border-top-left-radius: 15px;
                border-bottom-right-radius: 8px;
            }

            >.status-draft {
                background-color: #98a6b9;
            }

            >.status-submitted {
                background-color: $c-blue;
            }

            >.status-approved {
                background-color: $c-green;
            }

            >.status-confirmed {
                background-color: $c-green;
            }

            >.status-published {
                background-color: $c-green;
            }

            >.status-rejected {
                background-color: $c-red;
            }

            >.status-approved {
                background-color: $c-green;
            }

            >.status-expired {
                background-color: $c-grey;
            }
        }
    }
}

@mixin roadshow-proposal-card {
    .roadshow-proposal-card {
        display: flex;

        >.card-content {
            cursor: pointer;
            position: relative;
            background-color: $c-white;
            border: 1px solid $c-grey-line;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
            border-radius: 15px;
            width: calc(100% - 30px);
            padding: 15px;

            &:hover {
                border-color: $c-blue;
            }

            >.title {
                @include font-basic-bold($c-black, 1.3rem);
                line-height: 1.4rem;
                @include margin-top(20px);
                height: 40px;
                display: flex;
                align-items: center;
            }

            >.date {
                @include font-basic-bold($c-black, 0.9rem);
                @include margin-top(10px);
            }

            >.id {
                @include font-basic-bold($c-blue, 0.9rem);
                @include margin-top(5px);
            }

            >.offers {
                @include margin(15px 0 0 0);
                @include font-basic-semi-bold($c-black, $fs-basic);

                >.count {
                    @include font-basic-bold($c-black, $fs-basic);
                }
            }

            >.status {
                position: absolute;
                top: -1px;
                left: -1px;
                @include padding(7px 15px);
                background-color: $c-blue;
                @include font-basic-semi-bold($c-white, $fs-basic);
                border-top-left-radius: 15px;
                border-bottom-right-radius: 8px;
            }

            >.status-draft {
                background-color: #98a6b9;
            }

            >.status-active {
                background-color: $c-blue;
            }

            >.status-expired {
                background-color: $c-red;
            }

            >.status-deactivated {
                background-color: #6b6b6b;
            }
        }
    }
}

@mixin category-card-small {
    .category-card-small {
        @include margin(0 10px 15px 10px);
        display: inline-block;
        @include padding(6px 20px);
        @include font-basic-bold($c-black-light, $fs-basic);
        border: 1px solid $c-grey-line;
        border-radius: $radius-btn;
        cursor: pointer;

        &:hover {
            color: $c-grey;
        }
    }
}

@mixin tag-card {
    .tag-card {
        position: relative;
        @include margin(0 15px 20px 0);
        display: inline-block;
        @include padding(9px 20px);
        @include font-basic-bold($c-black-light, $fs-basic);
        border: 1px solid $c-grey-line;
        border-radius: $radius-btn;
        cursor: pointer;

        &:hover {
            color: $c-grey;

            >.settings {
                display: flex;
            }
        }
    }
}

@mixin dashboard-card {
    .dashboard-card {
        background-color: $c-white;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
        border-radius: 15px;
        cursor: pointer;

        &:hover {
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.16);
        }

        .dashboard-card_title {
            @include margin(20px 0 0 20px);
            @include font-basic-bold($c-black-light, 1.2rem);
        }

        .dashboard-card_subtitle {
            @include margin(6px 0 0 20px);
            @include font-basic-semi-bold($c-grey, 0.9rem);
        }

        .dashboard-card_counter {
            @include margin(20px 0 20px 20px);
            padding-left: 34px;
            padding-top: 7px;
            line-height: 24px;
            @include font-basic-bold($c-blue, 1.3rem);
        }

        .dashboard-icon-banners {
            @include image($icon-dashboard-banners, auto, 27px, auto, 22px, center left);
        }

        .dashboard-icon-offers {
            @include image($icon-dashboard-offers, auto, 27px, auto, 22px, center left);
        }

        .dashboard-icon-roadshow {
            @include image($icon-dashboard-roadshow, auto, 27px, auto, 22px, center left);
        }

        .dashboard-icon-tags {
            @include image($icon-dashboard-tags, auto, 27px, auto, 22px, center left);
        }

        .dashboard-icon-users {
            @include image($icon-dashboard-users, auto, 27px, auto, 22px, center left);
        }

        .dashboard-icon-agreements {
            @include image($icon-dashboard-agreements, auto, 27px, auto, 22px, center left);
        }

        .dashboard-icon-proposals {
            @include image($icon-dashboard-proposals, auto, 27px, auto, 22px, center left);
        }

        .dashboard-icon-categories {
            @include image($icon-dashboard-categories, auto, 27px, auto, 22px, center left);
        }

        .dashboard-icon-suppliers {
            @include image($icon-dashboard-supplies, auto, 27px, auto, 22px, center left);
        }

        .dashboard-icon-collections {
            @include image($icon-dashboard-collections, auto, 27px, auto, 22px, center left);
        }

        .dashboard-icon-offers-rating {
            @include image($icon-dashboard-offers-rating, auto, 27px, auto, 22px, center left);
        }
    }
}

@mixin analytics-card {
    .analytics-card {
        background-color: $c-white;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
        border-radius: 15px;
        padding: 20px 0 30px 0;

        &__title {
            margin-left: 20px;
            @include font-basic-bold($c-black-light, 1.2rem);
        }

        &__txt {
            margin-top: 6px;
            margin-left: 20px;
            @include font-basic-semi-bold($c-grey, 0.9rem);
        }

        &__download {
            margin-top: 10px;
            margin-left: 20px;
            display: inline-flex;
            align-items: center;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }

        &__link {
            @include font-basic-semi-bold($c-blue, 0.9rem);
        }

        &__d-icon {
            border-radius: 3px;
            background-color: $c-blue;
            margin-left: 5px;
            padding: 2px;
            @include font-basic-semi-bold($c-white, 0.7rem);
        }
    }
}

// 1.7. Filters
@mixin filter {
    .filter_box {
        width: 300px;
        margin-right: 50px;

        .filter_box_modal {
            width: 100%;

            .filter_box_modal_header {
                display: none;
            }

            .filter_box_modal_content {
                width: 100%;

                .search-keyword {
                    margin-top: 3px;
                    display: flex;
                    height: 48px;
                    align-items: center;
                    @include padding(0 20px);
                    background-color: $c-white;
                    border: none;
                    border-radius: 26px;
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
                    margin-bottom: 10px;

                    >.search-icon {
                        @include image($icon-search-0, 18px, 28px, 17px, auto, center);
                        @include margin(0 15px 0 0);
                        cursor: pointer;

                        &:hover {
                            @include image($icon-search-1, 18px, 28px, 17px, auto, center);
                        }
                    }

                    >.search-input {
                        width: 100%;
                        @include font-basic($c-grey, $fs-basic);
                        line-height: 1.3rem;
                        border: none;

                        &::placeholder {
                            line-height: 1.3rem !important;
                        }
                    }
                }

                .search-criterion {
                    >.criterion-header {
                        @include image($icon-search-arrow-0, 100%, 100%, auto, 9px, center right 3px);
                        @include font-basic-bold($c-black-light, 1.25rem);
                        border-bottom: 1px solid $c-grey-line;
                        @include padding(20px 0);
                        cursor: pointer;

                        &:hover {
                            color: $c-blue;
                            @include image($icon-search-arrow-2, 100%, 100%, auto, 9px, center right 3px);
                        }
                    }

                    >.criterion-options {
                        display: none;
                        @include padding(0 0 15px 0);
                        border-bottom: 1px solid $c-grey-line;
                        @include scroll-vertical();

                        >.option {
                            @include padding(10px 0 0 0);
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            >.f-checkbox {
                                margin: 0;

                                label {
                                    @include font-basic($c-black-light, $fs-basic);

                                    &:hover {
                                        color: $c-blue;
                                    }

                                    >.stars {
                                        position: relative;
                                        display: inline-block;
                                        margin: 0 10px;
                                        @include image($icon-stars-empty, 78px, 14px, auto, 13px, center left);

                                        >.stars-positive {
                                            position: absolute;
                                            @include image($icon-stars-full, 0%, 14px, auto, 13px, center left);
                                        }
                                    }
                                }
                            }

                            >.counter {
                                @include font-basic($c-grey, $fs-basic);
                            }

                            &:first-of-type {
                                padding: 0;
                            }
                        }
                    }

                    &:first-of-type {
                        >.criterion-header {
                            padding-top: 0;
                        }
                    }
                }

                .search-criterion-extended {
                    >.criterion-header {
                        @include image($icon-search-arrow-1, 100%, 100%, auto, 9px, center right 3px);
                        border: none;

                        &:hover {
                            @include image($icon-search-arrow-3, 100%, 100%, auto, 9px, center right 3px);
                        }
                    }

                    >.criterion-options {
                        display: block;
                        max-height: 190px;
                        overflow-y: auto;
                    }
                }

                .reset {
                    @include margin-top(10px);
                    text-align: right;

                    >.collapse-btn {
                        display: inline-block;
                        padding-top: 3px;
                        margin-right: 7px;
                        padding-right: 7px;
                        border-right: 2px solid $c-blue;
                        @include font-basic-semi-bold($c-blue, 0.9rem);
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }
                    }

                    >.reset-btn {
                        display: inline-block;
                        @include font-basic-semi-bold($c-blue, 0.9rem);
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }

            .filter_box_modal_footer {
                display: none;
            }
        }

        @include respSpecif(1000) {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 999;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(236, 241, 250, 0.7);

            .filter_box_modal {
                max-width: 600px;
                min-width: 500px;
                margin: 0 30px;
                display: flex;
                flex-direction: column;
                max-height: calc(100vh - 30px);
                min-height: 150px;
                background-color: #fff;
                padding: 0 30px;
                border-radius: 10px;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);

                .filter_box_modal_header {
                    display: block;
                    @include font-basic-bold($c-blue, 1rem);
                    @include padding(20px 0 15px 0);
                    border-bottom: 1px solid $c-grey-line;
                }

                .filter_box_modal_content {
                    @include margin(30px 0);
                    overflow-x: auto;
                    padding-right: 10px;
                    padding-left: 3px;
                    @include scroll-vertical();
                    max-height: calc(50vh - 50px);

                    .criterion-options {
                        display: block;
                        max-height: 1000px !important;
                        overflow-y: auto;
                    }

                    .reset {
                        display: none;
                    }
                }

                .filter_box_modal_footer {
                    display: block;
                    @include padding(0 0 20px 0);
                    display: flex;
                    justify-content: flex-end;

                    >* {
                        @include margin(0 0 0 10px);
                    }
                }
            }
        }
    }

    .filter_box-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(236, 241, 250, 0.7);

        .filter_box_modal {
            max-width: 600px;
            min-width: 500px;
            margin: 0 30px;
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 30px);
            min-height: 150px;
            background-color: #fff;
            padding: 0 30px;
            border-radius: 10px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);

            .filter_box_modal_header {
                display: block;
                @include font-basic-bold($c-blue, 1rem);
                @include padding(20px 0 15px 0);
                border-bottom: 1px solid $c-grey-line;
            }

            .filter_box_modal_content {
                @include margin(30px 0);
                overflow-x: auto;
                padding-right: 10px;
                padding-left: 3px;
                @include scroll-vertical();
                max-height: calc(50vh - 50px);

                .reset {
                    display: none;
                }
            }

            .filter_box_modal_footer {
                display: block;
                @include padding(0 0 20px 0);
                display: flex;
                justify-content: flex-end;

                >* {
                    @include margin(0 0 0 10px);
                }
            }
        }
    }
}

// TO DO: Obrisati
@mixin search {
    @include margin(0 50px 0 0);

    .search-keyword {
        display: flex;
        height: 48px;
        align-items: center;
        @include padding(0 20px);
        background-color: $c-white;
        border: none;
        border-radius: 26px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
        margin-bottom: 10px;

        >.search-icon {
            @include image($icon-search-0, 18px, 28px, 17px, auto, center);
            @include margin(0 15px 0 0);
            cursor: pointer;

            &:hover {
                @include image($icon-search-1, 18px, 28px, 17px, auto, center);
            }
        }

        >.search-input {
            width: 100%;
            @include font-basic($c-grey, $fs-basic);
            border: none;
        }
    }

    .search-criterion {
        >.criterion-header {
            @include image($icon-search-arrow-0, 100%, 100%, auto, 9px, center right 3px);
            @include font-basic-bold($c-black-light, 1.25rem);
            border-bottom: 1px solid $c-grey-line;
            @include padding(20px 0);
            cursor: pointer;

            &:hover {
                color: $c-blue;
                @include image($icon-search-arrow-2, 100%, 100%, auto, 9px, center right 3px);
            }
        }

        >.criterion-options {
            display: none;
            @include padding(0 0 15px 0);
            border-bottom: 1px solid $c-grey-line;
            @include scroll-vertical();
            max-height: 184px;
            overflow: auto;

            >.option {
                @include padding(10px 0 0 0);
                display: flex;
                align-items: center;
                justify-content: space-between;

                >.f-checkbox {
                    margin: 0;

                    label {
                        @include font-basic($c-black-light, $fs-basic);

                        &:hover {
                            color: $c-blue;
                        }

                        >.stars {
                            position: relative;
                            display: inline-block;
                            margin: 0 10px;
                            @include image($icon-stars-empty, 78px, 14px, auto, 13px, center left);

                            >.stars-positive {
                                position: absolute;
                                @include image($icon-stars-full, 0%, 14px, auto, 13px, center left);
                            }
                        }
                    }
                }

                >.counter {
                    @include font-basic($c-grey, $fs-basic);
                }

                &:first-of-type {
                    padding: 0;
                }
            }
        }

        &:first-of-type {
            >.criterion-header {
                padding-top: 0;
            }
        }
    }

    .search-criterion-extended {
        >.criterion-header {
            @include image($icon-search-arrow-1, 100%, 100%, auto, 9px, center right 3px);
            border: none;

            &:hover {
                @include image($icon-search-arrow-3, 100%, 100%, auto, 9px, center right 3px);
            }
        }

        >.criterion-options {
            display: block;
        }
    }

    .reset {
        @include margin-top(10px);
        text-align: right;

        >.reset-btn {
            display: inline-block;
            @include font-basic-semi-bold($c-blue, 0.9rem);
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

// 1.8. Settings
.settings {
    top: -23px;
    right: -30px;
    position: absolute;
    display: none;
    background-color: $c-white;
    box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
    @include padding(0px 5px);
    border-radius: 5px;
    z-index: 1;
    cursor: initial;

    >.edit {
        @include image($icon-edit, 15px, 15px, auto, 14px, center);
        @include margin(7px);
        cursor: pointer;
    }

    >.delete {
        @include image($icon-delete, 12px, 15px, auto, 14px, center);
        @include margin(7px);
        cursor: pointer;
    }
}

// 1.9. Notifications
.notif-container {
    position: fixed;
    z-index: 1000;
    bottom: 30px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @mixin notif() {
        width: 250px;
        padding: 15px 17px;
        border-radius: 14px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .notif-content {
            width: calc(100% - 20px);

            .title {
                @include font-basic-semi-bold($c-white, 1rem);
                margin-bottom: 5px;
            }

            .text {
                @include font-basic($c-white, $fs-basic);
                line-height: 1.1rem;
                word-break: break-word;
            }
        }
    }

    .notif-sucess {
        @include notif();
        background-color: $c-blue;

        .notif-icon {
            @include image($icon-notif-sucess, 30px, 30px, 25px, auto, center);
            margin-right: 10px;
        }
    }

    .notif-error {
        @include notif();
        background-color: $c-red;

        .notif-icon {
            @include image($icon-notif-error, 30px, 30px, 25px, auto, center);
            margin-right: 10px;
        }
    }

    .notif-warning {
        @include notif();
        background-color: #9c9c9c;

        .notif-icon {
            @include image($icon-notif-warning, 30px, 30px, 25px, auto, center);
            margin-right: 10px;
        }
    }

    .notif-info {
        @include notif();
        background-color: $c-white;
        border: 1px solid #e4e4e4;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);

        .notif-content {
            .title {
                color: $c-black-light;
            }

            .text {
                color: $c-grey;
            }
        }

        .notif-icon {
            @include image($icon-notif-info, 30px, 30px, 25px, auto, center);
            margin-right: 10px;
        }
    }

    .notif-refresh {
        @include notif();
        background-color: $c-white;
        border: 1px solid #e4e4e4;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);

        .notif-content {
            .title {
                color: $c-black-light;
            }

            .text {
                color: $c-grey;
            }
        }

        .notif-icon {
            @include image($icon-notif-info, 30px, 30px, 25px, auto, center);
            margin-right: 10px;
        }
    }
}

// 1.10. Workflow
.workflow_diagram {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &_states {
        display: flex;

        &_state {
            >* {
                @include font-basic($c-grey, 1rem);
            }

            &-draft {
                @include image($icon-progress-draft-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;

                &-active {
                    @include image($icon-progress-draft-1, 95px, 125px, auto, 94px, top center);
                    color: $c-green;
                }

                @include respSpecif(1000) {
                    background-size: auto 80px;
                    height: 110px;
                    width: 81px;
                }
            }

            &-review {
                @include image($icon-progress-review-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;

                &-active {
                    @include image($icon-progress-review-1, 95px, 125px, auto, 94px, top center);
                    color: $c-green;
                }

                @include respSpecif(1000) {
                    background-size: auto 80px;
                    height: 110px;
                    width: 81px;
                }
            }

            &-approved {
                @include image($icon-progress-approved-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;

                &-active {
                    @include image($icon-progress-approved-1, 95px, 125px, auto, 94px, top center);
                    color: $c-green;
                }

                @include respSpecif(1000) {
                    background-size: auto 80px;
                    height: 110px;
                    width: 81px;
                }
            }

            &-published {
                @include image($icon-progress-published-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;

                &-active {
                    @include image($icon-progress-published-1, 95px, 125px, auto, 94px, top center);
                    color: $c-green;
                }

                @include respSpecif(1000) {
                    background-size: auto 80px;
                    height: 110px;
                    width: 81px;
                }
            }

            &-submitted {
                @include image($icon-progress-submitted-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;

                &-active {
                    @include image($icon-progress-submitted-1, 95px, 125px, auto, 94px, top center);
                    color: $c-green;
                }

                @include respSpecif(1000) {
                    background-size: auto 80px;
                    height: 110px;
                    width: 81px;
                }
            }

            &-approved {
                @include image($icon-progress-approved-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;

                &-active {
                    @include image($icon-progress-approved-1, 95px, 125px, auto, 94px, top center);
                    color: $c-green;
                }

                @include respSpecif(1000) {
                    background-size: auto 80px;
                    height: 110px;
                    width: 81px;
                }
            }

            &-confirmed {
                @include image($icon-progress-confirmed-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;

                &-active {
                    @include image($icon-progress-confirmed-1, 95px, 125px, auto, 94px, top center);
                    color: $c-green;
                }

                @include respSpecif(1000) {
                    background-size: auto 80px;
                    height: 110px;
                    width: 81px;
                }
            }

            &-closed {
                @include image($icon-progress-closed-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;

                &-active {
                    @include image($icon-progress-closed-1, 95px, 125px, auto, 94px, top center);
                    color: $c-green;
                }

                @include respSpecif(1000) {
                    background-size: auto 80px;
                    height: 110px;
                    width: 81px;
                }
            }




            &-accepted {
                @include image($icon-progress-accepted-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;

                &-active {
                    @include image($icon-progress-accepted-1, 95px, 125px, auto, 94px, top center);
                    color: $c-green;
                }

                @include respSpecif(1000) {
                    background-size: auto 80px;
                    height: 110px;
                    width: 81px;
                }
            }

            &-scheduled {
                @include image($icon-scheduled-progres-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;

                &-active {
                    @include image($icon-scheduled-progres-1, 95px, 125px, auto, 94px, top center);
                    color: $c-green;
                }

                @include respSpecif(1000) {
                    background-size: auto 80px;
                    height: 110px;
                    width: 81px;
                }

            }

            &-invited {
                @include image($icon-progress-invited-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;

                &-active {
                    @include image($icon-progress-invited-1, 95px, 125px, auto, 94px, top center);
                    color: $c-green;
                }

                @include respSpecif(1000) {
                    background-size: auto 80px;
                    height: 110px;
                    width: 81px;
                }
            }

            &-blocked {
                @include image($icon-progress-blocked-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;

                &-active {
                    @include image($icon-progress-blocked-1, 95px, 125px, auto, 94px, top center);
                    color: $c-red;
                }

                @include respSpecif(1000) {
                    background-size: auto 80px;
                    height: 110px;
                    width: 81px;
                }
            }

            &-expired {
                @include image($icon-progress-expired-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;

                &-active {
                    @include image($icon-progress-expired-1, 95px, 125px, auto, 94px, top center);
                    color: $c-red;
                }

                @include respSpecif(1000) {
                    background-size: auto 80px;
                    height: 110px;
                    width: 81px;
                }
            }

            &-deactivated {
                @include image($icon-progress-deactivated-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;

                &-active {
                    @include image($icon-progress-deactivated-1, 95px, 125px, auto, 94px, top center);
                    color: $c-red;
                }

                @include respSpecif(1000) {
                    background-size: auto 80px;
                    height: 110px;
                    width: 81px;
                }
            }

            &-rejected {
                @include image($icon-progress-rejected-1, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;

                &-active {
                    @include image($icon-progress-rejected-1, 95px, 125px, auto, 94px, top center);
                    color: $c-red;
                }

                @include respSpecif(1000) {
                    background-size: auto 80px;
                    height: 110px;
                    width: 81px;
                }
            }

            &-returned {
                @include image($icon-progress-returned-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;

                &-active {
                    @include image($icon-progress-returned-1, 95px, 125px, auto, 94px, top center);
                    color: $c-green;
                }

                @include respSpecif(1000) {
                    background-size: auto 80px;
                    height: 110px;
                    width: 81px;
                }
            }

            &-union {
                @include image($icon-progress-union-0, 180px, 125px, auto, 14px, top 40px center);

                &-active {
                    @include image($icon-progress-union-1, 180px, 125px, auto, 14px, top 40px center);
                }

                @include respSpecif(1200) {
                    width: 130px;
                }

                @include respSpecif(1000) {
                    width: 80px;
                    height: 110px;
                    background-position: top 35px center;
                }
            }
        }
    }

    &_data {
        position: absolute;
        left: 0;
        top: 0;

        &_id {
            @include margin-top(5px);
            @include font-basic-semi-bold($c-blue, 1.5rem);
        }

        &_status {
            @include font-basic-semi-bold($c-black-light, 1.5rem);
        }

        @include respSpecif(800) {
            display: none;
        }
    }
}

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

// 2. Layoyt

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

.layout {
    position: relative;
    min-height: calc(100vh - 50px);
    background-image: url("/assets/images/backgrounds/shape_background.svg");
    background-size: 100% auto;
    background-repeat: repeat-y;
    padding-bottom: 50px;

    @-ms-viewport {
        width: 100vw;
    }
}

.hidden {
    display: none !important;
}

// 2.1. Navigation
.navigation-container {
    background-color: $c-cream;
    box-sizing: border-box;

    >.navigation-basic {
        background-color: $c-white;
        border-top: 8px solid $c-blue;
        box-shadow: $shadow-navbar;

        >.content {
            @include container();
            height: 90px;
            display: flex;
            align-items: center;

            >.user {
                >.user-img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    cursor: pointer;
                }

                .dropdown-user {
                    @include dropdownn(left, -10px, 18px);
                }
            }

            >.nav-min {
                display: none;
            }

            >.break {
                height: 50px;
                width: 1px;
                background-color: $c-grey-line;
                @include margin(0 30px);
            }

            >.nav-items {
                display: flex;
                width: 100%;
                flex: 1 1 auto;

                >.nav-item {
                    display: inline-block;
                    @include margin(0 15px);
                    padding-top: 5px;
                    @include font-basic($c-grey, $fs-basic);
                    cursor: pointer;

                    &:hover {
                        color: $c-blue;
                    }
                }

                >.is-active {
                    @include font-basic-semi-bold($c-blue, $fs-basic);
                }
            }

            >.nav-item-admin {
                >.nav-item {
                    @include margin(0 5px 0 20px, 0 0 0 20px);
                }
            }

            @include respSpecif(1230) {
                >.nav-item-admin {

                    .nav-item-categories,
                    .nav-item-collections {
                        display: none;
                    }
                }
            }

            >.search-container {
                display: flex;
                width: 400px;
                height: 48px;
                align-items: center;
                @include padding(0 20px);
                background-color: $c-white;
                border: none;
                border-radius: 26px;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);

                >.search-icon {
                    @include image($icon-search-0, 25px, 28px, 17px, auto, center);
                    @include margin(0 5px 0 0);
                    cursor: pointer;

                    &:hover {
                        @include image($icon-search-1, 25px, 28px, 17px, auto, center);
                    }
                }

                >.search-input {
                    width: 100%;
                    @include font-basic($c-grey, $fs-basic);
                    line-height: 1.3rem;
                    border: none;
                }

                .search-input::placeholder {
                    line-height: 1.3rem;
                }

                >.search-reset {
                    @include image($icon-searchReset-0, 15px, 15px, 10px, auto, center);
                    margin-left: 5px;
                    cursor: pointer;

                    &:hover {
                        @include image($icon-searchReset-1, 15px, 15px, 10px, auto, center);
                    }
                }
            }

            >.logo-offers {
                cursor: pointer;
                @include image($img-logo-mazaya-0, 60px, 50px, 38px, auto, center left);
                min-width: 60px;
                @include margin(0 0 -5px 0);

                &:hover {
                    @include image($img-logo-mazaya-1, 60px, 50px, 38px, auto, center left);
                }
            }

            .notifications {
                >.notification-btn {
                    position: relative;
                    @include image($icon-notifications, 28px, 28px, auto, 28px, center left);
                    min-width: 28px;
                    cursor: pointer;

                    >.counter {
                        position: absolute;
                        top: -5px;
                        left: 10px;
                        background-color: $c-blue;
                        @include padding(3px 6px);
                        @include font-basic-semi-bold($c-white, 0.5625rem, 0.5625rem, 0.5625rem, 0.5625rem);
                        border-radius: 9px;
                    }
                }
            }

            .mazaya-cart {
                >.mazaya-cart-btn {
                    position: relative;
                    @include image($icon-cart, 28px, 28px, auto, 28px, center left);
                    min-width: 28px;
                    cursor: pointer;
                    left: 22px;

                    >.counter {
                        position: absolute;
                        top: -5px;
                        left: 10px;
                        background-color: $c-blue;
                        @include padding(3px 6px);
                        @include font-basic-semi-bold($c-white, 0.5625rem, 0.5625rem, 0.5625rem, 0.5625rem);
                        border-radius: 9px;
                    }
                }
            }
        }
    }

    >.navigation-sub {
        @include height(72px);
        @include container();

        >.content {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;

            >.home {
                @include image($icon-home, 56.18px, 50px, 23px, auto, center);
                min-width: 23px;
                margin-right: 61px;
                cursor: pointer;
            }

            >.left-scroll {
                display: none;
            }

            >.right-scroll {
                display: none;
            }

            >.nav-sub-items {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                width: 100%;

                >.nav-sub-item {
                    @include margin(0 20px);
                    @include font-basic($c-grey, $fs-basic);
                    line-height: 64px;
                    border-top: 4px solid transparent;
                    border-bottom: 4px solid transparent;
                    cursor: pointer;
                    white-space: nowrap;
                    min-width: 0;

                    &:hover {
                        color: $c-blue;
                    }
                }

                >.nav-sub-item-active {
                    border-bottom: 4px solid $c-blue;
                    @include font-basic-semi-bold($c-blue, $fs-basic);
                }
            }
        }
    }

    .navigation-sub--resp {


        >.content {
            @include respSpecif(1300) {
                .home {
                    display: none;
                }
            }


            >.nav-sub-items {
                overflow-x: hidden;
                outline: 0;

                >.nav-sub-item {
                    display: inline-block;
                    flex: 0 0 auto;

                    &:hover {
                        color: $c-blue;
                    }
                }
            }

            >.left-scroll {
                display: inline-block;
                @include image($img-navbar-sub-arrowLeft, 40px, 20px, 12px, auto, left center);
                outline: none;
                cursor: pointer;
            }

            >.right-scroll {
                display: inline-block;
                @include image($img-navbar-sub-arrowRight, 40px, 20px, 12px, auto, right center);
                outline: none;
                cursor: pointer;
            }
        }
    }

    // Responsive
    @include resp(L) {
        >.navigation-basic {
            >.content {
                >.user {
                    display: none;
                }

                >.nav-min {
                    display: inline-block;
                    width: 52px;
                    cursor: pointer;

                    >.nav-img {
                        @include image($img-navbar-hamb-0, null, 52px, 33px, auto, center);
                        min-width: 33px;

                        &:hover {
                            @include image($img-navbar-hamb-1, null, 52px, 33px, auto, center);
                        }
                    }

                    >.dropdown-nav {
                        display: block;
                        @include dropdownn(left, -15px, 4px);

                        >.dropdown-list {
                            width: 120px;
                        }
                    }
                }

                >.nav-items {
                    display: none;
                }

                >.search-container {
                    width: 677px;
                }
            }
        }

        >.navigation-sub {
            >.content {
                >.home {
                    display: none;
                }

                >.nav-sub-items {
                    overflow-x: hidden;
                    outline: 0;

                    >.nav-sub-item {
                        display: inline-block;
                        flex: 0 0 auto;

                        &:hover {
                            color: $c-blue;
                        }
                    }
                }

                >.left-scroll {
                    display: inline-block;
                    @include image($img-navbar-sub-arrowLeft, 40px, 20px, 12px, auto, left center);
                    outline: none;
                    cursor: pointer;
                }

                >.right-scroll {
                    display: inline-block;
                    @include image($img-navbar-sub-arrowRight, 40px, 20px, 12px, auto, right center);
                    outline: none;
                    cursor: pointer;
                }
            }
        }
    }
}

// 2.2. Navigation
.notification {
    position: absolute;
    z-index: 50;
    top: 46px;
    width: 350px;
    left: -302px;
    background-color: $c-white;
    box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
    border-radius: 10px;

    &::after {
        position: absolute;
        content: "";
        top: -8px;
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 8px solid white;
        z-index: 0;

        right: 27px;
    }

    &::before {
        position: absolute;
        content: "";
        width: 12px;
        height: 12px;
        background-color: white;
        top: -7px;
        transform: rotate(45deg);
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
        z-index: -1;
        right: 30px;
    }

    >.notification-header {
        display: flex;
        height: 20px;
        padding: 15px;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $c-grey-line;
        background-color: white;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        >.title {
            @include font-basic-semi-bold($c-black, $fs-basic);
        }

        >.see-all {
            @include font-basic($c-blue, 0.8rem);
        }
    }

    >.notification-content {
        max-height: 50vh;
        overflow-y: auto;
        @include scroll-vertical();

        >.notification-item {
            @include padding(15px);
            border-bottom: 1px solid #e8e8e8;
            cursor: pointer;

            &:hover {
                background-color: #f8f8f8;
            }

            &:last-of-type {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            >.item-header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                >.title {
                    @include font-basic-semi-bold(#4c4c4c, $fs-basic);
                }

                >.time {
                    @include font-basic($c-grey-light, 0.8rem);
                }
            }

            >.item-content {
                @include margin(15px 0 0 0);
                @include font-basic($c-grey, $fs-basic);
                line-height: 1.1rem;
            }
        }
    }
}

// 2.3. Footer
.footer-container {
    @include margin(80px 0);

    >.footer {
        position: absolute;
        width: 100%;
        bottom: 35px;

        >.content {
            @include container-section();
            @include font-basic($c-blue, 1rem);
            padding-top: 20px !important;
            border-top: 1px solid $c-grey-line;
            display: flex;
            justify-content: flex-end;

            >.terms {
                cursor: pointer;
            }
        }
    }
}

.stop-scroll {
    overflow: hidden;
    margin-right: 15px;

    .navigation-container {
        margin-right: -15px;

        .navigation-basic {
            padding-right: 15px;
        }
    }
}

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

// 3.0. Pages

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

// 3.1. Offers
// Home
.offers-home-container {
    @include container-section();
    @include header-home();

    >.content {
        @include offer-card();
        @include grid-table(auto, max-content, 4, 50px, 25px, 100);
        @include grid-table-X(auto, max-content, 3, 50px, 50px, 100, 1200);
        @include grid-table-X(auto, max-content, 3, 50px, 25px, 100, 1100);
        @include grid-table-X(auto, max-content, 2, 50px, 50px, 100, 900);
        @include grid-table-X(auto, max-content, 2, 50px, 40px, 100, 800);
        @include grid-table-X(auto, max-content, 2, 50px, 25px, 100, 700);
    }

    .footer {
        @include footer-home();
    }
}

// All
.offers-container {
    @include container-section();
    @include header();

    >.content {
        display: flex;
        @include filter();

        .offers-box {
            .offers {
                @include offer-card();
            }

            >.offers-wide {
                @include grid-table(auto, max-content, 4, 50px, 25px, 100);
                @include grid-table-X(auto, max-content, 3, 50px, 50px, 100, 1200);
                @include grid-table-X(auto, max-content, 3, 50px, 25px, 100, 1100);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 100, 900);
                @include grid-table-X(auto, max-content, 2, 50px, 40px, 100, 800);
                @include grid-table-X(auto, max-content, 2, 50px, 25px, 100, 700);
            }

            >.offers-collapse {
                @include grid-table(auto, max-content, 3, 50px, 25px, 100);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 100, 1200);
                @include grid-table-X(auto, max-content, 2, 50px, 25px, 100, 1100);
                @include grid-table-X(auto, max-content, 3, 50px, 25px, 100, 1000);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 100, 900);
                @include grid-table-X(auto, max-content, 2, 50px, 40px, 100, 800);
                @include grid-table-X(auto, max-content, 2, 50px, 25px, 100, 700);
            }
        }

        .report-flag {
            display: flex;
            position: absolute;
            background: red;
            flex-direction: row;
            width: 45px;
            height: 25px;
            align-items: center;
            justify-content: center;
            border-radius: 0px 10px 0px 10px;
            top: 0;
            right: 0;
        }

        .report-flag-number {
            color: #ffffff;
        }

        .report-flag-icon {
            margin-left: 5px;
            @include image($icon-report-0, 15px, 15px, auto, 14px, center left);
        }
    }
}

// One
.offers-details-container {
    @include container-section();
    @include header();

    .header {
        .sub-title {
            >* {
                margin-right: 7px;
            }

            .bold {
                @include font-basic-bold($c-black-light, $fs-section-subtitle);
            }

            .line-through {
                @include font-basic-semi-bold($c-grey-light, 1rem);
                text-decoration: line-through;
            }

            .discount-price {
                margin-right: 5px;
                @include font-basic-semi-bold($c-grey-light, 1rem);
            }

            .discount {
                @include font-basic-semi-bold($c-black-light, 1rem);
                @include padding(0 5px);
                border-radius: 3px;
                background-color: $c-cream;
            }

            .promocode {
                padding-left: 26px;
                @include font-basic(#aebbcc, 1rem);
                line-height: 14px;
                @include image($icon-offer-coupon, auto, 15px, auto, 14px, center left);
                display: inline-flex !important;
                align-items: baseline;
            }

            >.collections {
                padding-left: 21px;
                @include font-basic(#aebbcc, 1rem);
                line-height: 14px;
                @include image($icon-offer-collection, auto, 15px, auto, 19px, center left);
                display: inline-flex !important;
                align-items: baseline;

                >.collection {
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }

            >.roadshows {
                padding-left: 20px;
                @include font-basic(#aebbcc, 1rem);
                line-height: 14px;
                @include image($icon-offer-roadshow, auto, 15px, auto, 14px, center left);
                display: inline-flex !important;
                align-items: baseline;

                >.roadshow {
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    .content {
        >.offers-details {
            display: flex;
            margin-bottom: 50px;

            >.gallery {
                display: flex;
                width: calc(100% - 400px);
                align-items: center;

                >.preview {
                    width: calc(100% / 5.8);
                    margin-right: 10px;
                    text-align: center;

                    >.btn-previous {
                        display: inline-block;
                        @include image($icon-gallery-arrowUp, 21px, 10px, auto, 10px, center);
                        cursor: pointer;

                        &:hover {
                            @include image($icon-gallery-arrowUp-1, 21px, 10px, auto, 10px, center);
                        }
                    }

                    >.btn-previus-disabled {
                        cursor: initial;

                        &:hover {
                            @include image($icon-gallery-arrowUp, 21px, 10px, auto, 10px, center);
                        }
                    }

                    >.images {
                        >img {
                            width: 100%;
                            margin: 5px 0;
                            border-radius: 8px;
                            cursor: pointer;

                            &:hover {
                                opacity: 0.8;
                            }
                        }

                        .image-no {
                            cursor: initial;
                            box-shadow: none !important;

                            &:hover {
                                opacity: 1;
                            }
                        }

                        .image-active {
                            box-shadow: 0px 0px 0px 2px #0073f9;
                        }

                        .img-c {
                            width: 100%;
                            position: relative;

                            &__placeholder {
                                width: 100%;
                                margin: 5px 0;
                            }

                            &__realImage {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                left: 0;
                                top: 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 8px;
                                overflow: hidden;
                                cursor: pointer;

                                img {
                                    max-width: 100%;
                                    max-height: calc(100% - 10px);
                                }
                            }
                        }
                    }

                    >.btn-next {
                        display: inline-block;
                        @include image($icon-gallery-arrowDown, 21px, 10px, auto, 10px, center);
                        cursor: pointer;

                        &:hover {
                            @include image($icon-gallery-arrowDown-1, 21px, 10px, auto, 10px, center);
                        }
                    }

                    >.btn-next-disabled {
                        cursor: initial;

                        &:hover {
                            @include image($icon-gallery-arrowDown, 21px, 10px, auto, 10px, center);
                        }
                    }
                }

                >.main-image {
                    width: calc(100% / 1.2);
                    position: relative;
                    height: 27.2rem;

                    >img {
                        width: 100%;
                        border-radius: 15px;
                        height: 100%;
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }

                    >.image-real {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 15px;
                        overflow: hidden;

                        >img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    >.tag {
                        position: absolute;
                        top: 0;
                        left: 0;
                        @include padding(7px 15px);
                        background-color: $c-blue;
                        @include font-basic-semi-bold($c-white, $fs-basic);
                        border-top-left-radius: 10px;
                        border-bottom-right-radius: 8px;
                    }
                }
            }

            >.data-box {
                position: relative;
                width: 400px;

                >.data {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;

                    @include padding(10px 0 10px 20px);

                    >.no-location {
                        padding-left: 12px;
                        padding-top: 5px;
                        @include font-basic($c-grey, 1rem);
                        line-height: 14px;
                        @include image($icon-location-0, auto, 15px, auto, 14px, center left);
                        display: flex !important;
                        align-items: baseline;
                    }

                    >.location {
                        padding-top: 2px;
                        padding-left: 12px;
                        @include font-basic($c-grey, 0.9rem);
                        line-height: 14px;
                        @include image($icon-location-0, auto, 15px, auto, 14px, center left);
                        display: flex !important;
                        align-items: baseline;
                        cursor: pointer;

                        &:hover {
                            color: $c-blue;
                            @include image($icon-location-1, auto, 15px, auto, 14px, center left);
                        }
                    }

                    >.locations {
                        padding-left: 18px;
                        padding-top: 2px;
                        @include font-basic($c-grey, 0.9rem);
                        line-height: 14px;
                        display: flex !important;
                        align-items: baseline;
                        cursor: pointer;
                        @include image($icon-locations-0, auto, 15px, auto, 14px, center left);
                        cursor: pointer;

                        &:hover {
                            color: $c-blue;
                            @include image($icon-locations-1, auto, 15px, auto, 14px, center left);
                        }
                    }

                    >.time {
                        @include margin(10px 0 0 0);
                        @include font-basic($c-blue, 0.9rem);
                    }

                    >.description {
                        height: calc(100% - 200px);
                        @include margin(40px 0 0 0);
                        @include font-basic($c-grey, 0.9rem);
                        line-height: 1.3rem;
                        overflow-y: auto;
                        word-break: break-word;
                        @include scroll-vertical();
                    }

                    >.rating {
                        @include margin(10px 0 0 0);
                        display: flex;
                        align-items: center;

                        >.value {
                            display: inline-block;
                            @include font-basic($c-blue, $fs-basic);
                        }

                        >.stars {
                            position: relative;
                            display: inline-block;
                            margin: 0 10px;
                            @include image($icon-stars-empty, 78px, 14px, auto, 13px, center left);

                            >.stars-positive {
                                position: absolute;
                                @include image($icon-stars-full, 0%, 14px, auto, 13px, center left);
                            }
                        }

                        >.voted {
                            display: inline-block;
                            @include font-basic($c-grey, $fs-basic);
                        }
                    }

                    >.company-data {
                        display: flex;
                        @include margin(15px 0 0 0);

                        >.logo {
                            display: inline-block;
                            @include margin(0 10px 0 0);

                            >img {
                                height: 75px;
                            }
                        }

                        >.basic-informations {
                            display: inline-block;

                            >.info {
                                @include margin(5px 0 0 0);
                                @include font-basic($c-grey, $fs-basic);

                                &:first-of-type {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }

                >.data-no-price {
                    .description {
                        height: calc(100% - 170px);
                    }
                }

                >.data-no-location {
                    .description {
                        margin-top: 0px;
                        height: calc(100% - 115px);
                    }
                }
            }

            @include respSpecif(900) {
                flex-wrap: wrap;

                >.gallery {
                    width: 100%;
                }

                >.data-box {
                    display: none;
                }
            }
        }

        >.offers-small-description {
            display: none;

            .text {
                @include font-basic($c-grey, 1rem);
                line-height: 1.3rem;
            }

            @include respSpecif(900) {
                display: block;
            }
        }

        >.offers-description {
            >.section-content {
                >*>*>* {
                    @include normalize();

                    table,
                    td,
                    th {
                        border: 1px solid black;
                    }

                    table {
                        margin-bottom: 20px !important;
                    }

                    td,
                    th {
                        padding: 12px 15px;
                        min-width: 100px;
                    }

                    th {
                        background-color: #efefef;
                    }

                }
            }

            .terms-and-condition {
                // max-height: 350px;
                overflow: auto;
                // @include scroll-vertical();
            }

        }

        >.offers-video {
            .offers-video {
                .video {
                    max-width: 600px;
                }
            }
        }

        >.offers-attachments {
            .attachments {
                >.attachment {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    @include margin(0 0 20px 0);

                    >.file-name {
                        @include font-basic-semi-bold($c-black-light, 1rem);
                        @include margin(0 20px 0 0);
                    }

                    >.btn-download {
                        @include image($icon-download-0, auto, auto, auto, 17px, center right 15px);
                        @include padding(6px 40px 6px 20px);
                        @include font-basic-semi-bold($c-blue, 1rem);
                        border: 1px solid $c-blue;
                        border-radius: $radius-btn;
                        cursor: pointer;

                        &:hover {
                            @include image($icon-download-1, auto, auto, auto, 17px, center right 15px);
                            @include padding(6px 40px 6px 20px);
                            color: $c-white;
                            background-color: $c-blue;
                        }
                    }
                }
            }
        }

        >.offers-location {
            .map {
                width: 100%;

                >* {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
            }
        }

        >.offers-reviews {
            .rating {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;

                >.value {
                    @include font-basic-bold($c-black-light, 4rem);
                }

                >.right {
                    @include margin(0 0 0 20px);

                    >.stars {
                        position: relative;
                        display: inline-block;
                        @include image($icon-stars-0, 148px, 24px, 148px, auto, center left);

                        >.stars-positive {
                            position: absolute;
                            @include image($icon-stars-1, 0%, 24px, 148px, auto, center left);
                        }
                    }

                    >.count {
                        @include margin(5px 0 5px 0);
                        @include font-basic-bold($c-black-light, $fs-basic);
                    }
                }
            }
        }

        >.offers-reports {
            .table-report {
                background: white;
                border-radius: 15px;
                padding: 15px 10px;
                padding-bottom: 12px;
            }

            .col-user {
                width: 23%;
            }

            .col-report {
                width: 60%;
            }

            .col-report-50 {
                width: 50%;
            }

            .col-date {
                width: 15%;
            }

            .col-btn {
                width: 10%;
            }

            .user-information {
                width: 25%;
                display: flex;
                flex-direction: column;

                .user-name,
                .user-email {
                    padding: 4px 4px;
                    font-family: Roboto, "Helvetica Neue", sans-serif;
                }

                .user-email {
                    color: $c-grey-light;
                    font-size: 15px;
                }
            }

            .report-text {
                width: 60%;
            }

            .report-text-50 {
                width: 50%;
            }

            .report-date {
                width: 15%;
            }

            .report-btn {
                width: 10%;
            }
        }
    }
}

// Edit
.offers-new-container {
    @include container-section();
    @include header();

    >.content {
        .attachments {
            >.attachment {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                @include margin(0 0 20px 0);

                >.file-name {
                    @include font-basic-semi-bold($c-black-light, 1rem);
                    @include margin(0 20px 0 0);
                    cursor: pointer;

                    &:hover {
                        color: $c-blue;
                    }
                }
            }

            >.no-attachments {
                @include font-basic-semi-bold($c-black-light, 1rem);
            }

            .note {
                @include margin-top(20px);
                @include font-basic($c-blue, 1rem);
            }
        }

        .location {
            .map {
                width: 100%;
            }

            .location-choice {
                >.title {
                    @include font-basic-bold($c-black-light, 1rem);
                    @include margin(0 0 15px 0);
                }

                >.f-checkbox {
                    @include margin(0 0 10px 0);
                }

                >.f-basic-btn {
                    @include margin(15px 0 0 0);
                }
            }

            @include respSpecif(1100) {
                .col-2 {
                    width: 100%;
                }

                .location-choice {
                    margin-top: 30px;
                }
            }
        }

        .images {
            .no-images-box {
                display: flex;
                align-items: center;
                flex-direction: column;

                >.icon {
                    @include image($icon-no-image-uploder, 200px, 180px, auto, 140px, bottom center);
                }

                >.btn {
                    @include margin(0 0 20px 0);
                }

                >.note {
                    @include font-basic($c-black-light, 1rem);
                    line-height: 1.3rem;

                    >span {
                        color: $c-blue
                    }
                }
            }

            .cropper-box {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .cropper {
                    width: 30%;

                    >.cropper-subtitle {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }
                }

                .prev {
                    width: 30%;

                    >.cropper-subtitle {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }

                    >img {
                        width: 100%;
                        background-color: $c-cream;
                        border: 1px solid $c-grey-line;
                        border-radius: 15px;
                    }
                }

                .imgs {
                    width: 30%;
                    flex-wrap: wrap;
                    overflow: auto;
                    padding: 0 10px;
                    @include scroll-vertical();

                    >.cropper-subtitle {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }

                    >.img-c {
                        position: relative;
                        display: inline-block;
                        width: 99px;
                        height: 66px;
                        background-color: $c-cream;
                        margin-bottom: 20px;
                        margin-right: 20px;
                        text-align: center;
                        border: 1px solid $c-grey-line;

                        >.img-box {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            >img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }

                        >.cover-tag {
                            position: absolute;
                            top: -8px;
                            left: -8px;
                            @include image($icon-cropper-cover, 18px, 18px, 18px, auto, center);
                        }
                    }

                    >.img-c-active {
                        border: 1px solid $c-blue;
                    }
                }

                @include respSpecif(1300) {
                    justify-content: flex-start;

                    .cropper {
                        width: 40%;
                    }

                    .prev {
                        width: 40%;
                        margin-left: 50px;
                    }

                    .imgs {
                        width: 100%;
                        margin-top: 20px;
                    }
                }
            }

            .imgs-prev-box {
                display: inline-flex;

                >* {
                    width: calc((100% - 60px) / 3);
                }

                >.imgs-prev-cover-c {
                    @include margin(0 30px 0 0);

                    >.imgs-prev-sub-title {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }

                    >.imgs-prev-cover-img {
                        width: 100%;
                        border-radius: 20px;
                    }
                }

                >.imgs-prev-main-c {
                    @include margin(0 30px 0 0);

                    >.imgs-prev-sub-title {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }

                    >.imgs-prev-main-img {
                        width: 100%;
                        border-radius: 20px;
                    }
                }

                >.imgs-prev-all-c {
                    >.imgs-prev-sub-title {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }

                    >.imgs-prev-imgs-c {
                        position: relative;
                        height: 100%;

                        >.imgs-prev-imgs {
                            position: absolute;
                            max-height: calc(100% - 32px);
                            overflow-y: auto;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            padding-right: 10px;
                            @include scroll-vertical();

                            >.imgs-prev-img {
                                border-radius: 10px;
                                width: calc((100% - 30px) / 3);
                                margin-bottom: 15px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            >.error {
                @include margin(10px 0 0 0);
                text-align: right;
                @include font-basic-semi-bold($c-red, 0.75rem);

                &:before {
                    content: "! ";
                }
            }
        }

        .close-announcement {
            .images {
                opacity: 0;
                height: 0;
                overflow: hidden;
            }
        }

        .open-announcement {
            .images {
                opacity: 1;
                height: auto;
                transition: opacity 2s ease-out;

                .section-content {
                    box-shadow: 0px 0px 7px rgb(0 0 0 / 8%);
                }
            }
        }
    }

    >.footer {
        @include margin(30px 0 0 0);

        >.tools-box {
            display: flex;
            justify-content: flex-end;

            >* {
                @include margin(0 0 0 10px);
            }
        }
    }
}

// HOME
.home-dashboard-container {
    @include container-section();
    @include header();

    >.content {
        >.section {
            @include margin(0 0 40px 0);

            .section_title {
                @include font-basic-bold($c-black-light, 1.6rem);
                @include margin(0 0 20px 0);
            }

            .section_content {
                @include grid-table(auto, max-content, 4, 50px, 25px, 100);
                @include grid-table-X(auto, max-content, 3, 50px, 25px, 100, 1200);
                @include grid-table-X(auto, max-content, 2, 50px, 25px, 100, 850);
                @include dashboard-card();
            }
        }
    }
}

.transfer-offers-container {
    @include container-section();
    @include header();
    @include footer();
}

.analytics-container {
    @include container-section();
    @include header();

    >.content {
        @include grid-table(auto, max-content, 4, 25px, 25px, 100);
        @include grid-table-X(auto, max-content, 3, 25px, 25px, 100, 1200);
        @include grid-table-X(auto, max-content, 2, 25px, 25px, 100, 850);

        @include analytics-card();
    }

    .dropdown-card-btn {
        @include image($icon-arrow-down, 23px, 10px, auto, 8px, center left 3px);
        background-repeat: no-repeat;
        display: inline-flex;
        margin-left: 140px;
    }

    .arrowDown {
        transform: rotate(180deg);
    }
}

.opened-card {
    display: flex;
}

.closed-card {
    display: none !important;
}

// Roadshow
.roadshow-home-container {
    @include container-section();
    @include header-home();

    >.content {
        .bannerRs {
            position: relative;
            padding: 80px;
            border-radius: calc(1.2vw / 1);
            @include image($bc-roadshow, auto, auto, auto, auto, center);

            display: flex;
            align-items: flex-end;

            &__data {
                width: calc(100% - 375px);

                .year {
                    @include font-basic-semi-bold($c-white, 5.5rem);
                }

                .title {
                    margin-top: 5px;
                    @include font-basic-semi-bold($c-white, 4rem);
                }

                .subtitle {
                    margin-top: 30px;
                    @include font-basic($c-white, 1.3rem);
                }
            }

            &__content {
                width: 375px;

                .text {
                    @include font-basic($c-white, 0.9rem);
                    line-height: 1.3rem;
                    text-align: right;
                }

                .footer {
                    margin-top: 10px;
                    display: flex;
                    justify-content: flex-end;

                    .btn {
                        margin-top: 10px;
                    }
                }
            }

            @include respSpecif(1040) {
                flex-direction: column;

                .bannerRs__data {
                    width: 100%;
                }

                .bannerRs__content {
                    margin-top: 40px;
                    width: 100%;

                    .text {
                        text-align: left;
                    }

                    .footer {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}

.roadshow-container {
    @include container-section();
    @include header();

    >.content {
        >.location-box {
            @include margin(0 0 50px 0);

            >.location-box-header {
                >.location-box-header-icon {}

                >.location-box-header-title {
                    @include image($icon-roadshow-location, auto, 100%, auto, 24px, center left);
                    display: inline-block;
                    @include padding(5px 0 5px 30px);
                    @include margin(0 0 20px 0);
                    @include font-basic-semi-bold($c-blue, 1.25rem);
                }
            }

            >.location-box-offers {
                @include grid-table(auto, max-content, 4, 50px, 25px, 100);
                @include grid-table-X(auto, max-content, 3, 50px, 40px, 100, 960);
                @include grid-table-X(auto, max-content, 3, 50px, 25px, 100, 880);
                @include grid-table-X(auto, max-content, 2, 50px, 25px, 100, 700);
                @include offer-roadshow-card();
            }

            >.location-box-footer {
                @include margin(40px 0 0 0);
                text-align: center;
            }
        }
    }
}

.roadshow-offers-container {
    @include container-section();
    @include header();

    >.content {
        @include grid-table(auto, max-content, 4, 50px, 25px, 100);
        @include grid-table-X(auto, max-content, 3, 50px, 40px, 100, 960);
        @include grid-table-X(auto, max-content, 3, 50px, 25px, 100, 880);
        @include grid-table-X(auto, max-content, 2, 50px, 25px, 100, 700);
        @include offer-roadshow-card();
    }
}

.roadshow-edit-container {
    @include container-section();
    @include header();

    >.content {
        .images {
            .no-images-box {
                display: flex;
                align-items: center;
                flex-direction: column;

                >.icon {
                    @include image($icon-no-image-uploder, 200px, 180px, auto, 140px, bottom center);
                }

                >.btn {
                    @include margin(0 0 20px 0);
                }

                >.note {
                    @include font-basic($c-black-light, 1rem);
                    line-height: 1.3rem;

                    >span {
                        color: $c-blue
                    }
                }
            }

            .cropper-box {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .cropper {
                    width: 30%;

                    >.cropper-subtitle {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }
                }

                .prev {
                    width: 30%;

                    >.cropper-subtitle {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }

                    >img {
                        width: 100%;
                        background-color: $c-cream;
                        border: 1px solid $c-grey-line;
                        border-radius: 15px;
                    }
                }

                .imgs {
                    width: 30%;
                    flex-wrap: wrap;
                    overflow: auto;
                    padding: 0 10px;
                    @include scroll-vertical();

                    >.cropper-subtitle {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }

                    >.img-c {
                        position: relative;
                        display: inline-block;
                        width: 99px;
                        height: 66px;
                        background-color: $c-cream;
                        margin-bottom: 20px;
                        margin-right: 20px;
                        text-align: center;
                        border: 1px solid $c-grey-line;

                        >.img-box {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            >img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }

                        >.cover-tag {
                            position: absolute;
                            top: -8px;
                            left: -8px;
                            @include image($icon-cropper-cover, 18px, 18px, 18px, auto, center);
                        }
                    }

                    >.img-c-active {
                        border: 1px solid $c-blue;
                    }
                }

                @include respSpecif(1300) {
                    justify-content: flex-start;

                    .cropper {
                        width: 40%;
                    }

                    .prev {
                        width: 40%;
                        margin-left: 50px;
                    }

                    .imgs {
                        width: 100%;
                        margin-top: 20px;
                    }
                }
            }

            .imgs-prev-box {
                display: inline-flex;

                >* {
                    width: calc((100% - 60px) / 3);
                }

                >.imgs-prev-cover-c {
                    @include margin(0 30px 0 0);

                    >.imgs-prev-sub-title {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }

                    >.imgs-prev-cover-img {
                        width: 100%;
                        border-radius: 20px;
                    }
                }

                >.imgs-prev-main-c {
                    @include margin(0 30px 0 0);

                    >.imgs-prev-sub-title {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }

                    >.imgs-prev-main-img {
                        width: 100%;
                        border-radius: 20px;
                    }
                }

                >.imgs-prev-all-c {
                    >.imgs-prev-sub-title {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }

                    >.imgs-prev-imgs-c {
                        position: relative;
                        height: 100%;

                        >.imgs-prev-imgs {
                            position: absolute;
                            max-height: calc(100% - 32px);
                            overflow-y: auto;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            padding-right: 10px;
                            @include scroll-vertical();

                            >.imgs-prev-img {
                                border-radius: 10px;
                                width: calc((100% - 30px) / 3);
                                margin-bottom: 15px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            >.error {
                @include margin(10px 0 0 0);
                text-align: right;
                @include font-basic-semi-bold($c-red, 0.75rem);

                &:before {
                    content: "! ";
                }
            }
        }

        .error {
            @include margin(10px 0 0 0);
            text-align: right;
            @include font-basic-semi-bold($c-red, 0.75rem);

            &:before {
                content: "! ";
            }
        }

        .progress {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            >.data {
                position: absolute;
                left: 0;
                top: 0;

                >.date {
                    @include font-basic-semi-bold($c-black-light, 1.75rem);
                }

                >.id {
                    @include margin-top(5px);
                    @include font-basic-semi-bold($c-blue, 1.75rem);
                }

                >.status {
                    max-width: max-content;
                    background-color: $c-blue;
                    border-radius: 3px;
                    @include padding(5px 10px);
                    @include font-basic-semi-bold($c-white, 1.75rem);
                    @include margin-top(5px);
                }
            }

            >.status-diagram {
                display: flex;

                >.draft {
                    @include image($icon-progress-draft-0, 95px, 125px, auto, 94px, top center);
                    display: inline-flex;
                    align-items: flex-end;
                    justify-content: center;
                    @include font-basic($c-grey, 1rem);
                }

                >.draft-active {
                    @include image($icon-progress-draft-1, 95px, 125px, auto, 94px, top center);
                    color: $c-green;
                }

                >.invited {
                    @include image($icon-progress-invited-0, 95px, 125px, auto, 94px, top center);
                    display: inline-flex;
                    align-items: flex-end;
                    justify-content: center;
                    @include font-basic($c-grey, 1rem);
                }

                >.invited-active {
                    @include image($icon-progress-invited-1, 95px, 125px, auto, 94px, top center);
                    color: $c-green;
                }

                >.published {
                    @include image($icon-progress-published-0, 95px, 125px, auto, 94px, top center);
                    display: inline-flex;
                    align-items: flex-end;
                    justify-content: center;
                    @include font-basic($c-grey, 1rem);
                }

                >.published-active {
                    @include image($icon-progress-published-1, 95px, 125px, auto, 94px, top center);
                    color: $c-green;
                }

                >.union {
                    @include image($icon-progress-union-0, 180px, 125px, auto, 14px, top 40px center);
                }

                >.union-active {
                    @include image($icon-progress-union-1, 180px, 125px, auto, 14px, top 40px center);
                }
            }
        }

        .table-invites {
            .table-header {
                .col-checkbox {
                    width: 20px;
                }

                .col-company {
                    flex-grow: 1;
                }

                .col-invite-events {
                    width: 480px;
                }

                .col-contact {
                    width: 250px;
                }

                .col-status {
                    width: 150px;
                    display: flex;
                    justify-content: center;
                }

                .col-invite-link {
                    width: 20px;
                }
            }

            .table-body {
                .col-checkbox {
                    width: 20px;
                }

                .col-company {
                    display: flex;
                    flex-grow: 1;
                    flex-basis: 0;
                    align-items: center;

                    >.logo {
                        width: 60px;
                        @include margin(0 10px 0 0);
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }
                    }

                    >.name {
                        @include font-basic-bold($c-black, 1rem);
                        cursor: pointer;

                        &:hover {
                            color: $c-blue;
                        }
                    }
                }

                .col-no-events {
                    width: 480px;
                    @include font-basic-semi-bold($c-blue, 1rem);
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }

                .col-invite-events {
                    width: 480px;

                    >.invite-event {
                        display: flex;
                        align-items: center;
                        @include padding(10px 0);

                        >.f-date {
                            @include margin(0 15px 0 0);
                            width: 200px;
                        }

                        >.f-select {
                            @include margin(0 15px 0 0);
                            width: 150px;
                        }

                        >.save-btn {
                            @include font-basic($c-blue, 1rem);
                            cursor: pointer;

                            &:hover {
                                opacity: 0.8;
                            }

                            margin-right: 10px;
                        }

                        >.delete-btn {
                            @include image($icon-delete, 12px, 15px, auto, 13px, center);
                            margin-right: 10px;
                            cursor: pointer;

                            &:hover {
                                opacity: 0.8;
                            }
                        }

                        >.add-btn {
                            @include image($icon-add-0, 20px, 20px, auto, 19px, center);
                            cursor: pointer;

                            &:hover {
                                @include image($icon-add-1, 20px, 20px, auto, 19px, center);
                            }
                        }
                    }
                }

                .col-contact {
                    width: 250px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;

                    >.contact {
                        @include font-basic($c-black, 0.9rem);
                        line-height: 1.3rem;
                        word-break: break-word;
                        cursor: pointer;

                        &:hover {
                            color: $c-blue;
                        }
                    }
                }

                .col-status {
                    width: 150px;

                    >.status {
                        text-align: center;
                    }

                    >.status-draft {
                        @include font-basic($c-status-draft, 1rem);
                    }

                    >.status-invited {
                        @include font-basic($c-status-invited, 1rem);
                    }

                    >.status-accepted {
                        @include font-basic($c-status-accepted, 1rem);
                    }

                    >.status-approved {
                        @include font-basic-semi-bold($c-status-approved, 1rem);
                    }

                    >.status-review {
                        @include font-basic-semi-bold($c-status-review, 1rem);
                    }

                    >.status-rejected {
                        @include font-basic-semi-bold($c-status-blocked, 1rem);
                    }

                    >.status-deactivated {
                        @include font-basic-semi-bold($c-status-blocked, 1rem);
                    }

                    >.status-blocked {
                        @include font-basic-semi-bold($c-status-blocked, 1rem);
                    }

                    >.status-expired {
                        @include font-basic-semi-bold($c-status-expired, 1rem);
                    }

                    >.status-renegotiation {
                        @include font-basic-semi-bold($c-status-renegotiation, 1rem);
                    }

                    >.status-returned {
                        @include font-basic-semi-bold($c-status-returned, 1rem);
                    }
                }

                .col-invite-link {
                    width: 20px;

                    .link-btn {
                        color: $c-blue;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }

            @include respSpecif(1300) {
                .col-contact {
                    display: none !important;
                }
            }

            @include respSpecif(1100) {
                .col-invite-events {
                    display: none !important;
                }
            }
        }
    }

    >.footer {
        @include margin(30px 0 0 0);

        >.tools-box {
            display: flex;
            justify-content: flex-end;

            >* {
                @include margin(0 0 0 10px);
            }
        }
    }

    @include resp(M) {
        >.content {
            >.progress {
                flex-direction: column;
                align-items: flex-start;

                >.data {
                    position: unset;
                    margin-bottom: 40px;
                }

                >.status-diagram {
                    align-self: center;

                    >.draft {
                        @include image($icon-progress-draft-0, 85px, 115px, auto, 84px, top center);
                    }

                    >.draft-active {
                        @include image($icon-progress-draft-1, 85px, 115px, auto, 84px, top center);
                    }

                    >.invited {
                        @include image($icon-progress-invited-0, 85px, 115px, auto, 84px, top center);
                    }

                    >.invited-active {
                        @include image($icon-progress-invited-1, 85px, 115px, auto, 84px, top center);
                    }

                    >.published {
                        @include image($icon-progress-published-0, 85px, 115px, auto, 84px, top center);
                    }

                    >.published-active {
                        @include image($icon-progress-published-1, 85px, 115px, auto, 84px, top center);
                    }

                    >.union {
                        @include image($icon-progress-union-0, 170px, 115px, auto, 14px, top 40px center);
                    }

                    >.union-active {
                        @include image($icon-progress-union-1, 170px, 115px, auto, 14px, top 40px center);
                    }
                }
            }
        }
    }

    @include resp(S) {
        >.content {
            >.progress {
                >.status-diagram {
                    >.draft {
                        @include image($icon-progress-draft-1, 65px, 95px, auto, 64px, top center);
                    }

                    >.draft-active {
                        @include image($icon-progress-draft-1, 65px, 95px, auto, 64px, top center);
                    }

                    >.invited {
                        @include image($icon-progress-invited-0, 65px, 95px, auto, 64px, top center);
                    }

                    >.invited-active {
                        @include image($icon-progress-invited-1, 65px, 95px, auto, 64px, top center);
                    }

                    >.published {
                        @include image($icon-progress-published-0, 65px, 95px, auto, 64px, top center);
                    }

                    >.published-active {
                        @include image($icon-progress-published-1, 65px, 95px, auto, 64px, top center);
                    }

                    >.union {
                        @include image($icon-progress-union-0, 150px, 95px, auto, 14px, top 30px center);
                    }

                    >.union-active {
                        @include image($icon-progress-union-1, 150px, 95px, auto, 14px, top 30px center);
                    }
                }
            }
        }
    }

    @include respSpecif(700) {
        >.content {
            >.progress {
                >.status-diagram {
                    >.draft {
                        @include image($icon-progress-draft-0, 55px, 70px, auto, 44px, top center);
                    }

                    >.draft-active {
                        @include image($icon-progress-draft-1, 55px, 70px, auto, 44px, top center);
                    }

                    >.review {
                        @include image($icon-progress-review-0, 55px, 70px, auto, 44px, top center);
                    }

                    >.review-active {
                        @include image($icon-progress-review-1, 55px, 70px, auto, 44px, top center);
                    }

                    >.approved {
                        @include image($icon-progress-approved-0, 55px, 70px, auto, 44px, top center);
                    }

                    >.approved-active {
                        @include image($icon-progress-approved-1, 55px, 70px, auto, 44px, top center);
                    }

                    >.rejected {
                        @include image($icon-progress-rejected-1, 55px, 70px, auto, 44px, top center);
                    }

                    >.rejected-active {
                        @include image($icon-progress-rejected-1, 55px, 70px, auto, 44px, top center);
                    }

                    >.union {
                        @include image($icon-progress-union-0, 150px, 70px, auto, 14px, top 15px center);
                    }

                    >.union-active {
                        @include image($icon-progress-union-1, 150px, 70px, auto, 14px, top 15px center);
                    }
                }
            }

            .images {
                .imgs-prev-box {
                    >.imgs-prev-all-c {
                        height: 7rem;
                    }
                }
            }
        }
    }
}

.modal-roadshow-add-events {
    .table-custom {
        .col-date {
            width: 250px;

            .f-date {
                margin: 0;
            }
        }

        .col-location {
            width: 250px;

            .f-select {
                margin: 0;
            }
        }

        .col-save {
            width: 100px;
        }
    }
}

.modal-invites-form-add-suppliers {
    .table-custom {
        .table-header {
            .col-company {
                flex-grow: 1;
            }

            .col-contacts {
                width: 300px;
            }
        }

        .table-body {
            .col-company {
                display: flex;
                flex-grow: 1;
                align-items: center;

                >.logo {
                    max-height: 50px;
                    width: 70px;
                    @include margin(0 10px 0 0);
                }

                >.name {
                    @include font-basic-bold($c-black, 1rem);
                }
            }

            .col-contacts {
                width: 300px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;

                >.contact {
                    @include font-basic($c-black, 0.9rem);
                    line-height: 1.3rem;
                }
            }
        }

        @include respSpecif(750) {
            .col-contacts {
                display: none !important;
            }
        }
    }
}

.roadshow-invite-edit-container {
    @include container-section();
    @include header();

    >.content {
        >.information {
            @include margin(0 0 50px 0);

            >* {
                @include margin(0 0 10px 0);
            }

            >.information-date {
                @include font-basic-semi-bold($c-black, 1rem);
            }

            >.information-locations {
                @include font-basic-semi-bold($c-blue, 1rem);
                display: flex;

                >.location {
                    margin-right: 5px;
                }
            }

            >.information-description {
                >.information-description-title {
                    @include font-basic-semi-bold($c-black, 1rem);
                }

                >.information-description-content {
                    @include margin-top(5px);
                    @include font-basic($c-black-light, 0.9rem);
                    line-height: 1.1rem;
                }
            }

            >.information-comment {
                >.information-comment-title {
                    @include font-basic-semi-bold($c-black, 1rem);
                }

                >.information-comment-content {
                    @include margin-top(5px);
                    @include font-basic($c-black-light, 0.9rem);
                    line-height: 1.1rem;
                }
            }
        }

        .schedule {
            @include margin-top(50px);

            .introduction {
                @include font-basic-semi-bold($c-black, 1rem);
            }

            .events {
                .event {
                    background-color: $c-white;
                    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
                    border-radius: 10px;
                    @include padding(15px);
                    @include margin(15px 0 0px 0);

                    .event-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .event-header-info {
                            display: flex;

                            .f-date,
                            .f-select {
                                @include margin(0 15px 0 0);
                                width: 200px;
                            }
                        }

                        .event-header-options {
                            >* {
                                margin: 0 10px;
                            }

                            >.option {
                                display: inline-block;
                                @include font-basic($c-blue, 1rem);
                                cursor: pointer;

                                &:hover {
                                    opacity: 0.8;
                                }
                            }

                            >.option-delete {
                                color: $c-red;
                            }
                        }
                    }

                    .event-content {
                        .event-content-offers {
                            @include grid-table(auto, max-content, 4, 50px, 25px, 100);
                            @include grid-table-X(auto, max-content, 3, 50px, 50px, 100, 1200);
                            @include grid-table-X(auto, max-content, 3, 50px, 25px, 100, 1100);
                            @include grid-table-X(auto, max-content, 2, 50px, 50px, 100, 900);
                            @include grid-table-X(auto, max-content, 2, 50px, 40px, 100, 800);
                            @include grid-table-X(auto, max-content, 2, 50px, 25px, 100, 700);
                            @include padding(20px 0);
                            @include offer-roadshow-card();
                        }

                        .event-content-no-offers {
                            @include height(100px);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @include font-basic($c-black, 1rem);

                            span {
                                margin-left: 5px;
                                @include font-basic-semi-bold($c-blue, 1rem);
                                cursor: pointer;

                                &:hover {
                                    opacity: 0.8;
                                }
                            }
                        }
                    }
                }
            }

            .note {
                >.request {
                    display: inline-block;
                    @include margin(15px 0 0 0);
                    @include font-basic-semi-bold($c-blue, 1rem);
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    >.footer {
        @include margin(30px 0 0 0);

        >.tools-box {
            display: flex;
            justify-content: flex-end;

            >* {
                @include margin(0 0 0 10px);
            }
        }
    }
}

.modal-schedule-form-add-offer {
    .modal-complex {
        height: calc(100vh - 30px);

        .modal-content {
            display: flex;
            height: calc(100% - 160px);
            @include filter();

            .offers-box {
                width: 100%;

                .offers {
                    @include offer-roadshow-card();
                }

                >.offers-wide {
                    @include grid-table(auto, max-content, 4, 50px, 25px, 100);
                    @include grid-table-X(auto, max-content, 3, 50px, 50px, 100, 1200);
                    @include grid-table-X(auto, max-content, 3, 50px, 25px, 100, 1100);
                    @include grid-table-X(auto, max-content, 2, 50px, 50px, 100, 900);
                    @include grid-table-X(auto, max-content, 2, 50px, 40px, 100, 800);
                    @include grid-table-X(auto, max-content, 2, 50px, 25px, 100, 700);
                }

                >.offers-collapse {
                    @include grid-table(auto, max-content, 3, 50px, 25px, 100);
                    @include grid-table-X(auto, max-content, 2, 50px, 50px, 100, 1200);
                    @include grid-table-X(auto, max-content, 2, 50px, 25px, 100, 1100);
                    @include grid-table-X(auto, max-content, 3, 50px, 25px, 100, 1000);
                    @include grid-table-X(auto, max-content, 2, 50px, 50px, 100, 900);
                    @include grid-table-X(auto, max-content, 2, 50px, 40px, 100, 800);
                    @include grid-table-X(auto, max-content, 2, 50px, 25px, 100, 700);
                }
            }
        }
    }
}

.roadshow-offer-edit-container {
    @include container-section();
    @include header();

    >.content {
        .attachments {
            >.attachment {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                @include margin(0 0 20px 0);

                >.file-name {
                    @include font-basic-semi-bold($c-black-light, 1rem);
                    @include margin(0 20px 0 0);
                    cursor: pointer;

                    &:hover {
                        color: $c-blue;
                    }
                }
            }

            .note {
                @include margin-top(20px);
                @include font-basic($c-blue, 1rem);
            }

            >.no-attachments {
                @include font-basic-semi-bold($c-black-light, 1rem);
            }
        }

        .images {
            .no-images-box {
                display: flex;
                align-items: center;
                flex-direction: column;

                >.icon {
                    @include image($icon-no-image-uploder, 200px, 180px, auto, 140px, bottom center);
                }

                >.btn {
                    @include margin(0 0 20px 0);
                }

                >.note {
                    @include font-basic($c-black-light, 1rem);
                    line-height: 1.3rem;

                    >span {
                        color: $c-blue
                    }
                }
            }

            .cropper-box {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .cropper {
                    width: 30%;

                    >.cropper-subtitle {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }
                }

                .prev {
                    width: 30%;

                    >.cropper-subtitle {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }

                    >img {
                        width: 100%;
                        background-color: $c-cream;
                        border: 1px solid $c-grey-line;
                        border-radius: 15px;
                    }
                }

                .imgs {
                    width: 30%;
                    flex-wrap: wrap;
                    overflow: auto;
                    padding: 0 10px;
                    @include scroll-vertical();

                    >.cropper-subtitle {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }

                    >.img-c {
                        position: relative;
                        display: inline-block;
                        width: 99px;
                        height: 66px;
                        background-color: $c-cream;
                        margin-bottom: 20px;
                        margin-right: 20px;
                        text-align: center;
                        border: 1px solid $c-grey-line;

                        >.img-box {
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            >img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }

                        >.cover-tag {
                            position: absolute;
                            top: -8px;
                            left: -8px;
                            @include image($icon-cropper-cover, 18px, 18px, 18px, auto, center);
                        }
                    }

                    >.img-c-active {
                        border: 1px solid $c-blue;
                    }
                }

                @include respSpecif(1300) {
                    justify-content: flex-start;

                    .cropper {
                        width: 40%;
                    }

                    .prev {
                        width: 40%;
                        margin-left: 50px;
                    }

                    .imgs {
                        width: 100%;
                        margin-top: 20px;
                    }
                }
            }

            .imgs-prev-box {
                display: inline-flex;

                >* {
                    width: calc((100% - 60px) / 3);
                }

                >.imgs-prev-cover-c {
                    @include margin(0 30px 0 0);

                    >.imgs-prev-sub-title {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }

                    >.imgs-prev-cover-img {
                        width: 100%;
                        border-radius: 20px;
                    }
                }

                >.imgs-prev-main-c {
                    @include margin(0 30px 0 0);

                    >.imgs-prev-sub-title {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }

                    >.imgs-prev-main-img {
                        width: 100%;
                        border-radius: 20px;
                    }
                }

                >.imgs-prev-all-c {
                    >.imgs-prev-sub-title {
                        @include font-basic-bold($c-black, 1rem);
                        @include margin(0 0 15px 0);
                    }

                    >.imgs-prev-imgs-c {
                        position: relative;
                        height: 100%;

                        >.imgs-prev-imgs {
                            position: absolute;
                            max-height: calc(100% - 32px);
                            overflow-y: auto;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            padding-right: 10px;
                            @include scroll-vertical();

                            >.imgs-prev-img {
                                border-radius: 10px;
                                width: calc((100% - 30px) / 3);
                                margin-bottom: 15px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            >.error {
                @include margin(10px 0 0 0);
                text-align: right;
                @include font-basic-semi-bold($c-red, 0.75rem);

                &:before {
                    content: "! ";
                }
            }
        }
    }

    >.footer {
        @include margin(30px 0 0 0);

        >.tools-box {
            display: flex;
            justify-content: flex-end;

            >* {
                @include margin(0 0 0 10px);
            }
        }
    }

    @include resp(M) {
        >.content {
            .images {
                .cropper-box {
                    flex-wrap: wrap;
                    justify-content: flex-start;

                    .cropper {
                        margin-right: 10%;
                    }

                    .imgs {
                        width: 100%;
                        text-align: left;
                        margin-top: 40px;
                    }
                }

                .imgs-prev-box {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-evenly;

                    >* {
                        width: 45%;
                    }

                    >.imgs-prev-main-c {
                        @include margin(0);
                    }

                    >.imgs-prev-all-c {
                        height: 10rem;
                        margin-top: 25px;
                        width: 100%;

                        >.imgs-prev-imgs-c {
                            >.imgs-prev-imgs {
                                >.imgs-prev-img {
                                    width: calc((50% - 30px) / 3);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include resp(S) {
        >.content {
            .images {
                .imgs-prev-box {
                    >.imgs-prev-all-c {
                        height: 8rem;
                    }
                }
            }
        }
    }

    @include respSpecif(700) {
        >.content {
            .images {
                .imgs-prev-box {
                    >.imgs-prev-all-c {
                        height: 7rem;
                    }
                }
            }
        }
    }
}

// Collection
.collections-container {
    @include container-section();
    @include header();

    .content {
        @include grid-table(auto, max-content, 2, 50px, 30px, 8);
        @include collection-card();
    }
}

.collections-home-container {
    @include container-section();
    @include header-home();

    >.content {
        .collection-cards {
            @include grid-table(auto, max-content, 2, 50px, 30px, 8);
            @include collection-card();

            @include respSpecif(900) {
                .description {
                    display: none;
                }
            }
        }
    }

    .footer {
        @include footer-home();
    }
}

.collection-edit-container {
    @include container-section();
    @include header();
    @include footer();
}

// 3.1 Categories

// All
.categories-container {
    @include container-section();
    @include header();

    >.content {
        @include grid-table(115px, 140px, 7, 20px, null, 99);
        @include grid-table-X(115px, 140px, 6, 20px, null, 99, 1100);
        @include grid-table-X(115px, 140px, 5, 20px, null, 99, 950);
        @include grid-table-X(115px, 140px, 4, 20px, null, 99, 750);
        @include padding(0 0 40px 0);
        @include category-card();
    }
}

// Placeholder

@keyframes glass {
    from {
        left: -200px;
    }

    to {
        left: calc(100% + 200px);
    }
}

.home-placeholder {
    @include container-section();

    &__banner {
        background-color: #efefef;
        border-radius: calc(1.2vw / 1);
        position: relative;
        overflow: hidden;

        .def {
            width: calc((100% - 20%) / 2);
            visibility: hidden;
        }

        .hoverEffect {
            position: absolute;
            background-color: red;
            height: 447px;
            width: 200px;
            top: 0;
            left: 100px;
            background: linear-gradient(90deg, rgb(239 239 239) 0%, #f9f9f98c 55%, rgb(239 239 239) 100%);
            animation-duration: 4s;
            animation-name: glass;
            animation-iteration-count: infinite;
            animation-direction: normal;
        }
    }

    &__section {
        @include container-section();

        .title {
            width: 380px;
            height: 27px;
            border-radius: 5px;
            margin-top: 30px;
            background-color: #efefef;
        }

        .subtitle {
            width: 260px;
            height: 20px;
            margin-top: 10px;
            border-radius: 5px;
            background-color: #efefef;
        }

        .items {
            margin-top: 40px;
            @include grid-table(115px, 140px, 7, 20px, null, 14);
            @include grid-table-X(115px, 140px, 6, 20px, null, 12, 1100);
            @include grid-table-X(115px, 140px, 5, 20px, null, 10, 950);
            @include grid-table-X(115px, 140px, 4, 20px, null, 8, 750);

            .item {
                height: 140px;

                &__img {
                    width: 66px;
                    height: 66px;
                    border-radius: 5px;
                    margin: 0 auto;
                    background-color: #efefef;
                }

                &__title {
                    width: 115px;
                    height: 19px;
                    border-radius: 5px;
                    margin: 0 auto;
                    margin-top: 10px;
                    background-color: #efefef;
                }
            }
        }
    }
}

// Home
.categories-home-container {
    @include container-section();
    @include header-home();

    >.category-cards {
        @include grid-table(115px, 140px, 7, 20px, null, 14);
        @include grid-table-X(115px, 140px, 6, 20px, null, 12, 1100);
        @include grid-table-X(115px, 140px, 5, 20px, null, 10, 950);
        @include grid-table-X(115px, 140px, 4, 20px, null, 8, 750);
        @include category-card();
    }

    >.category-cards-small {
        @include margin(50px 0 0 0);
        text-align: center;
        @include hide-childs(12, 15, inline-block);
        @include hide-childs-X(10, 11, inline-block, 1050);
        @include hide-childs-X(8, 12, inline-block, 830);
        @include category-card-small();
    }

    .footer {
        @include footer-home();
    }
}



// Edit
.category-edit-container {
    @include container-section();
    @include header();
    @include footer();
}

.user-inivations-edit-container {
    @include container-section();
    @include header();
    @include footer();
}

// Tags
.tags-container {
    @include container-section();
    @include header();

    >.content {
        @include tag-card();
    }
}

.def-locations-container {
    @include container-section();
    @include header();

    >.content {
        @include tag-card();
    }
}

.termsandcond-container {
    @include container-section();
    @include header();
    @include footer();

    >.content {
        @include tag-card();
    }
}

.tag-edit-container {
    @include container-section();
    @include header();
    @include footer();
}



// Users

.users-container {
    @include container-section();
    @include header();

    >.content {
        display: flex;
        @include filter();

        .users-box {
            width: 100%;

            .users {
                @include user-card();
                padding-bottom: 80px
            }

            .users-wide {
                @include grid-table(180px, max-content, 5, 115px, null, 99);
                @include grid-table-X(150px, max-content, 5, 115px, null, 99, 1100);
                @include grid-table-X(180px, max-content, 4, 115px, null, 99, 1000);
                @include grid-table-X(150px, max-content, 4, 115px, null, 99, 900);
                @include grid-table-X(160px, max-content, 3, 115px, null, 99, 750);
            }

            .users-collapse {
                @include grid-table(180px, max-content, 4, 115px, null, 99);
                @include grid-table-X(150px, max-content, 4, 115px, null, 99, 1100);
                @include grid-table-X(180px, max-content, 3, 115px, null, 99, 1000);
                @include grid-table-X(150px, max-content, 3, 115px, null, 99, 900);
                @include grid-table-X(160px, max-content, 2, 115px, null, 99, 750);
            }
        }
    }
}

.user-edit-container {
    @include container-section();
    @include header();
    @include footer();
}

// Suppliers
.suppliers-container {
    @include container-section();
    @include header();

    >.content {
        @include grid-table(200px, max-content, 5, 115px, null, 99);
        @include padding(0 0 40px 0);
        @include supplier-card();
    }
}

// Agreements
.offers-agreemnts-container {
    @include container-section();
    @include header();

    >.content {
        display: flex;
        @include filter();

        .agreements-box {
            width: 100%;

            .agreements {
                @include offer-agreement-card();
            }

            .agreements-wide {
                @include grid-table(auto, max-content, 4, 50px, 50px, 99);
                @include grid-table-X(auto, max-content, 4, 30px, 30px, 99, 1200);
                @include grid-table-X(auto, max-content, 3, 50px, 50px, 99, 1050);
                @include grid-table-X(auto, max-content, 3, 30px, 30px, 99, 900);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 99, 800);
                @include grid-table-X(auto, max-content, 2, 30px, 30px, 99, 700);
            }

            .agreements-collapse {
                @include grid-table(auto, max-content, 3, 50px, 50px, 99);
                @include grid-table-X(auto, max-content, 3, 30px, 30px, 99, 1250);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 99, 1150);
                @include grid-table-X(auto, max-content, 3, 50px, 50px, 99, 1000);
                @include grid-table-X(auto, max-content, 3, 30px, 30px, 99, 900);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 99, 800);
                @include grid-table-X(auto, max-content, 2, 30px, 30px, 99, 700);
            }
        }
    }
}

// Surveys
.surveys-container {
    @include container-section();
    @include header();

    >.content {
        display: flex;
        @include filter();

        .surveys-box {
            width: 100%;

            .surveys {
                @include survey-card();
            }

            .surveys-wide {
                @include grid-table(auto, max-content, 4, 50px, 50px, 99);
                @include grid-table-X(auto, max-content, 4, 30px, 30px, 99, 1200);
                @include grid-table-X(auto, max-content, 3, 50px, 50px, 99, 1050);
                @include grid-table-X(auto, max-content, 3, 30px, 30px, 99, 900);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 99, 800);
                @include grid-table-X(auto, max-content, 2, 30px, 30px, 99, 700);
            }

            .surveys-collapse {
                @include grid-table(auto, max-content, 3, 50px, 50px, 99);
                @include grid-table-X(auto, max-content, 3, 30px, 30px, 99, 1250);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 99, 1150);
                @include grid-table-X(auto, max-content, 3, 50px, 50px, 99, 1000);
                @include grid-table-X(auto, max-content, 3, 30px, 30px, 99, 900);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 99, 800);
                @include grid-table-X(auto, max-content, 2, 30px, 30px, 99, 700);
            }
        }
    }
}

.roadshow-planning-container {
    @include container-section();
    @include header();

    >.content {
        display: flex;
        @include filter();

        .roadshows-box {
            width: 100%;

            .roadshows {
                @include roadshow-planning-card();
            }

            .roadshows-wide {
                @include grid-table(auto, max-content, 4, 50px, 50px, 99);
                @include grid-table-X(auto, max-content, 4, 30px, 30px, 99, 1200);
                @include grid-table-X(auto, max-content, 3, 50px, 50px, 99, 1050);
                @include grid-table-X(auto, max-content, 3, 30px, 30px, 99, 900);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 99, 800);
                @include grid-table-X(auto, max-content, 2, 30px, 30px, 99, 700);
            }

            .roadshows-collapse {
                @include grid-table(auto, max-content, 3, 50px, 50px, 99);
                @include grid-table-X(auto, max-content, 3, 30px, 30px, 99, 1250);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 99, 1150);
                @include grid-table-X(auto, max-content, 3, 50px, 50px, 99, 1000);
                @include grid-table-X(auto, max-content, 3, 30px, 30px, 99, 900);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 99, 800);
                @include grid-table-X(auto, max-content, 2, 30px, 30px, 99, 700);
            }
        }
    }
}

.roadshow-proposals-container {
    @include container-section();
    @include header();

    >.content {
        display: flex;
        @include filter();

        .proposals-box {
            width: 100%;

            .proposals {
                @include roadshow-proposal-card();
            }

            .proposals-wide {
                @include grid-table(auto, max-content, 4, 50px, 50px, 99);
                @include grid-table-X(auto, max-content, 4, 30px, 30px, 99, 1200);
                @include grid-table-X(auto, max-content, 3, 50px, 50px, 99, 1050);
                @include grid-table-X(auto, max-content, 3, 30px, 30px, 99, 900);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 99, 800);
                @include grid-table-X(auto, max-content, 2, 30px, 30px, 99, 700);
            }

            .proposals-collapse {
                @include grid-table(auto, max-content, 3, 50px, 50px, 99);
                @include grid-table-X(auto, max-content, 3, 30px, 30px, 99, 1250);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 99, 1150);
                @include grid-table-X(auto, max-content, 3, 50px, 50px, 99, 1000);
                @include grid-table-X(auto, max-content, 3, 30px, 30px, 99, 900);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 99, 800);
                @include grid-table-X(auto, max-content, 2, 30px, 30px, 99, 700);
            }
        }
    }
}

.roadshow-offers-supplier-container {
    @include container-section();
    @include header();

    >.content {
        display: flex;
        @include filter();

        .offers-box {
            width: 100%;

            .offers {
                @include offer-roadshow-card();
            }

            >.offers-wide {
                @include grid-table(auto, max-content, 4, 50px, 25px, 100);
                @include grid-table-X(auto, max-content, 3, 50px, 50px, 100, 1200);
                @include grid-table-X(auto, max-content, 3, 50px, 25px, 100, 1100);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 100, 900);
                @include grid-table-X(auto, max-content, 2, 50px, 40px, 100, 800);
                @include grid-table-X(auto, max-content, 2, 50px, 25px, 100, 700);
            }

            >.offers-collapse {
                @include grid-table(auto, max-content, 3, 50px, 25px, 100);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 100, 1200);
                @include grid-table-X(auto, max-content, 2, 50px, 25px, 100, 1100);
                @include grid-table-X(auto, max-content, 3, 50px, 25px, 100, 1000);
                @include grid-table-X(auto, max-content, 2, 50px, 50px, 100, 900);
                @include grid-table-X(auto, max-content, 2, 50px, 40px, 100, 800);
                @include grid-table-X(auto, max-content, 2, 50px, 25px, 100, 700);
            }
        }
    }
}

.offer-agreement-container {
    @include container-section();
    @include header();

    >.content {
        .progress {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            >.data {
                position: absolute;
                left: 0;
                top: 0;

                >.date {
                    @include font-basic-semi-bold($c-black-light, 1.75rem);
                }

                >.id {
                    @include margin-top(5px);
                    @include font-basic-semi-bold($c-blue, 1.75rem);
                }
            }

            >.draft {
                @include image($icon-progress-draft-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;
                @include font-basic($c-grey, 1rem);
            }

            >.draft-active {
                @include image($icon-progress-draft-1, 95px, 125px, auto, 94px, top center);
                color: $c-green;
            }

            >.review {
                @include image($icon-progress-review-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;
                @include font-basic($c-grey, 1rem);
            }

            >.review-active {
                @include image($icon-progress-review-1, 95px, 125px, auto, 94px, top center);
                color: $c-green;
            }

            >.approved {
                @include image($icon-progress-approved-0, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;
                @include font-basic($c-grey, 1rem);
            }

            >.approved-active {
                @include image($icon-progress-approved-1, 95px, 125px, auto, 94px, top center);
                color: $c-green;
            }

            >.rejected {
                @include image($icon-progress-rejected-1, 95px, 125px, auto, 94px, top center);
                display: inline-flex;
                align-items: flex-end;
                justify-content: center;
                @include font-basic($c-red, 1rem);
            }

            >.rejected-active {
                @include image($icon-progress-rejected-1, 95px, 125px, auto, 94px, top center);
                color: $c-red;
            }

            >.union {
                @include image($icon-progress-union-0, 180px, 125px, auto, 14px, top 40px center);
            }

            >.union-active {
                @include image($icon-progress-union-1, 180px, 125px, auto, 14px, top 40px center);
            }
        }

        .location-choice {
            >.title {
                @include font-basic-bold($c-black-light, 1rem);
                margin-bottom: 20px;
            }

            >.f-checkbox {
                @include margin(0 0 10px 0);
            }

            >.f-basic-btn {
                @include margin(15px 0 0 0);
            }
        }

        .terms {
            .terms-conditions {
                max-height: 350px;
                overflow: auto;
                @include margin(0 0 30px 0);
                @include font-basic($c-black-light, $fs-basic);
                line-height: 1.3rem;

                p {
                    @include margin(0 0 15px 0);

                    &:last-of-type {
                        margin: 0;
                    }
                }

                @include scroll-vertical();
            }
        }

        .date-signature {
            .info {
                @include font-basic-bold($c-black-light, 1rem);
                @include margin(0 0 30px 0);
            }

            .stamp-c {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .f-basic {
                    width: calc(100% - 430px);
                }

                .f-recreation-services {
                    label {
                        width: 470px;
                    }

                    input {
                        width: calc(100% - 470px);
                    }
                }

                .stamp {
                    width: 400px;
                    @include padding(10px 0 0 30px);
                    @include font-basic-semi-bold($c-black-light, 1rem);
                }
            }
        }

        .attachments {
            .step-verifications {
                >.title {
                    @include font-basic-bold($c-black-light, 1rem);
                    margin-bottom: 20px;
                }

                .step {
                    margin-bottom: 15px;

                    .text {
                        @include font-basic($c-black-light, 0.95rem);
                        margin-bottom: 15px;
                    }

                    .actions {
                        margin: 15px 10px 0 10px;
                    }
                }


            }

            .attachments-list {
                .title {
                    @include font-basic-bold($c-black-light, 1rem);
                    margin-top: 15px;
                    margin-bottom: 10px;
                }

                .attachment {
                    margin-bottom: 7px;
                    display: flex;

                    .file-name {
                        @include font-basic-semi-bold($c-black-light, 0.95rem);
                        cursor: pointer;

                        &:hover {
                            color: $c-blue;
                        }
                    }

                    .delete {
                        @include image($icon-delete, 12px, 15px, auto, 14px, center);
                        margin-left: 6px;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }



            >.attachment {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                @include margin(0 0 20px 0);

                >.file-name {
                    @include font-basic-semi-bold($c-black-light, 1rem);
                    @include margin(0 20px 0 0);
                    cursor: pointer;
                }

                >.btn-download {
                    @include image($icon-download-0, auto, auto, auto, 17px, center right 15px);
                    @include padding(6px 40px 6px 20px);
                    @include font-basic-semi-bold($c-blue, 1rem);
                    border: 1px solid $c-blue;
                    border-radius: $radius-btn;
                    cursor: pointer;

                    &:hover {
                        @include image($icon-download-1, auto, auto, auto, 17px, center right 15px);
                        @include padding(6px 40px 6px 20px);
                        color: $c-white;
                        background-color: $c-blue;
                    }
                }
            }

            .note {
                @include margin-top(20px);
                @include font-basic($c-blue, 1rem);
            }

            .error {
                @include margin(10px 0 0 0);
                @include font-basic-semi-bold($c-red, 0.75rem);

                &:before {
                    content: "! ";
                }
            }
        }
    }

    >.footer {
        @include margin(30px 0 0 0);

        >.tools-box {
            display: flex;
            justify-content: flex-end;

            >* {
                @include margin(0 0 0 10px);
            }
        }
    }
}

.banners-edit-container {
    @include container-section();
    @include header();
    @include footer();

    .banners {
        @include grid-table(auto, max-content, 2, 50px, 30px, 100);
        @include banner-card(2);

        .banner-c {
            position: relative;

            &:hover {
                >.settings {
                    display: flex;
                }
            }

            .settings {
                top: -15px;
                right: -15px;
                position: absolute;
                display: hidden;
                background-color: $c-white;
                box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
                @include padding(0px 5px);
                border-radius: 5px;
                z-index: 1;
                cursor: initial;

                >.edit {
                    @include image($icon-edit, 15px, 15px, auto, 14px, center);
                    @include margin(7px);
                    cursor: pointer;
                }

                >.delete {
                    @include image($icon-delete, 12px, 15px, auto, 14px, center);
                    @include margin(7px);
                    cursor: pointer;
                }
            }
        }

        @include respSpecif(900) {
            @include grid-table(auto, max-content, 1, 50px, 30px, 100);
            @include banner-card(1);
        }
    }
}

.supplier-edit-container {
    @include container-section();
    @include header();
    @include footer();
}

.login-container {
    background: url("/assets/images/backgrounds/login_background.jpg") no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    margin-bottom: -50px; // layout fix
    position: relative;

    >.box {
        background-color: $c-white;
        border-radius: 13px;
        @include padding(35px 50px 30px 50px);
        width: 530px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        >.header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            @include margin(0 0 40px 0);
            height: 80px;

            >.title-box {
                >.title {
                    @include font-basic-semi-bold($c-blue, 2rem);

                    >u {
                        text-decoration: none;
                        border-bottom: 5px solid $c-blue;
                        @include padding(0 0 5px 0);
                    }
                }

                >.subtitle {
                    @include margin-top(25px);
                    @include font-basic-semi-bold($c-blue, 1.5rem);
                }
            }

            .logo-mazaya {
                @include image($img-logo-mazaya-1, 114px, 80px, auto, 66px, bottom 2px left);
            }

            >.logo {
                @include image($img-logo-adnoc-h, 200px, 100px, 200px, auto, center);
            }

            >.subtitle {
                margin-bottom: 4px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .subtitle_ar {
                    @include font-basic-bold(#002e6d, 1.1rem);
                    margin-bottom: 5px;
                }

                .subtitle_eng {
                    @include font-basic-bold(#002e6d, 1.1rem);
                }
            }
        }

        >.content {
            >.text {
                @include font-basic(#717171, 0.9rem);
                line-height: 1.3rem;

                >p {
                    @include margin(0 0 10px 0);
                }

                b {
                    @include font-basic-semi-bold(#002e6d, 0.9rem);
                }

                .link {
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }

                .bold-blue {
                    @include font-basic-semi-bold(#002e6d, 0.9rem);
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }

            >.options {
                margin-top: 35px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .btn-blue {
                    min-width: 160px;
                    // &:hover{
                    //     background-color: white;
                    //     color: #002E6D;
                    // }
                }
            }

            .options--centar {
                justify-content: center;

                .btn-blue {
                    margin: 0 15px;
                }
            }
        }

        >.footer {
            @include margin(50px 0 0 0);
            text-align: center;

            >.text {
                @include font-basic(#717171, $fs-basic);

                >b {
                    color: #002e6d;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }

            >.btn-blue {
                @include margin-top(20px);
                @include padding(6px 70px);
                padding-top: 10px;
            }
        }

        .copyright {
            position: absolute;
            bottom: -30px;
            left: 0;
            right: 0;
            text-align: center;
            @include font-basic(white, $fs-basic);
        }

        .user-counter {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 20px;
            margin-top: 50px;

            &--text {
                @include font-basic(#717171, 0.9rem);

                span {
                    @include font-basic-bold($c-blue, 0.9rem);
                }
            }
        }
    }
}

.login-info-container {
    background: url("/assets/images/backgrounds/register_background.png") no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    margin-bottom: -50px; // layout fix
    position: relative;

    >.box {
        background-color: $c-white;
        border-radius: 13px;
        @include padding(50px 50px 30px 50px);
        width: 500px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        >.header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            @include margin(0 0 40px 0);
            height: 80px;

            >.title-box {
                >.title {
                    @include font-basic-semi-bold($c-blue, 2rem);

                    >u {
                        text-decoration: none;
                        border-bottom: 5px solid $c-blue;
                        @include padding(0 0 5px 0);
                    }
                }

                >.subtitle {
                    @include margin-top(25px);
                    @include font-basic-semi-bold($c-blue, 1.5rem);
                }
            }

            .logo-mazaya {
                @include image($img-logo-mazaya-1, 114px, 80px, auto, 66px, bottom 2px left);
            }

            >.logo {
                @include image($img-logo-adnoc-h, 200px, 100px, 200px, auto, center);
            }

            >.subtitle {
                margin-bottom: 4px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .subtitle_ar {
                    @include font-basic-bold(#002e6d, 1.1rem);
                    margin-bottom: 5px;
                }

                .subtitle_eng {
                    @include font-basic-bold(#002e6d, 1.1rem);
                }
            }
        }

        >.content {
            >.text {
                @include font-basic(#717171, $fs-basic);
                line-height: 1.1rem;

                >p {
                    @include margin(0 0 10px 0);
                }

                >b {
                    @include font-basic-semi-bold($c-blue, $fs-basic);
                }

                .bold-blue {
                    @include font-basic-semi-bold($c-blue, $fs-basic);
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }

        >.footer {
            @include margin(50px 0 0 0);
            text-align: center;

            >.back {
                @include font-basic-semi-bold($c-blue, $fs-basic);
                cursor: pointer;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}

.company-register {
    background: url("/assets/images/backgrounds/register_background.png") no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    margin-bottom: -50px; // layout fix
    position: relative;

    .box {
        background-color: #fff;
        width: 450px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include margin(20px 0);
        min-height: 590px;
        display: flex;
        flex-direction: column;
        padding: 40px;
        gap: 25px;
        border-radius: 4px;

        .cancel-button {
            display: flex;
            flex-direction: row;
            gap: 10px;
            cursor: pointer;

            .cancel-button-arrow {
                @include image($img-navbar-sub-arrowLeft,
                    14px,
                    14px,
                    14px,
                    auto,
                    center);
            }
        }

        .logo {
            @include image($img-logo-mazaya-1,
                114px,
                80px,
                auto,
                66px,
                bottom 2px left);
        }

        .input-wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            width: 100%;
            margin-bottom: 10rem;
        }

        .input-array {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;

            .password-array {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                width: 100%;

                input {
                    padding: 20px;
                    border-radius: 10px;
                    border: 1px solid $c-grey-light;
                    font-size: 14px;
                    width: 60%;

                    &::placeholder {
                        font-size: 14px;
                    }

                    &:focus {
                        border: 1px solid $c-blue;
                    }
                }
            }

            .error-message {
                position: absolute;
                bottom: -20px;
                font-size: 14px;
                color: $c-red;

                &--upload {
                    bottom: -25px;
                }

                &--date {
                    left: 90px;
                    top: 66px;
                }
            }

            .custom-file-upload {
                border: 2px dashed $c-grey-light;
                border-radius: 10px;
                padding: 20px;
                width: 60%;
                background-color: $c-white;
                position: relative;

                input {
                    opacity: 0;
                    position: absolute;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }

                label {
                    font-size: 14px;
                    color: $c-grey-light;
                }
            }

            input {
                padding: 20px;
                border-radius: 10px;
                border: 1px solid $c-grey-light;
                font-size: 14px;
                width: 60%;

                &::placeholder {
                    font-size: 14px;
                }

                &:focus {
                    border: 1px solid $c-blue;
                }
            }

            button {
                background-color: $c-blue;
                color: $c-white;
                font-size: 14px;
                padding: 20px;
                border-radius: 10px;
                border: none;
                width: 55%;
            }
        }
    }

    .sublabel {
        font-size: 12px;
        font-weight: bold;
        color: #8b8b8b;
    }
}

.license-agreement-container {
    background: url("/assets/images/backgrounds/register_background.png") no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    margin-bottom: -50px; // layout fix
    position: relative;

    .box {
        background-color: #fff;
        width: 70%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        padding: 40px;
        gap: 20px;
        border-radius: 10px;

        .cancel-button {
            display: flex;
            flex-direction: row;
            gap: 10px;
            cursor: pointer;

            .cancel-button-arrow {
                @include image($img-navbar-sub-arrowLeft,
                    14px,
                    14px,
                    14px,
                    auto,
                    center);
            }
        }

        .logo {
            @include image($img-logo-mazaya-1,
                114px,
                80px,
                auto,
                66px,
                bottom 2px left);
        }

        ul {
            li {
                font-size: 12px;
                padding: 4px 0;
            }
        }

        .terms-agreement {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
        }

        .button-wrapper {
            display: flex;
            justify-content: center;

            button {
                background-color: $c-blue;
                color: $c-white;
                font-size: 16px;
                padding: 20px;
                border-radius: 10px;
                border: none;
                width: 35%;
            }
        }
    }
}


.register-container {
    background: url("/assets/images/backgrounds/login_background.jpg") no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    margin-bottom: -50px; // layout fix
    position: relative;

    .box {
        width: 1050px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include margin(20px 0);
        min-height: 610px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .form-container {
            position: relative;
            width: 470px;

            background-color: $c-white;
            border-radius: 25px;
            @include padding(20px);

            .title {
                @include margin-top(20px);
                @include font-basic-semi-bold($c-blue, 1.5rem);
                text-align: center;
            }

            .tabs {
                display: flex;
                justify-content: center;
                @include margin-top(30px);

                .tab {
                    @include font-basic-semi-bold($c-grey-light, 1rem);
                    @include margin(0 13px);
                    cursor: pointer;
                    @include padding(0 0 10px 0);

                    &:hover {
                        color: $c-blue;
                    }
                }

                .tab-active {
                    color: $c-blue;
                    border-bottom: 2px solid $c-blue;
                }
            }

            form {
                @include margin-top(30px);

                .f-basic,
                .f-textarea {
                    @include margin(0 0 20px 0);

                    label,
                    .label {
                        @include font-basic-semi-bold($c-grey, 0.9rem);
                        width: 220px;
                    }

                    input,
                    textarea {
                        @include font-basic($c-grey, 0.9rem);
                    }

                    textarea {
                        height: 75px;

                        &::placeholder {
                            color: #c4c4c4 !important;
                        }
                    }
                }
            }

            .conf {
                position: absolute;
                bottom: 40px;
                left: 0;
                right: 0;
                margin: auto;
                @include padding(6px 35px);
            }

            .register-footer {
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                text-align: center;
                bottom: 15px;
                @include font-basic($c-grey, $fs-basic);

                >b {
                    color: $c-blue;
                    cursor: pointer;
                }
            }
        }

        .right {
            width: 470px;
            @include padding(40px 0);

            .logo {
                @include image($img-logo-adnoc-h, 200px, 100px, 200px, auto, center);
            }

            .welcome {
                @include margin-top(20px);
                @include font-basic-semi-bold($c-white, 1.4rem);
                line-height: 1.9rem;
            }
        }
    }
}

// Baners
.banners-home-container {
    @include container-section();
    @include banner-card(1);
}

.banners-container {
    @include container-section();
    @include header();

    .content {
        @include grid-table(auto, max-content, 2, 50px, 30px, 100);
        @include banner-card(2);

        @include respSpecif(900) {
            @include grid-table(auto, max-content, 1, 50px, 30px, 100);
            @include banner-card(1);
        }
    }
}

// User-Invention
.user-invitation-container {
    @include container-section();
    @include header();

    .approve_btn {
        @include image($icon-approve-0, 19px, 30px, auto, 18px, center);
        cursor: pointer;

        &:hover {
            @include image($icon-approve-1, 19px, 30px, auto, 18px, center);
        }
    }

    .content {
        .table-user-invitation {
            .table-body {
                .col-user {
                    @include font-basic-bold($c-black, 0.9rem);
                    line-height: 1rem;
                }

                .col-type {
                    @include font-basic($c-black, 0.9rem);

                }

                .col-options {
                    @include font-basic-semi-bold($c-black, 0.9rem);

                    >* {
                        margin-left: 10px;
                    }

                    .delete {
                        @include image($icon-delete, 12px, 15px, auto, 14px, center);
                        @include margin(7px);
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }

            .col-user {
                flex-grow: 1;
            }

            .col-type {
                width: 300px;
            }

            .col-options {
                width: 100px;
            }

            .col-options-btn {
                width: 75px;
                display: flex;
                justify-content: center;
            }

            .col-date {
                width: 200px;
            }

            .col-suggestion {
                width: 580px;
                margin-right: 25px;

                &--wider {
                    width: 669px;
                }
            }

            .col-buyer {
                @include font-basic($c-black, 0.9rem);

                .buyer {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    cursor: pointer;
                    line-height: 1.3rem;

                    &:hover {
                        color: $c-blue;
                    }
                }

                @include hide-X(900);
            }

            // TODO: Mladen to change
            .col-data-overflow {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.mail-storage-container {
    @include container-section();
    @include header();

    .content {
        .table-mail-storage {
            .table-body {
                .col-userEmail {
                    @include font-basic-bold($c-black, 0.9rem);
                    line-height: 1rem;
                }

                .col-subject {
                    @include font-basic($c-black, 0.9rem);

                }

                .col-date {
                    @include font-basic($c-black, 0.9rem);

                }

                .col-status {
                    @include font-basic-semi-bold($c-black, 0.9rem);
                }
            }

            .col-userEmail {
                width: 300px;
            }

            .col-subject {
                flex-grow: 1;
            }

            .col-date {
                width: 200px;
            }

            .col-status {
                width: 150px;
            }
        }
    }
}

// User-Invention
.domains-container {
    @include container-section();
    @include header();

    .content {
        .table-domains {
            .table-body {
                .col-type {
                    @include font-basic-bold($c-black, 0.9rem);
                }

                .col-key {
                    @include font-basic($c-black, 0.9rem);

                }

                .col-domains {
                    @include font-basic($c-black, 0.9rem);

                }

                .col-options {
                    @include font-basic-semi-bold($c-black, 0.9rem);
                    display: flex;

                    >* {
                        margin-left: 10px;
                    }

                    .edit {
                        @include image($icon-edit, 20px, 15px, auto, 14px, center);
                        @include margin(7px);
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }
                    }

                    .delete {
                        @include image($icon-delete, 12px, 15px, auto, 14px, center);
                        @include margin(7px);
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }

            .col-type {
                width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
            }


            .col-key {
                width: 300px;

            }

            .col-domains {
                flex-grow: 1;

            }

            .col-options {
                width: 50px;
            }
        }
    }
}

// Suppliers
.suppliers-pending-container {
    @include container-section();
    @include header();

    .content {
        .table-suppliers-pending {
            .table-body {
                .col-suppliers {
                    @include font-basic-bold($c-black, 0.9rem);
                }

                .col-contact {
                    @include font-basic($c-black, 0.9rem);
                    line-height: 1.3rem;
                    word-break: break-word;

                    .contact_mail {
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        cursor: pointer;

                        &:hover {
                            color: $c-blue;
                        }
                    }

                    .contact_phone {
                        cursor: pointer;

                        &:hover {
                            color: $c-blue;
                        }
                    }
                }

                .col-description {
                    @include font-basic($c-black, 0.9rem);
                    line-height: 1.3rem;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    cursor: pointer;
                    @include hide-X(1300);
                }

                .col-status {
                    @include font-basic($c-black, 0.9rem);
                    line-height: 1.3rem;
                    display: -webkit-box;
                    overflow: hidden;
                    cursor: pointer;
                    @include hide-X(1300);
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                .col-options {
                    @include font-basic-semi-bold($c-black, 0.9rem);
                    display: flex;
                    justify-content: flex-end;

                    >* {
                        margin-left: 10px;
                    }

                    .approve_btn {
                        @include image($icon-approve-0, 19px, 30px, auto, 18px, center);
                        cursor: pointer;

                        &:hover {
                            @include image($icon-approve-1, 19px, 30px, auto, 18px, center);
                        }
                    }

                    .reject_btn {
                        @include image($icon-reject-0, 20px, 30px, auto, 19px, center);
                        cursor: pointer;

                        &:hover {
                            @include image($icon-reject-1, 20px, 30px, auto, 19px, center);
                        }
                    }

                    .description_btn {
                        display: none;
                        @include image($icon-comment-0, 16px, 30px, auto, 15px, center);
                        cursor: pointer;

                        &:hover {
                            @include image($icon-comment-1, 16px, 30px, auto, 15px, center);
                        }

                        @include show-X(block, 1300);
                    }
                }
            }

            .col-suppliers {
                flex-grow: 1;
            }

            .col-contact {
                width: 250px;
            }

            .col-status {
                width: 250px;
            }

            .col-description {
                width: 400px;
                @include hide-X(1300);
            }

            .col-options {
                width: 100px;
            }
        }

        // >.suppliers {

        //     >.supplier {
        //         display: flex;
        //         background-color: white;
        //         box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
        //         border-radius: 10px;
        //         @include padding(20px 0);
        //         @include margin(0 0 20px 0);

        //         >.company-name {
        //             display: flex;
        //             align-items: center;
        //             justify-content: center;
        //             flex: 0 0 150px;
        //             @include padding(0 15px);
        //             flex-direction: column;
        //             align-items: center;
        //             justify-content: center;
        //             border-right: 1px solid $c-grey-line;

        //             @include font-basic-bold($c-black, 1rem);
        //             text-align: center;
        //             line-height: 1.3rem;

        //         }

        //         >.contacts {
        //             display: flex;
        //             align-items: center;
        //             justify-content: center;
        //             flex: 0 0 300px;
        //             @include padding(0 15px);
        //             flex-direction: column;
        //             align-items: flex-start;
        //             justify-content: center;
        //             border-right: 1px solid $c-grey-line;

        //             >.contact {
        //                 @include font-basic($c-black, 1rem);
        //                 line-height: 1.3rem;
        //             }
        //         }

        //         >.description {
        //             display: flex;
        //             align-items: center;
        //             justify-content: center;
        //             @include padding(0 15px);
        //             flex-direction: column;
        //             align-items: flex-start;
        //             border-right: 1px solid $c-grey-line;
        //             width: 41%;

        //             >.label {
        //                 @include font-basic-semi-bold($c-black, 1rem);
        //             }

        //             >.data {
        //                 @include font-basic($c-black, 0.9rem);
        //                 line-height: 1rem;
        //                 height: 50px;
        //                 margin-top: 5px;
        //                 padding-right: 5px;
        //                 overflow-y: auto;
        //                 @include scroll-vertical();
        //             }
        //         }

        //         >.options {
        //             display: flex;
        //             align-items: center;
        //             justify-content: center;
        //             flex: 0 0 150px;
        //             @include padding(0 15px);
        //             flex-direction: row;
        //             align-items: center;

        //             >.btn {
        //                 width: 50px;
        //                 text-align: center;

        //                 &:first-child {
        //                     margin-right: 10px;
        //                 }
        //             }
        //         }
        //     }
        // }
    }
}

.suppliers-edit-container {
    @include container-section();
    @include header();
    @include footer();
}

// Offer rating

.offers-rating-container {
    @include container-section();
    @include header();
    @include footer();

    .content {
        .table-rating {
            .table-body {
                .col-buyer {
                    @include font-basic($c-black, 0.9rem);

                    .buyer {
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        cursor: pointer;
                        line-height: 1.3rem;

                        &:hover {
                            color: $c-blue;
                        }
                    }

                    @include hide-X(900);
                }

                .col-offer {
                    @include font-basic($c-black, 0.9rem);
                    line-height: 1.3rem;
                    word-break: break-word;

                    .offer__data {
                        display: inline-block;
                        @include font-basic-bold($c-black, 0.9rem);
                        cursor: pointer;

                        &:hover {
                            color: $c-blue;
                        }
                    }
                }

                .col-rate {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .rate__date {
                        @include font-basic($c-black, 0.9rem);
                        line-height: 1.3rem;
                    }

                    >.rate__rating {
                        position: relative;
                        display: inline-block;
                        margin-bottom: 5px;
                        @include image($icon-stars-empty, 78px, 14px, auto, 13px, center left);

                        >.rating-positive {
                            position: absolute;
                            @include image($icon-stars-full, 0%, 14px, auto, 13px, center left);
                        }
                    }
                }

                .col-comment {
                    @include font-basic($c-black, 0.9rem);
                    line-height: 1.3rem;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    @include hide-X(1300);
                    cursor: pointer;
                }

                .col-visible {
                    @include hide-X(1300);

                    >.visible__btn {
                        @include image($icon-visible-0, 50px, 30px, auto, 10px, center);
                        cursor: pointer;
                    }

                    >.visible__btn--active {
                        @include image($icon-visible-1, 50px, 30px, auto, 10px, center);
                        cursor: pointer;
                    }
                }

                .col-options {
                    align-items: center;
                    justify-content: flex-end;

                    >.visible_btn {
                        @include image($icon-visible-0, 30px, 30px, auto, 10px, center);
                        cursor: pointer;
                    }

                    >.visible_btn--active {
                        @include image($icon-visible-1, 30px, 30px, auto, 10px, center);
                        cursor: pointer;
                    }

                    .comment_btn {
                        @include image($icon-comment-0, 30px, 30px, auto, 14px, center);
                        @include margin(7px);
                        cursor: pointer;

                        &:hover {
                            @include image($icon-comment-1, 30px, 30px, auto, 14px, center);
                        }
                    }
                }
            }

            .col-buyer {
                min-width: 250px;
                max-width: 250px;
                @include hide-X(900);
            }

            .col-offer {
                flex-grow: 1;

            }

            .col-rate {
                min-width: 150px;
                max-width: 150px;
            }

            .col-comment {
                min-width: 500px;
                max-width: 500px;
                @include hide-X(1300);
            }

            .col-visible {
                width: 50px;
                @include hide-X(1300);
            }

            .col-options {
                width: 100px;
                display: none;
                @include show-X(flex, 1300);
            }
        }

        // >.ratings-header {
        //     display: flex;
        //     background-color: $c-cream;
        //     // box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
        //     border: 1px solid #cecece;
        //     border-radius: 10px;
        //     @include padding(20px 15px);
        //     @include margin(0 0 20px 0);

        //     >*{
        //         display: flex;
        //         align-items: center;
        //         justify-content: left;
        //         @include padding(0 5px);
        //         @include font-basic-semi-bold($c-black, $fs-basic);
        //     }

        // 	>.buyer {
        //         flex: 0 0 200px;

        // 	}

        // 	>.offer {
        //         flex: 0 0 200px;

        // 	}

        // 	>.rate {
        //         flex: 0 0 100px;
        //         justify-content: center;

        // 	}

        // 	>.comment {
        //         width: 100%;
        // 	}

        // 	>.visible {
        //         flex: 0 0 60px;
        //         justify-content: center;

        // 	}
        // }

        // >.ratings-content {

        // 	>.rating {
        //         display: flex;
        //         background-color: white;
        //         box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
        //         border-radius: 10px;
        //         @include padding(20px 15px);
        //         @include margin(0 0 20px 0);

        //         >*{
        //             display: flex;
        //             align-items: center;
        //             justify-content: left;
        //             @include padding(0 5px);
        //             @include font-basic-semi-bold($c-black, $fs-basic);
        //         }

        // 		>.buyer {
        //             flex: 0 0 200px;
        //             flex-direction: column;
        //             align-items: flex-start;

        // 			>.bayer-name {

        // 			}

        // 			>.bayer-lastaname {
        //                 margin-top: 5px;
        // 			}
        // 		}

        // 		>.offer {
        //             flex: 0 0 200px;
        //             flex-direction: column;
        //             align-items: flex-start;

        // 			>.offer-supplier {

        // 			}

        // 			>.offer-data {
        //                 margin-top: 5px;
        // 			}
        // 		}

        // 		>.rate {
        //             flex: 0 0 100px;
        //             justify-content: center;
        //             flex-direction: column;
        //             >.rating {
        //                 position: relative;
        //                 display: inline-block;
        //                 margin: 0 10px;
        //                 @include image($icon-stars-empty, 78px, 14px, auto, 13px, center left);

        //                 >.rating-positive {
        //                     position: absolute;
        //                     @include image($icon-stars-full, 0%, 14px, auto, 13px, center left);

        //                 }
        //             }
        //             >.date{
        //                 margin-bottom: 5px;
        //             }

        // 		}

        // 		>.comment {
        //             width: 100%;
        //             @include font-basic($c-black, $fs-basic);
        //             line-height: 1rem;
        //             cursor: pointer;
        // 		}

        // 		>.visible {
        //             flex: 0 0 60px;
        //             justify-content: center;
        //             >.visible-btn{
        //                 @include image($icon-visible-0, 30px, 30px, auto, 11px, center);
        //                 cursor: pointer;
        //             }
        //             >.visible-btn-active{
        //                 @include image($icon-visible-1, 30px, 30px, auto, 11px, center);
        //                 cursor: pointer;
        //             }
        // 		}
        // 	}
        // }
    }
}


.email-templates-container {
    @include container-section();
    @include header();
    @include footer();

    .content {
        .table-rating {
            .table-body {
                .col-name {
                    @include font-basic-bold($c-black, 0.9rem);
                    line-height: 1.3rem;
                    cursor: pointer;

                    &:hover {
                        color: $c-blue;
                    }
                }

                .col-message,
                .col-notification,
                .col-sms {
                    @include font-basic($c-black, 0.9rem);
                    line-height: 1.3rem;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    @include hide-X(1300);
                }
            }

            .col-name {
                flex-grow: 1;
            }

            .col-message {
                min-width: 200px;
                max-width: 200px;
                @include hide-X(1300);
            }

            .col-notification {
                min-width: 200px;
                max-width: 200px;
                @include hide-X(1300);
            }

            .col-sms {
                min-width: 200px;
                max-width: 200px;
                @include hide-X(1300);
            }

            .col-mailTemplateType {
                width: 100px;
            }

            .col-notificationType {
                width: 100px;
            }
        }
    }
}

.email-template-edit-container {
    @include container-section();
    @include header();
    @include footer();
}

.supplier-announcement-container {
    @include container-section();
    @include header();
    @include footer();

    >.content {

        .f-select-multiple {
            margin-left: 4.5%;
            display: inline-block;
            width: 100%;

            >.error {
                bottom: -20px;
                right: 0;
                @include font-basic-semi-bold($c-red, 0.75rem);


                &:before {
                    content: "! ";
                }
            }
        }

        .req {
            display: inline-block;
            @include font-basic-semi-bold($c-black-light, 1rem);
            margin-right: 2%;
            margin-bottom: 1%;

            &::after {
                @include font-basic-bold($c-blue, 1rem);
                content: "*";
                margin-left: 5px;
                left: 1px !important;
            }

        }

        .f-checkbox {
            display: inline-block;
            margin-right: 2%;

            label {
                @include font-basic-semi-bold($c-black-light, 0.9rem);
            }
        }

        .attachments {
            >.attachment {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                @include margin(0 0 20px 0);

                >.file-name {
                    @include font-basic-semi-bold($c-black-light, 1rem);
                    @include margin(0 20px 0 0);
                    cursor: pointer;

                    &:hover {
                        color: $c-blue;
                    }
                }
            }

            >.no-attachments {
                @include font-basic-semi-bold($c-black-light, 1rem);
            }

            .note {
                @include margin-top(20px);
                @include font-basic($c-blue, 1rem);
            }
        }

        display: flex;
        @include filter();
    }
}

// Companies / Suppliers
.companies-container {
    @include container-section();
    @include header();

    .content {
        .table-company {
            .table-body {
                .col-company {
                    display: flex;
                    flex-basis: 0;
                    align-items: center;

                    .logo {
                        width: 60px;
                        @include margin(0 10px 0 0);
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }
                    }

                    >.name {
                        @include font-basic-bold($c-black, 1rem);
                        line-height: 1.2rem;
                        cursor: pointer;

                        &:hover {
                            color: $c-blue;
                        }
                    }
                }

                .col-contacts {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;

                    >.contact {
                        @include font-basic($c-black, 0.9rem);
                        line-height: 1.3rem;
                        word-break: break-word;
                        cursor: pointer;

                        &:hover {
                            color: $c-blue;
                        }
                    }

                    @include hide-X(900);
                }

                .col-options {
                    display: flex;
                    justify-content: flex-end;

                    .edit {
                        @include image($icon-edit, 15px, 15px, auto, 14px, center);
                        @include margin(7px);
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }
                    }

                    .delete {
                        @include image($icon-delete, 12px, 15px, auto, 14px, center);
                        @include margin(7px);
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }

            .col-company {
                flex-grow: 1;
            }

            .col-contacts {
                width: 300px;
                @include hide-X(900);
            }

            .col-options {
                width: 100px;
            }
        }
    }
}

.company-edit-container {
    @include container-section();
    @include header();
    @include footer();

    .content {
        .location {
            .map {
                width: 100%;
            }

            .location-choice {
                >.title {
                    @include font-basic-bold($c-black-light, 1rem);
                    @include margin(0 0 15px 0);
                }

                >.f-checkbox {
                    @include margin(0 0 10px 0);
                }

                >.f-basic-btn {
                    @include margin(15px 0 0 0);
                }
            }

            @include respSpecif(1100) {
                .col-2 {
                    width: 100%;
                }

                .location-choice {
                    margin-top: 30px;
                }
            }
        }

        .focal-points {
            @include user-card();
            @include grid-table(180px, max-content, 6, 115px, null, 99);
            @include grid-table-X(150px, max-content, 6, 115px, null, 99, 1100);
            @include grid-table-X(180px, max-content, 5, 115px, null, 99, 1000);
            @include grid-table-X(150px, max-content, 5, 115px, null, 99, 900);
            @include grid-table-X(160px, max-content, 4, 115px, null, 99, 750);
            padding-bottom: 80px;
        }
    }
}

.survey-edit-container {
    @include container-section();
    @include header();
    @include footer();

    .questions {
        .question {
            margin-bottom: 20px;
            position: relative;

            &__delete {
                position: absolute;
                right: 20px;
                top: 20px;
                @include image($icon-delete, 12px, 15px, auto, 14px, center);
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .multiplechoice {
        .options {
            margin-bottom: 0;
        }

        .option {
            &__settings {
                top: -20px;
                right: -30px;
                position: absolute;
                display: none;
                background-color: $c-white;
                box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
                @include padding(0px 5px);
                border-radius: 5px;
                z-index: 1;
                cursor: initial;
            }

            &__delete {
                @include image($icon-delete, 12px, 15px, auto, 14px, center);
                @include margin(7px);
                cursor: pointer;
            }

            &:hover {
                .option__settings {
                    display: block;
                }
            }

        }

        .option-add {
            @include font-basic-semi-bold($c-grey, 1rem);
            cursor: pointer;

            &:hover {
                color: $c-blue;
            }
        }
    }

}

//117

.survey-results-container {
    @include container-section();
    @include header();
    @include footer();

    .question {
        margin-bottom: 20px;

        &__numeration {
            @include font-basic-semi-bold($c-black, 0.9rem);
        }

        &__text {
            margin-top: 10px;
            @include font-basic($c-black, 0.8rem);
            line-height: 1.1rem;
            overflow-wrap: break-word;

        }

        &__answer {
            margin-top: 30px;
        }

    }

    .qap-p-multiplechoice {
        width: 100%;
        display: flex;
        align-items: stretch;

        .multiplechoice-chart-labels {
            width: 100px;
            min-width: 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding-bottom: 30px;
            padding-top: 10px;

            .bar-label {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;
                text-align: right;
            }
        }
    }

    .qap-p-basic {
        .table {
            .col-answer-hideContent {
                overflow: hidden;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                word-break: break-word;
            }
        }

        .show-more {
            display: flex;
            align-items: center;
            justify-content: center;

            &__btn {
                @include font-basic($c-blue, 0.9rem);
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .empty-results {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;

        .sub-title {
            @include font-basic($c-grey-light, $fs-section-subtitle);
        }
    }
}


// 117
.survey-answers {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include image($bc-survey, auto, auto, auto, 100%, center);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    &__box {
        width: 75%;
    }

    &__header {
        display: flex;
        height: 100px;
        align-items: center;
        justify-content: center;
        align-items: flex-start;
    }

    &__logo {
        @include image($img-logo-mazaya-3, 100px, 60px, 80px, auto, center);
    }

    &__main {}

    .start {
        &__title {
            @include font-basic-semi-bold($c-white, 1.2rem);
            text-align: center;
        }

        &__description {
            margin-top: 20px;
            @include font-basic-semi-bold($c-white, 1rem);
            line-height: 1.5rem;
            word-break: break-word;
            text-align: justify;
            padding: 10px;
            max-height: 340px;
            overflow-y: scroll;
            padding-right: 15px;

            &:hover {
                padding-right: 10px;
            }

            &::-webkit-scrollbar {
                width: 5px;
                display: none;
            }

            &:hover {
                &::-webkit-scrollbar {
                    width: 5px;
                    display: block;
                }
            }

            /* Track */
            &::-webkit-scrollbar-track {
                // display: block;
                background: #f1f1f1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #888;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }

        &__footer {
            margin-top: 50px;
            display: flex;
            justify-content: center;
        }
    }

    .question {
        &__box {
            background-color: $c-white;
            border-radius: 15px;
            padding: 20px;
        }

        &__header {
            @include font-basic-bold($c-black, 0.8rem);
        }

        &__text {
            margin-top: 10px;
            word-break: break-word;
            @include font-basic($c-black, 0.8rem);
            line-height: 1.1rem;
        }

        &__content {
            margin-top: 20px;
            min-height: 170px;
        }

        &__footer {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
        }
    }

    .sw-btn {
        &--white {}
    }
}

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

// 4. Form

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

// Standard

@mixin f-standard {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    @include margin(0 0 30px 0);

    >label {
        display: inline-block;
        min-width: 115px;
        width: 115px;
        @include margin(0 10px 0 0);
        @include font-basic-semi-bold($c-black-light, 1rem);
        line-height: 1.2rem;
    }

    >.help-btn {
        right: 0px;
        bottom: 5px;
        @include image($icon-help-0, 17px, 17px, auto, 16px, center);
        position: absolute;
        cursor: pointer;
    }

    >.help {
        position: absolute;
        background-color: $c-blue;
        @include font-basic(#f1f1f1, $fs-basic);
        line-height: 1.1rem;
        padding: 10px;
        border-radius: 5px;
        z-index: 1000;

        top: 37px;
        width: calc(100% - 175px);
        right: 0;
    }

    >.req {
        &::after {
            @include font-basic-bold($c-blue, 1rem);
            content: "*";
            margin-left: 5px;
            left: 1px !important;
        }
    }

    >.limit {
        position: absolute;
        right: 0;
        bottom: 8px;
        @include font-basic-semi-bold($c-blue, 0.8rem);
        background-color: white;
    }

    input {
        background: transparent;
        outline: none;
        border: none;
        border-bottom: 1px solid #c4c4c4;
        width: 100%;
        @include font-basic(#505050, 1rem);
        line-height: 1.7rem;
        padding: 2px 5px 0px 2px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
        box-shadow: 0 1px 0 transparent;

        @include placeHolderInput() {
            @include font-basic(#c4c4c4, 1rem);
            line-height: 1.7rem;
            // font-style: italic;
        }
    }

    input[type="checkbox"] {
        display: none;

        &+label {
            display: block;
            position: relative;
            padding-left: 25px;
            line-height: 30px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
        }

        &+label:before {
            content: "";
            display: block;
            margin-top: 7px;
            width: 15px;
            height: 15px;
            border: 1px solid #c4c4c4;
            border-radius: 2px;
            position: absolute;
            left: 0;
            top: 0;
        }

        &:checked+label:before {
            background-color: $c-blue !important;
            border: 1px solid $c-blue;
        }

        &:checked+label:after {
            content: "";
            position: absolute;
            left: 6px;
            top: 8px;
            width: 3px;
            height: 7px;
            border: 1.6px solid white;
            border-top-color: transparent;
            border-left-color: transparent;
            transform: rotate(45deg);
        }

        &:disabled+label:before {
            background-color: #f8f8f8;
            cursor: not-allowed;
        }

        &:disabled+label {
            cursor: not-allowed;
            opacity: 0.7;

            &:hover {
                color: #333333;
            }
        }
    }

    .error {
        position: absolute;
        bottom: -20px;
        right: 0;
        @include font-basic-semi-bold($c-red, 0.75rem);

        &:before {
            content: "! ";
        }
    }

    .error-left {
        right: auto;
    }

    // Hover
    input:hover,
    textarea:hover,
    select:hover {
        background-color: #ffffff04;
    }

    // Focus
    input:focus,
    textarea:focus,
    select:focus {
        border-color: $c-blue;
        box-shadow: 0 1.5px 0px -0.5px $c-blue;
        transition: border-color 0.13s ease-in-out, box-shadow 0.13s ease-in-out, -webkit-box-shadow 0.13s ease-in-out;
    }

    // Readonly
    input:read-only,
    textarea:read-only,
    select:read-only {
        background-color: #ffffff08;
    }

    // Disabled
    input:disabled,
    textarea:disabled,
    select:disabled {
        background-color: #f8f8f8;
        border-radius: 5px;
        border: none;
        cursor: not-allowed;
    }

    // @include f-option-add();
    // @include f-option-delete();
    // @include f-option-readonly();
    // @include f-option-disabled();
}

.f-section {
    @include font-basic-bold($c-black, 1rem);
    @include margin(0 0 20px 0);
}

.f-long {
    @include f-standard();

    label {
        min-width: 250px;
        width: 250px;
    }
}

.f-basic {
    @include f-standard();

    .invalid,
    .unresponsive,
    .other {
        white-space: nowrap !important;
    }
}

.report-basic {
    margin: 0 0 10px 0;
}

.f-phone {
    @include f-standard();

    .intl-tel-wrapper {
        width: 100%;

        .iti {
            display: block;
        }
    }

    input {
        padding-left: 88px !important;

        &::placeholder {
            color: lighten(#333, 40);
            font-style: normal;
        }
    }

    input[disabled] {
        padding-left: 81px !important;
        padding-top: 1px;
    }

    .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
        background-color: transparent !important;
    }

    #country-search-box {
        padding-left: 5px !important;
    }

    .iti__arrow {
        margin-left: 4px;
    }

    .selected-dial-code {
        text-align: right;
        min-width: 28px;
    }

    .iti__country-list {
        @include scroll-vertical();
    }

    .dropdown-menu {
        margin: 9px 0 0 !important;
        border: none !important;
        box-shadow: -1px 3px 8px rgb(0 0 0 / 16%);
    }

    .iti__country-list {
        box-shadow: none;
    }

    .iti__selected-flag[disabled="true"] {
        width: 75px !important;
        background-color: #f1f1f1;

        .iti__arrow {
            display: none !important;
        }
    }
}

.f-basic-table {
    background: transparent;
    outline: none;
    border: none;
    width: 100%;
    @include font-basic(#505050, 1rem);
    line-height: 1.1rem;
}

.f-basic-btn {
    @include f-standard();

    label {
        width: 160px;
    }

    input {
        width: calc(100% - 160px - 160px);
    }

    button {
        margin-left: 15px;
    }
}

.f-select-row {
    @include f-standard();

    label {
        width: 160px;
    }

    .select-area {
        width: calc(100% - 180px);
    }
}

.f-checkbox {
    @include f-standard();
    justify-content: flex-start;

    label {
        min-width: auto;
        width: auto;

        >.help {
            @include font-basic($c-black-light, 1rem);
        }

        &:hover {
            color: $c-blue;
        }
    }
}


.f-radio-s {
    max-height: 160px;
    overflow-y: scroll;
    @include scroll-vertical();

    &__opt {
        margin-bottom: 10px;
    }

    label {
        @include font-basic($c-black-light, 0.9rem);
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type="radio"]:checked+label,
    [type="radio"]:not(:checked)+label {
        position: relative;
        padding-left: 24px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }

    [type="radio"]:checked+label:before,
    [type="radio"]:not(:checked)+label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 14px;
        height: 14px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: #fff;
    }

    [type="radio"]:checked+label:after,
    [type="radio"]:not(:checked)+label:after {
        content: '';
        width: 10px;
        height: 10px;
        background: $c-blue;
        position: absolute;
        top: 5px;
        left: 3px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    [type="radio"]:not(:checked)+label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    [type="radio"]:checked+label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

}

.f-checkbox-s {
    max-height: 160px;
    overflow-y: scroll;
    @include scroll-vertical();

    &__opt {
        margin-bottom: 10px;
    }

    label {
        @include font-basic($c-black-light, 0.9rem);
    }

    [type="checkbox"]:checked,
    [type="checkbox"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type="checkbox"]:checked+label,
    [type="checkbox"]:not(:checked)+label {
        position: relative;
        padding-left: 24px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }

    [type="checkbox"]:checked+label:before,
    [type="checkbox"]:not(:checked)+label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 14px;
        height: 14px;
        border: 1px solid #ddd;
        background: #fff;
    }

    [type="checkbox"]:checked+label:after,
    [type="checkbox"]:not(:checked)+label:after {
        content: '';
        width: 8px;
        height: 8px;
        background: $c-blue;
        position: absolute;
        top: 6px;
        left: 4px;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    [type="checkbox"]:not(:checked)+label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    [type="checkbox"]:checked+label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.f-stars {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    >.label {
        @include font-basic-semi-bold($c-black-light, 1rem);
        @include margin(0 0 10px 0);
    }

    .star {
        display: inline-block;
        @include margin(0 5px);
        @include image($icon-star-0, 30px, 30px, auto, 28px, center);
        cursor: pointer;
    }

    .star-active {
        display: inline-block;
        @include image($icon-star-1, 30px, 30px, auto, 28px, center);
    }

    .star-prev {
        display: inline-block;
        @include image($icon-star-1, 30px, 30px, auto, 28px, center);
    }

    >.error {
        @include margin(10px 0 0 0);
        text-align: right;
        @include font-basic-semi-bold($c-red, 0.75rem);

        &:before {
            content: "! ";
        }
    }
}

.f-excel {
    @include f-standard();
    justify-content: flex-start;
    display: flex;

    .label {
        display: inline-block;
        min-width: 115px;
        width: 115px;
        @include margin(0 10px 0 0);
        @include font-basic-semi-bold($c-black-light, 1rem);
        // line-height: 1.2rem;
    }

    .add-excel {
        @include font-basic-semi-bold($c-blue, 1rem);
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }

    .excel {
        .file {
            display: flex;

            &__name {
                @include font-basic-semi-bold($c-black-light, 1rem);
                cursor: pointer;

                &:hover {
                    color: $c-blue;
                }
            }

            &__delete {
                @include image($icon-delete, 12px, 15px, auto, 14px, center);
                margin-left: 6px;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}

.f-checkbox-basic {
    margin-bottom: 10px;

    input[type="checkbox"] {
        display: none;

        &+label {
            display: block;
            position: relative;
            padding-left: 25px;
            line-height: 30px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
        }

        &+label:before {
            content: "";
            display: block;
            margin-top: 5px;
            width: 15px;
            height: 15px;
            border: 1px solid #c4c4c4;
            border-radius: 2px;
            position: absolute;
            left: 0;
            top: 0;
        }

        &:checked+label:before {
            background-color: $c-blue !important;
            border: 1px solid $c-blue;
        }

        &:checked+label:after {
            content: "";
            position: absolute;
            left: 6px;
            top: 6px;
            width: 3px;
            height: 7px;
            border: 1.6px solid white;
            border-top-color: transparent;
            border-left-color: transparent;
            transform: rotate(45deg);
        }

        &:disabled+label:before {
            background-color: #f8f8f8;
            cursor: not-allowed;
        }

        &:disabled+label {
            cursor: not-allowed;
            opacity: 0.7;

            &:hover {
                color: #333333;
            }
        }
    }

    >label {
        min-height: 30px;
        @include font-basic-semi-bold($c-black-light, 1rem);
        line-height: 1.2rem !important;
        display: flex !important;
        align-items: center !important;
    }
}

.f-checkbox-simple {
    input[type="checkbox"] {
        display: none;

        &+label {
            display: block;
            position: relative;
            padding-left: 25px;
            line-height: 3px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
        }

        &+label:before {
            content: "";
            display: block;
            margin-top: -7px;
            width: 15px;
            height: 15px;
            border: 1px solid #c4c4c4;
            border-radius: 2px;
            position: absolute;
            left: 0;
            top: 0;
        }

        &:checked+label:before {
            background-color: $c-blue !important;
            border: 1px solid $c-blue;
        }

        &:checked+label:after {
            content: "";
            position: absolute;
            left: 6px;
            top: -6px;
            width: 3px;
            height: 7px;
            border: 1.6px solid white;
            border-top-color: transparent;
            border-left-color: transparent;
            transform: rotate(45deg);
        }

        &:disabled+label:before {
            background-color: #f8f8f8;
            cursor: not-allowed;
        }

        &:disabled+label {
            cursor: not-allowed;
            opacity: 0.7;

            &:hover {
                color: #333333;
            }
        }
    }
}

.f-checkbox-list {
    @include f-standard();
    justify-content: flex-start;
    @include margin(0 0 10px 0);

    label {
        min-width: auto;
        width: auto;

        >.help {
            @include font-basic($c-black-light, 1rem);
        }

        &:hover {
            color: $c-blue;
        }
    }
}

.f-select {
    @include f-standard();

    ng-select {
        width: 100%;
    }
}

.f-select-multiple {
    @include f-standard();
    min-height: 30px;
    height: auto;

    ng-select {
        width: 100%;
    }
}

.f-date {
    @include f-standard();
}

.f-date-simple {
    input {
        background-color: $c-cream;
        border: none;
        border-radius: $radius-btn;
        height: 34px;
        width: 165px;
        @include font-basic($c-blue, $fs-basic);
        padding: 0 10px;
        cursor: pointer;
    }
}

.f-date-table {
    background: transparent;
    outline: none;
    border: none;
    width: 100%;
    @include font-basic(#505050, 1rem);
}

.f-slider-s {
    &__labels {
        display: flex;
        justify-content: space-between;
        @include font-basic-semi-bold($c-black-light, 0.9rem);
    }
}

.f-textarea-table {
    resize: none;
    width: calc(100%);
    height: 61px;
    @include font-basic(#505050, 1rem);
    line-height: 1.3rem;
    border: none;
    overflow-y: scroll;
    @include scroll-vertical();
}

.f-textarea {
    width: 100% !important;
    position: relative;
    @include margin(0 0 30px 0);

    .label {
        @include font-basic-semi-bold($c-black-light, 1rem);
        @include margin(0 0 10px 0);

        &.req {
            &::after {
                @include font-basic-bold($c-blue, 1rem);
                content: "*";
                margin-left: 1px;
            }
        }
    }

    textarea {
        resize: none;
        padding: 10px 16px;
        width: calc(100% - 32px);
        height: 129px;
        @include font-basic(#505050, 0.9rem);
        line-height: 1.3rem;
        border: 1px solid #c4c4c4;
        overflow-y: scroll;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        @include scroll-vertical();
        overflow-x: hidden;

        &::placeholder {
            color: #c4c4c4 !important;
        }

        &:focus {
            border: 1px solid $c-blue;
            box-shadow: 0px 0px 0px 1px rgba(0, 115, 249, 1);
            transition: border-color 0.13s ease-in-out, box-shadow 0.13s ease-in-out,
                -webkit-box-shadow 0.13s ease-in-out;
        }
    }

    .error {
        position: absolute;
        bottom: -22px;
        right: 0;
        @include font-basic-semi-bold($c-red, 0.75rem);

        &:before {
            content: "! ";
        }
    }
}

.f-dropdown {
    @include dropdownn(right, 0, 45px);
}

.f-search {
    display: flex;
    height: 38px;
    align-items: center;
    @include padding(0 20px);
    background-color: $c-white;
    border: none;
    border-radius: 26px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
    margin-bottom: 10px;

    >.search-icon {
        @include image($icon-search-0, 18px, 28px, 16px, auto, center);
        @include margin(0 15px 0 0);
        cursor: pointer;

        &:hover {
            @include image($icon-search-1, 18px, 28px, 16px, auto, center);
        }
    }

    >.search-input {
        width: 100%;
        @include font-basic($c-grey, $fs-basic);
        border: none;
    }
}

.f-editor {
    width: 100% !important;
    @include margin(0 0 20px 0);

    .error {
        @include margin-top(10px);
        text-align: right;
        @include font-basic-semi-bold($c-red, 0.75rem);

        &:before {
            content: "! ";
        }
    }
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.f-image {
    display: flex;
    align-items: center;
    @include margin(0 0 30px 0);
    position: relative;
    flex-wrap: wrap;

    .label {
        display: inline-block;
        width: 100%;
        @include font-basic-semi-bold($c-black-light, 1rem);
        line-height: 1.2rem;
        margin: 0 0 10px 0;
    }

    .req {
        &::after {
            @include font-basic-bold($c-blue, 1rem);
            content: "*";
            margin-left: 5px;
            left: 1px !important;
        }
    }

    >.image {
        height: 68px;
        padding: 10px;
        border: 1px solid $c-grey-line;
        border-radius: 5px;
        background-color: $c-cream;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        >img {
            height: 100%;
            width: auto;
        }

        >.missing {
            @include font-basic-semi-bold($c-grey, 1rem);
            margin-top: 5px;
        }
    }

    >.image-active {
        background-color: transparent;
    }

    >button {
        margin-left: 10px;
    }

    .error {
        position: absolute;
        bottom: -20px;
        @include font-basic-semi-bold($c-red, 0.75rem);

        &:before {
            content: "! ";
        }
    }
}

.f-map {
    @include margin(0 0 30px 0);

    .map {
        width: 100%;
        height: auto;
        border-radius: 5px;
    }
}

.f-image-crop {
    .no-images-box {
        display: flex;
        align-items: center;
        flex-direction: column;

        >.icon {
            @include image($icon-no-image-uploder, 200px, 180px, auto, 140px, bottom center);
        }

        >.btn {
            @include margin(0 0 20px 0);
        }

        >.note {
            @include font-basic($c-black-light, 1rem);

            >span {
                color: $c-blue
            }
        }
    }

    .cropper-box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .cropper {
            width: 30%;

            >.cropper-subtitle {
                @include font-basic-bold($c-black, 1rem);
                @include margin(0 0 15px 0);
            }

            @include respSpecif(1100) {
                width: calc(100% / 2 - 30px);
            }
        }

        .prev {
            width: 30%;

            >.cropper-subtitle {
                @include font-basic-bold($c-black, 1rem);
                @include margin(0 0 15px 0);
            }

            >img {
                width: 100%;
                background-color: $c-cream;
                border: 1px solid $c-grey-line;
                border-radius: 15px;
            }

            @include respSpecif(1100) {
                width: calc(100% / 2 - 30px);
            }
        }

        .note {
            width: 30%;

            @include respSpecif(1100) {
                width: 100%;
                margin-top: 30px;
            }

            .note__title {
                margin-bottom: 10px;
                @include font-basic-bold($c-blue, 1rem);
            }

            .note__description {
                @include font-basic($c-black, 0.9rem);

                div {
                    margin-bottom: 7px;
                }
            }
        }
    }

    >.error {
        @include margin(10px 0 0 0);
        text-align: right;
        @include font-basic-semi-bold($c-red, 0.75rem);

        &:before {
            content: "! ";
        }
    }
}

.f-video {
    .no-video-box {
        display: flex;
        align-items: center;
        flex-direction: column;

        >.icon {
            @include image($icon-no-video-uploder, 200px, 180px, auto, 140px, bottom center);
        }

        >.btn {
            @include margin(10px 0 20px 0);
        }

        >.note {
            @include font-basic($c-black-light, 1rem);

            >b {
                @include font-basic($c-blue, 1rem);
            }
        }
    }

    .video-box {
        display: flex;
        justify-content: space-between;

        >.video {
            width: 50%;

            >video {
                border-radius: 15px;
            }
        }

        >.note {
            width: 30%;

            .note__title {
                margin-bottom: 10px;
                @include font-basic-bold($c-blue, 1rem);
            }

            .note__description {
                @include font-basic($c-black, 0.9rem);

                div {
                    margin-bottom: 7px;
                }
            }
        }
    }
}

.f-select-simple {
    width: 185px;
    height: 34px;
    background-color: $c-cream;
    border-radius: 16px;
    padding: 0 10px;
    display: flex !important;
    align-items: center;

    ng-select {
        width: 100%;

        .ng-select-container {
            background-color: transparent;
            border: none;
            box-shadow: none;
            height: 32px;
        }
    }

    .ng-select-focused {
        border: none !important;
        background-color: transparent !important;
        box-shadow: none !important;
    }

    .ng-select-opened>.ng-select-container {
        background-color: transparent;
        border: none;
    }

    .ng-select .ng-select-container:hover {
        box-shadow: none;
    }



    .ng-input {
        top: 0 !important;

        input {
            height: 27px;
            @include font-basic(#505050, 1rem);
        }
    }
}

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

// 5. Table

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

.table {
    @include margin(0 0 30px 0);

    >.table-title {
        @include font-basic-bold($c-black, 1rem);
        @include margin(0 0 20px 0);
    }

    >.table-header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        >.table-title {
            @include font-basic-bold($c-black, 1rem);
            @include margin(0 0 20px 0);
        }

        >.table-introduction {
            @include font-basic($c-black-light, 0.9rem);
        }

        >.table-tools {
            >* {
                margin-left: 15px;
            }
        }
    }

    // 117
    >table {
        width: 100%;
        @include font-basic(#505050, 0.85rem);
        line-height: 1.2rem;

        >thead {
            background-color: $c-cream;
            @include font-basic-semi-bold($c-black, 0.8rem);
        }

        th,
        td {
            @include padding(12px 15px 9px 15px);
            text-align: left;
            border: 1px solid #c4c4c4;
        }

        .col-delete {
            width: 20px;

            >.delete-btn {
                @include image($icon-delete, 20px, 15px, auto, 14px, center);
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }
        }

        .col-show-more {
            width: 20px;

            >.show-more-btn {
                @include image($icon-visible-0, 20px, 15px, auto, 8px, center);
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }
        }

        .req {
            &::after {
                @include font-basic-bold($c-blue, 1rem);
                content: "*";
                margin-left: 5px;
            }
        }

        .no-data {
            height: 50px;
            text-align: center;
            vertical-align: middle;

            >.action {
                cursor: pointer;

                &:hover {
                    color: blue;
                }
            }
        }
    }

    >.error {
        @include margin(10px 0 0 0);
        text-align: right;
        @include font-basic-semi-bold($c-red, 0.75rem);

        &:before {
            content: "! ";
        }
    }

    @include respSpecif(900) {
        overflow-x: auto !important;
        @include scroll-horizontal();

        table {
            min-width: 900px;
        }

        .no-data {
            text-align: left !important;
        }
    }
}

.table-custom {
    .table-options {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include margin(0 0 10px 0);
        @include padding(0 10px);

        >.option {
            padding: 0;
            background-color: transparent;
            border: none;
            @include font-basic($c-blue, 1rem);
            @include margin(0 0 0 15px);
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }

            &:disabled {
                cursor: not-allowed;
            }
        }
    }

    .table-header {
        display: flex;
        background-color: $c-cream;
        border: 1px solid #cecece;
        border-radius: 10px;
        @include padding(15px 15px);
        @include margin(0 5px 10px 5px);

        >* {
            display: flex;
            align-items: center;
            justify-content: left;
            @include font-basic-semi-bold($c-black, $fs-basic);
        }
    }

    .table-body {
        overflow-y: auto;
        @include scroll-vertical();
        padding: 0 5px;

        .table-row {
            display: flex;
            background-color: $c-white;
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
            border-radius: 10px;
            @include padding(15px);
            @include margin(0 0 7px 0);
            align-items: center;
            justify-content: space-between;
        }

        .table-row-empty {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }

    .table-footer {
        @include padding(0px 15px);
    }

    .col {
        @include margin(0 7px);
    }

    .col-flex {
        flex-grow: 1;
        @include margin(0 7px);
    }

    .col-checkbox {
        width: 40px;
        @include margin(0 7px);
    }

    .col-delete {
        width: 20px;
        @include margin(0 7px);

        >.delete-btn {
            @include image($icon-delete, 12px, 15px, auto, 14px, center);
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

// 6. Modals

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(236, 241, 250, 0.7);

    >.modal {
        display: flex;
        flex-direction: column;
        width: 500px;
        max-height: calc(100vh - 30px);
        min-height: 150px;
        background-color: $c-white;
        @include padding(0 30px);
        border-radius: 10px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);

        >.modal-header {
            @include font-basic-bold($c-blue, 1rem);
            @include padding(20px 0 15px 0);
            border-bottom: 1px solid $c-grey-line;
        }

        >.modal-tabs {
            @include padding(20px 0 0 0);

            >.tab {
                display: inline-block;
                @include margin(0 40px 0 0);
                @include font-basic($c-black-light, 1rem);
                line-height: 30px;
                border-bottom: 4px solid transparent;
                cursor: pointer;

                &:hover {
                    color: $c-blue;
                }
            }

            >.tab-active {
                border-bottom: 4px solid $c-blue;
                @include font-basic-semi-bold($c-blue, 1rem);
            }
        }

        >.modal-content {
            @include margin(20px 0);
            overflow-x: auto;
            padding-right: 5px;
            padding-left: 3px;
            @include scroll-vertical();
            max-height: calc(50vh - 50px);
        }

        >.modal-content-right {
            direction: rtl;
            padding-right: 0;
            padding-left: 5px;
            padding-right: 3px;
        }

        >.modal-footer {
            @include padding(0 0 20px 0);
            display: flex;
            justify-content: flex-end;

            >* {
                @include margin(0 0 0 10px);
            }
        }
    }

    >.modal-complex {
        display: flex;
        flex-direction: column;
        width: 500px;
        max-height: calc(100vh - 30px);
        min-height: 150px;
        background-color: $c-white;
        @include padding(0 30px);
        border-radius: 10px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);

        .modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include margin(25px 0 15px 0);
            padding: 0 5px;

            .modal-header-title {
                @include font-basic-bold($c-black-light, 1.5rem);
            }

            .modal-header-tools {
                display: flex;
                justify-content: flex-end;

                >* {
                    margin: 0 0 0 10px;
                }

                @include respSpecif(800) {
                    display: none !important;
                }
            }
        }

        .modal-content {
            overflow: hidden;
            padding: 5px;
            max-height: calc(100vh - 140px);

            .table-body {
                height: calc(100vh - 250px);
            }
        }

        .content-scroll {
            overflow-y: auto;
            max-height: inherit;
            @include scroll-vertical();
            margin-bottom: 5px;
        }

        .modal-footer {
            display: flex;
            align-items: center;
            padding: 15px 5px;
            border-top: 1px solid #efefef;

            >* {
                display: flex;
                flex-grow: 1;
                flex-basis: 0;
            }

            .modal-footer-options {
                justify-content: flex-start;

                @include respSpecif(1000) {
                    display: none !important;
                }
            }

            .modal-footer-pagination {
                justify-content: center;

                .pagination-container {
                    margin: 0;
                }

                @include respSpecif(800) {
                    display: none !important;
                }
            }

            .modal-footer-btns {
                justify-content: flex-end;

                >* {
                    margin: 0 0 0 10px;
                }
            }
        }
    }

    >.modal-width-min {}

    >.modal-width-s {
        width: 300px;
    }

    >.modal-width-m {
        width: 500px;
    }

    >.modal-width-l {
        width: auto;
        max-width: 800px;

        @include respSpecif(850) {
            margin: 0 25px;
        }
    }

    >.modal-width-xl {
        width: 1300px;

        @include respSpecif(1350) {
            margin: 0 25px;
        }
    }

    >.modal-width-max {
        width: calc(100vw - 100px);
        max-width: 1400px;
    }
}

// Article
.modal-article {
    >p {
        @include margin(20px 0 0 0);
        @include font-basic($c-black-light, $fs-basic);
        line-height: 1.2rem;

        &:first-of-type {
            margin-top: 0;
        }

        >.color-blue {
            color: $c-blue;
        }

        >.color-red {
            color: $c-red;
        }

        >b {
            @include font-basic-bold($c-black-light, $fs-basic);
        }

        >i {
            font-style: italic;
        }

        >a {
            color: $c-blue;
            cursor: pointer;
        }
    }
}

.article-right-orientation {
    text-align: right;
}

// Rate
.modal-rate {
    .stars {
        @include margin(0 0 20px 0);

        >.label {
            @include font-basic-semi-bold($c-black-light, 1rem);
            @include margin(0 0 10px 0);
        }

        .star {
            display: inline-block;
            @include margin(0 5px);
            @include image($icon-star-0, 30px, 30px, auto, 28px, center);
            cursor: pointer;
        }

        .star-active {
            display: inline-block;
            @include image($icon-star-1, 30px, 30px, auto, 28px, center);
        }

        .star-prev {
            display: inline-block;
            @include image($icon-star-1, 30px, 30px, auto, 28px, center);
        }

        >.error {
            @include margin(10px 0 0 0);
            text-align: right;
            @include font-basic-semi-bold($c-red, 0.75rem);

            &:before {
                content: "! ";
            }
        }
    }
}

.modal-comment {
    @include font-basic($c-black, $fs-basic);
    line-height: 1.2rem;
    padding: 5px 0;
}

.modal-qr-code {
    display: flex;
    align-items: center;
    justify-content: center;

    .img-qr {
        width: 200px;
    }
}

.modal-change-event {
    .events {
        .event {
            display: flex;

            .f-date {
                width: 30%;
            }

            .f-select {
                width: 30%;
            }
        }
    }
}

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

// 7. Placeholders

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +
.no {
    @include image($no-offers, 100%, 175px, auto, 160px, top center);
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    position: relative;
    margin-bottom: 50px;
    @include font-basic($c-black-light, 1rem);

    >.btn {
        margin-top: 15px;
        position: absolute;
        bottom: -50px;
    }
}

.no-offers {
    @include image($no-offers, 100%, 175px, auto, 145px, top center);
}

.no-categories {
    @include image($no-categories, 100%, 175px, auto, 145px, top center);
}

.no-roadshows {
    @include image($no-roadshows, 100%, 175px, auto, 145px, top center);
}

.no-collections {
    @include image($no-collections, 100%, 175px, auto, 145px, top center);
}

.no-users {
    @include image($no-adnocUsers, 100%, 175px, auto, 145px, top center);
}

.no-agreements {
    @include image($no-agreements, 100%, 175px, auto, 145px, top center);
}

.no-proposals {
    @include image($no-agreements, 100%, 175px, auto, 145px, top center);
}

.no-suppliers {
    @include image($no-suppliers, 100%, 175px, auto, 145px, top center);
}

.no-tags {
    @include image($no-tags, 100%, 175px, auto, 145px, top center);
}

.no-banners {
    @include image($no-banners, 100%, 175px, auto, 145px, top center);
}

.no-ratings {
    @include image($no-ratings, 100%, 175px, auto, 145px, top center);
}

.no-terms {
    @include image($no-terms, 100%, 175px, auto, 145px, top center);
}

.no-table {
    @include image($no-table, 100%, 110px, auto, 80px, top center);
    margin-top: 30px;
}

.no-locations {
    @include image($no-locations, 100%, 175px, auto, 145px, top center);
}

.no-date {
    @include image($no-date, 100%, 140px, auto, 110px, top center);
    margin-bottom: 0;
}

.no-info {
    margin-top: 50px;
    @include image($no-info, 100%, 100px, auto, 70px, top center);
}

.no-notifications {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    @include margin(0 auto 42px auto);
    @include font-basic($c-black-light, $fs-basic);
    @include image($icon-no-notification, 100%, 130px, 42px, auto, center);
}

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

// 8. Other
.sub-title-terms-and-conditions {
    @include margin(10px 0 15px 0);
    @include font-basic($c-grey-light, $fs-section-subtitle);
    display: flex;
}

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

// Placeholder no ads

.no-ads-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .no-ads-icon {
        @include image($icon-notifications, 200px, 200px, 200px, 200px, center);
    }

    .no-ads-text {
        @include font-basic($c-line-grey, $fs-basic);
        @include margin(10px 0);
    }
}

// Collections-available

.collections-available-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include margin(0 0 50px 0);

    .collections-available-img {
        @include image($test-collection-img, 700px, 175px, 700px, 175px, center);
        border-radius: 10px;
        background-size: contain;
    }

    .collections-available-info {
        @include margin(0 260px 50px 10px);

        .collections-available-title {
            @include font-basic-bold($c-black, $fs-collections-title);
            @include padding(0 0 5px 0);
        }

        .collections-available-date {
            @include font-basic($c-blue, $fs-basic);
            @include padding(0 0 10px 0);
        }

        .collections-available-description {
            text-align: justify;
        }
    }

    .tools-box {
        @include margin(0 0 0 10px);
        display: flex;

        .btn {
            width: 100px;
        }
    }
}

// Users

.user-content {
    display: flex;
    background-color: $c-white;
    border-radius: 10px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
    @include padding(30px 20px 20px 20px);
}

.user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 20%;
    padding: 30px 20px 0px 0px;

    .user-image-container {
        @include image($default-user-icon, 150px, 150px, 150px, 150px, center);
        border-radius: 50%;
        margin-bottom: 10px;
    }

    button {
        margin-bottom: 10px;
    }

    p {
        @include font-basic($c-grey-light, $fs-basic);
        width: 160px;
        text-align: center;
    }
}

.form-user {
    width: 80%;

    .section-modern {
        margin: 0;

        .section-content {
            box-shadow: none;
        }
    }
}

.col-button {
    .btn-blue-c {
        width: 100px;
    }
}

///////// carousel

.carousel {
    position: relative;
    overflow: hidden;
    display: flex;
    border-radius: 1.5rem;

    .carousel_page {
        min-width: 100%;
    }
}

.carousel-pagination {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    .page {
        position: relative;
        width: 12px;
        height: 12px;
        border: 1px solid #707070;
        border-radius: 50%;
        display: inline-block;
        margin: 0 10px;
        cursor: pointer;
    }

    .page-active::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #0073f9;
    }
}

// Tooltip

.tooltip {
    padding: 7px 5px;
    font-size: 14px;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: #000000;

}

.mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-link .mdc-tab__text-label {
    color: #000000;
}

.mat-mdc-tab.mdc-tab {
    height: 80px !important;
}

.mat-mdc-tab.mdc-tab:hover {
    height: 80px !important;
}

.mat-tab-label:hover {
    background-color: red;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: #0047B9;
}

.mat-elevation-z4 {
    box-shadow: none;
}

.mdc-tab__ripple::before,
.mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-link .mat-ripple-element {
    background-color: none !important;
}

.analytics-container .mat-mdc-tab .mat-mdc-focus-indicator .mdc-tab--active .ng-star-inserted .mdc-tab-indicator--active {
    background-color: none !important;

}

.mdc-tab__ripple::before,
.mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-link .mat-ripple-element {
    background-color: #fff
}

.mat-mdc-tab-label-container {
    border-bottom: 2px solid #b4b4b4 !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 30px !important;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
    border: solid 0px #595959;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
    border-left: solid 1px #d9d9d9;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #ffffff00 !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #000000;
    font-size: 14px;
    margin-left: 2px;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: #000000;
    margin-left: 3px;
    font-size: 14px;
}

.mdc-text-field--filled .mdc-line-ripple::after {
    border-bottom-color: #00000000;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 24px;
    // border: 1px solid #cec0c0;
    // min-width: 376px;
}

.mat-mdc-form-field-flex {
    display: inline-flex;
    align-items: baseline;
    box-sizing: border-box;
    margin-right: 2px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cec0c0;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
    color: #000;
}

.mdc-line-ripple::before,
.mdc-line-ripple::after {

    border-bottom-style: none !important;

}

.mdc-text-field {
    border-top-left-radius: var(--mdc-shape-small, 0px) !important;
    border-top-right-radius: var(--mdc-shape-small, 0px) !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    display: inline-flex;
    align-items: baseline;
    padding: 0 0px !important;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    will-change: opacity, transform, color;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
    color: rgb(0, 0, 0);
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::before {
    color: rgb(39, 9, 161);
}

.mdc-text-field .mdc-text-field__input {
    caret-color: #000000;
}

.mat-mdc-select-value {
    color: #000;
}

.mat-mdc-select-arrow {
    color: rgb(0, 0, 0);
}

.mat-mdc-select-arrow-wrapper {
    height: 24px;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    margin-right: 5px;
}

.mdc-menu-surface {
    background-color: #ffffff;
}

.mdc-list-item__primary-text {
    color: #000000;
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
    color: #000000;
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: #0047B9;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
    color: #0047B9;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #0047B9 !important;
}

.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #ffffff !important;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 200ms ease;
    border-radius: 30px;
    border: 1px solid #b1b1b1;
}

.owl-theme .owl-dots {

    margin-right: 34px;
    -webkit-tap-highlight-color: transparent;
}

.mat-mdc-form-field-infix {
    min-height: 48px;
}

.mdc-text-field--disabled.mdc-text-field--filled {
    background-color: #ffffff;
}

.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mdc-floating-label--float-above {
    color: #000;
}

.mat-mdc-select-disabled .mat-mdc-select-value {
    color: rgb(0 0 0);
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
    line-height: normal;
    pointer-events: none !important;
}

.modal-body .owl-theme .owl-nav .owl-prev,
.owl-theme .owl-nav .owl-next {
    color: rgb(255, 255, 255) !important;
    background-color: #0047B9 !important;
    border-radius: 15px !important;
    font-size: 14px !important;
    margin: 5px 29px 5px 28px !important;
}

.member-registration .ng-select .ng-select-container {
    border-bottom: 0px !important;
}

.ng-select .ng-select-focused {
    box-shadow: 0 0 0 #ffff !important;
}

.otp-input {
    width: 35px !important;
    height: 35px !important;
    font-size: 20px !important;
}

    .mat-mdc-radio-button .mdc-form-field {
        color: black !important;
      }
      .mat-mdc-radio-button.mat-accent {
        --mdc-radio-selected-icon-color: #0047b9;
        --mdc-radio-unselected-icon-color:#0047b9
      }

.registration-new .ng-select .ng-select-container .ng-value-container .ng-placeholder {
color:#fff;
}
.registration-new .ng-select .ng-select-container{
    color:#fff;
}