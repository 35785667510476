@import "src/design/standard.scss";

// Delete logo
.pac-container:after {
    background-image: none !important;
    height: 0px;
}

.pac-container{
    background-color: #fff;
    position: absolute !important;
    z-index: 1000;
    border-radius: 2px;
    font-family: Arial, sans-serif;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .gm-svpc{
      display: none !important;
  }

  .agm-map-container-inner{
      border-radius: 10px;
  }

  .gm-ui-hover-effect {
    display: none !important;
}
