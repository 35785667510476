html, body, div, span, applet, object, iframe,
p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type=search] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

body {
  overflow-y: scroll;
}

@font-face {
  font-family: "ADNOC Sans Regular";
  src: url("/assets/fonts/ADNOCSansRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Italic";
  src: url("/assets/fonts/Segoe-UI-Italic.woff") format("woff");
}
@font-face {
  font-family: "ADNOC Sans Medium";
  src: url("/assets/fonts/ADNOCSansMedium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Semi-Bold-Italic";
  src: url("/assets/fonts/Segoe-UI-Semi-Bold-Italic.woff") format("woff");
}
@font-face {
  font-family: "ADNOC Sans Bold";
  src: url("/assets/fonts/ADNOCSansBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Bold-Italic";
  src: url("/assets/fonts/Segoe-UI-Bold-Italic.woff") format("woff");
}
@font-face {
  font-family: "ADNOC Sans Regular";
  src: url("/assets/fonts/ADNOCSansRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Italic";
  src: url("/assets/fonts/Segoe-UI-Italic.woff") format("woff");
}
@font-face {
  font-family: "ADNOC Sans Medium";
  src: url("/assets/fonts/ADNOCSansMedium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Semi-Bold-Italic";
  src: url("/assets/fonts/Segoe-UI-Semi-Bold-Italic.woff") format("woff");
}
@font-face {
  font-family: "ADNOC Sans Bold";
  src: url("/assets/fonts/ADNOCSansBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Bold-Italic";
  src: url("/assets/fonts/Segoe-UI-Bold-Italic.woff") format("woff");
}
.cdk-global-overlay-wrapper, .cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: opacity 0.05s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: opacity 0.05s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: opacity 0.05s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: opacity 0.05s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.288);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: static !important;
  width: initial !important;
  overflow-y: inherit !important;
}
.cdk-global-scrollblock body {
  overflow: hidden;
  margin-right: 15px;
}
.cdk-global-scrollblock body .navigation-container {
  margin-right: -15px;
}
.cdk-global-scrollblock body .navigation-container .navigation-basic {
  padding-right: 15px;
}

.owl-dialog-container {
  position: relative;
  pointer-events: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  padding: 1.5em;
  -webkit-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  -moz-border-radius: 2px;
  border-radius: 2px;
  overflow: auto;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  height: 100%;
  outline: 0;
}

.owl-dt-container, .owl-dt-container * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.owl-dt-container {
  display: block;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 1rem;
  font-size: 16px;
  font-size: 1rem;
  background: #fff;
  pointer-events: auto;
  z-index: 1000;
}
.owl-dt-container-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.owl-dt-container-row:last-child {
  border-bottom: none;
}

.owl-dt-calendar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.owl-dt-calendar-control {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1em;
  width: 100%;
  padding: 0.5em;
  color: #000;
}

.owl-dt-calendar-control .owl-dt-calendar-control-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -moz-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button {
  padding: 0 0.8em;
}

.owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.owl-dt-calendar-main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -moz-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0 0.5em 0.5em;
  outline: 0;
}

.owl-dt-calendar-view {
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -moz-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.owl-dt-calendar-multi-year-view {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.owl-dt-calendar-multi-year-view .owl-dt-calendar-table {
  width: -webkit-calc(100% - 3em);
  width: -moz-calc(100% - 3em);
  width: calc(100% - 3em);
}

.owl-dt-calendar-multi-year-view .owl-dt-calendar-table .owl-dt-calendar-header th {
  padding-bottom: 0.25em;
}

.owl-dt-calendar-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.owl-dt-calendar-table .owl-dt-calendar-header {
  color: rgba(0, 0, 0, 0.4);
}

.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
  font-size: 0.7em;
  font-weight: 400;
  text-align: center;
  padding-bottom: 1em;
}

.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider {
  position: relative;
  height: 1px;
  padding-bottom: 0.5em;
}

.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider:after {
  content: "";
  position: absolute;
  top: 0;
  left: -0.5em;
  right: -0.5em;
  height: 1px;
  background: rgba(0, 0, 0, 0.12);
}

.owl-dt-calendar-table .owl-dt-calendar-cell {
  position: relative;
  height: 0;
  line-height: 0;
  text-align: center;
  outline: 0;
  color: rgba(0, 0, 0, 0.85);
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-content {
  position: absolute;
  top: 5%;
  left: 5%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 90%;
  height: 90%;
  font-size: 0.8em;
  line-height: 1;
  border: 1px solid transparent;
  -moz-border-radius: 999px;
  border-radius: 999px;
  color: inherit;
  cursor: pointer;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-out {
  opacity: 0.2;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  border-color: rgba(0, 0, 0, 0.4);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  color: rgba(255, 255, 255, 0.85);
  background-color: #0047ba;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today {
  -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.85);
  -moz-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.85);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.85);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-disabled {
  cursor: default;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-disabled > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  color: rgba(0, 0, 0, 0.4);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-disabled > .owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected {
  opacity: 0.4;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-disabled > .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  border-color: rgba(0, 0, 0, 0.2);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-active:focus > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected), .owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  background-color: rgba(0, 0, 0, 0.04);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-in-range {
  background: rgba(63, 81, 181, 0.2);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-from {
  -moz-border-radius-topleft: 999px;
  border-top-left-radius: 999px;
  -moz-border-radius-bottomleft: 999px;
  border-bottom-left-radius: 999px;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-to {
  -moz-border-radius-topright: 999px;
  border-top-right-radius: 999px;
  -moz-border-radius-bottomright: 999px;
  border-bottom-right-radius: 999px;
}

.owl-dt-timer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 7em;
  padding: 0.5em;
  outline: 0;
}

.owl-dt-timer-box {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 25%;
  height: 100%;
}

.owl-dt-timer-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -moz-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin: 0.2em 0;
}

.owl-dt-timer-content .owl-dt-timer-input {
  display: block;
  width: 2em;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.5);
  -moz-border-radius: 3px;
  border-radius: 3px;
  outline: medium none;
  font-size: 1.2em;
  padding: 0.2em;
}

.owl-dt-timer-divider {
  display: inline-block;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  position: absolute;
  width: 0.6em;
  height: 100%;
  left: -0.3em;
}

.owl-dt-timer-divider:after, .owl-dt-timer-divider:before {
  content: "";
  display: inline-block;
  width: 0.35em;
  height: 0.35em;
  position: absolute;
  left: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: currentColor;
}

.owl-dt-timer-divider:before {
  top: 35%;
}

.owl-dt-timer-divider:after {
  bottom: 35%;
}

.owl-dt-control-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  background-color: transparent;
  font-size: 1em;
  color: inherit;
}

.owl-dt-control-button .owl-dt-control-button-content {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  outline: 0;
}

.owl-dt-control-period-button .owl-dt-control-button-content {
  height: 1.5em;
  padding: 0 0.5em;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: background-color 0.05s linear;
  -o-transition: background-color 0.05s linear;
  -moz-transition: background-color 0.05s linear;
  transition: background-color 0.05s linear;
}

.owl-dt-control-period-button:hover > .owl-dt-control-button-content {
  background-color: rgba(0, 0, 0, 0.12);
}

.owl-dt-control-period-button .owl-dt-control-button-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin: 0.1em;
  -webkit-transition: -webkit-transform 0.05s;
  transition: -webkit-transform 0.05s;
  -o-transition: transform 0.05s;
  -moz-transition: transform 0.05s, -moz-transform 0.05s;
  transition: transform 0.05s;
  transition: transform 0.05s, -webkit-transform 0.05s, -moz-transform 0.05s;
}

.owl-dt-control-arrow-button .owl-dt-control-button-content {
  padding: 0;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
}

.owl-dt-control-arrow-button[disabled] {
  color: rgba(0, 0, 0, 0.4);
  cursor: default;
}

.owl-dt-control-arrow-button svg {
  width: 50%;
  height: 50%;
  fill: currentColor;
}

.owl-dt-inline-container, .owl-dt-popup-container {
  border-radius: 10px;
  position: relative;
  width: 18.5em;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
}

.owl-dt-inline-container .owl-dt-calendar, .owl-dt-inline-container .owl-dt-timer, .owl-dt-popup-container .owl-dt-calendar, .owl-dt-popup-container .owl-dt-timer {
  width: 100%;
}

.owl-dt-inline-container .owl-dt-calendar, .owl-dt-popup-container .owl-dt-calendar {
  height: 20.25em;
}

.owl-dt-dialog-container {
  max-height: 95vh;
  margin: -1.5em;
}

.owl-dt-dialog-container .owl-dt-calendar {
  min-width: 250px;
  min-height: 330px;
  max-width: 750px;
  max-height: 750px;
}

.owl-dt-dialog-container .owl-dt-timer {
  min-width: 250px;
  max-width: 750px;
}

@media all and (orientation: landscape) {
  .owl-dt-dialog-container .owl-dt-calendar {
    width: 58vh;
    height: 62vh;
  }
  .owl-dt-dialog-container .owl-dt-timer {
    width: 58vh;
  }
}
@media all and (orientation: portrait) {
  .owl-dt-dialog-container .owl-dt-calendar {
    width: 80vw;
    height: 80vw;
  }
  .owl-dt-dialog-container .owl-dt-timer {
    width: 80vw;
  }
}
.owl-dt-container-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 2em;
  color: #0047ba;
}

.owl-dt-container-control-button {
  font-size: 1em;
  width: 50%;
  height: 100%;
  -moz-border-radius: 0;
  border-radius: 0;
}

.owl-dt-container-control-button .owl-dt-control-button-content {
  height: 100%;
  width: 100%;
  -webkit-transition: background-color 0.05s linear;
  -o-transition: background-color 0.05s linear;
  -moz-transition: background-color 0.05s linear;
  transition: background-color 0.05s linear;
}

.owl-dt-container-control-button:hover .owl-dt-control-button-content {
  background-color: rgba(0, 0, 0, 0.1);
}

.owl-dt-container-info {
  padding: 0 0.5em;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.owl-dt-container-info .owl-dt-container-range {
  outline: 0;
}

.owl-dt-container-info .owl-dt-container-range .owl-dt-container-range-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5em 0;
  font-size: 0.8em;
}

.owl-dt-container-info .owl-dt-container-range:last-child {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.owl-dt-container-info .owl-dt-container-info-active {
  color: #0047ba;
}

.owl-dt-container-disabled, .owl-dt-trigger-disabled {
  background-image: none;
  cursor: default !important;
}

.owl-dt-timer-hour12 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #0047ba;
}

.owl-dt-timer-hour12 .owl-dt-timer-hour12-box {
  border: 1px solid currentColor;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-transition: background 0.05s;
  -o-transition: background 0.05s;
  -moz-transition: background 0.05s;
  transition: background 0.05s;
}

.owl-dt-timer-hour12 .owl-dt-timer-hour12-box .owl-dt-control-button-content {
  width: 100%;
  height: 100%;
  padding: 0.5em;
}

.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:focus .owl-dt-control-button-content, .owl-dt-timer-hour12 .owl-dt-timer-hour12-box:hover .owl-dt-control-button-content {
  background: #0047ba;
  color: #fff;
}

.owl-dt-calendar-only-current-month .owl-dt-calendar-cell-out {
  visibility: hidden;
  cursor: default;
}

.owl-dt-inline {
  display: inline-block;
}

.owl-dt-control {
  outline: 0;
  cursor: pointer;
}

.owl-dt-control .owl-dt-control-content {
  outline: 0;
}

.owl-dt-control:focus > .owl-dt-control-content {
  background-color: rgba(0, 0, 0, 0.12);
}

.owl-dt-control:not(:-moz-focusring):focus > .owl-dt-control-content {
  -moz-box-shadow: none;
  box-shadow: none;
}

.owl-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@font-face {
  font-family: "ADNOC Sans Regular";
  src: url("/assets/fonts/ADNOCSansRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Italic";
  src: url("/assets/fonts/Segoe-UI-Italic.woff") format("woff");
}
@font-face {
  font-family: "ADNOC Sans Medium";
  src: url("/assets/fonts/ADNOCSansMedium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Semi-Bold-Italic";
  src: url("/assets/fonts/Segoe-UI-Semi-Bold-Italic.woff") format("woff");
}
@font-face {
  font-family: "ADNOC Sans Bold";
  src: url("/assets/fonts/ADNOCSansBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Bold-Italic";
  src: url("/assets/fonts/Segoe-UI-Bold-Italic.woff") format("woff");
}
.ng-select {
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 1rem;
}
.ng-select.ng-select-opened > .ng-select-container {
  background: #ffffff;
  border-color: #ababab #c4c4c4 #d1d1d1;
  background-color: transparent;
}
.ng-select.ng-select-opened > .ng-select-container:hover {
  box-shadow: none;
}
.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: -2px;
  border-color: transparent transparent #919191;
  border-width: 0 5px 5px;
}
.ng-select.ng-select-opened > .ng-select-container .ng-arrow:hover {
  border-color: transparent transparent #2b2b2b;
}
.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.ng-select.ng-select-opened .ng-arrow-wrapper {
  width: 25px;
  padding-right: 5px;
  width: 30px;
  height: 25px;
  background: url("/assets/images/icons/arrowUp_icon.svg") no-repeat bottom 6px center;
  background-size: 15px auto;
}
.ng-select.ng-select-focused {
  border-color: #0073f9;
  box-shadow: 0 1.5px 0 #0073f9;
  transition: border-color 0.13s ease-in-out, box-shadow 0.13s ease-in-out;
}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: transparent;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0), 0 0 0 3px rgba(0, 126, 255, 0);
}
.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #f8f8f8;
  border: none;
  border-radius: 5px;
  cursor: not-allowed;
}
.ng-select.ng-select-disabled > .ng-select-container .ng-placeholder {
  color: transparent;
}
.ng-select.ng-select-disabled .ng-arrow-wrapper {
  display: none;
}
.ng-select.ng-select-disabled .ng-value-container {
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.ng-select.ng-select-disabled .ng-value-container .ng-placeholder {
  color: transparent;
}
.ng-select .ng-has-value .ng-placeholder {
  display: none;
}
.ng-select .ng-select-container {
  color: #333;
  background-color: #ffffff;
  background-color: transparent;
  border-radius: 0;
  border-bottom: 1px solid #c4c4c4;
  box-shadow: 0 1px 0 transparent;
  min-height: 28.6px;
  align-items: center;
}
.ng-select .ng-select-container:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}
.ng-select .ng-select-container .ng-value-container {
  align-items: center;
  padding-left: 0 !important;
  cursor: pointer;
}
[dir=rtl] .ng-select .ng-select-container .ng-value-container {
  padding-right: 0px;
  padding-left: 0;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #c4c4c4;
}
.ng-select.ng-select-single .ng-select-container {
  height: 28.6px;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  left: 0;
  padding-left: 0px;
  padding-right: 50px;
}
[dir=rtl] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-right: 0px;
  padding-left: 50px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input input {
  padding: 0;
}
.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
  background-color: #f9f9f9;
  border: 1px solid #dedede;
  border: none;
  border-radius: 5px;
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value .ng-value-label {
  padding: 0 5px;
}
.ng-select.ng-select-multiple.ng-select-disabled .ng-placeholder {
  color: transparent;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-left: 7px;
}
[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-right: 7px;
  padding-left: 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row-reverse;
  border: none;
  background-color: #efefef;
  padding: 5px 5px 5px 10px;
  border-radius: 15px;
  margin-bottom: 6px;
  margin-right: 10px;
}
[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-right: 0;
  margin-left: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: #f9f9f9;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 5px;
}
[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 0;
  padding-right: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-container {
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-placeholder {
  color: transparent;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: 1px 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  display: inline-block;
  padding: 1px 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: rgba(105, 105, 105, 0);
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid rgba(92, 92, 92, 0);
}
[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-left: 1px solid rgba(92, 92, 92, 0);
  border-right: none;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 1px solid rgba(92, 92, 92, 0);
}
[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 0;
  border-right: 1px solid rgba(92, 92, 92, 0);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 0 0px 3px;
}
[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 0px 3px 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  padding-bottom: 0px;
  padding-left: 3px;
  position: absolute;
  top: inherit;
  z-index: 1;
}
[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  padding-right: 3px;
  padding-left: 0;
}

.ng-select .ng-clear-wrapper {
  color: #919191;
}
.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: #D0021B;
}
.ng-select .ng-spinner-zone {
  padding: 5px 5px 0 0;
}
[dir=rtl] .ng-select .ng-spinner-zone {
  padding: 5px 0 0 5px;
}

.ng-select .ng-arrow-wrapper {
  width: 25px;
  padding-right: 5px;
  width: 30px;
  height: 25px;
  background: url("/assets/images/icons/arrowDown_icon.svg") no-repeat bottom 6px center;
  background-size: 15px auto;
}
[dir=rtl] .ng-select .ng-arrow-wrapper {
  padding-left: 5px;
  padding-right: 0;
}

.ng-dropdown-panel {
  background-color: #ffffff;
  left: 0;
}
.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border-radius: 10px;
  border-top-color: #dedede;
  margin-top: 10px;
}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:hover {
  color: #0047ba;
}
.ng-dropdown-panel.ng-select-top {
  bottom: 100%;
  border-radius: 10px;
  border-bottom-color: #dedede;
  margin-bottom: 5px;
}
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items:hover {
  color: #0047ba;
}
.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid #c4c4c4;
  padding: 5px 7px;
}
.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid #c4c4c4;
  padding: 5px 7px;
}
.ng-dropdown-panel .ng-dropdown-panel-items {
  padding: 0 25px;
  border-radius: 10px;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  max-height: 203px !important;
}
.ng-dropdown-panel .ng-dropdown-panel-items::-webkit-scrollbar {
  width: 6px;
}
.ng-dropdown-panel .ng-dropdown-panel-items::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.ng-dropdown-panel .ng-dropdown-panel-items::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.ng-dropdown-panel .ng-dropdown-panel-items::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  user-select: none;
  padding: 8px 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: rgba(122, 122, 122, 0);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  background-color: rgba(117, 117, 117, 0);
  font-weight: 600;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 21px 10px 15px 10px;
  border-bottom: 1px solid #C5C5C5;
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.875rem;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:last-of-type {
  border: none;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  color: #0047ba !important;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #333;
  background-color: rgba(117, 117, 117, 0);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.875rem;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: rgba(122, 122, 122, 0);
  color: #333;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #cccccc;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 22px;
}
[dir=rtl] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-right: 22px;
  padding-left: 0;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  font-size: 80%;
  font-weight: 400;
  padding-right: 5px;
}
[dir=rtl] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  padding-left: 5px;
  padding-right: 0;
}

[dir=rtl] .ng-dropdown-panel {
  direction: rtl;
  text-align: right;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  line-height: 1.3rem !important;
}

@font-face {
  font-family: "ADNOC Sans Regular";
  src: url("/assets/fonts/ADNOCSansRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Italic";
  src: url("/assets/fonts/Segoe-UI-Italic.woff") format("woff");
}
@font-face {
  font-family: "ADNOC Sans Medium";
  src: url("/assets/fonts/ADNOCSansMedium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Semi-Bold-Italic";
  src: url("/assets/fonts/Segoe-UI-Semi-Bold-Italic.woff") format("woff");
}
@font-face {
  font-family: "ADNOC Sans Bold";
  src: url("/assets/fonts/ADNOCSansBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Bold-Italic";
  src: url("/assets/fonts/Segoe-UI-Bold-Italic.woff") format("woff");
}
.cke_toolbox {
  display: flex !important;
  flex-wrap: wrap !important;
}

.cke_top, .cke_bottom {
  background: #efefef !important;
}

.cke_top {
  padding: 8px 8px 4px !important;
}

a.cke_button_off:hover, a.cke_button_off:focus, a.cke_button_off:active {
  background: white !important;
  border: 1px #bcbcbc solid;
  border-radius: 3px;
  padding: 3px 5px;
}

.cke_button_on {
  border-radius: 3px !important;
}

.cke_button {
  margin: 0 3px !important;
}

.ck-editor__inline {
  min-height: 500px;
}

.ck-off:hover {
  background: white !important;
}

.ck-on {
  background: #0047ba !important;
}
.ck-on .ck-icon, .ck-on .ck-button__label {
  color: white !important;
}

.cke_1_contents {
  min-height: 400px !important;
}

:host ::ng-deep .cke_1_contents {
  min-height: 400px !important;
}

.ck-onlyRead .cke_top, .ck-onlyRead .cke_1_bottom, .ck-onlyRead .cke_1_path {
  display: none !important;
}
.ck-onlyRead .ck-editor {
  min-height: 500px !important;
}
.ck-onlyRead .cke_contents {
  min-height: 400px !important;
}
.ck-onlyRead .cke_path_item {
  display: none !important;
}

/*!
* Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
* Copyright 2015 Daniel Cardoso <@DanielCardoso>
* Licensed under MIT
*/
.la-line-scale-pulse-out,
.la-line-scale-pulse-out > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.la-line-scale-pulse-out {
  display: block;
  font-size: 0;
  color: #0073f9;
}

.la-line-scale-pulse-out.la-dark {
  color: #333;
}

.la-line-scale-pulse-out > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-line-scale-pulse-out {
  width: 40px;
  height: 32px;
}

.la-line-scale-pulse-out > div {
  width: 4px;
  height: 32px;
  margin: 2px;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
  -webkit-animation: line-scale-pulse-out 0.9s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
  -moz-animation: line-scale-pulse-out 0.9s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
  -o-animation: line-scale-pulse-out 0.9s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
  animation: line-scale-pulse-out 0.9s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
}

.la-line-scale-pulse-out > div:nth-child(3) {
  -webkit-animation-delay: -0.9s;
  -moz-animation-delay: -0.9s;
  -o-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.la-line-scale-pulse-out > div:nth-child(2),
.la-line-scale-pulse-out > div:nth-child(4) {
  -webkit-animation-delay: -0.7s;
  -moz-animation-delay: -0.7s;
  -o-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.la-line-scale-pulse-out > div:nth-child(1),
.la-line-scale-pulse-out > div:nth-child(5) {
  -webkit-animation-delay: -0.5s;
  -moz-animation-delay: -0.5s;
  -o-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.la-line-scale-pulse-out.la-sm {
  width: 20px;
  height: 16px;
}

.la-line-scale-pulse-out.la-sm > div {
  width: 2px;
  height: 16px;
  margin: 1px;
  margin-top: 0;
  margin-bottom: 0;
}

.la-line-scale-pulse-out.la-2x {
  width: 80px;
  height: 64px;
}

.la-line-scale-pulse-out.la-2x > div {
  width: 8px;
  height: 64px;
  margin: 4px;
  margin-top: 0;
  margin-bottom: 0;
}

.la-line-scale-pulse-out.la-3x {
  width: 120px;
  height: 96px;
}

.la-line-scale-pulse-out.la-3x > div {
  width: 12px;
  height: 96px;
  margin: 6px;
  margin-top: 0;
  margin-bottom: 0;
}

/*
 * Animation
 */
@-webkit-keyframes line-scale-pulse-out {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -webkit-transform: scaley(0.3);
    transform: scaley(0.3);
  }
  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}
@-moz-keyframes line-scale-pulse-out {
  0% {
    -moz-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -moz-transform: scaley(0.3);
    transform: scaley(0.3);
  }
  100% {
    -moz-transform: scaley(1);
    transform: scaley(1);
  }
}
@-o-keyframes line-scale-pulse-out {
  0% {
    -o-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -o-transform: scaley(0.3);
    transform: scaley(0.3);
  }
  100% {
    -o-transform: scaley(1);
    transform: scaley(1);
  }
}
@keyframes line-scale-pulse-out {
  0% {
    -webkit-transform: scaley(1);
    -moz-transform: scaley(1);
    -o-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -webkit-transform: scaley(0.3);
    -moz-transform: scaley(0.3);
    -o-transform: scaley(0.3);
    transform: scaley(0.3);
  }
  100% {
    -webkit-transform: scaley(1);
    -moz-transform: scaley(1);
    -o-transform: scaley(1);
    transform: scaley(1);
  }
}
.spinner-container {
  position: fixed;
  top: 8px;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.spinner-icon-container {
  display: flex;
  justify-content: center;
}

.spinner-icon {
  display: block;
}

.spinner-message {
  margin-top: 10px;
  display: block;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

@font-face {
  font-family: "ADNOC Sans Regular";
  src: url("/assets/fonts/ADNOCSansRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Italic";
  src: url("/assets/fonts/Segoe-UI-Italic.woff") format("woff");
}
@font-face {
  font-family: "ADNOC Sans Medium";
  src: url("/assets/fonts/ADNOCSansMedium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Semi-Bold-Italic";
  src: url("/assets/fonts/Segoe-UI-Semi-Bold-Italic.woff") format("woff");
}
@font-face {
  font-family: "ADNOC Sans Bold";
  src: url("/assets/fonts/ADNOCSansBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Bold-Italic";
  src: url("/assets/fonts/Segoe-UI-Bold-Italic.woff") format("woff");
}
image-cropper > div {
  background-color: #efefef;
  border-radius: 15px;
}
image-cropper > div .square {
  background-color: #0047ba !important;
  border: 1px solid #0047ba !important;
  border: 1px solid #c5c5c5;
  border-radius: 50%;
}
image-cropper > div .source-image {
  border-radius: 15px;
}

@font-face {
  font-family: "ADNOC Sans Regular";
  src: url("/assets/fonts/ADNOCSansRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Italic";
  src: url("/assets/fonts/Segoe-UI-Italic.woff") format("woff");
}
@font-face {
  font-family: "ADNOC Sans Medium";
  src: url("/assets/fonts/ADNOCSansMedium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Semi-Bold-Italic";
  src: url("/assets/fonts/Segoe-UI-Semi-Bold-Italic.woff") format("woff");
}
@font-face {
  font-family: "ADNOC Sans Bold";
  src: url("/assets/fonts/ADNOCSansBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Bold-Italic";
  src: url("/assets/fonts/Segoe-UI-Bold-Italic.woff") format("woff");
}
.pac-container:after {
  background-image: none !important;
  height: 0px;
}

.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 1000;
  border-radius: 2px;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.gm-svpc {
  display: none !important;
}

.agm-map-container-inner {
  border-radius: 10px;
}

.gm-ui-hover-effect {
  display: none !important;
}

@font-face {
  font-family: "ADNOC Sans Regular";
  src: url("/assets/fonts/ADNOCSansRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Italic";
  src: url("/assets/fonts/Segoe-UI-Italic.woff") format("woff");
}
@font-face {
  font-family: "ADNOC Sans Medium";
  src: url("/assets/fonts/ADNOCSansMedium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Semi-Bold-Italic";
  src: url("/assets/fonts/Segoe-UI-Semi-Bold-Italic.woff") format("woff");
}
@font-face {
  font-family: "ADNOC Sans Bold";
  src: url("/assets/fonts/ADNOCSansBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Bold-Italic";
  src: url("/assets/fonts/Segoe-UI-Bold-Italic.woff") format("woff");
}
.full-calendar full-calendar {
  height: calc(100vh - 150px);
}
.full-calendar .fc-toolbar .fc-toolbar-title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.8rem;
}
@media only screen and (max-width: 1339px) {
  .full-calendar .fc-toolbar .fc-toolbar-title {
    font-size: 1.71rem;
  }
}
@media only screen and (max-width: 1049px) {
  .full-calendar .fc-toolbar .fc-toolbar-title {
    font-size: 1.62rem;
  }
}
@media only screen and (max-width: 849px) {
  .full-calendar .fc-toolbar .fc-toolbar-title {
    font-size: 1.53rem;
  }
}
.full-calendar .fc-toolbar .fc-toolbar-chunk .fc-button-group .fc-button {
  background-color: transparent;
  border: 1px solid #0047ba;
  height: 34px;
}
.full-calendar .fc-toolbar .fc-toolbar-chunk .fc-button-group .fc-button .fc-icon {
  color: #0047ba !important;
}
.full-calendar .fc-toolbar .fc-toolbar-chunk .fc-button-group .fc-button:focus {
  outline: none;
  box-shadow: none;
}
.full-calendar .fc-toolbar .fc-toolbar-chunk .fc-button-group .fc-prev-button {
  width: 34px;
  padding: 0 !important;
  border-right: none;
}
.full-calendar .fc-toolbar .fc-toolbar-chunk .fc-button-group .fc-next-button {
  width: 34px;
  padding: 0 !important;
}
.full-calendar .fc-toolbar .fc-toolbar-chunk .fc-button-group .fc-dayGridMonth-button {
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 1;
  line-height: 0;
}
.full-calendar .fc-toolbar .fc-toolbar-chunk .fc-button-group .fc-dayGridWeek-button {
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 1;
  line-height: 0;
}
.full-calendar .fc-toolbar .fc-toolbar-chunk .fc-button-group .fc-button-active {
  background-color: #0047ba;
  color: white;
}
.full-calendar table {
  background-color: white;
}
.full-calendar table thead .fc-scroller {
  overflow: hidden !important;
}
.full-calendar table tbody .fc-scroller::-webkit-scrollbar {
  width: 6px;
}
.full-calendar table tbody .fc-scroller::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.full-calendar table tbody .fc-scroller::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.full-calendar table tbody .fc-scroller::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.full-calendar .fc-col-header-cell-cushion {
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.9rem;
  padding: 5px 0 !important;
}
.full-calendar .fc-event {
  min-height: 70px;
}
.full-calendar .fc-event .fc-daygrid-event {
  border: none !important;
}
.full-calendar .fc-event .fc-event-title-container {
  display: none !important;
}
.full-calendar .fc-event .event-card {
  position: relative;
  overflow: hidden;
  height: 70px;
  margin-right: 5px;
  position: relative;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.full-calendar .fc-event .event-card .event-card-new {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.full-calendar .fc-event .event-card .event-card-title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1rem;
  text-transform: capitalize;
  margin-right: 10px;
}
.full-calendar .fc-event .event-card .event-card-content .event-card-content-locations {
  margin-top: 7px;
  opacity: 0.6;
  width: auto;
  height: 15px;
  background: url("/assets/images/icons/location_icon.svg") no-repeat center left;
  background-size: auto 14px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.full-calendar .fc-event .event-card .event-card-content .event-card-content-locations .event-card-content-location {
  margin-right: 5px;
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
}
.full-calendar .fc-event .event-card .event-card-content .event-card-content-roadshow {
  margin-top: 7px;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 0.9rem;
}
.full-calendar .fc-event .event-card .event-card-lock {
  position: absolute;
  right: 0px;
  bottom: 5px;
  width: 20px;
  height: 20px;
  background: url("/assets/images/icons/lock_roadshow_icon.svg") no-repeat center;
  background-size: auto 19px;
}
.full-calendar .fc-event .event-card .event-card-unlock {
  position: absolute;
  right: 0px;
  bottom: 5px;
  width: 20px;
  height: 20px;
  background: url("/assets/images/icons/unlock_roadshow_icon.svg") no-repeat center;
  background-size: auto 19px;
}

.tippi-text {
  background-color: red;
  width: 500px;
  height: 300px;
}

.tippy-roadshow-invite {
  position: relative;
  width: 450px;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
}
.tippy-roadshow-invite > .tippy-roadshow-invite-title {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
}
.tippy-roadshow-invite > .tippy-roadshow-invite-locations {
  margin-top: 10px;
  width: auto;
  height: 15px;
  background: url("/assets/images/icons/location_icon.svg") no-repeat center left;
  background-size: auto 14px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.tippy-roadshow-invite > .tippy-roadshow-invite-locations > .tippy-roadshow-invite-location {
  display: inline-block;
  margin: 0 5px 0 0;
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.9rem;
}
.tippy-roadshow-invite > .tippy-roadshow-invite-offers {
  width: auto;
  height: 15px;
  background: url("/assets/images/icons/offer_icon.svg") no-repeat center left;
  background-size: auto 13px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-top: 10px;
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.9rem;
}
.tippy-roadshow-invite > .tippy-roadshow-invite-roadshow {
  width: auto;
  height: 15px;
  background: url("/assets/images/icons/roadshow_icon.svg") no-repeat center left;
  background-size: auto 13px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-top: 10px;
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.9rem;
}
.tippy-roadshow-invite > .tippy-roadshow-invite-description {
  margin-top: 10px;
}
.tippy-roadshow-invite > .tippy-roadshow-invite-description > .tippy-roadshow-invite-description-title {
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.9rem;
}
.tippy-roadshow-invite > .tippy-roadshow-invite-description > .tippy-roadshow-invite-description-content {
  margin-top: 5px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.9rem;
}
.tippy-roadshow-invite > .tippy-roadshow-invite-focal {
  margin-top: 10px;
}
.tippy-roadshow-invite > .tippy-roadshow-invite-focal > .tippy-roadshow-invite-focal-title {
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.9rem;
}
.tippy-roadshow-invite > .tippy-roadshow-invite-focal > .tippy-roadshow-invite-focal-content {
  margin-top: 5px;
}
.tippy-roadshow-invite > .tippy-roadshow-invite-focal > .tippy-roadshow-invite-focal-content > .tippy-roadshow-invite-focal-content-contact {
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.9rem;
}
.tippy-roadshow-invite > .tippy-roadshow-invite-statistics {
  margin-top: 10px;
}
.tippy-roadshow-invite > .tippy-roadshow-invite-statistics > .tippy-roadshow-invite-statistic {
  padding: 0 5px;
  display: inline-block;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.9rem;
  border-left: 1px solid #6e6e6e;
}
.tippy-roadshow-invite > .tippy-roadshow-invite-statistics > .tippy-roadshow-invite-statistic:first-of-type {
  padding-left: 0 !important;
  border-left: none;
}
.tippy-roadshow-invite > .tippy-roadshow-invite-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px 15px;
  background-color: #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 0.875rem;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 8px;
}
@font-face {
  font-family: "ADNOC Sans Regular";
  src: url("/assets/fonts/ADNOCSansRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Italic";
  src: url("/assets/fonts/Segoe-UI-Italic.woff") format("woff");
}
@font-face {
  font-family: "ADNOC Sans Medium";
  src: url("/assets/fonts/ADNOCSansMedium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Semi-Bold-Italic";
  src: url("/assets/fonts/Segoe-UI-Semi-Bold-Italic.woff") format("woff");
}
@font-face {
  font-family: "ADNOC Sans Bold";
  src: url("/assets/fonts/ADNOCSansBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Segoe-UI-Bold-Italic";
  src: url("/assets/fonts/Segoe-UI-Bold-Italic.woff") format("woff");
}
.ngx-slider .ngx-slider-bar {
  height: 3px !important;
}
.ngx-slider .ngx-slider-pointer {
  width: 12px !important;
  height: 12px !important;
  top: -5px !important;
  background-color: #0047ba !important;
}
.ngx-slider .ngx-slider-pointer::after {
  content: none !important;
}
.ngx-slider .ngx-slider-selection {
  background-color: #0047ba !important;
}
.ngx-slider .ngx-slider-bubble {
  color: #000000 !important;
  font-size: 14px !important;
}

/* 
+ + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + 

DESIGN  ver 1.0

1. Elements
    1.1. Buttons
    1.2. Dropdown
    1.3. Pagination
    1.4. Headers
    1.5. Boxs
        1.5.1. Modern Box
        1.6.2. Classic Box
    1.6  Cards
        1.6.1. Offer Card
        1.6.2. Collection Card
        1.6.3. Category Card
        1.6.4  Category Small Card
        1.6.5. User Card
        1.6.6. Offer-agreement Card
        1.6.7. Tag Card 
    1.7. Notification
2. Layout
    2.1. Navigation
    2.2. Notification
    2.3. Footer
3. Pages
    2.1. Offer
    2.2. Offer home
    2.3. Categories
    2.4. Categories home
    2.5. Collections
    2.6. Collections home
    2.7. Roadshow
    2.8. Offer details
    2.9. Offer new
    2.10. Offer agreements
    2.11. Login
    2.12. Register
    2.13 Banner
    2.14 Suppliers Pending
4. Form
5. Table
6. Modals
7. No items
8. Other

+ + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + 
*/
.btn {
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  border-radius: 16px;
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: 0.875rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-width: 110px;
  height: 35px;
  background-color: #0047ba;
}
.btn:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #003487;
  transition: all 0.2s;
  border-radius: 16px;
  z-index: -1;
}
.btn:hover {
  color: #fff;
}
.btn:hover:before {
  width: 100%;
}

.btn-blue {
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  border-radius: 16px;
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: 0.875rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-width: 110px;
  height: 35px;
  background-color: #0047ba;
}
.btn-blue:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #003487;
  transition: all 0.2s;
  border-radius: 16px;
  z-index: -1;
}
.btn-blue:hover {
  color: #fff;
}
.btn-blue:hover:before {
  width: 100%;
}

.btn-red {
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  border-radius: 16px;
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: 0.875rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-width: 110px;
  height: 35px;
  background-color: #f55757;
}
.btn-red:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #f22727;
  transition: all 0.2s;
  border-radius: 16px;
  z-index: -1;
}
.btn-red:hover {
  color: #fff;
}
.btn-red:hover:before {
  width: 100%;
}

.btn-green {
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  border-radius: 16px;
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: 0.875rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-width: 110px;
  height: 35px;
  background-color: #42ad53;
}
.btn-green:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #348841;
  transition: all 0.2s;
  border-radius: 16px;
  z-index: -1;
}
.btn-green:hover {
  color: #fff;
}
.btn-green:hover:before {
  width: 100%;
}

.btn-green-c {
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  border-radius: 16px;
  font-family: "ADNOC Sans Regular";
  color: #42ad53;
  font-size: 0.875rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-width: 110px;
  height: 35px;
  background-color: transparent;
}
.btn-green-c:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s;
  border-radius: 16px;
  z-index: -1;
}
.btn-green-c:hover {
  color: #fff;
}
.btn-green-c:hover:before {
  width: 100%;
}

.btn-blue-c {
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  border-radius: 16px;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-width: 110px;
  height: 35px;
  background-color: transparent;
}
.btn-blue-c:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s;
  border-radius: 16px;
  z-index: -1;
}
.btn-blue-c:hover {
  color: #fff;
}
.btn-blue-c:hover:before {
  width: 100%;
}

.btn-red-c {
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  border-radius: 16px;
  font-family: "ADNOC Sans Regular";
  color: #f55757;
  font-size: 0.875rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-width: 110px;
  height: 35px;
  background-color: transparent;
}
.btn-red-c:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s;
  border-radius: 16px;
  z-index: -1;
}
.btn-red-c:hover {
  color: #fff;
}
.btn-red-c:hover:before {
  width: 100%;
}

.btn-white {
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  border-radius: 16px;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-width: 110px;
  height: 35px;
  background-color: #fff;
}
.btn-white:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #e6e6e6;
  transition: all 0.2s;
  border-radius: 16px;
  z-index: -1;
}
.btn-white:hover {
  color: #fff;
}
.btn-white:hover:before {
  width: 100%;
}

.btn-white-c {
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  border-radius: 16px;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-width: 110px;
  height: 35px;
  background-color: transparent;
}
.btn-white-c:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s;
  border-radius: 16px;
  z-index: -1;
}
.btn-white-c:hover {
  color: #fff;
}
.btn-white-c:hover:before {
  width: 100%;
}

.btn-transparent {
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  border-radius: 16px;
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: 0.875rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-width: 110px;
  height: 35px;
  background-color: transparent;
  border: 1px solid #fff;
}
.btn-transparent:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s;
  border-radius: 16px;
  z-index: -1;
}
.btn-transparent:hover {
  color: #fff;
}
.btn-transparent:hover:before {
  width: 100%;
}

.btn-grey {
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  border-radius: 16px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-width: 110px;
  height: 35px;
  background-color: #e2e2e2;
}
.btn-grey:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #c9c9c9;
  transition: all 0.2s;
  border-radius: 16px;
  z-index: -1;
}
.btn-grey:hover {
  color: #fff;
}
.btn-grey:hover:before {
  width: 100%;
}

.btn-share {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/share_btn_0_icon.svg") no-repeat center right 15px;
  background-size: auto 17px;
  padding: 6px 40px 6px 20px;
  padding-top: 10px;
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
  background-color: #e2e2e2;
  border-radius: 16px;
  border: none;
  cursor: pointer;
}
.btn-share:hover {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/share_btn_1_icon.svg") no-repeat center right 15px;
  background-size: auto 17px;
  color: #0047ba;
  background-color: #efefef;
}

.btn-share-modern {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/share_btn_0_icon.svg") no-repeat center right 15px;
  background-size: auto 17px;
  padding: 0 40px 0 1.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
  background-color: #e2e2e2;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.btn-share-modern .share-options {
  background-color: #d0d0d0;
  position: absolute;
  overflow: hidden;
  width: 100%;
  left: -160px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  transition-duration: 0.2s;
}
.btn-share-modern .share-options .share-fb {
  width: 20px;
  height: 20px;
  background: url("/assets/images/icons/share_fb_icon.svg") no-repeat center;
  background-size: auto 19px;
  margin: 0 5px;
  cursor: pointer;
}
.btn-share-modern .share-options .share-fb:hover {
  opacity: 0.8;
}
.btn-share-modern .share-options .share-twiter {
  width: 20px;
  height: 20px;
  background: url("/assets/images/icons/share_twiter_icon.svg") no-repeat center;
  background-size: auto 19px;
  margin: 0 5px;
  cursor: pointer;
}
.btn-share-modern .share-options .share-twiter:hover {
  opacity: 0.8;
}
.btn-share-modern .share-options .share-mail {
  width: 20px;
  height: 20px;
  background: url("/assets/images/icons/share_mail_icon.svg") no-repeat center;
  background-size: auto 19px;
  margin: 0 5px;
  cursor: pointer;
}
.btn-share-modern .share-options .share-mail:hover {
  opacity: 0.8;
}
.btn-share-modern:hover .share-options {
  -webkit-transform: translateX(160px);
  transform: translateX(160px);
  transition-duration: 0.2s;
}

.btn-qr {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/qr_0_icon.svg") no-repeat center right 15px;
  background-size: auto 17px;
  padding: 0 40px 0 1.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
  background-color: #e2e2e2;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.btn-qr:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: rgba(173, 173, 173, 0.158);
  transition: all 0.2s;
  border-radius: 16px;
  z-index: -1;
}
.btn-qr:hover:before {
  width: 100%;
}

.btn-favourites {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/favourites_btn_0_icon.svg") no-repeat center right 15px;
  background-size: auto 17px;
  padding: 0 40px 0 1.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
  background-color: #e2e2e2;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.btn-favourites:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: rgba(173, 173, 173, 0.158);
  transition: all 0.2s;
  border-radius: 16px;
  z-index: -1;
}
.btn-favourites:hover:before {
  width: 100%;
}

.btn-favourites-active {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/favourites_btn_1_icon.svg") no-repeat center right 15px;
  background-size: auto 17px;
  display: inline-flex;
  background-color: #e2e2e2;
}

.btn-delete {
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/reject_1_icon.svg") no-repeat center;
  background-size: auto 30px;
  cursor: pointer;
}

.btn-filter {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/filter_0_icon.svg") no-repeat center right 15px;
  background-size: auto 17px;
  padding: 0 40px 0 20px;
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #e2e2e2;
  background-color: #e2e2e2;
  border-radius: 16px;
  cursor: pointer;
}
.btn-filter .btn-filter-counter {
  display: block;
  position: absolute;
  padding: 1px;
  background-color: #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 0.7rem;
  line-height: 20px;
  width: 20px;
  height: 20px;
  text-align: center;
  right: 0;
  top: -9px;
  border-radius: 12px;
}
.btn-filter:hover {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/filter_1_icon.svg") no-repeat center right 15px;
  background-size: auto 17px;
  color: #fff;
  background-color: #0047ba;
}
.btn-filter:hover .btn-filter-counter {
  display: none;
}

.btn-filter-active {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/filter_1_icon.svg") no-repeat center right 15px;
  background-size: auto 17px;
  display: inline-flex;
  color: #fff;
  background-color: #0047ba;
}

.dropdown {
  display: block;
  position: relative;
}
.dropdown > .dropdown-btn {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/arrowDown_icon.svg") no-repeat center right 17px;
  background-size: auto 8px;
  padding: 0 40px 0 20px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  background-color: #e2e2e2;
  border-radius: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  cursor: pointer;
}
.dropdown > .dropdown-btn .default {
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
}
.dropdown > .dropdown-btn .value {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.875rem;
}
.dropdown > .dropdown-btn .value b {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 0.875rem;
}
.dropdown > .dropdown-btn-blue {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/arrowDown_icon.svg") no-repeat center right 17px;
  background-size: auto 8px;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  border-radius: 16px;
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: 0.875rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-width: 110px;
  height: 35px;
  background-color: #0047ba;
  padding: 0 40px 0 20px;
  border-radius: 16px;
  align-items: center;
  height: 35px;
  min-width: 30px;
  cursor: pointer;
}
.dropdown > .dropdown-btn-blue:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #003487;
  transition: all 0.2s;
  border-radius: 16px;
  z-index: -1;
}
.dropdown > .dropdown-btn-blue:hover {
  color: #fff;
}
.dropdown > .dropdown-btn-blue:hover:before {
  width: 100%;
}
.dropdown > .dropdown-btn-active {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/arrowUp_icon.svg") no-repeat center right 17px;
  background-size: auto 8px;
  display: inline-flex;
  background-color: #e2e2e2;
  height: 35px;
}
.dropdown > .dropdown-btn-blue-active {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/arrowUp_icon.svg") no-repeat center right 17px;
  background-size: auto 8px;
  display: inline-flex;
  background-color: #0047ba;
  height: 35px;
}
.dropdown > .dropdown-list {
  position: absolute;
  z-index: 20;
  top: 45px;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0 25px;
  border-radius: 10px;
  right: 0;
}
.dropdown > .dropdown-list::after {
  position: absolute;
  content: "";
  top: -8px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 8px solid white;
  z-index: 0;
  right: 27px;
}
.dropdown > .dropdown-list::before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  background-color: white;
  top: -7px;
  transform: rotate(45deg);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  z-index: -1;
  right: 30px;
}
.dropdown > .dropdown-list > .dropdown-item {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.875rem;
  padding: 12px 5px 12px 5px;
  border-bottom: 1px solid #c5c5c5;
  width: auto;
  white-space: nowrap;
  background-color: #fff;
  cursor: pointer;
}
.dropdown > .dropdown-list > .dropdown-item b {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 0.875rem;
  margin-right: 5px;
}
.dropdown > .dropdown-list > .dropdown-item:hover {
  color: #0047ba;
}
.dropdown > .dropdown-list > .dropdown-item:hover b {
  color: #0047ba;
}
.dropdown > .dropdown-list > .dropdown-item:last-of-type {
  border: none;
}

.pagination-container {
  margin-top: 60px;
  text-align: center;
}
.pagination-container .pagination {
  display: inline-flex;
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 1rem;
}
.pagination-container .pagination > * {
  margin: 0 10px;
}
.pagination-container .pagination > .first {
  width: 17px;
  height: 15px;
  background: url("/assets/images/icons/firstPage_pagination_0_icon.svg") no-repeat center;
  background-size: auto 14px;
  cursor: pointer;
}
.pagination-container .pagination > .first:hover {
  width: 17px;
  height: 15px;
  background: url("/assets/images/icons/firstPage_pagination_1_icon.svg") no-repeat center;
  background-size: auto 14px;
}
.pagination-container .pagination > .prev {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/prevPage_pagination_0_icon.svg") no-repeat center;
  background-size: auto 14px;
  cursor: pointer;
}
.pagination-container .pagination > .prev:hover {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/prevPage_pagination_1_icon.svg") no-repeat center;
  background-size: auto 14px;
}
.pagination-container .pagination > .counter {
  display: inline-flex;
}
.pagination-container .pagination > .counter > .currentPage {
  color: #0047ba;
  margin: 0 10px 0 0;
}
.pagination-container .pagination > .counter > .totalPage {
  margin: 0 0 0 10px;
}
.pagination-container .pagination > .next {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/nextPage_pagination_0_icon.svg") no-repeat center;
  background-size: auto 14px;
  cursor: pointer;
}
.pagination-container .pagination > .next:hover {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/nextPage_pagination_1_icon.svg") no-repeat center;
  background-size: auto 14px;
}
.pagination-container .pagination > .last {
  width: 17px;
  height: 15px;
  background: url("/assets/images/icons/lastPage_pagination_0_icon.svg") no-repeat center;
  background-size: auto 14px;
  cursor: pointer;
}
.pagination-container .pagination > .last:hover {
  width: 17px;
  height: 15px;
  background: url("/assets/images/icons/lastPage_pagination_1_icon.svg") no-repeat center;
  background-size: auto 14px;
}

.tooltip {
  position: absolute;
  background-color: lightgray;
  left: 200px;
  font-size: 16px;
  padding: 5px;
  border-radius: 5px;
}
.tooltip-vauchers {
  left: 290px;
}

.section-modern,
.section-classic {
  margin: 0 0 50px 0;
}
.section-modern > .section-header,
.section-classic > .section-header {
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 999px) {
  .section-modern > .section-header,
  .section-classic > .section-header {
    flex-direction: column;
    align-items: flex-start;
    margin: 15px 0 15px 0;
  }
  .section-modern > .section-header .tools,
  .section-classic > .section-header .tools {
    margin-top: 20px;
  }
  .section-modern > .section-header .tools > *,
  .section-classic > .section-header .tools > * {
    margin-left: 0;
    margin: 0 10px 0 0 !important;
  }
}
.section-modern > .section-header > .title,
.section-classic > .section-header > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
  display: flex;
  align-items: flex-end;
  position: relative;
  padding-top: 5px;
}
@media only screen and (max-width: 1339px) {
  .section-modern > .section-header > .title,
  .section-classic > .section-header > .title {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .section-modern > .section-header > .title,
  .section-classic > .section-header > .title {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .section-modern > .section-header > .title,
  .section-classic > .section-header > .title {
    font-size: 1.4875rem;
  }
}
.section-modern > .section-header > .title.req::after,
.section-classic > .section-header > .title.req::after {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  content: "*";
  position: absolute;
  right: -6px;
  top: 3px;
}
.section-modern > .section-header > .tools,
.section-classic > .section-header > .tools {
  display: flex;
}
.section-modern > .section-header > .tools button,
.section-classic > .section-header > .tools button {
  margin-left: 10px;
}
.section-modern > .section-header > .title,
.section-classic > .section-header > .title {
  position: relative;
}
.section-modern > .section-header > .title > .resize,
.section-classic > .section-header > .title > .resize {
  position: absolute;
  right: -25px;
  bottom: 6px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/resize_section_1_icon.svg") no-repeat center;
  background-size: auto 16px;
  cursor: pointer;
}
.section-modern > .section-content,
.section-classic > .section-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.section-modern > .section-content > *,
.section-classic > .section-content > * {
  width: 100%;
  word-break: break-word;
}
.section-modern .group,
.section-classic .group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 20px;
}
.section-modern .group > *,
.section-classic .group > * {
  width: 100%;
}
.section-modern .group > .gruop-title,
.section-classic .group > .gruop-title {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 20px 0;
}
.section-modern .col-2,
.section-classic .col-2 {
  width: calc(50% - 50px);
}
@media only screen and (max-width: 849px) {
  .section-modern .col-2,
  .section-classic .col-2 {
    width: 100%;
  }
}
.section-modern .col-3,
.section-classic .col-3 {
  width: calc(33.3333333333% - 75px);
}
.section-modern .col-4,
.section-classic .col-4 {
  width: calc(33.3333333333% - 75px);
}
.section-modern .col-button,
.section-classic .col-button {
  width: calc(50% + 20px);
}
.section-modern .attachments,
.section-classic .attachments {
  margin-right: auto;
}
.section-modern .attachments > .attachment,
.section-classic .attachments > .attachment {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.section-modern .attachments > .attachment .file-name,
.section-classic .attachments > .attachment .file-name {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 0.95rem;
  cursor: pointer;
}
.section-modern .attachments > .attachment .file-name:hover,
.section-classic .attachments > .attachment .file-name:hover {
  color: #0047ba;
}
.section-modern .attachments > .attachment .delete,
.section-classic .attachments > .attachment .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin-left: 6px;
  cursor: pointer;
}
.section-modern .attachments > .attachment .delete:hover,
.section-classic .attachments > .attachment .delete:hover {
  opacity: 0.8;
}

.section-modern-collapse,
.section-classic-collapse {
  margin: 0 0 0 0;
  border-bottom: 1px solid #c9d1db;
}
.section-modern-collapse > .section-header > .title > .resize,
.section-classic-collapse > .section-header > .title > .resize {
  cursor: pointer;
  margin-left: 7px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/resize_section_0_icon.svg") no-repeat center;
  background-size: auto 16px;
}
.section-modern-collapse > .section-header > .tools,
.section-classic-collapse > .section-header > .tools {
  display: none;
}
.section-modern-collapse > .section-content,
.section-classic-collapse > .section-content {
  display: none;
}

.section-modern .section-content {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
  padding: 20px 20px 10px 20px;
}
.section-modern .section-content .tabs {
  padding: 0 0 30px 0;
  margin-top: -10px;
}
.section-modern .section-content .tabs > .tab {
  display: inline-block;
  margin: 0 40px 0 0;
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 1rem;
  line-height: 30px;
  border-bottom: 4px solid transparent;
  cursor: pointer;
}
.section-modern .section-content .tabs > .tab:hover {
  color: #0047ba;
}
.section-modern .section-content .tabs > .tab-active {
  border-bottom: 4px solid #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 1rem;
}
.section-modern .section-content .section-content-part-title {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 20px 0;
}
.settings {
  top: -23px;
  right: -30px;
  position: absolute;
  display: none;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0px 5px;
  border-radius: 5px;
  z-index: 1;
  cursor: initial;
}
.settings > .edit {
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/edit_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.settings > .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.notif-container {
  position: fixed;
  z-index: 1000;
  bottom: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.notif-container .notif-sucess {
  width: 250px;
  padding: 15px 17px;
  border-radius: 14px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #0047ba;
}
.notif-container .notif-sucess .notif-content {
  width: calc(100% - 20px);
}
.notif-container .notif-sucess .notif-content .title {
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1rem;
  margin-bottom: 5px;
}
.notif-container .notif-sucess .notif-content .text {
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.1rem;
  word-break: break-word;
}
.notif-container .notif-sucess .notif-icon {
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/success_notif_icon.svg") no-repeat center;
  background-size: 25px auto;
  margin-right: 10px;
}
.notif-container .notif-error {
  width: 250px;
  padding: 15px 17px;
  border-radius: 14px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #f55757;
}
.notif-container .notif-error .notif-content {
  width: calc(100% - 20px);
}
.notif-container .notif-error .notif-content .title {
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1rem;
  margin-bottom: 5px;
}
.notif-container .notif-error .notif-content .text {
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.1rem;
  word-break: break-word;
}
.notif-container .notif-error .notif-icon {
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/error_notif_icon.svg") no-repeat center;
  background-size: 25px auto;
  margin-right: 10px;
}
.notif-container .notif-warning {
  width: 250px;
  padding: 15px 17px;
  border-radius: 14px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #9c9c9c;
}
.notif-container .notif-warning .notif-content {
  width: calc(100% - 20px);
}
.notif-container .notif-warning .notif-content .title {
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1rem;
  margin-bottom: 5px;
}
.notif-container .notif-warning .notif-content .text {
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.1rem;
  word-break: break-word;
}
.notif-container .notif-warning .notif-icon {
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/warning_notif_icon.svg") no-repeat center;
  background-size: 25px auto;
  margin-right: 10px;
}
.notif-container .notif-info {
  width: 250px;
  padding: 15px 17px;
  border-radius: 14px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
}
.notif-container .notif-info .notif-content {
  width: calc(100% - 20px);
}
.notif-container .notif-info .notif-content .title {
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1rem;
  margin-bottom: 5px;
}
.notif-container .notif-info .notif-content .text {
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.1rem;
  word-break: break-word;
}
.notif-container .notif-info .notif-content .title {
  color: #333333;
}
.notif-container .notif-info .notif-content .text {
  color: #6e6e6e;
}
.notif-container .notif-info .notif-icon {
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/info_notif_icon.svg") no-repeat center;
  background-size: 25px auto;
  margin-right: 10px;
}
.notif-container .notif-refresh {
  width: 250px;
  padding: 15px 17px;
  border-radius: 14px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
}
.notif-container .notif-refresh .notif-content {
  width: calc(100% - 20px);
}
.notif-container .notif-refresh .notif-content .title {
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1rem;
  margin-bottom: 5px;
}
.notif-container .notif-refresh .notif-content .text {
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.1rem;
  word-break: break-word;
}
.notif-container .notif-refresh .notif-content .title {
  color: #333333;
}
.notif-container .notif-refresh .notif-content .text {
  color: #6e6e6e;
}
.notif-container .notif-refresh .notif-icon {
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/info_notif_icon.svg") no-repeat center;
  background-size: 25px auto;
  margin-right: 10px;
}

.workflow_diagram {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.workflow_diagram_states {
  display: flex;
}
.workflow_diagram_states_state > * {
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 1rem;
}
.workflow_diagram_states_state-draft {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/draft_progress_0_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}
.workflow_diagram_states_state-draft-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/draft_progress_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
@media only screen and (max-width: 999px) {
  .workflow_diagram_states_state-draft {
    background-size: auto 80px;
    height: 110px;
    width: 81px;
  }
}
.workflow_diagram_states_state-review {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/review_progress_0_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}
.workflow_diagram_states_state-review-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/review_progress_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
@media only screen and (max-width: 999px) {
  .workflow_diagram_states_state-review {
    background-size: auto 80px;
    height: 110px;
    width: 81px;
  }
}
.workflow_diagram_states_state-approved {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/approved_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}
.workflow_diagram_states_state-approved-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/approved_progress_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
@media only screen and (max-width: 999px) {
  .workflow_diagram_states_state-approved {
    background-size: auto 80px;
    height: 110px;
    width: 81px;
  }
}
.workflow_diagram_states_state-published {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/published_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}
.workflow_diagram_states_state-published-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/published_progress_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
@media only screen and (max-width: 999px) {
  .workflow_diagram_states_state-published {
    background-size: auto 80px;
    height: 110px;
    width: 81px;
  }
}
.workflow_diagram_states_state-submitted {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/susubmitted_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}
.workflow_diagram_states_state-submitted-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/submitted_progress_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
@media only screen and (max-width: 999px) {
  .workflow_diagram_states_state-submitted {
    background-size: auto 80px;
    height: 110px;
    width: 81px;
  }
}
.workflow_diagram_states_state-approved {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/approved_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}
.workflow_diagram_states_state-approved-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/approved_progress_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
@media only screen and (max-width: 999px) {
  .workflow_diagram_states_state-approved {
    background-size: auto 80px;
    height: 110px;
    width: 81px;
  }
}
.workflow_diagram_states_state-confirmed {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/confirmed_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}
.workflow_diagram_states_state-confirmed-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/confirmed_progress_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
@media only screen and (max-width: 999px) {
  .workflow_diagram_states_state-confirmed {
    background-size: auto 80px;
    height: 110px;
    width: 81px;
  }
}
.workflow_diagram_states_state-closed {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/closed_progress_0_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}
.workflow_diagram_states_state-closed-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/closed_progress_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
@media only screen and (max-width: 999px) {
  .workflow_diagram_states_state-closed {
    background-size: auto 80px;
    height: 110px;
    width: 81px;
  }
}
.workflow_diagram_states_state-accepted {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/accepted_progress_0_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}
.workflow_diagram_states_state-accepted-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/accepted_progress_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
@media only screen and (max-width: 999px) {
  .workflow_diagram_states_state-accepted {
    background-size: auto 80px;
    height: 110px;
    width: 81px;
  }
}
.workflow_diagram_states_state-scheduled {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/scheduled_progres_0_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}
.workflow_diagram_states_state-scheduled-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/scheduled_progres_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
@media only screen and (max-width: 999px) {
  .workflow_diagram_states_state-scheduled {
    background-size: auto 80px;
    height: 110px;
    width: 81px;
  }
}
.workflow_diagram_states_state-invited {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/invited_progress_0_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}
.workflow_diagram_states_state-invited-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/invited_progress_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
@media only screen and (max-width: 999px) {
  .workflow_diagram_states_state-invited {
    background-size: auto 80px;
    height: 110px;
    width: 81px;
  }
}
.workflow_diagram_states_state-blocked {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/blocked_progress_0_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}
.workflow_diagram_states_state-blocked-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/blocked_progress_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #f55757;
}
@media only screen and (max-width: 999px) {
  .workflow_diagram_states_state-blocked {
    background-size: auto 80px;
    height: 110px;
    width: 81px;
  }
}
.workflow_diagram_states_state-expired {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/expired_progress_0_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}
.workflow_diagram_states_state-expired-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/expired_progress_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #f55757;
}
@media only screen and (max-width: 999px) {
  .workflow_diagram_states_state-expired {
    background-size: auto 80px;
    height: 110px;
    width: 81px;
  }
}
.workflow_diagram_states_state-deactivated {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/deactivated_progress_0_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}
.workflow_diagram_states_state-deactivated-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/deactivated_progress_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #f55757;
}
@media only screen and (max-width: 999px) {
  .workflow_diagram_states_state-deactivated {
    background-size: auto 80px;
    height: 110px;
    width: 81px;
  }
}
.workflow_diagram_states_state-rejected {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/rejected_progress_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}
.workflow_diagram_states_state-rejected-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/rejected_progress_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #f55757;
}
@media only screen and (max-width: 999px) {
  .workflow_diagram_states_state-rejected {
    background-size: auto 80px;
    height: 110px;
    width: 81px;
  }
}
.workflow_diagram_states_state-returned {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/returned_progress_0_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
}
.workflow_diagram_states_state-returned-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/returned_progress_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
@media only screen and (max-width: 999px) {
  .workflow_diagram_states_state-returned {
    background-size: auto 80px;
    height: 110px;
    width: 81px;
  }
}
.workflow_diagram_states_state-union {
  width: 180px;
  height: 125px;
  background: url("/assets/images/icons/union_progress_0_icon.svg") no-repeat top 40px center;
  background-size: auto 14px;
}
.workflow_diagram_states_state-union-active {
  width: 180px;
  height: 125px;
  background: url("/assets/images/icons/union_progress_1_icon.svg") no-repeat top 40px center;
  background-size: auto 14px;
}
@media only screen and (max-width: 1199px) {
  .workflow_diagram_states_state-union {
    width: 130px;
  }
}
@media only screen and (max-width: 999px) {
  .workflow_diagram_states_state-union {
    width: 80px;
    height: 110px;
    background-position: top 35px center;
  }
}
.workflow_diagram_data {
  position: absolute;
  left: 0;
  top: 0;
}
.workflow_diagram_data_id {
  margin-top: 5px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 1.5rem;
}
@media only screen and (max-width: 1339px) {
  .workflow_diagram_data_id {
    font-size: 1.425rem;
  }
}
@media only screen and (max-width: 1049px) {
  .workflow_diagram_data_id {
    font-size: 1.35rem;
  }
}
@media only screen and (max-width: 849px) {
  .workflow_diagram_data_id {
    font-size: 1.275rem;
  }
}
.workflow_diagram_data_status {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1.5rem;
}
@media only screen and (max-width: 1339px) {
  .workflow_diagram_data_status {
    font-size: 1.425rem;
  }
}
@media only screen and (max-width: 1049px) {
  .workflow_diagram_data_status {
    font-size: 1.35rem;
  }
}
@media only screen and (max-width: 849px) {
  .workflow_diagram_data_status {
    font-size: 1.275rem;
  }
}
@media only screen and (max-width: 799px) {
  .workflow_diagram_data {
    display: none;
  }
}

.layout {
  position: relative;
  min-height: calc(100vh - 50px);
  background-image: url("/assets/images/backgrounds/shape_background.svg");
  background-size: 100% auto;
  background-repeat: repeat-y;
  padding-bottom: 50px;
}
@-ms-viewport {
  .layout {
    width: 100vw;
  }
}

.hidden {
  display: none !important;
}

.navigation-container {
  background-color: #efefef;
  box-sizing: border-box;
}
.navigation-container > .navigation-basic {
  background-color: #fff;
  border-top: 8px solid #0047ba;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
}
.navigation-container > .navigation-basic > .content {
  max-width: 1300px;
  margin: 0 auto;
  height: 90px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .navigation-container > .navigation-basic > .content {
    padding: 0px 20px;
  }
}
.navigation-container > .navigation-basic > .content > .user > .user-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user {
  display: block;
  position: relative;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-btn {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/arrowDown_icon.svg") no-repeat center right 17px;
  background-size: auto 8px;
  padding: 0 40px 0 20px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  background-color: #e2e2e2;
  border-radius: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  cursor: pointer;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-btn .default {
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-btn .value {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.875rem;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-btn .value b {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 0.875rem;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-btn-blue {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/arrowDown_icon.svg") no-repeat center right 17px;
  background-size: auto 8px;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  border-radius: 16px;
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: 0.875rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-width: 110px;
  height: 35px;
  background-color: #0047ba;
  padding: 0 40px 0 20px;
  border-radius: 16px;
  align-items: center;
  height: 35px;
  min-width: 30px;
  cursor: pointer;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-btn-blue:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #003487;
  transition: all 0.2s;
  border-radius: 16px;
  z-index: -1;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-btn-blue:hover {
  color: #fff;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-btn-blue:hover:before {
  width: 100%;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-btn-active {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/arrowUp_icon.svg") no-repeat center right 17px;
  background-size: auto 8px;
  display: inline-flex;
  background-color: #e2e2e2;
  height: 35px;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-btn-blue-active {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/arrowUp_icon.svg") no-repeat center right 17px;
  background-size: auto 8px;
  display: inline-flex;
  background-color: #0047ba;
  height: 35px;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-list {
  position: absolute;
  z-index: 20;
  top: 18px;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0 25px;
  border-radius: 10px;
  left: -10px;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-list::after {
  position: absolute;
  content: "";
  top: -8px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 8px solid white;
  z-index: 0;
  left: 27px;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-list::before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  background-color: white;
  top: -7px;
  transform: rotate(45deg);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  z-index: -1;
  left: 30px;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-list > .dropdown-item {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.875rem;
  padding: 12px 5px 12px 5px;
  border-bottom: 1px solid #c5c5c5;
  width: auto;
  white-space: nowrap;
  background-color: #fff;
  cursor: pointer;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-list > .dropdown-item b {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 0.875rem;
  margin-right: 5px;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-list > .dropdown-item:hover {
  color: #0047ba;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-list > .dropdown-item:hover b {
  color: #0047ba;
}
.navigation-container > .navigation-basic > .content > .user .dropdown-user > .dropdown-list > .dropdown-item:last-of-type {
  border: none;
}
.navigation-container > .navigation-basic > .content > .nav-min {
  display: none;
}
.navigation-container > .navigation-basic > .content > .break {
  height: 50px;
  width: 1px;
  background-color: #c5c5c5;
  margin: 0 30px;
}
.navigation-container > .navigation-basic > .content > .nav-items {
  display: flex;
  width: 100%;
  flex: 1 1 auto;
}
.navigation-container > .navigation-basic > .content > .nav-items > .nav-item {
  display: inline-block;
  margin: 0 15px;
  padding-top: 5px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  cursor: pointer;
}
.navigation-container > .navigation-basic > .content > .nav-items > .nav-item:hover {
  color: #0047ba;
}
.navigation-container > .navigation-basic > .content > .nav-items > .is-active {
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.875rem;
}
.navigation-container > .navigation-basic > .content > .nav-item-admin > .nav-item {
  margin: 0 5px 0 20px;
}
@media only screen and (max-width: 1339px) {
  .navigation-container > .navigation-basic > .content > .nav-item-admin > .nav-item {
    margin: 0 0 0 20px;
  }
}
@media only screen and (max-width: 1229px) {
  .navigation-container > .navigation-basic > .content > .nav-item-admin .nav-item-categories,
  .navigation-container > .navigation-basic > .content > .nav-item-admin .nav-item-collections {
    display: none;
  }
}
.navigation-container > .navigation-basic > .content > .search-container {
  display: flex;
  width: 400px;
  height: 48px;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  border: none;
  border-radius: 26px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
}
.navigation-container > .navigation-basic > .content > .search-container > .search-icon {
  width: 25px;
  height: 28px;
  background: url("/assets/images/icons/search_0_icon.svg") no-repeat center;
  background-size: 17px auto;
  margin: 0 5px 0 0;
  cursor: pointer;
}
.navigation-container > .navigation-basic > .content > .search-container > .search-icon:hover {
  width: 25px;
  height: 28px;
  background: url("/assets/images/icons/search_1_icon.svg") no-repeat center;
  background-size: 17px auto;
}
.navigation-container > .navigation-basic > .content > .search-container > .search-input {
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 1.3rem;
  border: none;
}
.navigation-container > .navigation-basic > .content > .search-container .search-input::placeholder {
  line-height: 1.3rem;
}
.navigation-container > .navigation-basic > .content > .search-container > .search-reset {
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/searchReset_0_icon.svg") no-repeat center;
  background-size: 10px auto;
  margin-left: 5px;
  cursor: pointer;
}
.navigation-container > .navigation-basic > .content > .search-container > .search-reset:hover {
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/searchReset_1_icon.svg") no-repeat center;
  background-size: 10px auto;
}
.navigation-container > .navigation-basic > .content > .logo-offers {
  cursor: pointer;
  width: 60px;
  height: 50px;
  background: url("/assets/images/logos/mazaya_0_logo.svg") no-repeat center left;
  background-size: 38px auto;
  min-width: 60px;
  margin: 0 0 -5px 0;
}
.navigation-container > .navigation-basic > .content > .logo-offers:hover {
  width: 60px;
  height: 50px;
  background: url("/assets/images/logos/mazaya_1_logo.svg") no-repeat center left;
  background-size: 38px auto;
}
.navigation-container > .navigation-basic > .content .notifications > .notification-btn {
  position: relative;
  width: 28px;
  height: 28px;
  background: url("/assets/images/icons/notifications_icon.svg") no-repeat center left;
  background-size: auto 28px;
  min-width: 28px;
  cursor: pointer;
}
.navigation-container > .navigation-basic > .content .notifications > .notification-btn > .counter {
  position: absolute;
  top: -5px;
  left: 10px;
  background-color: #0047ba;
  padding: 3px 6px;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 0.5625rem;
  border-radius: 9px;
}
.navigation-container > .navigation-basic > .content .mazaya-cart > .mazaya-cart-btn {
  position: relative;
  width: 28px;
  height: 28px;
  background: url("/assets/images/mazayaPlus/cart.svg") no-repeat center left;
  background-size: auto 28px;
  min-width: 28px;
  cursor: pointer;
  left: 22px;
}
.navigation-container > .navigation-basic > .content .mazaya-cart > .mazaya-cart-btn > .counter {
  position: absolute;
  top: -5px;
  left: 10px;
  background-color: #0047ba;
  padding: 3px 6px;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 0.5625rem;
  border-radius: 9px;
}
.navigation-container > .navigation-sub {
  height: 72px;
  max-width: 1300px;
  margin: 0 auto;
}
@media only screen and (max-width: 1339px) {
  .navigation-container > .navigation-sub {
    padding: 0px 20px;
  }
}
.navigation-container > .navigation-sub > .content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.navigation-container > .navigation-sub > .content > .home {
  width: 56.18px;
  height: 50px;
  background: url("/assets/images/icons/home_icon.svg") no-repeat center;
  background-size: 23px auto;
  min-width: 23px;
  margin-right: 61px;
  cursor: pointer;
}
.navigation-container > .navigation-sub > .content > .left-scroll {
  display: none;
}
.navigation-container > .navigation-sub > .content > .right-scroll {
  display: none;
}
.navigation-container > .navigation-sub > .content > .nav-sub-items {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}
.navigation-container > .navigation-sub > .content > .nav-sub-items > .nav-sub-item {
  margin: 0 20px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 64px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  cursor: pointer;
  white-space: nowrap;
  min-width: 0;
}
.navigation-container > .navigation-sub > .content > .nav-sub-items > .nav-sub-item:hover {
  color: #0047ba;
}
.navigation-container > .navigation-sub > .content > .nav-sub-items > .nav-sub-item-active {
  border-bottom: 4px solid #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.875rem;
}
@media only screen and (max-width: 1299px) {
  .navigation-container .navigation-sub--resp > .content .home {
    display: none;
  }
}
.navigation-container .navigation-sub--resp > .content > .nav-sub-items {
  overflow-x: hidden;
  outline: 0;
}
.navigation-container .navigation-sub--resp > .content > .nav-sub-items > .nav-sub-item {
  display: inline-block;
  flex: 0 0 auto;
}
.navigation-container .navigation-sub--resp > .content > .nav-sub-items > .nav-sub-item:hover {
  color: #0047ba;
}
.navigation-container .navigation-sub--resp > .content > .left-scroll {
  display: inline-block;
  width: 40px;
  height: 20px;
  background: url("/assets/images/icons/arrowLeft_navbar_subbar_icon.svg") no-repeat left center;
  background-size: 12px auto;
  outline: none;
  cursor: pointer;
}
.navigation-container .navigation-sub--resp > .content > .right-scroll {
  display: inline-block;
  width: 40px;
  height: 20px;
  background: url("/assets/images/icons/arrowRight_navbar_subbar_icon.svg") no-repeat right center;
  background-size: 12px auto;
  outline: none;
  cursor: pointer;
}
@media only screen and (max-width: 1339px) {
  .navigation-container > .navigation-basic > .content > .user {
    display: none;
  }
  .navigation-container > .navigation-basic > .content > .nav-min {
    display: inline-block;
    width: 52px;
    cursor: pointer;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .nav-img {
    height: 52px;
    background: url("/assets/images/icons/hamburger_nav_0_icon.svg") no-repeat center;
    background-size: 33px auto;
    min-width: 33px;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .nav-img:hover {
    height: 52px;
    background: url("/assets/images/icons/hamburger_nav_1_icon.svg") no-repeat center;
    background-size: 33px auto;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav {
    display: block;
    display: block;
    position: relative;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-btn {
    width: auto;
    height: auto;
    background: url("/assets/images/icons/arrowDown_icon.svg") no-repeat center right 17px;
    background-size: auto 8px;
    padding: 0 40px 0 20px;
    font-family: "ADNOC Sans Regular";
    color: #8b8b8b;
    font-size: 0.875rem;
    background-color: #e2e2e2;
    border-radius: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    cursor: pointer;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-btn .default {
    font-family: "ADNOC Sans Regular";
    color: #8b8b8b;
    font-size: 0.875rem;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-btn .value {
    font-family: "ADNOC Sans Regular";
    color: #000000;
    font-size: 0.875rem;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-btn .value b {
    font-family: "ADNOC Sans Bold";
    color: #000000;
    font-size: 0.875rem;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-btn-blue {
    width: auto;
    height: auto;
    background: url("/assets/images/icons/arrowDown_icon.svg") no-repeat center right 17px;
    background-size: auto 8px;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.25rem;
    border-radius: 16px;
    font-family: "ADNOC Sans Regular";
    color: #fff;
    font-size: 0.875rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    min-width: 110px;
    height: 35px;
    background-color: #0047ba;
    padding: 0 40px 0 20px;
    border-radius: 16px;
    align-items: center;
    height: 35px;
    min-width: 30px;
    cursor: pointer;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-btn-blue:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #003487;
    transition: all 0.2s;
    border-radius: 16px;
    z-index: -1;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-btn-blue:hover {
    color: #fff;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-btn-blue:hover:before {
    width: 100%;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-btn-active {
    width: auto;
    height: auto;
    background: url("/assets/images/icons/arrowUp_icon.svg") no-repeat center right 17px;
    background-size: auto 8px;
    display: inline-flex;
    background-color: #e2e2e2;
    height: 35px;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-btn-blue-active {
    width: auto;
    height: auto;
    background: url("/assets/images/icons/arrowUp_icon.svg") no-repeat center right 17px;
    background-size: auto 8px;
    display: inline-flex;
    background-color: #0047ba;
    height: 35px;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-list {
    position: absolute;
    z-index: 20;
    top: 4px;
    background-color: #fff;
    box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
    padding: 0 25px;
    border-radius: 10px;
    left: -15px;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-list::after {
    position: absolute;
    content: "";
    top: -8px;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 8px solid white;
    z-index: 0;
    left: 27px;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-list::before {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    background-color: white;
    top: -7px;
    transform: rotate(45deg);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    z-index: -1;
    left: 30px;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-list > .dropdown-item {
    font-family: "ADNOC Sans Regular";
    color: #000000;
    font-size: 0.875rem;
    padding: 12px 5px 12px 5px;
    border-bottom: 1px solid #c5c5c5;
    width: auto;
    white-space: nowrap;
    background-color: #fff;
    cursor: pointer;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-list > .dropdown-item b {
    font-family: "ADNOC Sans Bold";
    color: #000000;
    font-size: 0.875rem;
    margin-right: 5px;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-list > .dropdown-item:hover {
    color: #0047ba;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-list > .dropdown-item:hover b {
    color: #0047ba;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-list > .dropdown-item:last-of-type {
    border: none;
  }
  .navigation-container > .navigation-basic > .content > .nav-min > .dropdown-nav > .dropdown-list {
    width: 120px;
  }
  .navigation-container > .navigation-basic > .content > .nav-items {
    display: none;
  }
  .navigation-container > .navigation-basic > .content > .search-container {
    width: 677px;
  }
  .navigation-container > .navigation-sub > .content > .home {
    display: none;
  }
  .navigation-container > .navigation-sub > .content > .nav-sub-items {
    overflow-x: hidden;
    outline: 0;
  }
  .navigation-container > .navigation-sub > .content > .nav-sub-items > .nav-sub-item {
    display: inline-block;
    flex: 0 0 auto;
  }
  .navigation-container > .navigation-sub > .content > .nav-sub-items > .nav-sub-item:hover {
    color: #0047ba;
  }
  .navigation-container > .navigation-sub > .content > .left-scroll {
    display: inline-block;
    width: 40px;
    height: 20px;
    background: url("/assets/images/icons/arrowLeft_navbar_subbar_icon.svg") no-repeat left center;
    background-size: 12px auto;
    outline: none;
    cursor: pointer;
  }
  .navigation-container > .navigation-sub > .content > .right-scroll {
    display: inline-block;
    width: 40px;
    height: 20px;
    background: url("/assets/images/icons/arrowRight_navbar_subbar_icon.svg") no-repeat right center;
    background-size: 12px auto;
    outline: none;
    cursor: pointer;
  }
}
.notification {
  position: absolute;
  z-index: 50;
  top: 46px;
  width: 350px;
  left: -302px;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}
.notification::after {
  position: absolute;
  content: "";
  top: -8px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 8px solid white;
  z-index: 0;
  right: 27px;
}
.notification::before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  background-color: white;
  top: -7px;
  transform: rotate(45deg);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  z-index: -1;
  right: 30px;
}
.notification > .notification-header {
  display: flex;
  height: 20px;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c5c5c5;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.notification > .notification-header > .title {
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.875rem;
}
.notification > .notification-header > .see-all {
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.8rem;
}
.notification > .notification-content {
  max-height: 50vh;
  overflow-y: auto;
}
.notification > .notification-content::-webkit-scrollbar {
  width: 6px;
}
.notification > .notification-content::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.notification > .notification-content::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.notification > .notification-content::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.notification > .notification-content > .notification-item {
  padding: 15px;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
}
.notification > .notification-content > .notification-item:hover {
  background-color: #f8f8f8;
}
.notification > .notification-content > .notification-item:last-of-type {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.notification > .notification-content > .notification-item > .item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notification > .notification-content > .notification-item > .item-header > .title {
  font-family: "ADNOC Sans Medium";
  color: #4c4c4c;
  font-size: 0.875rem;
}
.notification > .notification-content > .notification-item > .item-header > .time {
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.8rem;
}
.notification > .notification-content > .notification-item > .item-content {
  margin: 15px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 1.1rem;
}
.footer-container {
  margin: 80px 0;
}
.footer-container > .footer {
  position: absolute;
  width: 100%;
  bottom: 35px;
}
.footer-container > .footer > .content {
  max-width: 1300px;
  margin: 50px auto 0 auto;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 1rem;
  padding-top: 20px !important;
  border-top: 1px solid #c5c5c5;
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 1339px) {
  .footer-container > .footer > .content {
    padding: 0px 20px;
  }
}
.footer-container > .footer > .content > .terms {
  cursor: pointer;
}

.stop-scroll {
  overflow: hidden;
  margin-right: 15px;
}
.stop-scroll .navigation-container {
  margin-right: -15px;
}
.stop-scroll .navigation-container .navigation-basic {
  padding-right: 15px;
}

.offers-home-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .offers-home-container {
    padding: 0px 20px;
  }
}
.offers-home-container .header {
  margin: 0 0 40px 0;
}
.offers-home-container .header > .title {
  text-align: left;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
}
@media only screen and (max-width: 1339px) {
  .offers-home-container .header > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-home-container .header > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-home-container .header > .title {
    font-size: 1.9125rem;
  }
}
.offers-home-container .header > .sub-title {
  margin: 5px 0 0 0;
  text-align: left;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .offers-home-container .header > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-home-container .header > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-home-container .header > .sub-title {
    font-size: 1.0625rem;
  }
}
.offers-home-container > .content {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.offers-home-container > .content .offer-card {
  position: relative;
}
.offers-home-container > .content .offer-card > .cover {
  position: relative;
  border-radius: 10px;
}
.offers-home-container > .content .offer-card > .cover > .img-cover {
  top: 0;
  left: 0;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
}
.offers-home-container > .content .offer-card > .cover > .img-cover:hover {
  opacity: 0.8;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
}
.offers-home-container > .content .offer-card > .cover > .img-placeholder {
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
}
.offers-home-container > .content .offer-card > .cover > .status {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  background-color: #8b8b8b;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1rem;
  text-align: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2705882353);
}
.offers-home-container > .content .offer-card > .cover > .checked {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/checker_1_icon.svg") no-repeat center;
  background-size: auto 16px;
}
.offers-home-container > .content .offer-card > img {
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
}
.offers-home-container > .content .offer-card > img:hover {
  opacity: 0.7;
  transition: 1s ease;
}
.offers-home-container > .content .offer-card > .data {
  padding: 10px 10px 0 10px;
}
.offers-home-container > .content .offer-card > .data > .company-name {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1.125rem;
  line-height: 1.4rem;
  cursor: pointer;
  margin-top: 5px;
}
.offers-home-container > .content .offer-card > .data > .company-name:hover {
  color: #0047ba;
}
.offers-home-container > .content .offer-card > .data > .title {
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 1rem;
  line-height: 1.3rem;
  cursor: pointer;
  margin-top: 4px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.offers-home-container > .content .offer-card > .data > .title:hover {
  color: #0047ba;
}
.offers-home-container > .content .offer-card > .data > .sub-title {
  display: flex;
  justify-content: space-between;
}
.offers-home-container > .content .offer-card > .data > .sub-title > .category {
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
  cursor: pointer;
  line-height: 17px;
}
.offers-home-container > .content .offer-card > .data > .sub-title > .category:hover {
  opacity: 0.8;
}
.offers-home-container > .content .offer-card > .data > .sub-title > .location {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/location_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 12px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.offers-home-container > .content .offer-card > .data > .sub-title > .locations {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/locations_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 12px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.offers-home-container > .content .offer-card > .data > .description {
  margin: 10px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 18px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 36px;
  word-break: break-word;
}
.offers-home-container > .content .offer-card > .data > .price {
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  padding: 3px 5px 3px 5px;
  border-radius: 3px;
  background-color: #e6e6e6;
}
.offers-home-container > .content .offer-card > .data > .price .line-through {
  margin-right: 5px;
  font-family: "ADNOC Sans Medium";
  color: #8b8b8b;
  font-size: 1rem;
  text-decoration: line-through;
}
.offers-home-container > .content .offer-card > .data > .price .bold {
  color: #000000;
}
.offers-home-container > .content .offer-card > .data > .price .discount {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
}
.offers-home-container > .content .offer-card > .data > .time {
  margin: 15px 0 0 0;
}
.offers-home-container > .content .offer-card > .data > .time > .date {
  margin: 0 10px 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1.125rem;
}
.offers-home-container > .content .offer-card > .data > .time > .hours {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 1.125rem;
}
.offers-home-container > .content .offer-card > .data > .rating {
  margin: 10px 0 0 0;
}
.offers-home-container > .content .offer-card > .data > .rating > .value {
  display: inline-block;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
}
.offers-home-container > .content .offer-card > .data > .rating > .stars {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  width: 78px;
  height: 14px;
  background: url("/assets/images/icons/stars_empty_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.offers-home-container > .content .offer-card > .data > .rating > .stars > .stars-positive {
  position: absolute;
  width: 0%;
  height: 14px;
  background: url("/assets/images/icons/stars_full_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.offers-home-container > .content .offer-card > .data > .rating > .voted {
  display: inline-block;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.offers-home-container > .content .offer-card > .tag {
  position: absolute;
  top: 0;
  left: 0;
  padding: 7px 15px;
  background-color: #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 0.875rem;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 8px;
}
.offers-home-container > .content .offer-card > .status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px 15px;
  background-color: #8b8b8b;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 0.875rem;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 8px;
}
.offers-home-container > .content > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.offers-home-container > .content > div:nth-child(1) {
  margin-top: 0;
}
.offers-home-container > .content > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.offers-home-container > .content > div:nth-child(2) {
  margin-top: 0;
}
.offers-home-container > .content > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.offers-home-container > .content > div:nth-child(3) {
  margin-top: 0;
}
.offers-home-container > .content > div:nth-child(4n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.offers-home-container > .content > div:nth-child(4) {
  margin-top: 0;
}
.offers-home-container > .content > div:nth-child(4n) {
  margin-right: 0;
}
@media only screen and (max-width: 1199px) {
  .offers-home-container > .content {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-home-container > .content > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-home-container > .content > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-home-container > .content > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-home-container > .content > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-home-container > .content > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-home-container > .content > div:nth-child(3) {
    margin-top: 0;
  }
  .offers-home-container > .content > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1099px) {
  .offers-home-container > .content {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-home-container > .content > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .offers-home-container > .content > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-home-container > .content > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .offers-home-container > .content > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-home-container > .content > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .offers-home-container > .content > div:nth-child(3) {
    margin-top: 0;
  }
  .offers-home-container > .content > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .offers-home-container > .content {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-home-container > .content > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-home-container > .content > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-home-container > .content > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-home-container > .content > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-home-container > .content > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 799px) {
  .offers-home-container > .content {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-home-container > .content > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 40px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .offers-home-container > .content > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-home-container > .content > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 40px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .offers-home-container > .content > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-home-container > .content > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .offers-home-container > .content {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-home-container > .content > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .offers-home-container > .content > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-home-container > .content > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .offers-home-container > .content > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-home-container > .content > div:nth-child(2n) {
    margin-right: 0;
  }
}
.offers-home-container .footer {
  margin: 40px 0 0 0;
  text-align: center;
}
@media only screen and (max-width: 1339px) {
  .offers-home-container .footer {
    margin: 30px 0 0 0;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-home-container .footer {
    margin: 20px 0 0 0;
  }
}
.offers-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .offers-container {
    padding: 0px 20px;
  }
}
.offers-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .offers-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .offers-container .header .title-box {
    margin-bottom: 20px;
  }
}
.offers-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.offers-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .offers-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.offers-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .offers-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.offers-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .offers-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.offers-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.offers-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .offers-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.offers-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.offers-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .offers-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .offers-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.offers-container > .content {
  display: flex;
}
.offers-container > .content .filter_box {
  width: 300px;
  margin-right: 50px;
}
.offers-container > .content .filter_box .filter_box_modal {
  width: 100%;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_header {
  display: none;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content {
  width: 100%;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword {
  margin-top: 3px;
  display: flex;
  height: 48px;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  border: none;
  border-radius: 26px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_0_icon.svg") no-repeat center;
  background-size: 17px auto;
  margin: 0 15px 0 0;
  cursor: pointer;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon:hover {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_1_icon.svg") no-repeat center;
  background-size: 17px auto;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input {
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 1.3rem;
  border: none;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input::placeholder {
  line-height: 1.3rem !important;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_0_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
  border-bottom: 1px solid #c5c5c5;
  padding: 20px 0;
  cursor: pointer;
}
@media only screen and (max-width: 1339px) {
  .offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.0625rem;
  }
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header:hover {
  color: #0047ba;
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_2_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options {
  display: none;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar {
  width: 6px;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option {
  padding: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox {
  margin: 0;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label:hover {
  color: #0047ba;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  width: 78px;
  height: 14px;
  background: url("/assets/images/icons/stars_empty_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars > .stars-positive {
  position: absolute;
  width: 0%;
  height: 14px;
  background: url("/assets/images/icons/stars_full_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .counter {
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option:first-of-type {
  padding: 0;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion:first-of-type > .criterion-header {
  padding-top: 0;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_1_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  border: none;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header:hover {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_3_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-options {
  display: block;
  max-height: 190px;
  overflow-y: auto;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset {
  margin-top: 10px;
  text-align: right;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn {
  display: inline-block;
  padding-top: 3px;
  margin-right: 7px;
  padding-right: 7px;
  border-right: 2px solid #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn:hover {
  opacity: 0.8;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn {
  display: inline-block;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn:hover {
  opacity: 0.8;
}
.offers-container > .content .filter_box .filter_box_modal .filter_box_modal_footer {
  display: none;
}
@media only screen and (max-width: 999px) {
  .offers-container > .content .filter_box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(236, 241, 250, 0.7);
  }
  .offers-container > .content .filter_box .filter_box_modal {
    max-width: 600px;
    min-width: 500px;
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 30px);
    min-height: 150px;
    background-color: #fff;
    padding: 0 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  }
  .offers-container > .content .filter_box .filter_box_modal .filter_box_modal_header {
    display: block;
    font-family: "ADNOC Sans Bold";
    color: #0047ba;
    font-size: 1rem;
    padding: 20px 0 15px 0;
    border-bottom: 1px solid #c5c5c5;
  }
  .offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content {
    margin: 30px 0;
    overflow-x: auto;
    padding-right: 10px;
    padding-left: 3px;
    max-height: calc(50vh - 50px);
  }
  .offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
    width: 6px;
  }
  .offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
    background: #f8f8f8;
  }
  .offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
    background: #c0cace;
    border-radius: 5px;
    cursor: pointer;
  }
  .offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
    background: #0047ba;
  }
  .offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .criterion-options {
    display: block;
    max-height: 1000px !important;
    overflow-y: auto;
  }
  .offers-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset {
    display: none;
  }
  .offers-container > .content .filter_box .filter_box_modal .filter_box_modal_footer {
    display: block;
    padding: 0 0 20px 0;
    display: flex;
    justify-content: flex-end;
  }
  .offers-container > .content .filter_box .filter_box_modal .filter_box_modal_footer > * {
    margin: 0 0 0 10px;
  }
}
.offers-container > .content .filter_box-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(236, 241, 250, 0.7);
}
.offers-container > .content .filter_box-modal .filter_box_modal {
  max-width: 600px;
  min-width: 500px;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 30px);
  min-height: 150px;
  background-color: #fff;
  padding: 0 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}
.offers-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_header {
  display: block;
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  padding: 20px 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.offers-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content {
  margin: 30px 0;
  overflow-x: auto;
  padding-right: 10px;
  padding-left: 3px;
  max-height: calc(50vh - 50px);
}
.offers-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
  width: 6px;
}
.offers-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.offers-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.offers-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.offers-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content .reset {
  display: none;
}
.offers-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_footer {
  display: block;
  padding: 0 0 20px 0;
  display: flex;
  justify-content: flex-end;
}
.offers-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_footer > * {
  margin: 0 0 0 10px;
}
.offers-container > .content .offers-box .offers .offer-card {
  position: relative;
}
.offers-container > .content .offers-box .offers .offer-card > .cover {
  position: relative;
  border-radius: 10px;
}
.offers-container > .content .offers-box .offers .offer-card > .cover > .img-cover {
  top: 0;
  left: 0;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
}
.offers-container > .content .offers-box .offers .offer-card > .cover > .img-cover:hover {
  opacity: 0.8;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
}
.offers-container > .content .offers-box .offers .offer-card > .cover > .img-placeholder {
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
}
.offers-container > .content .offers-box .offers .offer-card > .cover > .status {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  background-color: #8b8b8b;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1rem;
  text-align: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2705882353);
}
.offers-container > .content .offers-box .offers .offer-card > .cover > .checked {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/checker_1_icon.svg") no-repeat center;
  background-size: auto 16px;
}
.offers-container > .content .offers-box .offers .offer-card > img {
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
}
.offers-container > .content .offers-box .offers .offer-card > img:hover {
  opacity: 0.7;
  transition: 1s ease;
}
.offers-container > .content .offers-box .offers .offer-card > .data {
  padding: 10px 10px 0 10px;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .company-name {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1.125rem;
  line-height: 1.4rem;
  cursor: pointer;
  margin-top: 5px;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .company-name:hover {
  color: #0047ba;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .title {
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 1rem;
  line-height: 1.3rem;
  cursor: pointer;
  margin-top: 4px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .title:hover {
  color: #0047ba;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .sub-title {
  display: flex;
  justify-content: space-between;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .sub-title > .category {
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
  cursor: pointer;
  line-height: 17px;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .sub-title > .category:hover {
  opacity: 0.8;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .sub-title > .location {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/location_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 12px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .sub-title > .locations {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/locations_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 12px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .description {
  margin: 10px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 18px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 36px;
  word-break: break-word;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .price {
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  padding: 3px 5px 3px 5px;
  border-radius: 3px;
  background-color: #e6e6e6;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .price .line-through {
  margin-right: 5px;
  font-family: "ADNOC Sans Medium";
  color: #8b8b8b;
  font-size: 1rem;
  text-decoration: line-through;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .price .bold {
  color: #000000;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .price .discount {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .time {
  margin: 15px 0 0 0;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .time > .date {
  margin: 0 10px 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1.125rem;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .time > .hours {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 1.125rem;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .rating {
  margin: 10px 0 0 0;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .rating > .value {
  display: inline-block;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .rating > .stars {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  width: 78px;
  height: 14px;
  background: url("/assets/images/icons/stars_empty_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .rating > .stars > .stars-positive {
  position: absolute;
  width: 0%;
  height: 14px;
  background: url("/assets/images/icons/stars_full_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.offers-container > .content .offers-box .offers .offer-card > .data > .rating > .voted {
  display: inline-block;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.offers-container > .content .offers-box .offers .offer-card > .tag {
  position: absolute;
  top: 0;
  left: 0;
  padding: 7px 15px;
  background-color: #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 0.875rem;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 8px;
}
.offers-container > .content .offers-box .offers .offer-card > .status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px 15px;
  background-color: #8b8b8b;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 0.875rem;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 8px;
}
.offers-container > .content .offers-box > .offers-wide {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.offers-container > .content .offers-box > .offers-wide > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.offers-container > .content .offers-box > .offers-wide > div:nth-child(1) {
  margin-top: 0;
}
.offers-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.offers-container > .content .offers-box > .offers-wide > div:nth-child(2) {
  margin-top: 0;
}
.offers-container > .content .offers-box > .offers-wide > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.offers-container > .content .offers-box > .offers-wide > div:nth-child(3) {
  margin-top: 0;
}
.offers-container > .content .offers-box > .offers-wide > div:nth-child(4n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.offers-container > .content .offers-box > .offers-wide > div:nth-child(4) {
  margin-top: 0;
}
.offers-container > .content .offers-box > .offers-wide > div:nth-child(4n) {
  margin-right: 0;
}
@media only screen and (max-width: 1199px) {
  .offers-container > .content .offers-box > .offers-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1099px) {
  .offers-container > .content .offers-box > .offers-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .offers-container > .content .offers-box > .offers-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 799px) {
  .offers-container > .content .offers-box > .offers-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 40px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 40px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .offers-container > .content .offers-box > .offers-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
    margin-right: 0;
  }
}
.offers-container > .content .offers-box > .offers-collapse {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.offers-container > .content .offers-box > .offers-collapse > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.offers-container > .content .offers-box > .offers-collapse > div:nth-child(1) {
  margin-top: 0;
}
.offers-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.offers-container > .content .offers-box > .offers-collapse > div:nth-child(2) {
  margin-top: 0;
}
.offers-container > .content .offers-box > .offers-collapse > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.offers-container > .content .offers-box > .offers-collapse > div:nth-child(3) {
  margin-top: 0;
}
.offers-container > .content .offers-box > .offers-collapse > div:nth-child(3n) {
  margin-right: 0;
}
@media only screen and (max-width: 1199px) {
  .offers-container > .content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1099px) {
  .offers-container > .content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 999px) {
  .offers-container > .content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .offers-container > .content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 799px) {
  .offers-container > .content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 40px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 40px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .offers-container > .content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
.offers-container > .content .report-flag {
  display: flex;
  position: absolute;
  background: red;
  flex-direction: row;
  width: 45px;
  height: 25px;
  align-items: center;
  justify-content: center;
  border-radius: 0px 10px 0px 10px;
  top: 0;
  right: 0;
}
.offers-container > .content .report-flag-number {
  color: #ffffff;
}
.offers-container > .content .report-flag-icon {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/report_0_icon.svg") no-repeat center left;
  background-size: auto 14px;
}

.offers-details-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .offers-details-container {
    padding: 0px 20px;
  }
}
.offers-details-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .offers-details-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .offers-details-container .header .title-box {
    margin-bottom: 20px;
  }
}
.offers-details-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.offers-details-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .offers-details-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-details-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-details-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.offers-details-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .offers-details-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-details-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-details-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.offers-details-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .offers-details-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-details-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-details-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.offers-details-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.offers-details-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .offers-details-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-details-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-details-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.offers-details-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.offers-details-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .offers-details-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .offers-details-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.offers-details-container .header .sub-title > * {
  margin-right: 7px;
}
.offers-details-container .header .sub-title .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .offers-details-container .header .sub-title .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-details-container .header .sub-title .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-details-container .header .sub-title .bold {
    font-size: 1.0625rem;
  }
}
.offers-details-container .header .sub-title .line-through {
  font-family: "ADNOC Sans Medium";
  color: #8b8b8b;
  font-size: 1rem;
  text-decoration: line-through;
}
.offers-details-container .header .sub-title .discount-price {
  margin-right: 5px;
  font-family: "ADNOC Sans Medium";
  color: #8b8b8b;
  font-size: 1rem;
}
.offers-details-container .header .sub-title .discount {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  padding: 0 5px;
  border-radius: 3px;
  background-color: #efefef;
}
.offers-details-container .header .sub-title .promocode {
  padding-left: 26px;
  font-family: "ADNOC Sans Regular";
  color: #aebbcc;
  font-size: 1rem;
  line-height: 14px;
  width: auto;
  height: 15px;
  background: url("/assets/images/icons/coupon_offer_icon.svg") no-repeat center left;
  background-size: auto 14px;
  display: inline-flex !important;
  align-items: baseline;
}
.offers-details-container .header .sub-title > .collections {
  padding-left: 21px;
  font-family: "ADNOC Sans Regular";
  color: #aebbcc;
  font-size: 1rem;
  line-height: 14px;
  width: auto;
  height: 15px;
  background: url("/assets/images/icons/collection_offer_icon.svg") no-repeat center left;
  background-size: auto 19px;
  display: inline-flex !important;
  align-items: baseline;
}
.offers-details-container .header .sub-title > .collections > .collection {
  cursor: pointer;
}
.offers-details-container .header .sub-title > .collections > .collection:hover {
  opacity: 0.8;
}
.offers-details-container .header .sub-title > .roadshows {
  padding-left: 20px;
  font-family: "ADNOC Sans Regular";
  color: #aebbcc;
  font-size: 1rem;
  line-height: 14px;
  width: auto;
  height: 15px;
  background: url("/assets/images/icons/roadshow_offer_icon.svg") no-repeat center left;
  background-size: auto 14px;
  display: inline-flex !important;
  align-items: baseline;
}
.offers-details-container .header .sub-title > .roadshows > .roadshow {
  cursor: pointer;
}
.offers-details-container .header .sub-title > .roadshows > .roadshow:hover {
  opacity: 0.8;
}
.offers-details-container .content > .offers-details {
  display: flex;
  margin-bottom: 50px;
}
.offers-details-container .content > .offers-details > .gallery {
  display: flex;
  width: calc(100% - 400px);
  align-items: center;
}
.offers-details-container .content > .offers-details > .gallery > .preview {
  width: 17.2413793103%;
  margin-right: 10px;
  text-align: center;
}
.offers-details-container .content > .offers-details > .gallery > .preview > .btn-previous {
  display: inline-block;
  width: 21px;
  height: 10px;
  background: url("/assets/images/icons/arrowUp_gallery_icon.svg") no-repeat center;
  background-size: auto 10px;
  cursor: pointer;
}
.offers-details-container .content > .offers-details > .gallery > .preview > .btn-previous:hover {
  width: 21px;
  height: 10px;
  background: url("/assets/images/icons/arrowUp_gallery_1_icon.svg") no-repeat center;
  background-size: auto 10px;
}
.offers-details-container .content > .offers-details > .gallery > .preview > .btn-previus-disabled {
  cursor: initial;
}
.offers-details-container .content > .offers-details > .gallery > .preview > .btn-previus-disabled:hover {
  width: 21px;
  height: 10px;
  background: url("/assets/images/icons/arrowUp_gallery_icon.svg") no-repeat center;
  background-size: auto 10px;
}
.offers-details-container .content > .offers-details > .gallery > .preview > .images > img {
  width: 100%;
  margin: 5px 0;
  border-radius: 8px;
  cursor: pointer;
}
.offers-details-container .content > .offers-details > .gallery > .preview > .images > img:hover {
  opacity: 0.8;
}
.offers-details-container .content > .offers-details > .gallery > .preview > .images .image-no {
  cursor: initial;
  box-shadow: none !important;
}
.offers-details-container .content > .offers-details > .gallery > .preview > .images .image-no:hover {
  opacity: 1;
}
.offers-details-container .content > .offers-details > .gallery > .preview > .images .image-active {
  box-shadow: 0px 0px 0px 2px #0073f9;
}
.offers-details-container .content > .offers-details > .gallery > .preview > .images .img-c {
  width: 100%;
  position: relative;
}
.offers-details-container .content > .offers-details > .gallery > .preview > .images .img-c__placeholder {
  width: 100%;
  margin: 5px 0;
}
.offers-details-container .content > .offers-details > .gallery > .preview > .images .img-c__realImage {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}
.offers-details-container .content > .offers-details > .gallery > .preview > .images .img-c__realImage img {
  max-width: 100%;
  max-height: calc(100% - 10px);
}
.offers-details-container .content > .offers-details > .gallery > .preview > .btn-next {
  display: inline-block;
  width: 21px;
  height: 10px;
  background: url("/assets/images/icons/arrowDown_gallery_icon.svg") no-repeat center;
  background-size: auto 10px;
  cursor: pointer;
}
.offers-details-container .content > .offers-details > .gallery > .preview > .btn-next:hover {
  width: 21px;
  height: 10px;
  background: url("/assets/images/icons/arrowDown_gallery_1_icon.svg") no-repeat center;
  background-size: auto 10px;
}
.offers-details-container .content > .offers-details > .gallery > .preview > .btn-next-disabled {
  cursor: initial;
}
.offers-details-container .content > .offers-details > .gallery > .preview > .btn-next-disabled:hover {
  width: 21px;
  height: 10px;
  background: url("/assets/images/icons/arrowDown_gallery_icon.svg") no-repeat center;
  background-size: auto 10px;
}
.offers-details-container .content > .offers-details > .gallery > .main-image {
  width: 83.3333333333%;
  position: relative;
  height: 27.2rem;
}
.offers-details-container .content > .offers-details > .gallery > .main-image > img {
  width: 100%;
  border-radius: 15px;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.offers-details-container .content > .offers-details > .gallery > .main-image > .image-real {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  overflow: hidden;
}
.offers-details-container .content > .offers-details > .gallery > .main-image > .image-real > img {
  max-width: 100%;
  max-height: 100%;
}
.offers-details-container .content > .offers-details > .gallery > .main-image > .tag {
  position: absolute;
  top: 0;
  left: 0;
  padding: 7px 15px;
  background-color: #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 0.875rem;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 8px;
}
.offers-details-container .content > .offers-details > .data-box {
  position: relative;
  width: 400px;
}
.offers-details-container .content > .offers-details > .data-box > .data {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 0 10px 20px;
}
.offers-details-container .content > .offers-details > .data-box > .data > .no-location {
  padding-left: 12px;
  padding-top: 5px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 1rem;
  line-height: 14px;
  width: auto;
  height: 15px;
  background: url("/assets/images/icons/location_0_icon.svg") no-repeat center left;
  background-size: auto 14px;
  display: flex !important;
  align-items: baseline;
}
.offers-details-container .content > .offers-details > .data-box > .data > .location {
  padding-top: 2px;
  padding-left: 12px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.9rem;
  line-height: 14px;
  width: auto;
  height: 15px;
  background: url("/assets/images/icons/location_0_icon.svg") no-repeat center left;
  background-size: auto 14px;
  display: flex !important;
  align-items: baseline;
  cursor: pointer;
}
.offers-details-container .content > .offers-details > .data-box > .data > .location:hover {
  color: #0047ba;
  width: auto;
  height: 15px;
  background: url("/assets/images/icons/location_1_icon.svg") no-repeat center left;
  background-size: auto 14px;
}
.offers-details-container .content > .offers-details > .data-box > .data > .locations {
  padding-left: 18px;
  padding-top: 2px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.9rem;
  line-height: 14px;
  display: flex !important;
  align-items: baseline;
  cursor: pointer;
  width: auto;
  height: 15px;
  background: url("/assets/images/icons/locations_0_icon.svg") no-repeat center left;
  background-size: auto 14px;
  cursor: pointer;
}
.offers-details-container .content > .offers-details > .data-box > .data > .locations:hover {
  color: #0047ba;
  width: auto;
  height: 15px;
  background: url("/assets/images/icons/locations_1_icon.svg") no-repeat center left;
  background-size: auto 14px;
}
.offers-details-container .content > .offers-details > .data-box > .data > .time {
  margin: 10px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.9rem;
}
.offers-details-container .content > .offers-details > .data-box > .data > .description {
  height: calc(100% - 200px);
  margin: 40px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.9rem;
  line-height: 1.3rem;
  overflow-y: auto;
  word-break: break-word;
}
.offers-details-container .content > .offers-details > .data-box > .data > .description::-webkit-scrollbar {
  width: 6px;
}
.offers-details-container .content > .offers-details > .data-box > .data > .description::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.offers-details-container .content > .offers-details > .data-box > .data > .description::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.offers-details-container .content > .offers-details > .data-box > .data > .description::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.offers-details-container .content > .offers-details > .data-box > .data > .rating {
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
}
.offers-details-container .content > .offers-details > .data-box > .data > .rating > .value {
  display: inline-block;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
}
.offers-details-container .content > .offers-details > .data-box > .data > .rating > .stars {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  width: 78px;
  height: 14px;
  background: url("/assets/images/icons/stars_empty_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.offers-details-container .content > .offers-details > .data-box > .data > .rating > .stars > .stars-positive {
  position: absolute;
  width: 0%;
  height: 14px;
  background: url("/assets/images/icons/stars_full_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.offers-details-container .content > .offers-details > .data-box > .data > .rating > .voted {
  display: inline-block;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.offers-details-container .content > .offers-details > .data-box > .data > .company-data {
  display: flex;
  margin: 15px 0 0 0;
}
.offers-details-container .content > .offers-details > .data-box > .data > .company-data > .logo {
  display: inline-block;
  margin: 0 10px 0 0;
}
.offers-details-container .content > .offers-details > .data-box > .data > .company-data > .logo > img {
  height: 75px;
}
.offers-details-container .content > .offers-details > .data-box > .data > .company-data > .basic-informations {
  display: inline-block;
}
.offers-details-container .content > .offers-details > .data-box > .data > .company-data > .basic-informations > .info {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.offers-details-container .content > .offers-details > .data-box > .data > .company-data > .basic-informations > .info:first-of-type {
  margin-top: 0;
}
.offers-details-container .content > .offers-details > .data-box > .data-no-price .description {
  height: calc(100% - 170px);
}
.offers-details-container .content > .offers-details > .data-box > .data-no-location .description {
  margin-top: 0px;
  height: calc(100% - 115px);
}
@media only screen and (max-width: 899px) {
  .offers-details-container .content > .offers-details {
    flex-wrap: wrap;
  }
  .offers-details-container .content > .offers-details > .gallery {
    width: 100%;
  }
  .offers-details-container .content > .offers-details > .data-box {
    display: none;
  }
}
.offers-details-container .content > .offers-small-description {
  display: none;
}
.offers-details-container .content > .offers-small-description .text {
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 1rem;
  line-height: 1.3rem;
}
@media only screen and (max-width: 899px) {
  .offers-details-container .content > .offers-small-description {
    display: block;
  }
}
.offers-details-container .content > .offers-description > .section-content > * > * > * {
  font-family: Helvetica, sans-serif;
  font-size: 0.9rem;
  line-height: 1.2rem; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
.offers-details-container .content > .offers-description > .section-content > * > * > * h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * body {
  font-family: Helvetica, sans-serif;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
.offers-details-container .content > .offers-description > .section-content > * > * > * pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
.offers-details-container .content > .offers-description > .section-content > * > * > * a {
  background-color: transparent;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}
.offers-details-container .content > .offers-description > .section-content > * > * > * b,
.offers-details-container .content > .offers-description > .section-content > * > * > * strong {
  font-weight: bolder;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * code,
.offers-details-container .content > .offers-description > .section-content > * > * > * kbd,
.offers-details-container .content > .offers-description > .section-content > * > * > * samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
.offers-details-container .content > .offers-description > .section-content > * > * > * small {
  font-size: 80%;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * sub,
.offers-details-container .content > .offers-description > .section-content > * > * > * sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * sub {
  bottom: -0.25em;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * sup {
  top: -0.5em;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * img {
  border-style: none;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * p {
  margin: revert;
  padding: revert;
  border: 0;
  font-size: revert;
  vertical-align: revert;
  line-height: revert;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * button,
.offers-details-container .content > .offers-description > .section-content > * > * > * input,
.offers-details-container .content > .offers-description > .section-content > * > * > * optgroup,
.offers-details-container .content > .offers-description > .section-content > * > * > * select,
.offers-details-container .content > .offers-description > .section-content > * > * > * textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}
.offers-details-container .content > .offers-description > .section-content > * > * > * fieldset {
  padding: 0.35em 0.75em 0.625em;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}
.offers-details-container .content > .offers-description > .section-content > * > * > * progress {
  vertical-align: baseline;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * details {
  display: block;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * summary {
  display: list-item;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * template {
  display: none;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * [hidden] {
  display: none;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * table,
.offers-details-container .content > .offers-description > .section-content > * > * > * td,
.offers-details-container .content > .offers-description > .section-content > * > * > * th {
  border: 1px solid black;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * table {
  margin-bottom: 20px !important;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * td,
.offers-details-container .content > .offers-description > .section-content > * > * > * th {
  padding: 12px 15px;
  min-width: 100px;
}
.offers-details-container .content > .offers-description > .section-content > * > * > * th {
  background-color: #efefef;
}
.offers-details-container .content > .offers-description .terms-and-condition {
  overflow: auto;
}
.offers-details-container .content > .offers-video .offers-video .video {
  max-width: 600px;
}
.offers-details-container .content > .offers-attachments .attachments > .attachment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 20px 0;
}
.offers-details-container .content > .offers-attachments .attachments > .attachment > .file-name {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  margin: 0 20px 0 0;
}
.offers-details-container .content > .offers-attachments .attachments > .attachment > .btn-download {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/download_0_icon.svg") no-repeat center right 15px;
  background-size: auto 17px;
  padding: 6px 40px 6px 20px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 1rem;
  border: 1px solid #0047ba;
  border-radius: 16px;
  cursor: pointer;
}
.offers-details-container .content > .offers-attachments .attachments > .attachment > .btn-download:hover {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/download_1_icon.svg") no-repeat center right 15px;
  background-size: auto 17px;
  padding: 6px 40px 6px 20px;
  color: #fff;
  background-color: #0047ba;
}
.offers-details-container .content > .offers-location .map {
  width: 100%;
}
.offers-details-container .content > .offers-location .map > * {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.offers-details-container .content > .offers-reviews .rating {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.offers-details-container .content > .offers-reviews .rating > .value {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 4rem;
}
@media only screen and (max-width: 1339px) {
  .offers-details-container .content > .offers-reviews .rating > .value {
    font-size: 3.8rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-details-container .content > .offers-reviews .rating > .value {
    font-size: 3.6rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-details-container .content > .offers-reviews .rating > .value {
    font-size: 3.4rem;
  }
}
.offers-details-container .content > .offers-reviews .rating > .right {
  margin: 0 0 0 20px;
}
.offers-details-container .content > .offers-reviews .rating > .right > .stars {
  position: relative;
  display: inline-block;
  width: 148px;
  height: 24px;
  background: url("/assets/images/icons/stars_0_icon.svg") no-repeat center left;
  background-size: 148px auto;
}
.offers-details-container .content > .offers-reviews .rating > .right > .stars > .stars-positive {
  position: absolute;
  width: 0%;
  height: 24px;
  background: url("/assets/images/icons/stars_1_icon.svg") no-repeat center left;
  background-size: 148px auto;
}
.offers-details-container .content > .offers-reviews .rating > .right > .count {
  margin: 5px 0 5px 0;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 0.875rem;
}
.offers-details-container .content > .offers-reports .table-report {
  background: white;
  border-radius: 15px;
  padding: 15px 10px;
  padding-bottom: 12px;
}
.offers-details-container .content > .offers-reports .col-user {
  width: 23%;
}
.offers-details-container .content > .offers-reports .col-report {
  width: 60%;
}
.offers-details-container .content > .offers-reports .col-report-50 {
  width: 50%;
}
.offers-details-container .content > .offers-reports .col-date {
  width: 15%;
}
.offers-details-container .content > .offers-reports .col-btn {
  width: 10%;
}
.offers-details-container .content > .offers-reports .user-information {
  width: 25%;
  display: flex;
  flex-direction: column;
}
.offers-details-container .content > .offers-reports .user-information .user-name,
.offers-details-container .content > .offers-reports .user-information .user-email {
  padding: 4px 4px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.offers-details-container .content > .offers-reports .user-information .user-email {
  color: #8b8b8b;
  font-size: 15px;
}
.offers-details-container .content > .offers-reports .report-text {
  width: 60%;
}
.offers-details-container .content > .offers-reports .report-text-50 {
  width: 50%;
}
.offers-details-container .content > .offers-reports .report-date {
  width: 15%;
}
.offers-details-container .content > .offers-reports .report-btn {
  width: 10%;
}

.offers-new-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .offers-new-container {
    padding: 0px 20px;
  }
}
.offers-new-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .offers-new-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .offers-new-container .header .title-box {
    margin-bottom: 20px;
  }
}
.offers-new-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.offers-new-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .offers-new-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-new-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-new-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.offers-new-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .offers-new-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-new-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-new-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.offers-new-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .offers-new-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-new-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-new-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.offers-new-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.offers-new-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .offers-new-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-new-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-new-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.offers-new-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.offers-new-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .offers-new-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .offers-new-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.offers-new-container > .content .attachments > .attachment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 20px 0;
}
.offers-new-container > .content .attachments > .attachment > .file-name {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  margin: 0 20px 0 0;
  cursor: pointer;
}
.offers-new-container > .content .attachments > .attachment > .file-name:hover {
  color: #0047ba;
}
.offers-new-container > .content .attachments > .no-attachments {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
}
.offers-new-container > .content .attachments .note {
  margin-top: 20px;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 1rem;
}
.offers-new-container > .content .location .map {
  width: 100%;
}
.offers-new-container > .content .location .location-choice > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.offers-new-container > .content .location .location-choice > .f-checkbox {
  margin: 0 0 10px 0;
}
.offers-new-container > .content .location .location-choice > .f-basic-btn {
  margin: 15px 0 0 0;
}
@media only screen and (max-width: 1099px) {
  .offers-new-container > .content .location .col-2 {
    width: 100%;
  }
  .offers-new-container > .content .location .location-choice {
    margin-top: 30px;
  }
}
.offers-new-container > .content .images .no-images-box {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.offers-new-container > .content .images .no-images-box > .icon {
  width: 200px;
  height: 180px;
  background: url("/assets/images/icons/noImageUploder_icon.svg") no-repeat bottom center;
  background-size: auto 140px;
}
.offers-new-container > .content .images .no-images-box > .btn {
  margin: 0 0 20px 0;
}
.offers-new-container > .content .images .no-images-box > .note {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 1rem;
  line-height: 1.3rem;
}
.offers-new-container > .content .images .no-images-box > .note > span {
  color: #0047ba;
}
.offers-new-container > .content .images .cropper-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.offers-new-container > .content .images .cropper-box .cropper {
  width: 30%;
}
.offers-new-container > .content .images .cropper-box .cropper > .cropper-subtitle {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.offers-new-container > .content .images .cropper-box .prev {
  width: 30%;
}
.offers-new-container > .content .images .cropper-box .prev > .cropper-subtitle {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.offers-new-container > .content .images .cropper-box .prev > img {
  width: 100%;
  background-color: #efefef;
  border: 1px solid #c5c5c5;
  border-radius: 15px;
}
.offers-new-container > .content .images .cropper-box .imgs {
  width: 30%;
  flex-wrap: wrap;
  overflow: auto;
  padding: 0 10px;
}
.offers-new-container > .content .images .cropper-box .imgs::-webkit-scrollbar {
  width: 6px;
}
.offers-new-container > .content .images .cropper-box .imgs::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.offers-new-container > .content .images .cropper-box .imgs::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.offers-new-container > .content .images .cropper-box .imgs::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.offers-new-container > .content .images .cropper-box .imgs > .cropper-subtitle {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.offers-new-container > .content .images .cropper-box .imgs > .img-c {
  position: relative;
  display: inline-block;
  width: 99px;
  height: 66px;
  background-color: #efefef;
  margin-bottom: 20px;
  margin-right: 20px;
  text-align: center;
  border: 1px solid #c5c5c5;
}
.offers-new-container > .content .images .cropper-box .imgs > .img-c > .img-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.offers-new-container > .content .images .cropper-box .imgs > .img-c > .img-box > img {
  max-width: 100%;
  max-height: 100%;
}
.offers-new-container > .content .images .cropper-box .imgs > .img-c > .cover-tag {
  position: absolute;
  top: -8px;
  left: -8px;
  width: 18px;
  height: 18px;
  background: url("/assets/images/icons/coverStart_cropper_icon.svg") no-repeat center;
  background-size: 18px auto;
}
.offers-new-container > .content .images .cropper-box .imgs > .img-c-active {
  border: 1px solid #0047ba;
}
@media only screen and (max-width: 1299px) {
  .offers-new-container > .content .images .cropper-box {
    justify-content: flex-start;
  }
  .offers-new-container > .content .images .cropper-box .cropper {
    width: 40%;
  }
  .offers-new-container > .content .images .cropper-box .prev {
    width: 40%;
    margin-left: 50px;
  }
  .offers-new-container > .content .images .cropper-box .imgs {
    width: 100%;
    margin-top: 20px;
  }
}
.offers-new-container > .content .images .imgs-prev-box {
  display: inline-flex;
}
.offers-new-container > .content .images .imgs-prev-box > * {
  width: calc((100% - 60px) / 3);
}
.offers-new-container > .content .images .imgs-prev-box > .imgs-prev-cover-c {
  margin: 0 30px 0 0;
}
.offers-new-container > .content .images .imgs-prev-box > .imgs-prev-cover-c > .imgs-prev-sub-title {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.offers-new-container > .content .images .imgs-prev-box > .imgs-prev-cover-c > .imgs-prev-cover-img {
  width: 100%;
  border-radius: 20px;
}
.offers-new-container > .content .images .imgs-prev-box > .imgs-prev-main-c {
  margin: 0 30px 0 0;
}
.offers-new-container > .content .images .imgs-prev-box > .imgs-prev-main-c > .imgs-prev-sub-title {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.offers-new-container > .content .images .imgs-prev-box > .imgs-prev-main-c > .imgs-prev-main-img {
  width: 100%;
  border-radius: 20px;
}
.offers-new-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-sub-title {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.offers-new-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c {
  position: relative;
  height: 100%;
}
.offers-new-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs {
  position: absolute;
  max-height: calc(100% - 32px);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 10px;
}
.offers-new-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs::-webkit-scrollbar {
  width: 6px;
}
.offers-new-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.offers-new-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.offers-new-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.offers-new-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs > .imgs-prev-img {
  border-radius: 10px;
  width: calc((100% - 30px) / 3);
  margin-bottom: 15px;
  cursor: pointer;
}
.offers-new-container > .content .images > .error {
  margin: 10px 0 0 0;
  text-align: right;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.offers-new-container > .content .images > .error:before {
  content: "! ";
}
.offers-new-container > .content .close-announcement .images {
  opacity: 0;
  height: 0;
  overflow: hidden;
}
.offers-new-container > .content .open-announcement .images {
  opacity: 1;
  height: auto;
  transition: opacity 2s ease-out;
}
.offers-new-container > .content .open-announcement .images .section-content {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
}
.offers-new-container > .footer {
  margin: 30px 0 0 0;
}
.offers-new-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.offers-new-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.home-dashboard-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .home-dashboard-container {
    padding: 0px 20px;
  }
}
.home-dashboard-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .home-dashboard-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .home-dashboard-container .header .title-box {
    margin-bottom: 20px;
  }
}
.home-dashboard-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.home-dashboard-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .home-dashboard-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .home-dashboard-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .home-dashboard-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.home-dashboard-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .home-dashboard-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .home-dashboard-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .home-dashboard-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.home-dashboard-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .home-dashboard-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .home-dashboard-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .home-dashboard-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.home-dashboard-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.home-dashboard-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .home-dashboard-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .home-dashboard-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .home-dashboard-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.home-dashboard-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.home-dashboard-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .home-dashboard-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .home-dashboard-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.home-dashboard-container > .content > .section {
  margin: 0 0 40px 0;
}
.home-dashboard-container > .content > .section .section_title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.6rem;
  margin: 0 0 20px 0;
}
@media only screen and (max-width: 1339px) {
  .home-dashboard-container > .content > .section .section_title {
    font-size: 1.52rem;
  }
}
@media only screen and (max-width: 1049px) {
  .home-dashboard-container > .content > .section .section_title {
    font-size: 1.44rem;
  }
}
@media only screen and (max-width: 849px) {
  .home-dashboard-container > .content > .section .section_title {
    font-size: 1.36rem;
  }
}
.home-dashboard-container > .content > .section .section_content {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.home-dashboard-container > .content > .section .section_content > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.home-dashboard-container > .content > .section .section_content > div:nth-child(1) {
  margin-top: 0;
}
.home-dashboard-container > .content > .section .section_content > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.home-dashboard-container > .content > .section .section_content > div:nth-child(2) {
  margin-top: 0;
}
.home-dashboard-container > .content > .section .section_content > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.home-dashboard-container > .content > .section .section_content > div:nth-child(3) {
  margin-top: 0;
}
.home-dashboard-container > .content > .section .section_content > div:nth-child(4n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.home-dashboard-container > .content > .section .section_content > div:nth-child(4) {
  margin-top: 0;
}
.home-dashboard-container > .content > .section .section_content > div:nth-child(4n) {
  margin-right: 0;
}
@media only screen and (max-width: 1199px) {
  .home-dashboard-container > .content > .section .section_content {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .home-dashboard-container > .content > .section .section_content > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .home-dashboard-container > .content > .section .section_content > div:nth-child(1) {
    margin-top: 0;
  }
  .home-dashboard-container > .content > .section .section_content > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .home-dashboard-container > .content > .section .section_content > div:nth-child(2) {
    margin-top: 0;
  }
  .home-dashboard-container > .content > .section .section_content > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .home-dashboard-container > .content > .section .section_content > div:nth-child(3) {
    margin-top: 0;
  }
  .home-dashboard-container > .content > .section .section_content > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 849px) {
  .home-dashboard-container > .content > .section .section_content {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .home-dashboard-container > .content > .section .section_content > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .home-dashboard-container > .content > .section .section_content > div:nth-child(1) {
    margin-top: 0;
  }
  .home-dashboard-container > .content > .section .section_content > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .home-dashboard-container > .content > .section .section_content > div:nth-child(2) {
    margin-top: 0;
  }
  .home-dashboard-container > .content > .section .section_content > div:nth-child(2n) {
    margin-right: 0;
  }
}
.home-dashboard-container > .content > .section .section_content .dashboard-card {
  background-color: #fff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  cursor: pointer;
}
.home-dashboard-container > .content > .section .section_content .dashboard-card:hover {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.16);
}
.home-dashboard-container > .content > .section .section_content .dashboard-card .dashboard-card_title {
  margin: 20px 0 0 20px;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.2rem;
}
.home-dashboard-container > .content > .section .section_content .dashboard-card .dashboard-card_subtitle {
  margin: 6px 0 0 20px;
  font-family: "ADNOC Sans Medium";
  color: #6e6e6e;
  font-size: 0.9rem;
}
.home-dashboard-container > .content > .section .section_content .dashboard-card .dashboard-card_counter {
  margin: 20px 0 20px 20px;
  padding-left: 34px;
  padding-top: 7px;
  line-height: 24px;
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1.3rem;
}
@media only screen and (max-width: 1339px) {
  .home-dashboard-container > .content > .section .section_content .dashboard-card .dashboard-card_counter {
    font-size: 1.235rem;
  }
}
@media only screen and (max-width: 1049px) {
  .home-dashboard-container > .content > .section .section_content .dashboard-card .dashboard-card_counter {
    font-size: 1.17rem;
  }
}
@media only screen and (max-width: 849px) {
  .home-dashboard-container > .content > .section .section_content .dashboard-card .dashboard-card_counter {
    font-size: 1.105rem;
  }
}
.home-dashboard-container > .content > .section .section_content .dashboard-card .dashboard-icon-banners {
  width: auto;
  height: 27px;
  background: url("/assets/images/icons/banners_dashboard_icon.svg") no-repeat center left;
  background-size: auto 22px;
}
.home-dashboard-container > .content > .section .section_content .dashboard-card .dashboard-icon-offers {
  width: auto;
  height: 27px;
  background: url("/assets/images/icons/offers_dashboard_icon.svg") no-repeat center left;
  background-size: auto 22px;
}
.home-dashboard-container > .content > .section .section_content .dashboard-card .dashboard-icon-roadshow {
  width: auto;
  height: 27px;
  background: url("/assets/images/icons/roadshow_dashboard_icon.svg") no-repeat center left;
  background-size: auto 22px;
}
.home-dashboard-container > .content > .section .section_content .dashboard-card .dashboard-icon-tags {
  width: auto;
  height: 27px;
  background: url("/assets/images/icons/tags_dashboard_icon.svg") no-repeat center left;
  background-size: auto 22px;
}
.home-dashboard-container > .content > .section .section_content .dashboard-card .dashboard-icon-users {
  width: auto;
  height: 27px;
  background: url("/assets/images/icons/users_dashboard_icon.svg") no-repeat center left;
  background-size: auto 22px;
}
.home-dashboard-container > .content > .section .section_content .dashboard-card .dashboard-icon-agreements {
  width: auto;
  height: 27px;
  background: url("/assets/images/icons/agreements_dashboard_icon.svg") no-repeat center left;
  background-size: auto 22px;
}
.home-dashboard-container > .content > .section .section_content .dashboard-card .dashboard-icon-proposals {
  width: auto;
  height: 27px;
  background: url("/assets/images/icons/proposals_dashboard_icon.svg") no-repeat center left;
  background-size: auto 22px;
}
.home-dashboard-container > .content > .section .section_content .dashboard-card .dashboard-icon-categories {
  width: auto;
  height: 27px;
  background: url("/assets/images/icons/categories_dashboard_icon.svg") no-repeat center left;
  background-size: auto 22px;
}
.home-dashboard-container > .content > .section .section_content .dashboard-card .dashboard-icon-suppliers {
  width: auto;
  height: 27px;
  background: url("/assets/images/icons/companies_dashboard_icon.svg") no-repeat center left;
  background-size: auto 22px;
}
.home-dashboard-container > .content > .section .section_content .dashboard-card .dashboard-icon-collections {
  width: auto;
  height: 27px;
  background: url("/assets/images/icons/collections_dashboard_icon.svg") no-repeat center left;
  background-size: auto 22px;
}
.home-dashboard-container > .content > .section .section_content .dashboard-card .dashboard-icon-offers-rating {
  width: auto;
  height: 27px;
  background: url("/assets/images/icons/offers-rating_dashboard_icon.svg") no-repeat center left;
  background-size: auto 22px;
}

.transfer-offers-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .transfer-offers-container {
    padding: 0px 20px;
  }
}
.transfer-offers-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .transfer-offers-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .transfer-offers-container .header .title-box {
    margin-bottom: 20px;
  }
}
.transfer-offers-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.transfer-offers-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .transfer-offers-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .transfer-offers-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .transfer-offers-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.transfer-offers-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .transfer-offers-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .transfer-offers-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .transfer-offers-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.transfer-offers-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .transfer-offers-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .transfer-offers-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .transfer-offers-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.transfer-offers-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.transfer-offers-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .transfer-offers-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .transfer-offers-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .transfer-offers-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.transfer-offers-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.transfer-offers-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .transfer-offers-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .transfer-offers-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.transfer-offers-container > .footer {
  margin: 30px 0 0 0;
}
.transfer-offers-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.transfer-offers-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.analytics-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .analytics-container {
    padding: 0px 20px;
  }
}
.analytics-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .analytics-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .analytics-container .header .title-box {
    margin-bottom: 20px;
  }
}
.analytics-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.analytics-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .analytics-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .analytics-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .analytics-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.analytics-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .analytics-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .analytics-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .analytics-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.analytics-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .analytics-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .analytics-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .analytics-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.analytics-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.analytics-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .analytics-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .analytics-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .analytics-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.analytics-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.analytics-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .analytics-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .analytics-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.analytics-container > .content {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.analytics-container > .content > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 25px;
}
.analytics-container > .content > div:nth-child(1) {
  margin-top: 0;
}
.analytics-container > .content > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 25px;
}
.analytics-container > .content > div:nth-child(2) {
  margin-top: 0;
}
.analytics-container > .content > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 25px;
}
.analytics-container > .content > div:nth-child(3) {
  margin-top: 0;
}
.analytics-container > .content > div:nth-child(4n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 25px;
}
.analytics-container > .content > div:nth-child(4) {
  margin-top: 0;
}
.analytics-container > .content > div:nth-child(4n) {
  margin-right: 0;
}
@media only screen and (max-width: 1199px) {
  .analytics-container > .content {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .analytics-container > .content > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 25px;
  }
  .analytics-container > .content > div:nth-child(1) {
    margin-top: 0;
  }
  .analytics-container > .content > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 25px;
  }
  .analytics-container > .content > div:nth-child(2) {
    margin-top: 0;
  }
  .analytics-container > .content > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 25px;
  }
  .analytics-container > .content > div:nth-child(3) {
    margin-top: 0;
  }
  .analytics-container > .content > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 849px) {
  .analytics-container > .content {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .analytics-container > .content > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 25px;
  }
  .analytics-container > .content > div:nth-child(1) {
    margin-top: 0;
  }
  .analytics-container > .content > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 25px;
  }
  .analytics-container > .content > div:nth-child(2) {
    margin-top: 0;
  }
  .analytics-container > .content > div:nth-child(2n) {
    margin-right: 0;
  }
}
.analytics-container > .content .analytics-card {
  background-color: #fff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 20px 0 30px 0;
}
.analytics-container > .content .analytics-card__title {
  margin-left: 20px;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.2rem;
}
.analytics-container > .content .analytics-card__txt {
  margin-top: 6px;
  margin-left: 20px;
  font-family: "ADNOC Sans Medium";
  color: #6e6e6e;
  font-size: 0.9rem;
}
.analytics-container > .content .analytics-card__download {
  margin-top: 10px;
  margin-left: 20px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.analytics-container > .content .analytics-card__download:hover {
  opacity: 0.8;
}
.analytics-container > .content .analytics-card__link {
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
}
.analytics-container > .content .analytics-card__d-icon {
  border-radius: 3px;
  background-color: #0047ba;
  margin-left: 5px;
  padding: 2px;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 0.7rem;
}
.analytics-container .dropdown-card-btn {
  width: 23px;
  height: 10px;
  background: url("/assets/images/icons/arrowDown_icon.svg") no-repeat center left 3px;
  background-size: auto 8px;
  background-repeat: no-repeat;
  display: inline-flex;
  margin-left: 140px;
}
.analytics-container .arrowDown {
  transform: rotate(180deg);
}

.opened-card {
  display: flex;
}

.closed-card {
  display: none !important;
}

.roadshow-home-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .roadshow-home-container {
    padding: 0px 20px;
  }
}
.roadshow-home-container .header {
  margin: 0 0 40px 0;
}
.roadshow-home-container .header > .title {
  text-align: left;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-home-container .header > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-home-container .header > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-home-container .header > .title {
    font-size: 1.9125rem;
  }
}
.roadshow-home-container .header > .sub-title {
  margin: 5px 0 0 0;
  text-align: left;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-home-container .header > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-home-container .header > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-home-container .header > .sub-title {
    font-size: 1.0625rem;
  }
}
.roadshow-home-container > .content .bannerRs {
  position: relative;
  padding: 80px;
  border-radius: 1.2vw;
  width: auto;
  height: auto;
  background: url("/assets/images/backgrounds/roadshow_background.svg") no-repeat center;
  background-size: auto auto;
  display: flex;
  align-items: flex-end;
}
.roadshow-home-container > .content .bannerRs__data {
  width: calc(100% - 375px);
}
.roadshow-home-container > .content .bannerRs__data .year {
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 5.5rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-home-container > .content .bannerRs__data .year {
    font-size: 5.225rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-home-container > .content .bannerRs__data .year {
    font-size: 4.95rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-home-container > .content .bannerRs__data .year {
    font-size: 4.675rem;
  }
}
.roadshow-home-container > .content .bannerRs__data .title {
  margin-top: 5px;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 4rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-home-container > .content .bannerRs__data .title {
    font-size: 3.8rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-home-container > .content .bannerRs__data .title {
    font-size: 3.6rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-home-container > .content .bannerRs__data .title {
    font-size: 3.4rem;
  }
}
.roadshow-home-container > .content .bannerRs__data .subtitle {
  margin-top: 30px;
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: 1.3rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-home-container > .content .bannerRs__data .subtitle {
    font-size: 1.235rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-home-container > .content .bannerRs__data .subtitle {
    font-size: 1.17rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-home-container > .content .bannerRs__data .subtitle {
    font-size: 1.105rem;
  }
}
.roadshow-home-container > .content .bannerRs__content {
  width: 375px;
}
.roadshow-home-container > .content .bannerRs__content .text {
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: 0.9rem;
  line-height: 1.3rem;
  text-align: right;
}
.roadshow-home-container > .content .bannerRs__content .footer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.roadshow-home-container > .content .bannerRs__content .footer .btn {
  margin-top: 10px;
}
@media only screen and (max-width: 1039px) {
  .roadshow-home-container > .content .bannerRs {
    flex-direction: column;
  }
  .roadshow-home-container > .content .bannerRs .bannerRs__data {
    width: 100%;
  }
  .roadshow-home-container > .content .bannerRs .bannerRs__content {
    margin-top: 40px;
    width: 100%;
  }
  .roadshow-home-container > .content .bannerRs .bannerRs__content .text {
    text-align: left;
  }
  .roadshow-home-container > .content .bannerRs .bannerRs__content .footer {
    justify-content: flex-start;
  }
}

.roadshow-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .roadshow-container {
    padding: 0px 20px;
  }
}
.roadshow-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .roadshow-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .roadshow-container .header .title-box {
    margin-bottom: 20px;
  }
}
.roadshow-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.roadshow-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .roadshow-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.roadshow-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.roadshow-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.roadshow-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.roadshow-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.roadshow-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.roadshow-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .roadshow-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .roadshow-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.roadshow-container > .content > .location-box {
  margin: 0 0 50px 0;
}
.roadshow-container > .content > .location-box > .location-box-header > .location-box-header-title {
  width: auto;
  height: 100%;
  background: url("/assets/images/icons/location_roadshow_icon.svg") no-repeat center left;
  background-size: auto 24px;
  display: inline-block;
  padding: 5px 0 5px 30px;
  margin: 0 0 20px 0;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-container > .content > .location-box > .location-box-header > .location-box-header-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-container > .content > .location-box > .location-box-header > .location-box-header-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-container > .content > .location-box > .location-box-header > .location-box-header-title {
    font-size: 1.0625rem;
  }
}
.roadshow-container > .content > .location-box > .location-box-offers {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(1) {
  margin-top: 0;
}
.roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(2) {
  margin-top: 0;
}
.roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(3) {
  margin-top: 0;
}
.roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(4n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(4) {
  margin-top: 0;
}
.roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(4n) {
  margin-right: 0;
}
@media only screen and (max-width: 959px) {
  .roadshow-container > .content > .location-box > .location-box-offers {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 40px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 40px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 40px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 879px) {
  .roadshow-container > .content > .location-box > .location-box-offers {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .roadshow-container > .content > .location-box > .location-box-offers {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-container > .content > .location-box > .location-box-offers > div:nth-child(2n) {
    margin-right: 0;
  }
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card {
  position: relative;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .cover {
  position: relative;
  border-radius: 10px;
  height: 12.2rem;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .cover > .img-cover {
  top: 0;
  left: 0;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
  height: 100%;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .cover > .img-cover:hover {
  opacity: 0.8;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .cover > .img-placeholder {
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .cover > .details {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  background-color: #8b8b8b;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1rem;
  text-align: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2705882353);
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .cover > .details .roadshow-title {
  display: none;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .cover:hover .duration {
  display: none;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .cover:hover .roadshow-title {
  display: inline-block;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .cover > .checked {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/checker_1_icon.svg") no-repeat center;
  background-size: auto 16px;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > img {
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > img:hover {
  opacity: 0.8;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .data {
  padding: 10px 10px 0 10px;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .data > .title {
  display: inline-block;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1.125rem;
  word-break: break-word;
  cursor: pointer;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .data > .title:hover {
  color: #0047ba;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .data > .sub-title {
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .data > .sub-title > .category {
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
  cursor: pointer;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .data > .sub-title > .location {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/location_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 12px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 17px;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .data > .sub-title > .locations {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/locations_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 12px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 17px;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .data > .sub-title > .proposals {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/proposals_0_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 19px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 12px;
  cursor: pointer;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .data > .sub-title > .proposals:hover {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/proposals_1_icon.svg") no-repeat center left;
  background-size: auto 11px;
  color: #0047ba;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .data > .description {
  margin: 10px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 18px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 36px;
  word-break: break-word;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .data > .price {
  display: flex;
  align-items: center;
  margin: 15px 0 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .data > .price .line-through {
  margin-right: 5px;
  font-family: "ADNOC Sans Medium";
  color: #8b8b8b;
  font-size: 1rem;
  text-decoration: line-through;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .data > .price .bold {
  color: #000000;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .data > .price .discount {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  margin: 0 5px;
  padding: 7px 5px 3px 5px;
  border-radius: 3px;
  background-color: #efefef;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .tag {
  position: absolute;
  top: 0;
  left: 0;
  padding: 7px 15px;
  background-color: #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 0.875rem;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 8px;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card:hover > .settings {
  display: flex;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .settings {
  top: -15px;
  right: -15px;
  position: absolute;
  display: none;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0px 5px;
  border-radius: 5px;
  z-index: 1;
  cursor: initial;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .settings > .edit {
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/edit_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.roadshow-container > .content > .location-box > .location-box-offers .offer-roadshow-card > .settings > .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.roadshow-container > .content > .location-box > .location-box-footer {
  margin: 40px 0 0 0;
  text-align: center;
}
.roadshow-offers-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .roadshow-offers-container {
    padding: 0px 20px;
  }
}
.roadshow-offers-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .roadshow-offers-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .roadshow-offers-container .header .title-box {
    margin-bottom: 20px;
  }
}
.roadshow-offers-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.roadshow-offers-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .roadshow-offers-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-offers-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-offers-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.roadshow-offers-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-offers-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-offers-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-offers-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.roadshow-offers-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-offers-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-offers-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-offers-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.roadshow-offers-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.roadshow-offers-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-offers-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-offers-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-offers-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.roadshow-offers-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.roadshow-offers-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .roadshow-offers-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .roadshow-offers-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.roadshow-offers-container > .content {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.roadshow-offers-container > .content > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-offers-container > .content > div:nth-child(1) {
  margin-top: 0;
}
.roadshow-offers-container > .content > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-offers-container > .content > div:nth-child(2) {
  margin-top: 0;
}
.roadshow-offers-container > .content > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-offers-container > .content > div:nth-child(3) {
  margin-top: 0;
}
.roadshow-offers-container > .content > div:nth-child(4n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-offers-container > .content > div:nth-child(4) {
  margin-top: 0;
}
.roadshow-offers-container > .content > div:nth-child(4n) {
  margin-right: 0;
}
@media only screen and (max-width: 959px) {
  .roadshow-offers-container > .content {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-offers-container > .content > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 40px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .roadshow-offers-container > .content > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-offers-container > .content > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 40px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .roadshow-offers-container > .content > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-offers-container > .content > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 40px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .roadshow-offers-container > .content > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-offers-container > .content > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 879px) {
  .roadshow-offers-container > .content {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-offers-container > .content > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-offers-container > .content > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-offers-container > .content > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-offers-container > .content > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-offers-container > .content > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-offers-container > .content > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-offers-container > .content > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .roadshow-offers-container > .content {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-offers-container > .content > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-offers-container > .content > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-offers-container > .content > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-offers-container > .content > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-offers-container > .content > div:nth-child(2n) {
    margin-right: 0;
  }
}
.roadshow-offers-container > .content .offer-roadshow-card {
  position: relative;
}
.roadshow-offers-container > .content .offer-roadshow-card > .cover {
  position: relative;
  border-radius: 10px;
  height: 12.2rem;
}
.roadshow-offers-container > .content .offer-roadshow-card > .cover > .img-cover {
  top: 0;
  left: 0;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
  height: 100%;
}
.roadshow-offers-container > .content .offer-roadshow-card > .cover > .img-cover:hover {
  opacity: 0.8;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
}
.roadshow-offers-container > .content .offer-roadshow-card > .cover > .img-placeholder {
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
}
.roadshow-offers-container > .content .offer-roadshow-card > .cover > .details {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  background-color: #8b8b8b;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1rem;
  text-align: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2705882353);
}
.roadshow-offers-container > .content .offer-roadshow-card > .cover > .details .roadshow-title {
  display: none;
}
.roadshow-offers-container > .content .offer-roadshow-card > .cover:hover .duration {
  display: none;
}
.roadshow-offers-container > .content .offer-roadshow-card > .cover:hover .roadshow-title {
  display: inline-block;
}
.roadshow-offers-container > .content .offer-roadshow-card > .cover > .checked {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/checker_1_icon.svg") no-repeat center;
  background-size: auto 16px;
}
.roadshow-offers-container > .content .offer-roadshow-card > img {
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
}
.roadshow-offers-container > .content .offer-roadshow-card > img:hover {
  opacity: 0.8;
}
.roadshow-offers-container > .content .offer-roadshow-card > .data {
  padding: 10px 10px 0 10px;
}
.roadshow-offers-container > .content .offer-roadshow-card > .data > .title {
  display: inline-block;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1.125rem;
  word-break: break-word;
  cursor: pointer;
}
.roadshow-offers-container > .content .offer-roadshow-card > .data > .title:hover {
  color: #0047ba;
}
.roadshow-offers-container > .content .offer-roadshow-card > .data > .sub-title {
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
}
.roadshow-offers-container > .content .offer-roadshow-card > .data > .sub-title > .category {
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
  cursor: pointer;
}
.roadshow-offers-container > .content .offer-roadshow-card > .data > .sub-title > .location {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/location_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 12px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 17px;
}
.roadshow-offers-container > .content .offer-roadshow-card > .data > .sub-title > .locations {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/locations_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 12px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 17px;
}
.roadshow-offers-container > .content .offer-roadshow-card > .data > .sub-title > .proposals {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/proposals_0_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 19px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 12px;
  cursor: pointer;
}
.roadshow-offers-container > .content .offer-roadshow-card > .data > .sub-title > .proposals:hover {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/proposals_1_icon.svg") no-repeat center left;
  background-size: auto 11px;
  color: #0047ba;
}
.roadshow-offers-container > .content .offer-roadshow-card > .data > .description {
  margin: 10px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 18px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 36px;
  word-break: break-word;
}
.roadshow-offers-container > .content .offer-roadshow-card > .data > .price {
  display: flex;
  align-items: center;
  margin: 15px 0 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
}
.roadshow-offers-container > .content .offer-roadshow-card > .data > .price .line-through {
  margin-right: 5px;
  font-family: "ADNOC Sans Medium";
  color: #8b8b8b;
  font-size: 1rem;
  text-decoration: line-through;
}
.roadshow-offers-container > .content .offer-roadshow-card > .data > .price .bold {
  color: #000000;
}
.roadshow-offers-container > .content .offer-roadshow-card > .data > .price .discount {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  margin: 0 5px;
  padding: 7px 5px 3px 5px;
  border-radius: 3px;
  background-color: #efefef;
}
.roadshow-offers-container > .content .offer-roadshow-card > .tag {
  position: absolute;
  top: 0;
  left: 0;
  padding: 7px 15px;
  background-color: #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 0.875rem;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 8px;
}
.roadshow-offers-container > .content .offer-roadshow-card:hover > .settings {
  display: flex;
}
.roadshow-offers-container > .content .offer-roadshow-card > .settings {
  top: -15px;
  right: -15px;
  position: absolute;
  display: none;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0px 5px;
  border-radius: 5px;
  z-index: 1;
  cursor: initial;
}
.roadshow-offers-container > .content .offer-roadshow-card > .settings > .edit {
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/edit_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.roadshow-offers-container > .content .offer-roadshow-card > .settings > .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.roadshow-edit-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .roadshow-edit-container {
    padding: 0px 20px;
  }
}
.roadshow-edit-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .roadshow-edit-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .roadshow-edit-container .header .title-box {
    margin-bottom: 20px;
  }
}
.roadshow-edit-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.roadshow-edit-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .roadshow-edit-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-edit-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-edit-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.roadshow-edit-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-edit-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-edit-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-edit-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.roadshow-edit-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-edit-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-edit-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-edit-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.roadshow-edit-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.roadshow-edit-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.roadshow-edit-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.roadshow-edit-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .roadshow-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .roadshow-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.roadshow-edit-container > .content .images .no-images-box {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.roadshow-edit-container > .content .images .no-images-box > .icon {
  width: 200px;
  height: 180px;
  background: url("/assets/images/icons/noImageUploder_icon.svg") no-repeat bottom center;
  background-size: auto 140px;
}
.roadshow-edit-container > .content .images .no-images-box > .btn {
  margin: 0 0 20px 0;
}
.roadshow-edit-container > .content .images .no-images-box > .note {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 1rem;
  line-height: 1.3rem;
}
.roadshow-edit-container > .content .images .no-images-box > .note > span {
  color: #0047ba;
}
.roadshow-edit-container > .content .images .cropper-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.roadshow-edit-container > .content .images .cropper-box .cropper {
  width: 30%;
}
.roadshow-edit-container > .content .images .cropper-box .cropper > .cropper-subtitle {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.roadshow-edit-container > .content .images .cropper-box .prev {
  width: 30%;
}
.roadshow-edit-container > .content .images .cropper-box .prev > .cropper-subtitle {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.roadshow-edit-container > .content .images .cropper-box .prev > img {
  width: 100%;
  background-color: #efefef;
  border: 1px solid #c5c5c5;
  border-radius: 15px;
}
.roadshow-edit-container > .content .images .cropper-box .imgs {
  width: 30%;
  flex-wrap: wrap;
  overflow: auto;
  padding: 0 10px;
}
.roadshow-edit-container > .content .images .cropper-box .imgs::-webkit-scrollbar {
  width: 6px;
}
.roadshow-edit-container > .content .images .cropper-box .imgs::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.roadshow-edit-container > .content .images .cropper-box .imgs::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.roadshow-edit-container > .content .images .cropper-box .imgs::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.roadshow-edit-container > .content .images .cropper-box .imgs > .cropper-subtitle {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.roadshow-edit-container > .content .images .cropper-box .imgs > .img-c {
  position: relative;
  display: inline-block;
  width: 99px;
  height: 66px;
  background-color: #efefef;
  margin-bottom: 20px;
  margin-right: 20px;
  text-align: center;
  border: 1px solid #c5c5c5;
}
.roadshow-edit-container > .content .images .cropper-box .imgs > .img-c > .img-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.roadshow-edit-container > .content .images .cropper-box .imgs > .img-c > .img-box > img {
  max-width: 100%;
  max-height: 100%;
}
.roadshow-edit-container > .content .images .cropper-box .imgs > .img-c > .cover-tag {
  position: absolute;
  top: -8px;
  left: -8px;
  width: 18px;
  height: 18px;
  background: url("/assets/images/icons/coverStart_cropper_icon.svg") no-repeat center;
  background-size: 18px auto;
}
.roadshow-edit-container > .content .images .cropper-box .imgs > .img-c-active {
  border: 1px solid #0047ba;
}
@media only screen and (max-width: 1299px) {
  .roadshow-edit-container > .content .images .cropper-box {
    justify-content: flex-start;
  }
  .roadshow-edit-container > .content .images .cropper-box .cropper {
    width: 40%;
  }
  .roadshow-edit-container > .content .images .cropper-box .prev {
    width: 40%;
    margin-left: 50px;
  }
  .roadshow-edit-container > .content .images .cropper-box .imgs {
    width: 100%;
    margin-top: 20px;
  }
}
.roadshow-edit-container > .content .images .imgs-prev-box {
  display: inline-flex;
}
.roadshow-edit-container > .content .images .imgs-prev-box > * {
  width: calc((100% - 60px) / 3);
}
.roadshow-edit-container > .content .images .imgs-prev-box > .imgs-prev-cover-c {
  margin: 0 30px 0 0;
}
.roadshow-edit-container > .content .images .imgs-prev-box > .imgs-prev-cover-c > .imgs-prev-sub-title {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.roadshow-edit-container > .content .images .imgs-prev-box > .imgs-prev-cover-c > .imgs-prev-cover-img {
  width: 100%;
  border-radius: 20px;
}
.roadshow-edit-container > .content .images .imgs-prev-box > .imgs-prev-main-c {
  margin: 0 30px 0 0;
}
.roadshow-edit-container > .content .images .imgs-prev-box > .imgs-prev-main-c > .imgs-prev-sub-title {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.roadshow-edit-container > .content .images .imgs-prev-box > .imgs-prev-main-c > .imgs-prev-main-img {
  width: 100%;
  border-radius: 20px;
}
.roadshow-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-sub-title {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.roadshow-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c {
  position: relative;
  height: 100%;
}
.roadshow-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs {
  position: absolute;
  max-height: calc(100% - 32px);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 10px;
}
.roadshow-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs::-webkit-scrollbar {
  width: 6px;
}
.roadshow-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.roadshow-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.roadshow-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.roadshow-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs > .imgs-prev-img {
  border-radius: 10px;
  width: calc((100% - 30px) / 3);
  margin-bottom: 15px;
  cursor: pointer;
}
.roadshow-edit-container > .content .images > .error {
  margin: 10px 0 0 0;
  text-align: right;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.roadshow-edit-container > .content .images > .error:before {
  content: "! ";
}
.roadshow-edit-container > .content .error {
  margin: 10px 0 0 0;
  text-align: right;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.roadshow-edit-container > .content .error:before {
  content: "! ";
}
.roadshow-edit-container > .content .progress {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.roadshow-edit-container > .content .progress > .data {
  position: absolute;
  left: 0;
  top: 0;
}
.roadshow-edit-container > .content .progress > .data > .date {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-edit-container > .content .progress > .data > .date {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-edit-container > .content .progress > .data > .date {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-edit-container > .content .progress > .data > .date {
    font-size: 1.4875rem;
  }
}
.roadshow-edit-container > .content .progress > .data > .id {
  margin-top: 5px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-edit-container > .content .progress > .data > .id {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-edit-container > .content .progress > .data > .id {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-edit-container > .content .progress > .data > .id {
    font-size: 1.4875rem;
  }
}
.roadshow-edit-container > .content .progress > .data > .status {
  max-width: max-content;
  background-color: #0047ba;
  border-radius: 3px;
  padding: 5px 10px;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1.75rem;
  margin-top: 5px;
}
@media only screen and (max-width: 1339px) {
  .roadshow-edit-container > .content .progress > .data > .status {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-edit-container > .content .progress > .data > .status {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-edit-container > .content .progress > .data > .status {
    font-size: 1.4875rem;
  }
}
.roadshow-edit-container > .content .progress > .status-diagram {
  display: flex;
}
.roadshow-edit-container > .content .progress > .status-diagram > .draft {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/draft_progress_0_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 1rem;
}
.roadshow-edit-container > .content .progress > .status-diagram > .draft-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/draft_progress_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
.roadshow-edit-container > .content .progress > .status-diagram > .invited {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/invited_progress_0_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 1rem;
}
.roadshow-edit-container > .content .progress > .status-diagram > .invited-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/invited_progress_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
.roadshow-edit-container > .content .progress > .status-diagram > .published {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/published_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 1rem;
}
.roadshow-edit-container > .content .progress > .status-diagram > .published-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/published_progress_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
.roadshow-edit-container > .content .progress > .status-diagram > .union {
  width: 180px;
  height: 125px;
  background: url("/assets/images/icons/union_progress_0_icon.svg") no-repeat top 40px center;
  background-size: auto 14px;
}
.roadshow-edit-container > .content .progress > .status-diagram > .union-active {
  width: 180px;
  height: 125px;
  background: url("/assets/images/icons/union_progress_1_icon.svg") no-repeat top 40px center;
  background-size: auto 14px;
}
.roadshow-edit-container > .content .table-invites .table-header .col-checkbox {
  width: 20px;
}
.roadshow-edit-container > .content .table-invites .table-header .col-company {
  flex-grow: 1;
}
.roadshow-edit-container > .content .table-invites .table-header .col-invite-events {
  width: 480px;
}
.roadshow-edit-container > .content .table-invites .table-header .col-contact {
  width: 250px;
}
.roadshow-edit-container > .content .table-invites .table-header .col-status {
  width: 150px;
  display: flex;
  justify-content: center;
}
.roadshow-edit-container > .content .table-invites .table-header .col-invite-link {
  width: 20px;
}
.roadshow-edit-container > .content .table-invites .table-body .col-checkbox {
  width: 20px;
}
.roadshow-edit-container > .content .table-invites .table-body .col-company {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
}
.roadshow-edit-container > .content .table-invites .table-body .col-company > .logo {
  width: 60px;
  margin: 0 10px 0 0;
  cursor: pointer;
}
.roadshow-edit-container > .content .table-invites .table-body .col-company > .logo:hover {
  opacity: 0.8;
}
.roadshow-edit-container > .content .table-invites .table-body .col-company > .name {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  cursor: pointer;
}
.roadshow-edit-container > .content .table-invites .table-body .col-company > .name:hover {
  color: #0047ba;
}
.roadshow-edit-container > .content .table-invites .table-body .col-no-events {
  width: 480px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 1rem;
  cursor: pointer;
}
.roadshow-edit-container > .content .table-invites .table-body .col-no-events:hover {
  opacity: 0.8;
}
.roadshow-edit-container > .content .table-invites .table-body .col-invite-events {
  width: 480px;
}
.roadshow-edit-container > .content .table-invites .table-body .col-invite-events > .invite-event {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.roadshow-edit-container > .content .table-invites .table-body .col-invite-events > .invite-event > .f-date {
  margin: 0 15px 0 0;
  width: 200px;
}
.roadshow-edit-container > .content .table-invites .table-body .col-invite-events > .invite-event > .f-select {
  margin: 0 15px 0 0;
  width: 150px;
}
.roadshow-edit-container > .content .table-invites .table-body .col-invite-events > .invite-event > .save-btn {
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 10px;
}
.roadshow-edit-container > .content .table-invites .table-body .col-invite-events > .invite-event > .save-btn:hover {
  opacity: 0.8;
}
.roadshow-edit-container > .content .table-invites .table-body .col-invite-events > .invite-event > .delete-btn {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 13px;
  margin-right: 10px;
  cursor: pointer;
}
.roadshow-edit-container > .content .table-invites .table-body .col-invite-events > .invite-event > .delete-btn:hover {
  opacity: 0.8;
}
.roadshow-edit-container > .content .table-invites .table-body .col-invite-events > .invite-event > .add-btn {
  width: 20px;
  height: 20px;
  background: url("/assets/images/icons/add_0_icon.svg") no-repeat center;
  background-size: auto 19px;
  cursor: pointer;
}
.roadshow-edit-container > .content .table-invites .table-body .col-invite-events > .invite-event > .add-btn:hover {
  width: 20px;
  height: 20px;
  background: url("/assets/images/icons/add_1_icon.svg") no-repeat center;
  background-size: auto 19px;
}
.roadshow-edit-container > .content .table-invites .table-body .col-contact {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.roadshow-edit-container > .content .table-invites .table-body .col-contact > .contact {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
  line-height: 1.3rem;
  word-break: break-word;
  cursor: pointer;
}
.roadshow-edit-container > .content .table-invites .table-body .col-contact > .contact:hover {
  color: #0047ba;
}
.roadshow-edit-container > .content .table-invites .table-body .col-status {
  width: 150px;
}
.roadshow-edit-container > .content .table-invites .table-body .col-status > .status {
  text-align: center;
}
.roadshow-edit-container > .content .table-invites .table-body .col-status > .status-draft {
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1rem;
}
.roadshow-edit-container > .content .table-invites .table-body .col-status > .status-invited {
  font-family: "ADNOC Sans Regular";
  color: #0073f9;
  font-size: 1rem;
}
.roadshow-edit-container > .content .table-invites .table-body .col-status > .status-accepted {
  font-family: "ADNOC Sans Regular";
  color: #d76cf6;
  font-size: 1rem;
}
.roadshow-edit-container > .content .table-invites .table-body .col-status > .status-approved {
  font-family: "ADNOC Sans Medium";
  color: #57d26b;
  font-size: 1rem;
}
.roadshow-edit-container > .content .table-invites .table-body .col-status > .status-review {
  font-family: "ADNOC Sans Medium";
  color: #5ea8ff;
  font-size: 1rem;
}
.roadshow-edit-container > .content .table-invites .table-body .col-status > .status-rejected {
  font-family: "ADNOC Sans Medium";
  color: #ff5f69;
  font-size: 1rem;
}
.roadshow-edit-container > .content .table-invites .table-body .col-status > .status-deactivated {
  font-family: "ADNOC Sans Medium";
  color: #ff5f69;
  font-size: 1rem;
}
.roadshow-edit-container > .content .table-invites .table-body .col-status > .status-blocked {
  font-family: "ADNOC Sans Medium";
  color: #ff5f69;
  font-size: 1rem;
}
.roadshow-edit-container > .content .table-invites .table-body .col-status > .status-expired {
  font-family: "ADNOC Sans Medium";
  color: #c2c2c2;
  font-size: 1rem;
}
.roadshow-edit-container > .content .table-invites .table-body .col-status > .status-renegotiation {
  font-family: "ADNOC Sans Medium";
  color: #ffaf70;
  font-size: 1rem;
}
.roadshow-edit-container > .content .table-invites .table-body .col-status > .status-returned {
  font-family: "ADNOC Sans Medium";
  color: #ffc815;
  font-size: 1rem;
}
.roadshow-edit-container > .content .table-invites .table-body .col-invite-link {
  width: 20px;
}
.roadshow-edit-container > .content .table-invites .table-body .col-invite-link .link-btn {
  color: #0047ba;
  cursor: pointer;
}
.roadshow-edit-container > .content .table-invites .table-body .col-invite-link .link-btn:hover {
  opacity: 0.8;
}
@media only screen and (max-width: 1299px) {
  .roadshow-edit-container > .content .table-invites .col-contact {
    display: none !important;
  }
}
@media only screen and (max-width: 1099px) {
  .roadshow-edit-container > .content .table-invites .col-invite-events {
    display: none !important;
  }
}
.roadshow-edit-container > .footer {
  margin: 30px 0 0 0;
}
.roadshow-edit-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.roadshow-edit-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 1049px) {
  .roadshow-edit-container > .content > .progress {
    flex-direction: column;
    align-items: flex-start;
  }
  .roadshow-edit-container > .content > .progress > .data {
    position: unset;
    margin-bottom: 40px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram {
    align-self: center;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .draft {
    width: 85px;
    height: 115px;
    background: url("/assets/images/icons/draft_progress_0_icon.svg") no-repeat top center;
    background-size: auto 84px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .draft-active {
    width: 85px;
    height: 115px;
    background: url("/assets/images/icons/draft_progress_1_icon.svg") no-repeat top center;
    background-size: auto 84px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .invited {
    width: 85px;
    height: 115px;
    background: url("/assets/images/icons/invited_progress_0_icon.svg") no-repeat top center;
    background-size: auto 84px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .invited-active {
    width: 85px;
    height: 115px;
    background: url("/assets/images/icons/invited_progress_1_icon.svg") no-repeat top center;
    background-size: auto 84px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .published {
    width: 85px;
    height: 115px;
    background: url("/assets/images/icons/published_icon.svg") no-repeat top center;
    background-size: auto 84px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .published-active {
    width: 85px;
    height: 115px;
    background: url("/assets/images/icons/published_progress_icon.svg") no-repeat top center;
    background-size: auto 84px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .union {
    width: 170px;
    height: 115px;
    background: url("/assets/images/icons/union_progress_0_icon.svg") no-repeat top 40px center;
    background-size: auto 14px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .union-active {
    width: 170px;
    height: 115px;
    background: url("/assets/images/icons/union_progress_1_icon.svg") no-repeat top 40px center;
    background-size: auto 14px;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-edit-container > .content > .progress > .status-diagram > .draft {
    width: 65px;
    height: 95px;
    background: url("/assets/images/icons/draft_progress_1_icon.svg") no-repeat top center;
    background-size: auto 64px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .draft-active {
    width: 65px;
    height: 95px;
    background: url("/assets/images/icons/draft_progress_1_icon.svg") no-repeat top center;
    background-size: auto 64px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .invited {
    width: 65px;
    height: 95px;
    background: url("/assets/images/icons/invited_progress_0_icon.svg") no-repeat top center;
    background-size: auto 64px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .invited-active {
    width: 65px;
    height: 95px;
    background: url("/assets/images/icons/invited_progress_1_icon.svg") no-repeat top center;
    background-size: auto 64px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .published {
    width: 65px;
    height: 95px;
    background: url("/assets/images/icons/published_icon.svg") no-repeat top center;
    background-size: auto 64px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .published-active {
    width: 65px;
    height: 95px;
    background: url("/assets/images/icons/published_progress_icon.svg") no-repeat top center;
    background-size: auto 64px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .union {
    width: 150px;
    height: 95px;
    background: url("/assets/images/icons/union_progress_0_icon.svg") no-repeat top 30px center;
    background-size: auto 14px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .union-active {
    width: 150px;
    height: 95px;
    background: url("/assets/images/icons/union_progress_1_icon.svg") no-repeat top 30px center;
    background-size: auto 14px;
  }
}
@media only screen and (max-width: 699px) {
  .roadshow-edit-container > .content > .progress > .status-diagram > .draft {
    width: 55px;
    height: 70px;
    background: url("/assets/images/icons/draft_progress_0_icon.svg") no-repeat top center;
    background-size: auto 44px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .draft-active {
    width: 55px;
    height: 70px;
    background: url("/assets/images/icons/draft_progress_1_icon.svg") no-repeat top center;
    background-size: auto 44px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .review {
    width: 55px;
    height: 70px;
    background: url("/assets/images/icons/review_progress_0_icon.svg") no-repeat top center;
    background-size: auto 44px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .review-active {
    width: 55px;
    height: 70px;
    background: url("/assets/images/icons/review_progress_1_icon.svg") no-repeat top center;
    background-size: auto 44px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .approved {
    width: 55px;
    height: 70px;
    background: url("/assets/images/icons/approved_icon.svg") no-repeat top center;
    background-size: auto 44px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .approved-active {
    width: 55px;
    height: 70px;
    background: url("/assets/images/icons/approved_progress_icon.svg") no-repeat top center;
    background-size: auto 44px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .rejected {
    width: 55px;
    height: 70px;
    background: url("/assets/images/icons/rejected_progress_1_icon.svg") no-repeat top center;
    background-size: auto 44px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .rejected-active {
    width: 55px;
    height: 70px;
    background: url("/assets/images/icons/rejected_progress_1_icon.svg") no-repeat top center;
    background-size: auto 44px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .union {
    width: 150px;
    height: 70px;
    background: url("/assets/images/icons/union_progress_0_icon.svg") no-repeat top 15px center;
    background-size: auto 14px;
  }
  .roadshow-edit-container > .content > .progress > .status-diagram > .union-active {
    width: 150px;
    height: 70px;
    background: url("/assets/images/icons/union_progress_1_icon.svg") no-repeat top 15px center;
    background-size: auto 14px;
  }
  .roadshow-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c {
    height: 7rem;
  }
}

.modal-roadshow-add-events .table-custom .col-date {
  width: 250px;
}
.modal-roadshow-add-events .table-custom .col-date .f-date {
  margin: 0;
}
.modal-roadshow-add-events .table-custom .col-location {
  width: 250px;
}
.modal-roadshow-add-events .table-custom .col-location .f-select {
  margin: 0;
}
.modal-roadshow-add-events .table-custom .col-save {
  width: 100px;
}

.modal-invites-form-add-suppliers .table-custom .table-header .col-company {
  flex-grow: 1;
}
.modal-invites-form-add-suppliers .table-custom .table-header .col-contacts {
  width: 300px;
}
.modal-invites-form-add-suppliers .table-custom .table-body .col-company {
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.modal-invites-form-add-suppliers .table-custom .table-body .col-company > .logo {
  max-height: 50px;
  width: 70px;
  margin: 0 10px 0 0;
}
.modal-invites-form-add-suppliers .table-custom .table-body .col-company > .name {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
}
.modal-invites-form-add-suppliers .table-custom .table-body .col-contacts {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-invites-form-add-suppliers .table-custom .table-body .col-contacts > .contact {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
  line-height: 1.3rem;
}
@media only screen and (max-width: 749px) {
  .modal-invites-form-add-suppliers .table-custom .col-contacts {
    display: none !important;
  }
}

.roadshow-invite-edit-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .roadshow-invite-edit-container {
    padding: 0px 20px;
  }
}
.roadshow-invite-edit-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .roadshow-invite-edit-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .roadshow-invite-edit-container .header .title-box {
    margin-bottom: 20px;
  }
}
.roadshow-invite-edit-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.roadshow-invite-edit-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .roadshow-invite-edit-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-invite-edit-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-invite-edit-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.roadshow-invite-edit-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-invite-edit-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-invite-edit-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-invite-edit-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.roadshow-invite-edit-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-invite-edit-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-invite-edit-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-invite-edit-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.roadshow-invite-edit-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.roadshow-invite-edit-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-invite-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-invite-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-invite-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.roadshow-invite-edit-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.roadshow-invite-edit-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .roadshow-invite-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .roadshow-invite-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.roadshow-invite-edit-container > .content > .information {
  margin: 0 0 50px 0;
}
.roadshow-invite-edit-container > .content > .information > * {
  margin: 0 0 10px 0;
}
.roadshow-invite-edit-container > .content > .information > .information-date {
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 1rem;
}
.roadshow-invite-edit-container > .content > .information > .information-locations {
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 1rem;
  display: flex;
}
.roadshow-invite-edit-container > .content > .information > .information-locations > .location {
  margin-right: 5px;
}
.roadshow-invite-edit-container > .content > .information > .information-description > .information-description-title {
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 1rem;
}
.roadshow-invite-edit-container > .content > .information > .information-description > .information-description-content {
  margin-top: 5px;
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.9rem;
  line-height: 1.1rem;
}
.roadshow-invite-edit-container > .content > .information > .information-comment > .information-comment-title {
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 1rem;
}
.roadshow-invite-edit-container > .content > .information > .information-comment > .information-comment-content {
  margin-top: 5px;
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.9rem;
  line-height: 1.1rem;
}
.roadshow-invite-edit-container > .content .schedule {
  margin-top: 50px;
}
.roadshow-invite-edit-container > .content .schedule .introduction {
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 1rem;
}
.roadshow-invite-edit-container > .content .schedule .events .event {
  background-color: #fff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 15px;
  margin: 15px 0 0px 0;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-header .event-header-info {
  display: flex;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-header .event-header-info .f-date,
.roadshow-invite-edit-container > .content .schedule .events .event .event-header .event-header-info .f-select {
  margin: 0 15px 0 0;
  width: 200px;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-header .event-header-options > * {
  margin: 0 10px;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-header .event-header-options > .option {
  display: inline-block;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 1rem;
  cursor: pointer;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-header .event-header-options > .option:hover {
  opacity: 0.8;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-header .event-header-options > .option-delete {
  color: #f55757;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  padding: 20px 0;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(1) {
  margin-top: 0;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(2) {
  margin-top: 0;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(3) {
  margin-top: 0;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(4n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(4) {
  margin-top: 0;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(4n) {
  margin-right: 0;
}
@media only screen and (max-width: 1199px) {
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1099px) {
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 799px) {
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 40px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 40px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers > div:nth-child(2n) {
    margin-right: 0;
  }
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card {
  position: relative;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .cover {
  position: relative;
  border-radius: 10px;
  height: 12.2rem;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .cover > .img-cover {
  top: 0;
  left: 0;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
  height: 100%;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .cover > .img-cover:hover {
  opacity: 0.8;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .cover > .img-placeholder {
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .cover > .details {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  background-color: #8b8b8b;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1rem;
  text-align: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2705882353);
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .cover > .details .roadshow-title {
  display: none;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .cover:hover .duration {
  display: none;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .cover:hover .roadshow-title {
  display: inline-block;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .cover > .checked {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/checker_1_icon.svg") no-repeat center;
  background-size: auto 16px;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > img {
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > img:hover {
  opacity: 0.8;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .data {
  padding: 10px 10px 0 10px;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .data > .title {
  display: inline-block;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1.125rem;
  word-break: break-word;
  cursor: pointer;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .data > .title:hover {
  color: #0047ba;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .data > .sub-title {
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .data > .sub-title > .category {
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
  cursor: pointer;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .data > .sub-title > .location {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/location_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 12px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 17px;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .data > .sub-title > .locations {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/locations_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 12px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 17px;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .data > .sub-title > .proposals {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/proposals_0_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 19px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 12px;
  cursor: pointer;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .data > .sub-title > .proposals:hover {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/proposals_1_icon.svg") no-repeat center left;
  background-size: auto 11px;
  color: #0047ba;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .data > .description {
  margin: 10px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 18px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 36px;
  word-break: break-word;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .data > .price {
  display: flex;
  align-items: center;
  margin: 15px 0 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .data > .price .line-through {
  margin-right: 5px;
  font-family: "ADNOC Sans Medium";
  color: #8b8b8b;
  font-size: 1rem;
  text-decoration: line-through;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .data > .price .bold {
  color: #000000;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .data > .price .discount {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  margin: 0 5px;
  padding: 7px 5px 3px 5px;
  border-radius: 3px;
  background-color: #efefef;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .tag {
  position: absolute;
  top: 0;
  left: 0;
  padding: 7px 15px;
  background-color: #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 0.875rem;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 8px;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card:hover > .settings {
  display: flex;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .settings {
  top: -15px;
  right: -15px;
  position: absolute;
  display: none;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0px 5px;
  border-radius: 5px;
  z-index: 1;
  cursor: initial;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .settings > .edit {
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/edit_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-offers .offer-roadshow-card > .settings > .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-no-offers {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 1rem;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-no-offers span {
  margin-left: 5px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 1rem;
  cursor: pointer;
}
.roadshow-invite-edit-container > .content .schedule .events .event .event-content .event-content-no-offers span:hover {
  opacity: 0.8;
}
.roadshow-invite-edit-container > .content .schedule .note > .request {
  display: inline-block;
  margin: 15px 0 0 0;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 1rem;
  cursor: pointer;
}
.roadshow-invite-edit-container > .content .schedule .note > .request:hover {
  opacity: 0.8;
}
.roadshow-invite-edit-container > .footer {
  margin: 30px 0 0 0;
}
.roadshow-invite-edit-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.roadshow-invite-edit-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.modal-schedule-form-add-offer .modal-complex {
  height: calc(100vh - 30px);
}
.modal-schedule-form-add-offer .modal-complex .modal-content {
  display: flex;
  height: calc(100% - 160px);
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box {
  width: 300px;
  margin-right: 50px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal {
  width: 100%;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_header {
  display: none;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content {
  width: 100%;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword {
  margin-top: 3px;
  display: flex;
  height: 48px;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  border: none;
  border-radius: 26px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_0_icon.svg") no-repeat center;
  background-size: 17px auto;
  margin: 0 15px 0 0;
  cursor: pointer;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon:hover {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_1_icon.svg") no-repeat center;
  background-size: 17px auto;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input {
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 1.3rem;
  border: none;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input::placeholder {
  line-height: 1.3rem !important;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_0_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
  border-bottom: 1px solid #c5c5c5;
  padding: 20px 0;
  cursor: pointer;
}
@media only screen and (max-width: 1339px) {
  .modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.0625rem;
  }
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header:hover {
  color: #0047ba;
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_2_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options {
  display: none;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar {
  width: 6px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option {
  padding: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox {
  margin: 0;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label:hover {
  color: #0047ba;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  width: 78px;
  height: 14px;
  background: url("/assets/images/icons/stars_empty_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars > .stars-positive {
  position: absolute;
  width: 0%;
  height: 14px;
  background: url("/assets/images/icons/stars_full_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .counter {
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option:first-of-type {
  padding: 0;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion:first-of-type > .criterion-header {
  padding-top: 0;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_1_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  border: none;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header:hover {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_3_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-options {
  display: block;
  max-height: 190px;
  overflow-y: auto;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .reset {
  margin-top: 10px;
  text-align: right;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn {
  display: inline-block;
  padding-top: 3px;
  margin-right: 7px;
  padding-right: 7px;
  border-right: 2px solid #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn:hover {
  opacity: 0.8;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn {
  display: inline-block;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn:hover {
  opacity: 0.8;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_footer {
  display: none;
}
@media only screen and (max-width: 999px) {
  .modal-schedule-form-add-offer .modal-complex .modal-content .filter_box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(236, 241, 250, 0.7);
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal {
    max-width: 600px;
    min-width: 500px;
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 30px);
    min-height: 150px;
    background-color: #fff;
    padding: 0 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_header {
    display: block;
    font-family: "ADNOC Sans Bold";
    color: #0047ba;
    font-size: 1rem;
    padding: 20px 0 15px 0;
    border-bottom: 1px solid #c5c5c5;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content {
    margin: 30px 0;
    overflow-x: auto;
    padding-right: 10px;
    padding-left: 3px;
    max-height: calc(50vh - 50px);
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
    width: 6px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
    background: #f8f8f8;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
    background: #c0cace;
    border-radius: 5px;
    cursor: pointer;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
    background: #0047ba;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .criterion-options {
    display: block;
    max-height: 1000px !important;
    overflow-y: auto;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_content .reset {
    display: none;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_footer {
    display: block;
    padding: 0 0 20px 0;
    display: flex;
    justify-content: flex-end;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .filter_box .filter_box_modal .filter_box_modal_footer > * {
    margin: 0 0 0 10px;
  }
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(236, 241, 250, 0.7);
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box-modal .filter_box_modal {
  max-width: 600px;
  min-width: 500px;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 30px);
  min-height: 150px;
  background-color: #fff;
  padding: 0 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box-modal .filter_box_modal .filter_box_modal_header {
  display: block;
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  padding: 20px 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box-modal .filter_box_modal .filter_box_modal_content {
  margin: 30px 0;
  overflow-x: auto;
  padding-right: 10px;
  padding-left: 3px;
  max-height: calc(50vh - 50px);
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
  width: 6px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box-modal .filter_box_modal .filter_box_modal_content .reset {
  display: none;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box-modal .filter_box_modal .filter_box_modal_footer {
  display: block;
  padding: 0 0 20px 0;
  display: flex;
  justify-content: flex-end;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .filter_box-modal .filter_box_modal .filter_box_modal_footer > * {
  margin: 0 0 0 10px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box {
  width: 100%;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card {
  position: relative;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .cover {
  position: relative;
  border-radius: 10px;
  height: 12.2rem;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .cover > .img-cover {
  top: 0;
  left: 0;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
  height: 100%;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .cover > .img-cover:hover {
  opacity: 0.8;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .cover > .img-placeholder {
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .cover > .details {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  background-color: #8b8b8b;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1rem;
  text-align: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2705882353);
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .cover > .details .roadshow-title {
  display: none;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .cover:hover .duration {
  display: none;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .cover:hover .roadshow-title {
  display: inline-block;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .cover > .checked {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/checker_1_icon.svg") no-repeat center;
  background-size: auto 16px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > img {
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > img:hover {
  opacity: 0.8;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .data {
  padding: 10px 10px 0 10px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .data > .title {
  display: inline-block;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1.125rem;
  word-break: break-word;
  cursor: pointer;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .data > .title:hover {
  color: #0047ba;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .data > .sub-title {
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .data > .sub-title > .category {
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
  cursor: pointer;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .data > .sub-title > .location {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/location_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 12px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 17px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .data > .sub-title > .locations {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/locations_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 12px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 17px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .data > .sub-title > .proposals {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/proposals_0_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 19px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 12px;
  cursor: pointer;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .data > .sub-title > .proposals:hover {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/proposals_1_icon.svg") no-repeat center left;
  background-size: auto 11px;
  color: #0047ba;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .data > .description {
  margin: 10px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 18px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 36px;
  word-break: break-word;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .data > .price {
  display: flex;
  align-items: center;
  margin: 15px 0 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .data > .price .line-through {
  margin-right: 5px;
  font-family: "ADNOC Sans Medium";
  color: #8b8b8b;
  font-size: 1rem;
  text-decoration: line-through;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .data > .price .bold {
  color: #000000;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .data > .price .discount {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  margin: 0 5px;
  padding: 7px 5px 3px 5px;
  border-radius: 3px;
  background-color: #efefef;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .tag {
  position: absolute;
  top: 0;
  left: 0;
  padding: 7px 15px;
  background-color: #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 0.875rem;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 8px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card:hover > .settings {
  display: flex;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .settings {
  top: -15px;
  right: -15px;
  position: absolute;
  display: none;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0px 5px;
  border-radius: 5px;
  z-index: 1;
  cursor: initial;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .settings > .edit {
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/edit_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box .offers .offer-roadshow-card > .settings > .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(1) {
  margin-top: 0;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(2) {
  margin-top: 0;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(3) {
  margin-top: 0;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(4n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(4) {
  margin-top: 0;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(4n) {
  margin-right: 0;
}
@media only screen and (max-width: 1199px) {
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1099px) {
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 799px) {
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 40px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 40px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-wide > div:nth-child(2n) {
    margin-right: 0;
  }
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(1) {
  margin-top: 0;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2) {
  margin-top: 0;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(3) {
  margin-top: 0;
}
.modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(3n) {
  margin-right: 0;
}
@media only screen and (max-width: 1199px) {
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1099px) {
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 999px) {
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 799px) {
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 40px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 40px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .modal-schedule-form-add-offer .modal-complex .modal-content .offers-box > .offers-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}

.roadshow-offer-edit-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .roadshow-offer-edit-container {
    padding: 0px 20px;
  }
}
.roadshow-offer-edit-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .roadshow-offer-edit-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .roadshow-offer-edit-container .header .title-box {
    margin-bottom: 20px;
  }
}
.roadshow-offer-edit-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.roadshow-offer-edit-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .roadshow-offer-edit-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-offer-edit-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-offer-edit-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.roadshow-offer-edit-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-offer-edit-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-offer-edit-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-offer-edit-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.roadshow-offer-edit-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-offer-edit-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-offer-edit-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-offer-edit-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.roadshow-offer-edit-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.roadshow-offer-edit-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-offer-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-offer-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-offer-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.roadshow-offer-edit-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.roadshow-offer-edit-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .roadshow-offer-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .roadshow-offer-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.roadshow-offer-edit-container > .content .attachments > .attachment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 20px 0;
}
.roadshow-offer-edit-container > .content .attachments > .attachment > .file-name {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  margin: 0 20px 0 0;
  cursor: pointer;
}
.roadshow-offer-edit-container > .content .attachments > .attachment > .file-name:hover {
  color: #0047ba;
}
.roadshow-offer-edit-container > .content .attachments .note {
  margin-top: 20px;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 1rem;
}
.roadshow-offer-edit-container > .content .attachments > .no-attachments {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
}
.roadshow-offer-edit-container > .content .images .no-images-box {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.roadshow-offer-edit-container > .content .images .no-images-box > .icon {
  width: 200px;
  height: 180px;
  background: url("/assets/images/icons/noImageUploder_icon.svg") no-repeat bottom center;
  background-size: auto 140px;
}
.roadshow-offer-edit-container > .content .images .no-images-box > .btn {
  margin: 0 0 20px 0;
}
.roadshow-offer-edit-container > .content .images .no-images-box > .note {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 1rem;
  line-height: 1.3rem;
}
.roadshow-offer-edit-container > .content .images .no-images-box > .note > span {
  color: #0047ba;
}
.roadshow-offer-edit-container > .content .images .cropper-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.roadshow-offer-edit-container > .content .images .cropper-box .cropper {
  width: 30%;
}
.roadshow-offer-edit-container > .content .images .cropper-box .cropper > .cropper-subtitle {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.roadshow-offer-edit-container > .content .images .cropper-box .prev {
  width: 30%;
}
.roadshow-offer-edit-container > .content .images .cropper-box .prev > .cropper-subtitle {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.roadshow-offer-edit-container > .content .images .cropper-box .prev > img {
  width: 100%;
  background-color: #efefef;
  border: 1px solid #c5c5c5;
  border-radius: 15px;
}
.roadshow-offer-edit-container > .content .images .cropper-box .imgs {
  width: 30%;
  flex-wrap: wrap;
  overflow: auto;
  padding: 0 10px;
}
.roadshow-offer-edit-container > .content .images .cropper-box .imgs::-webkit-scrollbar {
  width: 6px;
}
.roadshow-offer-edit-container > .content .images .cropper-box .imgs::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.roadshow-offer-edit-container > .content .images .cropper-box .imgs::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.roadshow-offer-edit-container > .content .images .cropper-box .imgs::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.roadshow-offer-edit-container > .content .images .cropper-box .imgs > .cropper-subtitle {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.roadshow-offer-edit-container > .content .images .cropper-box .imgs > .img-c {
  position: relative;
  display: inline-block;
  width: 99px;
  height: 66px;
  background-color: #efefef;
  margin-bottom: 20px;
  margin-right: 20px;
  text-align: center;
  border: 1px solid #c5c5c5;
}
.roadshow-offer-edit-container > .content .images .cropper-box .imgs > .img-c > .img-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.roadshow-offer-edit-container > .content .images .cropper-box .imgs > .img-c > .img-box > img {
  max-width: 100%;
  max-height: 100%;
}
.roadshow-offer-edit-container > .content .images .cropper-box .imgs > .img-c > .cover-tag {
  position: absolute;
  top: -8px;
  left: -8px;
  width: 18px;
  height: 18px;
  background: url("/assets/images/icons/coverStart_cropper_icon.svg") no-repeat center;
  background-size: 18px auto;
}
.roadshow-offer-edit-container > .content .images .cropper-box .imgs > .img-c-active {
  border: 1px solid #0047ba;
}
@media only screen and (max-width: 1299px) {
  .roadshow-offer-edit-container > .content .images .cropper-box {
    justify-content: flex-start;
  }
  .roadshow-offer-edit-container > .content .images .cropper-box .cropper {
    width: 40%;
  }
  .roadshow-offer-edit-container > .content .images .cropper-box .prev {
    width: 40%;
    margin-left: 50px;
  }
  .roadshow-offer-edit-container > .content .images .cropper-box .imgs {
    width: 100%;
    margin-top: 20px;
  }
}
.roadshow-offer-edit-container > .content .images .imgs-prev-box {
  display: inline-flex;
}
.roadshow-offer-edit-container > .content .images .imgs-prev-box > * {
  width: calc((100% - 60px) / 3);
}
.roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-cover-c {
  margin: 0 30px 0 0;
}
.roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-cover-c > .imgs-prev-sub-title {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-cover-c > .imgs-prev-cover-img {
  width: 100%;
  border-radius: 20px;
}
.roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-main-c {
  margin: 0 30px 0 0;
}
.roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-main-c > .imgs-prev-sub-title {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-main-c > .imgs-prev-main-img {
  width: 100%;
  border-radius: 20px;
}
.roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-sub-title {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c {
  position: relative;
  height: 100%;
}
.roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs {
  position: absolute;
  max-height: calc(100% - 32px);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 10px;
}
.roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs::-webkit-scrollbar {
  width: 6px;
}
.roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs > .imgs-prev-img {
  border-radius: 10px;
  width: calc((100% - 30px) / 3);
  margin-bottom: 15px;
  cursor: pointer;
}
.roadshow-offer-edit-container > .content .images > .error {
  margin: 10px 0 0 0;
  text-align: right;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.roadshow-offer-edit-container > .content .images > .error:before {
  content: "! ";
}
.roadshow-offer-edit-container > .footer {
  margin: 30px 0 0 0;
}
.roadshow-offer-edit-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.roadshow-offer-edit-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 1049px) {
  .roadshow-offer-edit-container > .content .images .cropper-box {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .roadshow-offer-edit-container > .content .images .cropper-box .cropper {
    margin-right: 10%;
  }
  .roadshow-offer-edit-container > .content .images .cropper-box .imgs {
    width: 100%;
    text-align: left;
    margin-top: 40px;
  }
  .roadshow-offer-edit-container > .content .images .imgs-prev-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .roadshow-offer-edit-container > .content .images .imgs-prev-box > * {
    width: 45%;
  }
  .roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-main-c {
    margin: 0;
  }
  .roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c {
    height: 10rem;
    margin-top: 25px;
    width: 100%;
  }
  .roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c > .imgs-prev-imgs-c > .imgs-prev-imgs > .imgs-prev-img {
    width: calc((50% - 30px) / 3);
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c {
    height: 8rem;
  }
}
@media only screen and (max-width: 699px) {
  .roadshow-offer-edit-container > .content .images .imgs-prev-box > .imgs-prev-all-c {
    height: 7rem;
  }
}

.collections-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .collections-container {
    padding: 0px 20px;
  }
}
.collections-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .collections-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .collections-container .header .title-box {
    margin-bottom: 20px;
  }
}
.collections-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.collections-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .collections-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .collections-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .collections-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.collections-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .collections-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .collections-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .collections-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.collections-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .collections-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .collections-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .collections-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.collections-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.collections-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .collections-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .collections-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .collections-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.collections-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.collections-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .collections-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .collections-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.collections-container .content {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.collections-container .content > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
  height: max-content;
  margin-right: 30px;
  margin-top: 50px;
}
.collections-container .content > div:nth-child(1) {
  margin-top: 0;
}
.collections-container .content > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
  height: max-content;
  margin-right: 30px;
  margin-top: 50px;
}
.collections-container .content > div:nth-child(2) {
  margin-top: 0;
}
.collections-container .content > div:nth-child(2n) {
  margin-right: 0;
}
.collections-container .content > div:nth-child(1n) {
  display: none;
}
.collections-container .content > div:nth-child(1) {
  display: inline-block;
}
.collections-container .content > div:nth-child(2) {
  display: inline-block;
}
.collections-container .content > div:nth-child(3) {
  display: inline-block;
}
.collections-container .content > div:nth-child(4) {
  display: inline-block;
}
.collections-container .content > div:nth-child(5) {
  display: inline-block;
}
.collections-container .content > div:nth-child(6) {
  display: inline-block;
}
.collections-container .content > div:nth-child(7) {
  display: inline-block;
}
.collections-container .content > div:nth-child(8) {
  display: inline-block;
}
.collections-container .content .collection-card {
  position: relative;
  cursor: pointer;
}
.collections-container .content .collection-card > img {
  width: 100%;
  height: auto;
  border-radius: 15px;
}
.collections-container .content .collection-card > .data {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: calc(100% - 60px);
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
}
@media only screen and (max-width: 999px) {
  .collections-container .content .collection-card > .data {
    padding: 10px 20px;
  }
}
.collections-container .content .collection-card > .data > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .collections-container .content .collection-card > .data > .title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .collections-container .content .collection-card > .data > .title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .collections-container .content .collection-card > .data > .title {
    font-size: 1.0625rem;
  }
}
@media only screen and (max-width: 999px) {
  .collections-container .content .collection-card > .data > .title {
    font-size: 0.9rem;
  }
}
.collections-container .content .collection-card > .data > .description {
  margin: 10px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
  line-height: 1.3rem;
}
@media only screen and (max-width: 999px) {
  .collections-container .content .collection-card > .data > .description {
    display: none;
  }
}
.collections-container .content .collection-card > .data > .footer {
  margin: 10px 0 0 0;
  text-align: right;
}
@media only screen and (max-width: 999px) {
  .collections-container .content .collection-card > .data > .footer {
    display: none;
  }
}
.collections-container .content .collection-card:hover > .settings {
  display: flex;
}
.collections-container .content .collection-card > .settings {
  top: -15px;
  right: -15px;
  position: absolute;
  display: none;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0px 5px;
  border-radius: 5px;
  z-index: 1;
  cursor: initial;
}
.collections-container .content .collection-card > .settings > .edit {
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/edit_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.collections-container .content .collection-card > .settings > .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.collections-home-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .collections-home-container {
    padding: 0px 20px;
  }
}
.collections-home-container .header {
  margin: 0 0 40px 0;
}
.collections-home-container .header > .title {
  text-align: left;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
}
@media only screen and (max-width: 1339px) {
  .collections-home-container .header > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .collections-home-container .header > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .collections-home-container .header > .title {
    font-size: 1.9125rem;
  }
}
.collections-home-container .header > .sub-title {
  margin: 5px 0 0 0;
  text-align: left;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .collections-home-container .header > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .collections-home-container .header > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .collections-home-container .header > .sub-title {
    font-size: 1.0625rem;
  }
}
.collections-home-container > .content .collection-cards {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.collections-home-container > .content .collection-cards > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
  height: max-content;
  margin-right: 30px;
  margin-top: 50px;
}
.collections-home-container > .content .collection-cards > div:nth-child(1) {
  margin-top: 0;
}
.collections-home-container > .content .collection-cards > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
  height: max-content;
  margin-right: 30px;
  margin-top: 50px;
}
.collections-home-container > .content .collection-cards > div:nth-child(2) {
  margin-top: 0;
}
.collections-home-container > .content .collection-cards > div:nth-child(2n) {
  margin-right: 0;
}
.collections-home-container > .content .collection-cards > div:nth-child(1n) {
  display: none;
}
.collections-home-container > .content .collection-cards > div:nth-child(1) {
  display: inline-block;
}
.collections-home-container > .content .collection-cards > div:nth-child(2) {
  display: inline-block;
}
.collections-home-container > .content .collection-cards > div:nth-child(3) {
  display: inline-block;
}
.collections-home-container > .content .collection-cards > div:nth-child(4) {
  display: inline-block;
}
.collections-home-container > .content .collection-cards > div:nth-child(5) {
  display: inline-block;
}
.collections-home-container > .content .collection-cards > div:nth-child(6) {
  display: inline-block;
}
.collections-home-container > .content .collection-cards > div:nth-child(7) {
  display: inline-block;
}
.collections-home-container > .content .collection-cards > div:nth-child(8) {
  display: inline-block;
}
.collections-home-container > .content .collection-cards .collection-card {
  position: relative;
  cursor: pointer;
}
.collections-home-container > .content .collection-cards .collection-card > img {
  width: 100%;
  height: auto;
  border-radius: 15px;
}
.collections-home-container > .content .collection-cards .collection-card > .data {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: calc(100% - 60px);
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
}
@media only screen and (max-width: 999px) {
  .collections-home-container > .content .collection-cards .collection-card > .data {
    padding: 10px 20px;
  }
}
.collections-home-container > .content .collection-cards .collection-card > .data > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .collections-home-container > .content .collection-cards .collection-card > .data > .title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .collections-home-container > .content .collection-cards .collection-card > .data > .title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .collections-home-container > .content .collection-cards .collection-card > .data > .title {
    font-size: 1.0625rem;
  }
}
@media only screen and (max-width: 999px) {
  .collections-home-container > .content .collection-cards .collection-card > .data > .title {
    font-size: 0.9rem;
  }
}
.collections-home-container > .content .collection-cards .collection-card > .data > .description {
  margin: 10px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
  line-height: 1.3rem;
}
@media only screen and (max-width: 999px) {
  .collections-home-container > .content .collection-cards .collection-card > .data > .description {
    display: none;
  }
}
.collections-home-container > .content .collection-cards .collection-card > .data > .footer {
  margin: 10px 0 0 0;
  text-align: right;
}
@media only screen and (max-width: 999px) {
  .collections-home-container > .content .collection-cards .collection-card > .data > .footer {
    display: none;
  }
}
.collections-home-container > .content .collection-cards .collection-card:hover > .settings {
  display: flex;
}
.collections-home-container > .content .collection-cards .collection-card > .settings {
  top: -15px;
  right: -15px;
  position: absolute;
  display: none;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0px 5px;
  border-radius: 5px;
  z-index: 1;
  cursor: initial;
}
.collections-home-container > .content .collection-cards .collection-card > .settings > .edit {
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/edit_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.collections-home-container > .content .collection-cards .collection-card > .settings > .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
@media only screen and (max-width: 899px) {
  .collections-home-container > .content .collection-cards .description {
    display: none;
  }
}
.collections-home-container .footer {
  margin: 40px 0 0 0;
  text-align: center;
}
@media only screen and (max-width: 1339px) {
  .collections-home-container .footer {
    margin: 30px 0 0 0;
  }
}
@media only screen and (max-width: 1049px) {
  .collections-home-container .footer {
    margin: 20px 0 0 0;
  }
}
.collection-edit-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .collection-edit-container {
    padding: 0px 20px;
  }
}
.collection-edit-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .collection-edit-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .collection-edit-container .header .title-box {
    margin-bottom: 20px;
  }
}
.collection-edit-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.collection-edit-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .collection-edit-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .collection-edit-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .collection-edit-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.collection-edit-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .collection-edit-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .collection-edit-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .collection-edit-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.collection-edit-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .collection-edit-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .collection-edit-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .collection-edit-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.collection-edit-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.collection-edit-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .collection-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .collection-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .collection-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.collection-edit-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.collection-edit-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .collection-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .collection-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.collection-edit-container > .footer {
  margin: 30px 0 0 0;
}
.collection-edit-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.collection-edit-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.categories-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .categories-container {
    padding: 0px 20px;
  }
}
.categories-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .categories-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .categories-container .header .title-box {
    margin-bottom: 20px;
  }
}
.categories-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.categories-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .categories-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .categories-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .categories-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.categories-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .categories-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .categories-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .categories-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.categories-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .categories-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .categories-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .categories-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.categories-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.categories-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .categories-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .categories-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .categories-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.categories-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.categories-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .categories-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .categories-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.categories-container > .content {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  padding: 0 0 40px 0;
}
.categories-container > .content > div:nth-child(1n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.categories-container > .content > div:nth-child(1) {
  margin-top: 0;
}
.categories-container > .content > div:nth-child(2n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.categories-container > .content > div:nth-child(2) {
  margin-top: 0;
}
.categories-container > .content > div:nth-child(3n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.categories-container > .content > div:nth-child(3) {
  margin-top: 0;
}
.categories-container > .content > div:nth-child(4n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.categories-container > .content > div:nth-child(4) {
  margin-top: 0;
}
.categories-container > .content > div:nth-child(5n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.categories-container > .content > div:nth-child(5) {
  margin-top: 0;
}
.categories-container > .content > div:nth-child(6n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.categories-container > .content > div:nth-child(6) {
  margin-top: 0;
}
.categories-container > .content > div:nth-child(7n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.categories-container > .content > div:nth-child(7) {
  margin-top: 0;
}
.categories-container > .content > div:nth-child(7n) {
  margin-right: 0;
}
@media only screen and (max-width: 1099px) {
  .categories-container > .content {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .categories-container > .content > div:nth-child(1n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-container > .content > div:nth-child(1) {
    margin-top: 0;
  }
  .categories-container > .content > div:nth-child(2n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-container > .content > div:nth-child(2) {
    margin-top: 0;
  }
  .categories-container > .content > div:nth-child(3n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-container > .content > div:nth-child(3) {
    margin-top: 0;
  }
  .categories-container > .content > div:nth-child(4n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-container > .content > div:nth-child(4) {
    margin-top: 0;
  }
  .categories-container > .content > div:nth-child(5n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-container > .content > div:nth-child(5) {
    margin-top: 0;
  }
  .categories-container > .content > div:nth-child(6n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-container > .content > div:nth-child(6) {
    margin-top: 0;
  }
  .categories-container > .content > div:nth-child(6n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 949px) {
  .categories-container > .content {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .categories-container > .content > div:nth-child(1n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 5) / (5 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-container > .content > div:nth-child(1) {
    margin-top: 0;
  }
  .categories-container > .content > div:nth-child(2n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 5) / (5 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-container > .content > div:nth-child(2) {
    margin-top: 0;
  }
  .categories-container > .content > div:nth-child(3n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 5) / (5 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-container > .content > div:nth-child(3) {
    margin-top: 0;
  }
  .categories-container > .content > div:nth-child(4n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 5) / (5 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-container > .content > div:nth-child(4) {
    margin-top: 0;
  }
  .categories-container > .content > div:nth-child(5n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 5) / (5 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-container > .content > div:nth-child(5) {
    margin-top: 0;
  }
  .categories-container > .content > div:nth-child(5n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 749px) {
  .categories-container > .content {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .categories-container > .content > div:nth-child(1n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 4) / (4 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-container > .content > div:nth-child(1) {
    margin-top: 0;
  }
  .categories-container > .content > div:nth-child(2n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 4) / (4 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-container > .content > div:nth-child(2) {
    margin-top: 0;
  }
  .categories-container > .content > div:nth-child(3n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 4) / (4 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-container > .content > div:nth-child(3) {
    margin-top: 0;
  }
  .categories-container > .content > div:nth-child(4n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 4) / (4 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-container > .content > div:nth-child(4) {
    margin-top: 0;
  }
  .categories-container > .content > div:nth-child(4n) {
    margin-right: 0;
  }
}
.categories-container > .content .category-card {
  position: relative;
  display: flex;
  height: 140px;
}
.categories-container > .content .category-card > img {
  margin: 0 auto;
  width: 66px;
  display: block;
  cursor: pointer;
}
.categories-container > .content .category-card > img:hover {
  opacity: 0.8;
}
.categories-container > .content .category-card > .data {
  position: absolute;
  width: 100%;
}
.categories-container > .content .category-card > .data > .title {
  margin: 10px 0 0 0;
  text-align: center;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1rem;
  line-height: 1.2rem;
  word-break: break-word;
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: center;
}
.categories-container > .content .category-card > .data > .counter {
  margin: 5px 0 0 0;
  text-align: center;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
}
.categories-container > .content .category-card:hover > .settings {
  display: flex;
}
.categories-container > .content .category-card > .settings {
  top: -15px;
  right: -15px;
  position: absolute;
  display: none;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0px 5px;
  border-radius: 5px;
  z-index: 1;
  cursor: initial;
}
.categories-container > .content .category-card > .settings > .edit {
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/edit_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.categories-container > .content .category-card > .settings > .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
@keyframes glass {
  from {
    left: -200px;
  }
  to {
    left: calc(100% + 200px);
  }
}
.home-placeholder {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .home-placeholder {
    padding: 0px 20px;
  }
}
.home-placeholder__banner {
  background-color: #efefef;
  border-radius: 1.2vw;
  position: relative;
  overflow: hidden;
}
.home-placeholder__banner .def {
  width: 40%;
  visibility: hidden;
}
.home-placeholder__banner .hoverEffect {
  position: absolute;
  background-color: red;
  height: 447px;
  width: 200px;
  top: 0;
  left: 100px;
  background: linear-gradient(90deg, rgb(239, 239, 239) 0%, rgba(249, 249, 249, 0.5490196078) 55%, rgb(239, 239, 239) 100%);
  animation-duration: 4s;
  animation-name: glass;
  animation-iteration-count: infinite;
  animation-direction: normal;
}
.home-placeholder__section {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .home-placeholder__section {
    padding: 0px 20px;
  }
}
.home-placeholder__section .title {
  width: 380px;
  height: 27px;
  border-radius: 5px;
  margin-top: 30px;
  background-color: #efefef;
}
.home-placeholder__section .subtitle {
  width: 260px;
  height: 20px;
  margin-top: 10px;
  border-radius: 5px;
  background-color: #efefef;
}
.home-placeholder__section .items {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.home-placeholder__section .items > div:nth-child(1n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.home-placeholder__section .items > div:nth-child(1) {
  margin-top: 0;
}
.home-placeholder__section .items > div:nth-child(2n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.home-placeholder__section .items > div:nth-child(2) {
  margin-top: 0;
}
.home-placeholder__section .items > div:nth-child(3n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.home-placeholder__section .items > div:nth-child(3) {
  margin-top: 0;
}
.home-placeholder__section .items > div:nth-child(4n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.home-placeholder__section .items > div:nth-child(4) {
  margin-top: 0;
}
.home-placeholder__section .items > div:nth-child(5n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.home-placeholder__section .items > div:nth-child(5) {
  margin-top: 0;
}
.home-placeholder__section .items > div:nth-child(6n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.home-placeholder__section .items > div:nth-child(6) {
  margin-top: 0;
}
.home-placeholder__section .items > div:nth-child(7n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.home-placeholder__section .items > div:nth-child(7) {
  margin-top: 0;
}
.home-placeholder__section .items > div:nth-child(7n) {
  margin-right: 0;
}
.home-placeholder__section .items > div:nth-child(1n) {
  display: none;
}
.home-placeholder__section .items > div:nth-child(1) {
  display: inline-block;
}
.home-placeholder__section .items > div:nth-child(2) {
  display: inline-block;
}
.home-placeholder__section .items > div:nth-child(3) {
  display: inline-block;
}
.home-placeholder__section .items > div:nth-child(4) {
  display: inline-block;
}
.home-placeholder__section .items > div:nth-child(5) {
  display: inline-block;
}
.home-placeholder__section .items > div:nth-child(6) {
  display: inline-block;
}
.home-placeholder__section .items > div:nth-child(7) {
  display: inline-block;
}
.home-placeholder__section .items > div:nth-child(8) {
  display: inline-block;
}
.home-placeholder__section .items > div:nth-child(9) {
  display: inline-block;
}
.home-placeholder__section .items > div:nth-child(10) {
  display: inline-block;
}
.home-placeholder__section .items > div:nth-child(11) {
  display: inline-block;
}
.home-placeholder__section .items > div:nth-child(12) {
  display: inline-block;
}
.home-placeholder__section .items > div:nth-child(13) {
  display: inline-block;
}
.home-placeholder__section .items > div:nth-child(14) {
  display: inline-block;
}
@media only screen and (max-width: 1099px) {
  .home-placeholder__section .items {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .home-placeholder__section .items > div:nth-child(1n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .home-placeholder__section .items > div:nth-child(1) {
    margin-top: 0;
  }
  .home-placeholder__section .items > div:nth-child(2n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .home-placeholder__section .items > div:nth-child(2) {
    margin-top: 0;
  }
  .home-placeholder__section .items > div:nth-child(3n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .home-placeholder__section .items > div:nth-child(3) {
    margin-top: 0;
  }
  .home-placeholder__section .items > div:nth-child(4n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .home-placeholder__section .items > div:nth-child(4) {
    margin-top: 0;
  }
  .home-placeholder__section .items > div:nth-child(5n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .home-placeholder__section .items > div:nth-child(5) {
    margin-top: 0;
  }
  .home-placeholder__section .items > div:nth-child(6n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .home-placeholder__section .items > div:nth-child(6) {
    margin-top: 0;
  }
  .home-placeholder__section .items > div:nth-child(6n) {
    margin-right: 0;
  }
  .home-placeholder__section .items > div:nth-child(1n) {
    display: none;
  }
  .home-placeholder__section .items > div:nth-child(1) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(2) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(3) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(4) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(5) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(6) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(7) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(8) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(9) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(10) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(11) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(12) {
    display: inline-block;
  }
}
@media only screen and (max-width: 949px) {
  .home-placeholder__section .items {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .home-placeholder__section .items > div:nth-child(1n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 5) / (5 - 1) - 1px);
    margin-top: 20px;
  }
  .home-placeholder__section .items > div:nth-child(1) {
    margin-top: 0;
  }
  .home-placeholder__section .items > div:nth-child(2n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 5) / (5 - 1) - 1px);
    margin-top: 20px;
  }
  .home-placeholder__section .items > div:nth-child(2) {
    margin-top: 0;
  }
  .home-placeholder__section .items > div:nth-child(3n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 5) / (5 - 1) - 1px);
    margin-top: 20px;
  }
  .home-placeholder__section .items > div:nth-child(3) {
    margin-top: 0;
  }
  .home-placeholder__section .items > div:nth-child(4n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 5) / (5 - 1) - 1px);
    margin-top: 20px;
  }
  .home-placeholder__section .items > div:nth-child(4) {
    margin-top: 0;
  }
  .home-placeholder__section .items > div:nth-child(5n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 5) / (5 - 1) - 1px);
    margin-top: 20px;
  }
  .home-placeholder__section .items > div:nth-child(5) {
    margin-top: 0;
  }
  .home-placeholder__section .items > div:nth-child(5n) {
    margin-right: 0;
  }
  .home-placeholder__section .items > div:nth-child(1n) {
    display: none;
  }
  .home-placeholder__section .items > div:nth-child(1) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(2) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(3) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(4) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(5) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(6) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(7) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(8) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(9) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(10) {
    display: inline-block;
  }
}
@media only screen and (max-width: 749px) {
  .home-placeholder__section .items {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .home-placeholder__section .items > div:nth-child(1n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 4) / (4 - 1) - 1px);
    margin-top: 20px;
  }
  .home-placeholder__section .items > div:nth-child(1) {
    margin-top: 0;
  }
  .home-placeholder__section .items > div:nth-child(2n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 4) / (4 - 1) - 1px);
    margin-top: 20px;
  }
  .home-placeholder__section .items > div:nth-child(2) {
    margin-top: 0;
  }
  .home-placeholder__section .items > div:nth-child(3n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 4) / (4 - 1) - 1px);
    margin-top: 20px;
  }
  .home-placeholder__section .items > div:nth-child(3) {
    margin-top: 0;
  }
  .home-placeholder__section .items > div:nth-child(4n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 4) / (4 - 1) - 1px);
    margin-top: 20px;
  }
  .home-placeholder__section .items > div:nth-child(4) {
    margin-top: 0;
  }
  .home-placeholder__section .items > div:nth-child(4n) {
    margin-right: 0;
  }
  .home-placeholder__section .items > div:nth-child(1n) {
    display: none;
  }
  .home-placeholder__section .items > div:nth-child(1) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(2) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(3) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(4) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(5) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(6) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(7) {
    display: inline-block;
  }
  .home-placeholder__section .items > div:nth-child(8) {
    display: inline-block;
  }
}
.home-placeholder__section .items .item {
  height: 140px;
}
.home-placeholder__section .items .item__img {
  width: 66px;
  height: 66px;
  border-radius: 5px;
  margin: 0 auto;
  background-color: #efefef;
}
.home-placeholder__section .items .item__title {
  width: 115px;
  height: 19px;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 10px;
  background-color: #efefef;
}

.categories-home-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .categories-home-container {
    padding: 0px 20px;
  }
}
.categories-home-container .header {
  margin: 0 0 40px 0;
}
.categories-home-container .header > .title {
  text-align: left;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
}
@media only screen and (max-width: 1339px) {
  .categories-home-container .header > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .categories-home-container .header > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .categories-home-container .header > .title {
    font-size: 1.9125rem;
  }
}
.categories-home-container .header > .sub-title {
  margin: 5px 0 0 0;
  text-align: left;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .categories-home-container .header > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .categories-home-container .header > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .categories-home-container .header > .sub-title {
    font-size: 1.0625rem;
  }
}
.categories-home-container > .category-cards {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.categories-home-container > .category-cards > div:nth-child(1n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.categories-home-container > .category-cards > div:nth-child(1) {
  margin-top: 0;
}
.categories-home-container > .category-cards > div:nth-child(2n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.categories-home-container > .category-cards > div:nth-child(2) {
  margin-top: 0;
}
.categories-home-container > .category-cards > div:nth-child(3n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.categories-home-container > .category-cards > div:nth-child(3) {
  margin-top: 0;
}
.categories-home-container > .category-cards > div:nth-child(4n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.categories-home-container > .category-cards > div:nth-child(4) {
  margin-top: 0;
}
.categories-home-container > .category-cards > div:nth-child(5n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.categories-home-container > .category-cards > div:nth-child(5) {
  margin-top: 0;
}
.categories-home-container > .category-cards > div:nth-child(6n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.categories-home-container > .category-cards > div:nth-child(6) {
  margin-top: 0;
}
.categories-home-container > .category-cards > div:nth-child(7n) {
  display: inline-block;
  width: 115px;
  height: 140px;
  margin-right: calc((100% - 115px * 7) / (7 - 1) - 1px);
  margin-top: 20px;
}
.categories-home-container > .category-cards > div:nth-child(7) {
  margin-top: 0;
}
.categories-home-container > .category-cards > div:nth-child(7n) {
  margin-right: 0;
}
.categories-home-container > .category-cards > div:nth-child(1n) {
  display: none;
}
.categories-home-container > .category-cards > div:nth-child(1) {
  display: inline-block;
}
.categories-home-container > .category-cards > div:nth-child(2) {
  display: inline-block;
}
.categories-home-container > .category-cards > div:nth-child(3) {
  display: inline-block;
}
.categories-home-container > .category-cards > div:nth-child(4) {
  display: inline-block;
}
.categories-home-container > .category-cards > div:nth-child(5) {
  display: inline-block;
}
.categories-home-container > .category-cards > div:nth-child(6) {
  display: inline-block;
}
.categories-home-container > .category-cards > div:nth-child(7) {
  display: inline-block;
}
.categories-home-container > .category-cards > div:nth-child(8) {
  display: inline-block;
}
.categories-home-container > .category-cards > div:nth-child(9) {
  display: inline-block;
}
.categories-home-container > .category-cards > div:nth-child(10) {
  display: inline-block;
}
.categories-home-container > .category-cards > div:nth-child(11) {
  display: inline-block;
}
.categories-home-container > .category-cards > div:nth-child(12) {
  display: inline-block;
}
.categories-home-container > .category-cards > div:nth-child(13) {
  display: inline-block;
}
.categories-home-container > .category-cards > div:nth-child(14) {
  display: inline-block;
}
@media only screen and (max-width: 1099px) {
  .categories-home-container > .category-cards {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .categories-home-container > .category-cards > div:nth-child(1n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-home-container > .category-cards > div:nth-child(1) {
    margin-top: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(2n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-home-container > .category-cards > div:nth-child(2) {
    margin-top: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(3n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-home-container > .category-cards > div:nth-child(3) {
    margin-top: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(4n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-home-container > .category-cards > div:nth-child(4) {
    margin-top: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(5n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-home-container > .category-cards > div:nth-child(5) {
    margin-top: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(6n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 6) / (6 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-home-container > .category-cards > div:nth-child(6) {
    margin-top: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(6n) {
    margin-right: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(1n) {
    display: none;
  }
  .categories-home-container > .category-cards > div:nth-child(1) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(2) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(3) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(4) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(5) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(6) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(7) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(8) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(9) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(10) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(11) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(12) {
    display: inline-block;
  }
}
@media only screen and (max-width: 949px) {
  .categories-home-container > .category-cards {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .categories-home-container > .category-cards > div:nth-child(1n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 5) / (5 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-home-container > .category-cards > div:nth-child(1) {
    margin-top: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(2n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 5) / (5 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-home-container > .category-cards > div:nth-child(2) {
    margin-top: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(3n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 5) / (5 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-home-container > .category-cards > div:nth-child(3) {
    margin-top: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(4n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 5) / (5 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-home-container > .category-cards > div:nth-child(4) {
    margin-top: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(5n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 5) / (5 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-home-container > .category-cards > div:nth-child(5) {
    margin-top: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(5n) {
    margin-right: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(1n) {
    display: none;
  }
  .categories-home-container > .category-cards > div:nth-child(1) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(2) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(3) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(4) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(5) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(6) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(7) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(8) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(9) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(10) {
    display: inline-block;
  }
}
@media only screen and (max-width: 749px) {
  .categories-home-container > .category-cards {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .categories-home-container > .category-cards > div:nth-child(1n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 4) / (4 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-home-container > .category-cards > div:nth-child(1) {
    margin-top: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(2n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 4) / (4 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-home-container > .category-cards > div:nth-child(2) {
    margin-top: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(3n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 4) / (4 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-home-container > .category-cards > div:nth-child(3) {
    margin-top: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(4n) {
    display: inline-block;
    width: 115px;
    height: 140px;
    margin-right: calc((100% - 115px * 4) / (4 - 1) - 1px);
    margin-top: 20px;
  }
  .categories-home-container > .category-cards > div:nth-child(4) {
    margin-top: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(4n) {
    margin-right: 0;
  }
  .categories-home-container > .category-cards > div:nth-child(1n) {
    display: none;
  }
  .categories-home-container > .category-cards > div:nth-child(1) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(2) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(3) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(4) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(5) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(6) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(7) {
    display: inline-block;
  }
  .categories-home-container > .category-cards > div:nth-child(8) {
    display: inline-block;
  }
}
.categories-home-container > .category-cards .category-card {
  position: relative;
  display: flex;
  height: 140px;
}
.categories-home-container > .category-cards .category-card > img {
  margin: 0 auto;
  width: 66px;
  display: block;
  cursor: pointer;
}
.categories-home-container > .category-cards .category-card > img:hover {
  opacity: 0.8;
}
.categories-home-container > .category-cards .category-card > .data {
  position: absolute;
  width: 100%;
}
.categories-home-container > .category-cards .category-card > .data > .title {
  margin: 10px 0 0 0;
  text-align: center;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1rem;
  line-height: 1.2rem;
  word-break: break-word;
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: center;
}
.categories-home-container > .category-cards .category-card > .data > .counter {
  margin: 5px 0 0 0;
  text-align: center;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
}
.categories-home-container > .category-cards .category-card:hover > .settings {
  display: flex;
}
.categories-home-container > .category-cards .category-card > .settings {
  top: -15px;
  right: -15px;
  position: absolute;
  display: none;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0px 5px;
  border-radius: 5px;
  z-index: 1;
  cursor: initial;
}
.categories-home-container > .category-cards .category-card > .settings > .edit {
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/edit_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.categories-home-container > .category-cards .category-card > .settings > .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.categories-home-container > .category-cards-small {
  margin: 50px 0 0 0;
  text-align: center;
}
.categories-home-container > .category-cards-small > div:nth-child(1n) {
  display: none !important;
}
.categories-home-container > .category-cards-small > div:nth-child(15) {
  display: inline-block !important;
}
.categories-home-container > .category-cards-small > div:nth-child(16) {
  display: inline-block !important;
}
.categories-home-container > .category-cards-small > div:nth-child(17) {
  display: inline-block !important;
}
.categories-home-container > .category-cards-small > div:nth-child(18) {
  display: inline-block !important;
}
.categories-home-container > .category-cards-small > div:nth-child(19) {
  display: inline-block !important;
}
.categories-home-container > .category-cards-small > div:nth-child(20) {
  display: inline-block !important;
}
.categories-home-container > .category-cards-small > div:nth-child(21) {
  display: inline-block !important;
}
.categories-home-container > .category-cards-small > div:nth-child(22) {
  display: inline-block !important;
}
.categories-home-container > .category-cards-small > div:nth-child(23) {
  display: inline-block !important;
}
.categories-home-container > .category-cards-small > div:nth-child(24) {
  display: inline-block !important;
}
.categories-home-container > .category-cards-small > div:nth-child(25) {
  display: inline-block !important;
}
.categories-home-container > .category-cards-small > div:nth-child(26) {
  display: inline-block !important;
}
@media only screen and (max-width: 1049px) {
  .categories-home-container > .category-cards-small > div:nth-child(1n) {
    display: none !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(11) {
    display: inline-block !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(12) {
    display: inline-block !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(13) {
    display: inline-block !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(14) {
    display: inline-block !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(15) {
    display: inline-block !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(16) {
    display: inline-block !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(17) {
    display: inline-block !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(18) {
    display: inline-block !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(19) {
    display: inline-block !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(20) {
    display: inline-block !important;
  }
}
@media only screen and (max-width: 829px) {
  .categories-home-container > .category-cards-small > div:nth-child(1n) {
    display: none !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(12) {
    display: inline-block !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(13) {
    display: inline-block !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(14) {
    display: inline-block !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(15) {
    display: inline-block !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(16) {
    display: inline-block !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(17) {
    display: inline-block !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(18) {
    display: inline-block !important;
  }
  .categories-home-container > .category-cards-small > div:nth-child(19) {
    display: inline-block !important;
  }
}
.categories-home-container > .category-cards-small .category-card-small {
  margin: 0 10px 15px 10px;
  display: inline-block;
  padding: 6px 20px;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 0.875rem;
  border: 1px solid #c5c5c5;
  border-radius: 16px;
  cursor: pointer;
}
.categories-home-container > .category-cards-small .category-card-small:hover {
  color: #6e6e6e;
}
.categories-home-container .footer {
  margin: 40px 0 0 0;
  text-align: center;
}
@media only screen and (max-width: 1339px) {
  .categories-home-container .footer {
    margin: 30px 0 0 0;
  }
}
@media only screen and (max-width: 1049px) {
  .categories-home-container .footer {
    margin: 20px 0 0 0;
  }
}
.category-edit-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .category-edit-container {
    padding: 0px 20px;
  }
}
.category-edit-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .category-edit-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .category-edit-container .header .title-box {
    margin-bottom: 20px;
  }
}
.category-edit-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.category-edit-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .category-edit-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .category-edit-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .category-edit-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.category-edit-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .category-edit-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .category-edit-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .category-edit-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.category-edit-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .category-edit-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .category-edit-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .category-edit-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.category-edit-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.category-edit-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .category-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .category-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .category-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.category-edit-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.category-edit-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .category-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .category-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.category-edit-container > .footer {
  margin: 30px 0 0 0;
}
.category-edit-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.category-edit-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.user-inivations-edit-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .user-inivations-edit-container {
    padding: 0px 20px;
  }
}
.user-inivations-edit-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .user-inivations-edit-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .user-inivations-edit-container .header .title-box {
    margin-bottom: 20px;
  }
}
.user-inivations-edit-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.user-inivations-edit-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .user-inivations-edit-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .user-inivations-edit-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .user-inivations-edit-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.user-inivations-edit-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .user-inivations-edit-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .user-inivations-edit-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .user-inivations-edit-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.user-inivations-edit-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .user-inivations-edit-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .user-inivations-edit-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .user-inivations-edit-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.user-inivations-edit-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.user-inivations-edit-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .user-inivations-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .user-inivations-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .user-inivations-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.user-inivations-edit-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.user-inivations-edit-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .user-inivations-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .user-inivations-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.user-inivations-edit-container > .footer {
  margin: 30px 0 0 0;
}
.user-inivations-edit-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.user-inivations-edit-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.tags-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .tags-container {
    padding: 0px 20px;
  }
}
.tags-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .tags-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .tags-container .header .title-box {
    margin-bottom: 20px;
  }
}
.tags-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.tags-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .tags-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .tags-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .tags-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.tags-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .tags-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .tags-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .tags-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.tags-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .tags-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .tags-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .tags-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.tags-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.tags-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .tags-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .tags-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .tags-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.tags-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.tags-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .tags-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .tags-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.tags-container > .content .tag-card {
  position: relative;
  margin: 0 15px 20px 0;
  display: inline-block;
  padding: 9px 20px;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 0.875rem;
  border: 1px solid #c5c5c5;
  border-radius: 16px;
  cursor: pointer;
}
.tags-container > .content .tag-card:hover {
  color: #6e6e6e;
}
.tags-container > .content .tag-card:hover > .settings {
  display: flex;
}

.def-locations-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .def-locations-container {
    padding: 0px 20px;
  }
}
.def-locations-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .def-locations-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .def-locations-container .header .title-box {
    margin-bottom: 20px;
  }
}
.def-locations-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.def-locations-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .def-locations-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .def-locations-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .def-locations-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.def-locations-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .def-locations-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .def-locations-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .def-locations-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.def-locations-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .def-locations-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .def-locations-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .def-locations-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.def-locations-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.def-locations-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .def-locations-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .def-locations-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .def-locations-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.def-locations-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.def-locations-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .def-locations-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .def-locations-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.def-locations-container > .content .tag-card {
  position: relative;
  margin: 0 15px 20px 0;
  display: inline-block;
  padding: 9px 20px;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 0.875rem;
  border: 1px solid #c5c5c5;
  border-radius: 16px;
  cursor: pointer;
}
.def-locations-container > .content .tag-card:hover {
  color: #6e6e6e;
}
.def-locations-container > .content .tag-card:hover > .settings {
  display: flex;
}

.termsandcond-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .termsandcond-container {
    padding: 0px 20px;
  }
}
.termsandcond-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .termsandcond-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .termsandcond-container .header .title-box {
    margin-bottom: 20px;
  }
}
.termsandcond-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.termsandcond-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .termsandcond-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .termsandcond-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .termsandcond-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.termsandcond-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .termsandcond-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .termsandcond-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .termsandcond-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.termsandcond-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .termsandcond-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .termsandcond-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .termsandcond-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.termsandcond-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.termsandcond-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .termsandcond-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .termsandcond-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .termsandcond-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.termsandcond-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.termsandcond-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .termsandcond-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .termsandcond-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.termsandcond-container > .footer {
  margin: 30px 0 0 0;
}
.termsandcond-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.termsandcond-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.termsandcond-container > .content .tag-card {
  position: relative;
  margin: 0 15px 20px 0;
  display: inline-block;
  padding: 9px 20px;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 0.875rem;
  border: 1px solid #c5c5c5;
  border-radius: 16px;
  cursor: pointer;
}
.termsandcond-container > .content .tag-card:hover {
  color: #6e6e6e;
}
.termsandcond-container > .content .tag-card:hover > .settings {
  display: flex;
}

.tag-edit-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .tag-edit-container {
    padding: 0px 20px;
  }
}
.tag-edit-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .tag-edit-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .tag-edit-container .header .title-box {
    margin-bottom: 20px;
  }
}
.tag-edit-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.tag-edit-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .tag-edit-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .tag-edit-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .tag-edit-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.tag-edit-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .tag-edit-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .tag-edit-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .tag-edit-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.tag-edit-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .tag-edit-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .tag-edit-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .tag-edit-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.tag-edit-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.tag-edit-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .tag-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .tag-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .tag-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.tag-edit-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.tag-edit-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .tag-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .tag-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.tag-edit-container > .footer {
  margin: 30px 0 0 0;
}
.tag-edit-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.tag-edit-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.users-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .users-container {
    padding: 0px 20px;
  }
}
.users-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .users-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .users-container .header .title-box {
    margin-bottom: 20px;
  }
}
.users-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.users-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .users-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .users-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .users-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.users-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .users-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .users-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .users-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.users-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .users-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .users-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .users-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.users-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.users-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .users-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .users-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .users-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.users-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.users-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .users-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .users-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.users-container > .content {
  display: flex;
}
.users-container > .content .filter_box {
  width: 300px;
  margin-right: 50px;
}
.users-container > .content .filter_box .filter_box_modal {
  width: 100%;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_header {
  display: none;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content {
  width: 100%;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword {
  margin-top: 3px;
  display: flex;
  height: 48px;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  border: none;
  border-radius: 26px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_0_icon.svg") no-repeat center;
  background-size: 17px auto;
  margin: 0 15px 0 0;
  cursor: pointer;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon:hover {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_1_icon.svg") no-repeat center;
  background-size: 17px auto;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input {
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 1.3rem;
  border: none;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input::placeholder {
  line-height: 1.3rem !important;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_0_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
  border-bottom: 1px solid #c5c5c5;
  padding: 20px 0;
  cursor: pointer;
}
@media only screen and (max-width: 1339px) {
  .users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.0625rem;
  }
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header:hover {
  color: #0047ba;
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_2_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options {
  display: none;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar {
  width: 6px;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option {
  padding: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox {
  margin: 0;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label:hover {
  color: #0047ba;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  width: 78px;
  height: 14px;
  background: url("/assets/images/icons/stars_empty_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars > .stars-positive {
  position: absolute;
  width: 0%;
  height: 14px;
  background: url("/assets/images/icons/stars_full_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .counter {
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option:first-of-type {
  padding: 0;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion:first-of-type > .criterion-header {
  padding-top: 0;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_1_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  border: none;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header:hover {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_3_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-options {
  display: block;
  max-height: 190px;
  overflow-y: auto;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset {
  margin-top: 10px;
  text-align: right;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn {
  display: inline-block;
  padding-top: 3px;
  margin-right: 7px;
  padding-right: 7px;
  border-right: 2px solid #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn:hover {
  opacity: 0.8;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn {
  display: inline-block;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn:hover {
  opacity: 0.8;
}
.users-container > .content .filter_box .filter_box_modal .filter_box_modal_footer {
  display: none;
}
@media only screen and (max-width: 999px) {
  .users-container > .content .filter_box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(236, 241, 250, 0.7);
  }
  .users-container > .content .filter_box .filter_box_modal {
    max-width: 600px;
    min-width: 500px;
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 30px);
    min-height: 150px;
    background-color: #fff;
    padding: 0 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  }
  .users-container > .content .filter_box .filter_box_modal .filter_box_modal_header {
    display: block;
    font-family: "ADNOC Sans Bold";
    color: #0047ba;
    font-size: 1rem;
    padding: 20px 0 15px 0;
    border-bottom: 1px solid #c5c5c5;
  }
  .users-container > .content .filter_box .filter_box_modal .filter_box_modal_content {
    margin: 30px 0;
    overflow-x: auto;
    padding-right: 10px;
    padding-left: 3px;
    max-height: calc(50vh - 50px);
  }
  .users-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
    width: 6px;
  }
  .users-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
    background: #f8f8f8;
  }
  .users-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
    background: #c0cace;
    border-radius: 5px;
    cursor: pointer;
  }
  .users-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
    background: #0047ba;
  }
  .users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .criterion-options {
    display: block;
    max-height: 1000px !important;
    overflow-y: auto;
  }
  .users-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset {
    display: none;
  }
  .users-container > .content .filter_box .filter_box_modal .filter_box_modal_footer {
    display: block;
    padding: 0 0 20px 0;
    display: flex;
    justify-content: flex-end;
  }
  .users-container > .content .filter_box .filter_box_modal .filter_box_modal_footer > * {
    margin: 0 0 0 10px;
  }
}
.users-container > .content .filter_box-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(236, 241, 250, 0.7);
}
.users-container > .content .filter_box-modal .filter_box_modal {
  max-width: 600px;
  min-width: 500px;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 30px);
  min-height: 150px;
  background-color: #fff;
  padding: 0 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}
.users-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_header {
  display: block;
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  padding: 20px 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.users-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content {
  margin: 30px 0;
  overflow-x: auto;
  padding-right: 10px;
  padding-left: 3px;
  max-height: calc(50vh - 50px);
}
.users-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
  width: 6px;
}
.users-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.users-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.users-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.users-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content .reset {
  display: none;
}
.users-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_footer {
  display: block;
  padding: 0 0 20px 0;
  display: flex;
  justify-content: flex-end;
}
.users-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_footer > * {
  margin: 0 0 0 10px;
}
.users-container > .content .users-box {
  width: 100%;
}
.users-container > .content .users-box .users {
  padding-bottom: 80px;
}
.users-container > .content .users-box .users .user-card {
  position: relative;
  display: flex;
  text-align: center;
}
.users-container > .content .users-box .users .user-card > img {
  width: 70%;
  border-radius: 50%;
  border: 1px solid #c5c5c5;
  cursor: pointer;
}
.users-container > .content .users-box .users .user-card > img:hover {
  opacity: 0.8;
}
.users-container > .content .users-box .users .user-card > .data {
  position: absolute;
  width: 100%;
}
.users-container > .content .users-box .users .user-card > .data > .name {
  margin: 15px 0 0 0;
  text-align: center;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  line-height: 1.2rem;
}
.users-container > .content .users-box .users .user-card > .data > .title {
  margin: 8px 0 0 0;
  text-align: center;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
}
.users-container > .content .users-box .users .user-card > .data > .mail {
  margin: 7px 0 0 0;
  text-align: center;
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 1.2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: copy;
}
.users-container > .content .users-box .users .user-card > .data > .mail:hover {
  color: #0047ba;
}
.users-container > .content .users-box .users .user-card:hover > .settings {
  display: flex;
}
.users-container > .content .users-box .users .user-card > .settings {
  top: 0px;
  right: 7px;
  position: absolute;
  display: none;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0px 5px;
  border-radius: 5px;
  z-index: 1;
  cursor: initial;
}
.users-container > .content .users-box .users .user-card > .settings > .edit {
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/edit_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.users-container > .content .users-box .users .user-card > .settings > .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.users-container > .content .users-box .users .user-card-inactive > img {
  opacity: 0.5;
}
.users-container > .content .users-box .users-wide {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.users-container > .content .users-box .users-wide > div:nth-child(1n) {
  display: inline-block;
  width: 180px;
  height: max-content;
  margin-right: calc((100% - 180px * 5) / (5 - 1) - 1px);
  margin-top: 115px;
}
.users-container > .content .users-box .users-wide > div:nth-child(1) {
  margin-top: 0;
}
.users-container > .content .users-box .users-wide > div:nth-child(2n) {
  display: inline-block;
  width: 180px;
  height: max-content;
  margin-right: calc((100% - 180px * 5) / (5 - 1) - 1px);
  margin-top: 115px;
}
.users-container > .content .users-box .users-wide > div:nth-child(2) {
  margin-top: 0;
}
.users-container > .content .users-box .users-wide > div:nth-child(3n) {
  display: inline-block;
  width: 180px;
  height: max-content;
  margin-right: calc((100% - 180px * 5) / (5 - 1) - 1px);
  margin-top: 115px;
}
.users-container > .content .users-box .users-wide > div:nth-child(3) {
  margin-top: 0;
}
.users-container > .content .users-box .users-wide > div:nth-child(4n) {
  display: inline-block;
  width: 180px;
  height: max-content;
  margin-right: calc((100% - 180px * 5) / (5 - 1) - 1px);
  margin-top: 115px;
}
.users-container > .content .users-box .users-wide > div:nth-child(4) {
  margin-top: 0;
}
.users-container > .content .users-box .users-wide > div:nth-child(5n) {
  display: inline-block;
  width: 180px;
  height: max-content;
  margin-right: calc((100% - 180px * 5) / (5 - 1) - 1px);
  margin-top: 115px;
}
.users-container > .content .users-box .users-wide > div:nth-child(5) {
  margin-top: 0;
}
.users-container > .content .users-box .users-wide > div:nth-child(5n) {
  margin-right: 0;
}
@media only screen and (max-width: 1099px) {
  .users-container > .content .users-box .users-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(1n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 5) / (5 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(2n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 5) / (5 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(3n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 5) / (5 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(4n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 5) / (5 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(4) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(5n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 5) / (5 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(5) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(5n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 999px) {
  .users-container > .content .users-box .users-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(1n) {
    display: inline-block;
    width: 180px;
    height: max-content;
    margin-right: calc((100% - 180px * 4) / (4 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(2n) {
    display: inline-block;
    width: 180px;
    height: max-content;
    margin-right: calc((100% - 180px * 4) / (4 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(3n) {
    display: inline-block;
    width: 180px;
    height: max-content;
    margin-right: calc((100% - 180px * 4) / (4 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(4n) {
    display: inline-block;
    width: 180px;
    height: max-content;
    margin-right: calc((100% - 180px * 4) / (4 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(4) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(4n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .users-container > .content .users-box .users-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(1n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 4) / (4 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(2n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 4) / (4 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(3n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 4) / (4 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(4n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 4) / (4 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(4) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(4n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 749px) {
  .users-container > .content .users-box .users-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(1n) {
    display: inline-block;
    width: 160px;
    height: max-content;
    margin-right: calc((100% - 160px * 3) / (3 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(2n) {
    display: inline-block;
    width: 160px;
    height: max-content;
    margin-right: calc((100% - 160px * 3) / (3 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(3n) {
    display: inline-block;
    width: 160px;
    height: max-content;
    margin-right: calc((100% - 160px * 3) / (3 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-wide > div:nth-child(3n) {
    margin-right: 0;
  }
}
.users-container > .content .users-box .users-collapse {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.users-container > .content .users-box .users-collapse > div:nth-child(1n) {
  display: inline-block;
  width: 180px;
  height: max-content;
  margin-right: calc((100% - 180px * 4) / (4 - 1) - 1px);
  margin-top: 115px;
}
.users-container > .content .users-box .users-collapse > div:nth-child(1) {
  margin-top: 0;
}
.users-container > .content .users-box .users-collapse > div:nth-child(2n) {
  display: inline-block;
  width: 180px;
  height: max-content;
  margin-right: calc((100% - 180px * 4) / (4 - 1) - 1px);
  margin-top: 115px;
}
.users-container > .content .users-box .users-collapse > div:nth-child(2) {
  margin-top: 0;
}
.users-container > .content .users-box .users-collapse > div:nth-child(3n) {
  display: inline-block;
  width: 180px;
  height: max-content;
  margin-right: calc((100% - 180px * 4) / (4 - 1) - 1px);
  margin-top: 115px;
}
.users-container > .content .users-box .users-collapse > div:nth-child(3) {
  margin-top: 0;
}
.users-container > .content .users-box .users-collapse > div:nth-child(4n) {
  display: inline-block;
  width: 180px;
  height: max-content;
  margin-right: calc((100% - 180px * 4) / (4 - 1) - 1px);
  margin-top: 115px;
}
.users-container > .content .users-box .users-collapse > div:nth-child(4) {
  margin-top: 0;
}
.users-container > .content .users-box .users-collapse > div:nth-child(4n) {
  margin-right: 0;
}
@media only screen and (max-width: 1099px) {
  .users-container > .content .users-box .users-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(1n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 4) / (4 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(2n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 4) / (4 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(3n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 4) / (4 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(4n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 4) / (4 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(4) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(4n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 999px) {
  .users-container > .content .users-box .users-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(1n) {
    display: inline-block;
    width: 180px;
    height: max-content;
    margin-right: calc((100% - 180px * 3) / (3 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(2n) {
    display: inline-block;
    width: 180px;
    height: max-content;
    margin-right: calc((100% - 180px * 3) / (3 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(3n) {
    display: inline-block;
    width: 180px;
    height: max-content;
    margin-right: calc((100% - 180px * 3) / (3 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .users-container > .content .users-box .users-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(1n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 3) / (3 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(2n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 3) / (3 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(3n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 3) / (3 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 749px) {
  .users-container > .content .users-box .users-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(1n) {
    display: inline-block;
    width: 160px;
    height: max-content;
    margin-right: calc((100% - 160px * 2) / (2 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(2n) {
    display: inline-block;
    width: 160px;
    height: max-content;
    margin-right: calc((100% - 160px * 2) / (2 - 1) - 1px);
    margin-top: 115px;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .users-container > .content .users-box .users-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}

.user-edit-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .user-edit-container {
    padding: 0px 20px;
  }
}
.user-edit-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .user-edit-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .user-edit-container .header .title-box {
    margin-bottom: 20px;
  }
}
.user-edit-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.user-edit-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .user-edit-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .user-edit-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .user-edit-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.user-edit-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .user-edit-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .user-edit-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .user-edit-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.user-edit-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .user-edit-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .user-edit-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .user-edit-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.user-edit-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.user-edit-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .user-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .user-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .user-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.user-edit-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.user-edit-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .user-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .user-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.user-edit-container > .footer {
  margin: 30px 0 0 0;
}
.user-edit-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.user-edit-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.suppliers-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .suppliers-container {
    padding: 0px 20px;
  }
}
.suppliers-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .suppliers-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .suppliers-container .header .title-box {
    margin-bottom: 20px;
  }
}
.suppliers-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.suppliers-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .suppliers-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .suppliers-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .suppliers-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.suppliers-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .suppliers-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .suppliers-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .suppliers-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.suppliers-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .suppliers-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .suppliers-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .suppliers-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.suppliers-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.suppliers-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .suppliers-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .suppliers-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .suppliers-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.suppliers-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.suppliers-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .suppliers-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .suppliers-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.suppliers-container > .content {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  padding: 0 0 40px 0;
}
.suppliers-container > .content > div:nth-child(1n) {
  display: inline-block;
  width: 200px;
  height: max-content;
  margin-right: calc((100% - 200px * 5) / (5 - 1) - 1px);
  margin-top: 115px;
}
.suppliers-container > .content > div:nth-child(1) {
  margin-top: 0;
}
.suppliers-container > .content > div:nth-child(2n) {
  display: inline-block;
  width: 200px;
  height: max-content;
  margin-right: calc((100% - 200px * 5) / (5 - 1) - 1px);
  margin-top: 115px;
}
.suppliers-container > .content > div:nth-child(2) {
  margin-top: 0;
}
.suppliers-container > .content > div:nth-child(3n) {
  display: inline-block;
  width: 200px;
  height: max-content;
  margin-right: calc((100% - 200px * 5) / (5 - 1) - 1px);
  margin-top: 115px;
}
.suppliers-container > .content > div:nth-child(3) {
  margin-top: 0;
}
.suppliers-container > .content > div:nth-child(4n) {
  display: inline-block;
  width: 200px;
  height: max-content;
  margin-right: calc((100% - 200px * 5) / (5 - 1) - 1px);
  margin-top: 115px;
}
.suppliers-container > .content > div:nth-child(4) {
  margin-top: 0;
}
.suppliers-container > .content > div:nth-child(5n) {
  display: inline-block;
  width: 200px;
  height: max-content;
  margin-right: calc((100% - 200px * 5) / (5 - 1) - 1px);
  margin-top: 115px;
}
.suppliers-container > .content > div:nth-child(5) {
  margin-top: 0;
}
.suppliers-container > .content > div:nth-child(5n) {
  margin-right: 0;
}
.suppliers-container > .content .user-card {
  position: relative;
  display: flex;
}
.suppliers-container > .content .user-card > img {
  width: 100%;
  border-radius: 50%;
  border: 1px solid #c5c5c5;
  cursor: pointer;
}
.suppliers-container > .content .user-card > img:hover {
  opacity: 0.8;
}
.suppliers-container > .content .user-card > .data {
  position: absolute;
  width: 100%;
}
.suppliers-container > .content .user-card > .data > .name {
  margin: 15px 0 0 0;
  text-align: center;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
}
.suppliers-container > .content .user-card > .data > .title {
  margin: 8px 0 0 0;
  text-align: center;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
}
.suppliers-container > .content .user-card > .data > .mail {
  margin: 7px 0 0 0;
  text-align: center;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.suppliers-container > .content .user-card:hover > .settings {
  display: flex;
}
.suppliers-container > .content .user-card > .settings {
  top: -15px;
  right: -15px;
  position: absolute;
  display: none;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0px 5px;
  border-radius: 5px;
  z-index: 1;
  cursor: initial;
}
.suppliers-container > .content .user-card > .settings > .edit {
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/edit_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.suppliers-container > .content .user-card > .settings > .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.suppliers-container > .content .user-card-inactive > img {
  opacity: 0.5;
}

.offers-agreemnts-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .offers-agreemnts-container {
    padding: 0px 20px;
  }
}
.offers-agreemnts-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .offers-agreemnts-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .offers-agreemnts-container .header .title-box {
    margin-bottom: 20px;
  }
}
.offers-agreemnts-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.offers-agreemnts-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .offers-agreemnts-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-agreemnts-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-agreemnts-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.offers-agreemnts-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .offers-agreemnts-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-agreemnts-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-agreemnts-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.offers-agreemnts-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .offers-agreemnts-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-agreemnts-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-agreemnts-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.offers-agreemnts-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.offers-agreemnts-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .offers-agreemnts-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-agreemnts-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-agreemnts-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.offers-agreemnts-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.offers-agreemnts-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .offers-agreemnts-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .offers-agreemnts-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.offers-agreemnts-container > .content {
  display: flex;
}
.offers-agreemnts-container > .content .filter_box {
  width: 300px;
  margin-right: 50px;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal {
  width: 100%;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_header {
  display: none;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content {
  width: 100%;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword {
  margin-top: 3px;
  display: flex;
  height: 48px;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  border: none;
  border-radius: 26px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_0_icon.svg") no-repeat center;
  background-size: 17px auto;
  margin: 0 15px 0 0;
  cursor: pointer;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon:hover {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_1_icon.svg") no-repeat center;
  background-size: 17px auto;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input {
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 1.3rem;
  border: none;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input::placeholder {
  line-height: 1.3rem !important;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_0_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
  border-bottom: 1px solid #c5c5c5;
  padding: 20px 0;
  cursor: pointer;
}
@media only screen and (max-width: 1339px) {
  .offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.0625rem;
  }
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header:hover {
  color: #0047ba;
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_2_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options {
  display: none;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar {
  width: 6px;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option {
  padding: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox {
  margin: 0;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label:hover {
  color: #0047ba;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  width: 78px;
  height: 14px;
  background: url("/assets/images/icons/stars_empty_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars > .stars-positive {
  position: absolute;
  width: 0%;
  height: 14px;
  background: url("/assets/images/icons/stars_full_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .counter {
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option:first-of-type {
  padding: 0;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion:first-of-type > .criterion-header {
  padding-top: 0;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_1_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  border: none;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header:hover {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_3_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-options {
  display: block;
  max-height: 190px;
  overflow-y: auto;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset {
  margin-top: 10px;
  text-align: right;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn {
  display: inline-block;
  padding-top: 3px;
  margin-right: 7px;
  padding-right: 7px;
  border-right: 2px solid #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn:hover {
  opacity: 0.8;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn {
  display: inline-block;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn:hover {
  opacity: 0.8;
}
.offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_footer {
  display: none;
}
@media only screen and (max-width: 999px) {
  .offers-agreemnts-container > .content .filter_box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(236, 241, 250, 0.7);
  }
  .offers-agreemnts-container > .content .filter_box .filter_box_modal {
    max-width: 600px;
    min-width: 500px;
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 30px);
    min-height: 150px;
    background-color: #fff;
    padding: 0 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  }
  .offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_header {
    display: block;
    font-family: "ADNOC Sans Bold";
    color: #0047ba;
    font-size: 1rem;
    padding: 20px 0 15px 0;
    border-bottom: 1px solid #c5c5c5;
  }
  .offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content {
    margin: 30px 0;
    overflow-x: auto;
    padding-right: 10px;
    padding-left: 3px;
    max-height: calc(50vh - 50px);
  }
  .offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
    width: 6px;
  }
  .offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
    background: #f8f8f8;
  }
  .offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
    background: #c0cace;
    border-radius: 5px;
    cursor: pointer;
  }
  .offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
    background: #0047ba;
  }
  .offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .criterion-options {
    display: block;
    max-height: 1000px !important;
    overflow-y: auto;
  }
  .offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset {
    display: none;
  }
  .offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_footer {
    display: block;
    padding: 0 0 20px 0;
    display: flex;
    justify-content: flex-end;
  }
  .offers-agreemnts-container > .content .filter_box .filter_box_modal .filter_box_modal_footer > * {
    margin: 0 0 0 10px;
  }
}
.offers-agreemnts-container > .content .filter_box-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(236, 241, 250, 0.7);
}
.offers-agreemnts-container > .content .filter_box-modal .filter_box_modal {
  max-width: 600px;
  min-width: 500px;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 30px);
  min-height: 150px;
  background-color: #fff;
  padding: 0 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}
.offers-agreemnts-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_header {
  display: block;
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  padding: 20px 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.offers-agreemnts-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content {
  margin: 30px 0;
  overflow-x: auto;
  padding-right: 10px;
  padding-left: 3px;
  max-height: calc(50vh - 50px);
}
.offers-agreemnts-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
  width: 6px;
}
.offers-agreemnts-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.offers-agreemnts-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.offers-agreemnts-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.offers-agreemnts-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content .reset {
  display: none;
}
.offers-agreemnts-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_footer {
  display: block;
  padding: 0 0 20px 0;
  display: flex;
  justify-content: flex-end;
}
.offers-agreemnts-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_footer > * {
  margin: 0 0 0 10px;
}
.offers-agreemnts-container > .content .agreements-box {
  width: 100%;
}
.offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card {
  display: flex;
}
.offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content {
  cursor: pointer;
  position: relative;
  background-color: #f7fbfc;
  border: 1px solid #c5c5c5;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  width: calc(100% - 30px);
  padding: 15px;
  height: 165px;
}
.offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content:hover {
  border-color: #0047ba;
}
.offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content > .id {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1.5rem;
  margin: 0 0 15px 0;
}
@media only screen and (max-width: 1339px) {
  .offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content > .id {
    font-size: 1.425rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content > .id {
    font-size: 1.35rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content > .id {
    font-size: 1.275rem;
  }
}
.offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content > .date {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  line-height: 1.2rem;
  margin: 5px 0 0 0;
}
.offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content > .details {
  margin: 20px 0 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 0.875rem;
}
.offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content > .details > * {
  margin: 5px 0 0 0;
}
.offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content > .status {
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: #0047ba;
  border-radius: 3px;
  padding: 5px 10px 5px 10px;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1rem;
}
.offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content > .status-draft {
  background-color: #8b8b8b;
}
.offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content > .status-review {
  background-color: #5ea8ff;
}
.offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content > .status-approved {
  background-color: #57d26b;
}
.offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content > .status-rejected {
  background-color: #ff5f69;
}
.offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content > .status-pendingApproval {
  background-color: #ffaf70;
}
.offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content > .status-expired {
  background-color: #c2c2c2;
}
.offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content > .status-blocked {
  background-color: #ff5f69;
}
.offers-agreemnts-container > .content .agreements-box .agreements .offer-agreement-card > .card-content > .status-cancelled {
  background-color: #ff5f69;
}
.offers-agreemnts-container > .content .agreements-box .agreements-wide {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 50px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(1) {
  margin-top: 0;
}
.offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 50px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(2) {
  margin-top: 0;
}
.offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 50px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(3) {
  margin-top: 0;
}
.offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(4n) {
  display: inline-block;
  width: calc((100% - 50px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(4) {
  margin-top: 0;
}
.offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(4n) {
  margin-right: 0;
}
@media only screen and (max-width: 1199px) {
  .offers-agreemnts-container > .content .agreements-box .agreements-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (4 - 1)) / 4 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (4 - 1)) / 4 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 30px * (4 - 1)) / 4 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(4n) {
    display: inline-block;
    width: calc((100% - 30px * (4 - 1)) / 4 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(4) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(4n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-agreemnts-container > .content .agreements-box .agreements-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .offers-agreemnts-container > .content .agreements-box .agreements-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 799px) {
  .offers-agreemnts-container > .content .agreements-box .agreements-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .offers-agreemnts-container > .content .agreements-box .agreements-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-wide > div:nth-child(2n) {
    margin-right: 0;
  }
}
.offers-agreemnts-container > .content .agreements-box .agreements-collapse {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(1) {
  margin-top: 0;
}
.offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(2) {
  margin-top: 0;
}
.offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(3) {
  margin-top: 0;
}
.offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(3n) {
  margin-right: 0;
}
@media only screen and (max-width: 1249px) {
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1149px) {
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 999px) {
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 799px) {
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .offers-agreemnts-container > .content .agreements-box .agreements-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}

.surveys-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .surveys-container {
    padding: 0px 20px;
  }
}
.surveys-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .surveys-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .surveys-container .header .title-box {
    margin-bottom: 20px;
  }
}
.surveys-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.surveys-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .surveys-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .surveys-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .surveys-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.surveys-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .surveys-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .surveys-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .surveys-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.surveys-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .surveys-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .surveys-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .surveys-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.surveys-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.surveys-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .surveys-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .surveys-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .surveys-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.surveys-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.surveys-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .surveys-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .surveys-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.surveys-container > .content {
  display: flex;
}
.surveys-container > .content .filter_box {
  width: 300px;
  margin-right: 50px;
}
.surveys-container > .content .filter_box .filter_box_modal {
  width: 100%;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_header {
  display: none;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content {
  width: 100%;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword {
  margin-top: 3px;
  display: flex;
  height: 48px;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  border: none;
  border-radius: 26px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_0_icon.svg") no-repeat center;
  background-size: 17px auto;
  margin: 0 15px 0 0;
  cursor: pointer;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon:hover {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_1_icon.svg") no-repeat center;
  background-size: 17px auto;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input {
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 1.3rem;
  border: none;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input::placeholder {
  line-height: 1.3rem !important;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_0_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
  border-bottom: 1px solid #c5c5c5;
  padding: 20px 0;
  cursor: pointer;
}
@media only screen and (max-width: 1339px) {
  .surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.0625rem;
  }
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header:hover {
  color: #0047ba;
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_2_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options {
  display: none;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar {
  width: 6px;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option {
  padding: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox {
  margin: 0;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label:hover {
  color: #0047ba;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  width: 78px;
  height: 14px;
  background: url("/assets/images/icons/stars_empty_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars > .stars-positive {
  position: absolute;
  width: 0%;
  height: 14px;
  background: url("/assets/images/icons/stars_full_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .counter {
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option:first-of-type {
  padding: 0;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion:first-of-type > .criterion-header {
  padding-top: 0;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_1_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  border: none;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header:hover {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_3_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-options {
  display: block;
  max-height: 190px;
  overflow-y: auto;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset {
  margin-top: 10px;
  text-align: right;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn {
  display: inline-block;
  padding-top: 3px;
  margin-right: 7px;
  padding-right: 7px;
  border-right: 2px solid #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn:hover {
  opacity: 0.8;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn {
  display: inline-block;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn:hover {
  opacity: 0.8;
}
.surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_footer {
  display: none;
}
@media only screen and (max-width: 999px) {
  .surveys-container > .content .filter_box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(236, 241, 250, 0.7);
  }
  .surveys-container > .content .filter_box .filter_box_modal {
    max-width: 600px;
    min-width: 500px;
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 30px);
    min-height: 150px;
    background-color: #fff;
    padding: 0 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  }
  .surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_header {
    display: block;
    font-family: "ADNOC Sans Bold";
    color: #0047ba;
    font-size: 1rem;
    padding: 20px 0 15px 0;
    border-bottom: 1px solid #c5c5c5;
  }
  .surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content {
    margin: 30px 0;
    overflow-x: auto;
    padding-right: 10px;
    padding-left: 3px;
    max-height: calc(50vh - 50px);
  }
  .surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
    width: 6px;
  }
  .surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
    background: #f8f8f8;
  }
  .surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
    background: #c0cace;
    border-radius: 5px;
    cursor: pointer;
  }
  .surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
    background: #0047ba;
  }
  .surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .criterion-options {
    display: block;
    max-height: 1000px !important;
    overflow-y: auto;
  }
  .surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset {
    display: none;
  }
  .surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_footer {
    display: block;
    padding: 0 0 20px 0;
    display: flex;
    justify-content: flex-end;
  }
  .surveys-container > .content .filter_box .filter_box_modal .filter_box_modal_footer > * {
    margin: 0 0 0 10px;
  }
}
.surveys-container > .content .filter_box-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(236, 241, 250, 0.7);
}
.surveys-container > .content .filter_box-modal .filter_box_modal {
  max-width: 600px;
  min-width: 500px;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 30px);
  min-height: 150px;
  background-color: #fff;
  padding: 0 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}
.surveys-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_header {
  display: block;
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  padding: 20px 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.surveys-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content {
  margin: 30px 0;
  overflow-x: auto;
  padding-right: 10px;
  padding-left: 3px;
  max-height: calc(50vh - 50px);
}
.surveys-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
  width: 6px;
}
.surveys-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.surveys-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.surveys-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.surveys-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content .reset {
  display: none;
}
.surveys-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_footer {
  display: block;
  padding: 0 0 20px 0;
  display: flex;
  justify-content: flex-end;
}
.surveys-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_footer > * {
  margin: 0 0 0 10px;
}
.surveys-container > .content .surveys-box {
  width: 100%;
}
.surveys-container > .content .surveys-box .surveys .survey-card {
  display: flex;
}
.surveys-container > .content .surveys-box .surveys .survey-card > .card-content {
  cursor: pointer;
  position: relative;
  background-color: #f7fbfc;
  border: 1px solid #c5c5c5;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  width: calc(100% - 30px);
  padding: 15px;
  height: 145px;
}
.surveys-container > .content .surveys-box .surveys .survey-card > .card-content:hover {
  border-color: #0047ba;
}
.surveys-container > .content .surveys-box .surveys .survey-card > .card-content > .id {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1.5rem;
  margin: 0 0 15px 0;
}
@media only screen and (max-width: 1339px) {
  .surveys-container > .content .surveys-box .surveys .survey-card > .card-content > .id {
    font-size: 1.425rem;
  }
}
@media only screen and (max-width: 1049px) {
  .surveys-container > .content .surveys-box .surveys .survey-card > .card-content > .id {
    font-size: 1.35rem;
  }
}
@media only screen and (max-width: 849px) {
  .surveys-container > .content .surveys-box .surveys .survey-card > .card-content > .id {
    font-size: 1.275rem;
  }
}
.surveys-container > .content .surveys-box .surveys .survey-card > .card-content > .date {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  line-height: 1.2rem;
  margin: 5px 0 0 0;
  word-break: break-word;
}
.surveys-container > .content .surveys-box .surveys .survey-card > .card-content > .details {
  margin: 20px 0 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 0.875rem;
  top: 60%;
  position: absolute;
}
.surveys-container > .content .surveys-box .surveys .survey-card > .card-content > .details > * {
  margin: 5px 0 0 0;
}
.surveys-container > .content .surveys-box .surveys .survey-card > .card-content > .status {
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: #0047ba;
  border-radius: 3px;
  padding: 5px 10px 5px 10px;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1rem;
}
.surveys-container > .content .surveys-box .surveys .survey-card > .card-content > .status-draft {
  background-color: #8b8b8b;
}
.surveys-container > .content .surveys-box .surveys .survey-card > .card-content > .status-published {
  background-color: #57d26b;
}
.surveys-container > .content .surveys-box .surveys .survey-card > .card-content > .status-responses {
  background-color: #0073f9;
}
.surveys-container > .content .surveys-box .surveys .survey-card > .card-content > .status-expired {
  background-color: #c2c2c2;
}
.surveys-container > .content .surveys-box .surveys-wide {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.surveys-container > .content .surveys-box .surveys-wide > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 50px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.surveys-container > .content .surveys-box .surveys-wide > div:nth-child(1) {
  margin-top: 0;
}
.surveys-container > .content .surveys-box .surveys-wide > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 50px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.surveys-container > .content .surveys-box .surveys-wide > div:nth-child(2) {
  margin-top: 0;
}
.surveys-container > .content .surveys-box .surveys-wide > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 50px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.surveys-container > .content .surveys-box .surveys-wide > div:nth-child(3) {
  margin-top: 0;
}
.surveys-container > .content .surveys-box .surveys-wide > div:nth-child(4n) {
  display: inline-block;
  width: calc((100% - 50px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.surveys-container > .content .surveys-box .surveys-wide > div:nth-child(4) {
  margin-top: 0;
}
.surveys-container > .content .surveys-box .surveys-wide > div:nth-child(4n) {
  margin-right: 0;
}
@media only screen and (max-width: 1199px) {
  .surveys-container > .content .surveys-box .surveys-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (4 - 1)) / 4 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (4 - 1)) / 4 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 30px * (4 - 1)) / 4 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(4n) {
    display: inline-block;
    width: calc((100% - 30px * (4 - 1)) / 4 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(4) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(4n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1049px) {
  .surveys-container > .content .surveys-box .surveys-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .surveys-container > .content .surveys-box .surveys-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 799px) {
  .surveys-container > .content .surveys-box .surveys-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .surveys-container > .content .surveys-box .surveys-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-wide > div:nth-child(2n) {
    margin-right: 0;
  }
}
.surveys-container > .content .surveys-box .surveys-collapse {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(1) {
  margin-top: 0;
}
.surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(2) {
  margin-top: 0;
}
.surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(3) {
  margin-top: 0;
}
.surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(3n) {
  margin-right: 0;
}
@media only screen and (max-width: 1249px) {
  .surveys-container > .content .surveys-box .surveys-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1149px) {
  .surveys-container > .content .surveys-box .surveys-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 999px) {
  .surveys-container > .content .surveys-box .surveys-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .surveys-container > .content .surveys-box .surveys-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 799px) {
  .surveys-container > .content .surveys-box .surveys-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .surveys-container > .content .surveys-box .surveys-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .surveys-container > .content .surveys-box .surveys-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}

.roadshow-planning-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .roadshow-planning-container {
    padding: 0px 20px;
  }
}
.roadshow-planning-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .roadshow-planning-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .roadshow-planning-container .header .title-box {
    margin-bottom: 20px;
  }
}
.roadshow-planning-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.roadshow-planning-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .roadshow-planning-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-planning-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-planning-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.roadshow-planning-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-planning-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-planning-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-planning-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.roadshow-planning-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-planning-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-planning-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-planning-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.roadshow-planning-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.roadshow-planning-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-planning-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-planning-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-planning-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.roadshow-planning-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.roadshow-planning-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .roadshow-planning-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .roadshow-planning-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.roadshow-planning-container > .content {
  display: flex;
}
.roadshow-planning-container > .content .filter_box {
  width: 300px;
  margin-right: 50px;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal {
  width: 100%;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_header {
  display: none;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content {
  width: 100%;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword {
  margin-top: 3px;
  display: flex;
  height: 48px;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  border: none;
  border-radius: 26px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_0_icon.svg") no-repeat center;
  background-size: 17px auto;
  margin: 0 15px 0 0;
  cursor: pointer;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon:hover {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_1_icon.svg") no-repeat center;
  background-size: 17px auto;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input {
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 1.3rem;
  border: none;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input::placeholder {
  line-height: 1.3rem !important;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_0_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
  border-bottom: 1px solid #c5c5c5;
  padding: 20px 0;
  cursor: pointer;
}
@media only screen and (max-width: 1339px) {
  .roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.0625rem;
  }
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header:hover {
  color: #0047ba;
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_2_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options {
  display: none;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar {
  width: 6px;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option {
  padding: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox {
  margin: 0;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label:hover {
  color: #0047ba;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  width: 78px;
  height: 14px;
  background: url("/assets/images/icons/stars_empty_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars > .stars-positive {
  position: absolute;
  width: 0%;
  height: 14px;
  background: url("/assets/images/icons/stars_full_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .counter {
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option:first-of-type {
  padding: 0;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion:first-of-type > .criterion-header {
  padding-top: 0;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_1_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  border: none;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header:hover {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_3_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-options {
  display: block;
  max-height: 190px;
  overflow-y: auto;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset {
  margin-top: 10px;
  text-align: right;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn {
  display: inline-block;
  padding-top: 3px;
  margin-right: 7px;
  padding-right: 7px;
  border-right: 2px solid #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn:hover {
  opacity: 0.8;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn {
  display: inline-block;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn:hover {
  opacity: 0.8;
}
.roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_footer {
  display: none;
}
@media only screen and (max-width: 999px) {
  .roadshow-planning-container > .content .filter_box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(236, 241, 250, 0.7);
  }
  .roadshow-planning-container > .content .filter_box .filter_box_modal {
    max-width: 600px;
    min-width: 500px;
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 30px);
    min-height: 150px;
    background-color: #fff;
    padding: 0 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  }
  .roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_header {
    display: block;
    font-family: "ADNOC Sans Bold";
    color: #0047ba;
    font-size: 1rem;
    padding: 20px 0 15px 0;
    border-bottom: 1px solid #c5c5c5;
  }
  .roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content {
    margin: 30px 0;
    overflow-x: auto;
    padding-right: 10px;
    padding-left: 3px;
    max-height: calc(50vh - 50px);
  }
  .roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
    width: 6px;
  }
  .roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
    background: #f8f8f8;
  }
  .roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
    background: #c0cace;
    border-radius: 5px;
    cursor: pointer;
  }
  .roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
    background: #0047ba;
  }
  .roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .criterion-options {
    display: block;
    max-height: 1000px !important;
    overflow-y: auto;
  }
  .roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset {
    display: none;
  }
  .roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_footer {
    display: block;
    padding: 0 0 20px 0;
    display: flex;
    justify-content: flex-end;
  }
  .roadshow-planning-container > .content .filter_box .filter_box_modal .filter_box_modal_footer > * {
    margin: 0 0 0 10px;
  }
}
.roadshow-planning-container > .content .filter_box-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(236, 241, 250, 0.7);
}
.roadshow-planning-container > .content .filter_box-modal .filter_box_modal {
  max-width: 600px;
  min-width: 500px;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 30px);
  min-height: 150px;
  background-color: #fff;
  padding: 0 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}
.roadshow-planning-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_header {
  display: block;
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  padding: 20px 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.roadshow-planning-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content {
  margin: 30px 0;
  overflow-x: auto;
  padding-right: 10px;
  padding-left: 3px;
  max-height: calc(50vh - 50px);
}
.roadshow-planning-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
  width: 6px;
}
.roadshow-planning-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.roadshow-planning-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.roadshow-planning-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.roadshow-planning-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content .reset {
  display: none;
}
.roadshow-planning-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_footer {
  display: block;
  padding: 0 0 20px 0;
  display: flex;
  justify-content: flex-end;
}
.roadshow-planning-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_footer > * {
  margin: 0 0 0 10px;
}
.roadshow-planning-container > .content .roadshows-box {
  width: 100%;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card {
  display: flex;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content {
  cursor: pointer;
  position: relative;
  background-color: #fff;
  border: 1px solid #c5c5c5;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  width: calc(100% - 30px);
  padding: 15px;
  height: 7rem;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content:hover {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.12);
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .title {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1.3rem;
  line-height: 1.4rem;
  margin-top: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  word-break: break-word;
}
@media only screen and (max-width: 1339px) {
  .roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .title {
    font-size: 1.235rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .title {
    font-size: 1.17rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .title {
    font-size: 1.105rem;
  }
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .date {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 0.9rem;
  margin-top: 10px;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .locations {
  display: flex;
  align-items: baseline;
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 0.9rem;
  margin-top: 5px;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .locations > .location {
  margin-right: 5px;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .locations > .location.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .locations > .more {
  font-family: "ADNOC Sans Bold";
  color: #6e6e6e;
  font-size: 0.8rem;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .invitations {
  margin: 15px 0 0 0;
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.875rem;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .invitations > .invited {
  margin: 0 0 6px 0;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .invitations > .review-renegotiation {
  margin: 0 0 6px 0;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .invitations > .review-renegotiation > .review {
  display: inline-block;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .invitations > .review-renegotiation > .renegotiation {
  display: inline-block;
  margin: 0 0 0 15px;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .invitations > .approved-rejected > .approved {
  display: inline-block;
  color: #0047ba;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .invitations > .approved-rejected > .rejected {
  margin: 0 0 0 15px;
  display: inline-block;
  color: #f55757;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .offers {
  margin: 15px 0 0 0;
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.875rem;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .offers > .count {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 0.875rem;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .status {
  position: absolute;
  top: -1px;
  left: -1px;
  padding: 8px 15px 5px 15px;
  background-color: #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 0.875rem;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 8px;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .status-draft {
  background-color: #98a6b9;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .status-submitted {
  background-color: #0047ba;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .status-approved {
  background-color: #42ad53;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .status-confirmed {
  background-color: #42ad53;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .status-published {
  background-color: #42ad53;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .status-rejected {
  background-color: #f55757;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .status-approved {
  background-color: #42ad53;
}
.roadshow-planning-container > .content .roadshows-box .roadshows .roadshow-planning-card > .card-content > .status-expired {
  background-color: #6e6e6e;
}
.roadshow-planning-container > .content .roadshows-box .roadshows-wide {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 50px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(1) {
  margin-top: 0;
}
.roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 50px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(2) {
  margin-top: 0;
}
.roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 50px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(3) {
  margin-top: 0;
}
.roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(4n) {
  display: inline-block;
  width: calc((100% - 50px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(4) {
  margin-top: 0;
}
.roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(4n) {
  margin-right: 0;
}
@media only screen and (max-width: 1199px) {
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (4 - 1)) / 4 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (4 - 1)) / 4 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 30px * (4 - 1)) / 4 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(4n) {
    display: inline-block;
    width: calc((100% - 30px * (4 - 1)) / 4 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(4) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(4n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 799px) {
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-wide > div:nth-child(2n) {
    margin-right: 0;
  }
}
.roadshow-planning-container > .content .roadshows-box .roadshows-collapse {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(1) {
  margin-top: 0;
}
.roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(2) {
  margin-top: 0;
}
.roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(3) {
  margin-top: 0;
}
.roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(3n) {
  margin-right: 0;
}
@media only screen and (max-width: 1249px) {
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1149px) {
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 999px) {
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 799px) {
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-planning-container > .content .roadshows-box .roadshows-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}

.roadshow-proposals-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .roadshow-proposals-container {
    padding: 0px 20px;
  }
}
.roadshow-proposals-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .roadshow-proposals-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .roadshow-proposals-container .header .title-box {
    margin-bottom: 20px;
  }
}
.roadshow-proposals-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.roadshow-proposals-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .roadshow-proposals-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-proposals-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-proposals-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.roadshow-proposals-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-proposals-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-proposals-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-proposals-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.roadshow-proposals-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-proposals-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-proposals-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-proposals-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.roadshow-proposals-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.roadshow-proposals-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-proposals-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-proposals-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-proposals-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.roadshow-proposals-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.roadshow-proposals-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .roadshow-proposals-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .roadshow-proposals-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.roadshow-proposals-container > .content {
  display: flex;
}
.roadshow-proposals-container > .content .filter_box {
  width: 300px;
  margin-right: 50px;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal {
  width: 100%;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_header {
  display: none;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content {
  width: 100%;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword {
  margin-top: 3px;
  display: flex;
  height: 48px;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  border: none;
  border-radius: 26px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_0_icon.svg") no-repeat center;
  background-size: 17px auto;
  margin: 0 15px 0 0;
  cursor: pointer;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon:hover {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_1_icon.svg") no-repeat center;
  background-size: 17px auto;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input {
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 1.3rem;
  border: none;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input::placeholder {
  line-height: 1.3rem !important;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_0_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
  border-bottom: 1px solid #c5c5c5;
  padding: 20px 0;
  cursor: pointer;
}
@media only screen and (max-width: 1339px) {
  .roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.0625rem;
  }
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header:hover {
  color: #0047ba;
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_2_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options {
  display: none;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar {
  width: 6px;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option {
  padding: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox {
  margin: 0;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label:hover {
  color: #0047ba;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  width: 78px;
  height: 14px;
  background: url("/assets/images/icons/stars_empty_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars > .stars-positive {
  position: absolute;
  width: 0%;
  height: 14px;
  background: url("/assets/images/icons/stars_full_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .counter {
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option:first-of-type {
  padding: 0;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion:first-of-type > .criterion-header {
  padding-top: 0;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_1_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  border: none;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header:hover {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_3_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-options {
  display: block;
  max-height: 190px;
  overflow-y: auto;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset {
  margin-top: 10px;
  text-align: right;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn {
  display: inline-block;
  padding-top: 3px;
  margin-right: 7px;
  padding-right: 7px;
  border-right: 2px solid #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn:hover {
  opacity: 0.8;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn {
  display: inline-block;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn:hover {
  opacity: 0.8;
}
.roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_footer {
  display: none;
}
@media only screen and (max-width: 999px) {
  .roadshow-proposals-container > .content .filter_box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(236, 241, 250, 0.7);
  }
  .roadshow-proposals-container > .content .filter_box .filter_box_modal {
    max-width: 600px;
    min-width: 500px;
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 30px);
    min-height: 150px;
    background-color: #fff;
    padding: 0 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  }
  .roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_header {
    display: block;
    font-family: "ADNOC Sans Bold";
    color: #0047ba;
    font-size: 1rem;
    padding: 20px 0 15px 0;
    border-bottom: 1px solid #c5c5c5;
  }
  .roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content {
    margin: 30px 0;
    overflow-x: auto;
    padding-right: 10px;
    padding-left: 3px;
    max-height: calc(50vh - 50px);
  }
  .roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
    width: 6px;
  }
  .roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
    background: #f8f8f8;
  }
  .roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
    background: #c0cace;
    border-radius: 5px;
    cursor: pointer;
  }
  .roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
    background: #0047ba;
  }
  .roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .criterion-options {
    display: block;
    max-height: 1000px !important;
    overflow-y: auto;
  }
  .roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset {
    display: none;
  }
  .roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_footer {
    display: block;
    padding: 0 0 20px 0;
    display: flex;
    justify-content: flex-end;
  }
  .roadshow-proposals-container > .content .filter_box .filter_box_modal .filter_box_modal_footer > * {
    margin: 0 0 0 10px;
  }
}
.roadshow-proposals-container > .content .filter_box-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(236, 241, 250, 0.7);
}
.roadshow-proposals-container > .content .filter_box-modal .filter_box_modal {
  max-width: 600px;
  min-width: 500px;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 30px);
  min-height: 150px;
  background-color: #fff;
  padding: 0 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}
.roadshow-proposals-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_header {
  display: block;
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  padding: 20px 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.roadshow-proposals-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content {
  margin: 30px 0;
  overflow-x: auto;
  padding-right: 10px;
  padding-left: 3px;
  max-height: calc(50vh - 50px);
}
.roadshow-proposals-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
  width: 6px;
}
.roadshow-proposals-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.roadshow-proposals-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.roadshow-proposals-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.roadshow-proposals-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content .reset {
  display: none;
}
.roadshow-proposals-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_footer {
  display: block;
  padding: 0 0 20px 0;
  display: flex;
  justify-content: flex-end;
}
.roadshow-proposals-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_footer > * {
  margin: 0 0 0 10px;
}
.roadshow-proposals-container > .content .proposals-box {
  width: 100%;
}
.roadshow-proposals-container > .content .proposals-box .proposals .roadshow-proposal-card {
  display: flex;
}
.roadshow-proposals-container > .content .proposals-box .proposals .roadshow-proposal-card > .card-content {
  cursor: pointer;
  position: relative;
  background-color: #fff;
  border: 1px solid #c5c5c5;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  width: calc(100% - 30px);
  padding: 15px;
}
.roadshow-proposals-container > .content .proposals-box .proposals .roadshow-proposal-card > .card-content:hover {
  border-color: #0047ba;
}
.roadshow-proposals-container > .content .proposals-box .proposals .roadshow-proposal-card > .card-content > .title {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1.3rem;
  line-height: 1.4rem;
  margin-top: 20px;
  height: 40px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .roadshow-proposals-container > .content .proposals-box .proposals .roadshow-proposal-card > .card-content > .title {
    font-size: 1.235rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-proposals-container > .content .proposals-box .proposals .roadshow-proposal-card > .card-content > .title {
    font-size: 1.17rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-proposals-container > .content .proposals-box .proposals .roadshow-proposal-card > .card-content > .title {
    font-size: 1.105rem;
  }
}
.roadshow-proposals-container > .content .proposals-box .proposals .roadshow-proposal-card > .card-content > .date {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 0.9rem;
  margin-top: 10px;
}
.roadshow-proposals-container > .content .proposals-box .proposals .roadshow-proposal-card > .card-content > .id {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 0.9rem;
  margin-top: 5px;
}
.roadshow-proposals-container > .content .proposals-box .proposals .roadshow-proposal-card > .card-content > .offers {
  margin: 15px 0 0 0;
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.875rem;
}
.roadshow-proposals-container > .content .proposals-box .proposals .roadshow-proposal-card > .card-content > .offers > .count {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 0.875rem;
}
.roadshow-proposals-container > .content .proposals-box .proposals .roadshow-proposal-card > .card-content > .status {
  position: absolute;
  top: -1px;
  left: -1px;
  padding: 7px 15px;
  background-color: #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 0.875rem;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 8px;
}
.roadshow-proposals-container > .content .proposals-box .proposals .roadshow-proposal-card > .card-content > .status-draft {
  background-color: #98a6b9;
}
.roadshow-proposals-container > .content .proposals-box .proposals .roadshow-proposal-card > .card-content > .status-active {
  background-color: #0047ba;
}
.roadshow-proposals-container > .content .proposals-box .proposals .roadshow-proposal-card > .card-content > .status-expired {
  background-color: #f55757;
}
.roadshow-proposals-container > .content .proposals-box .proposals .roadshow-proposal-card > .card-content > .status-deactivated {
  background-color: #6b6b6b;
}
.roadshow-proposals-container > .content .proposals-box .proposals-wide {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 50px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(1) {
  margin-top: 0;
}
.roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 50px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(2) {
  margin-top: 0;
}
.roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 50px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(3) {
  margin-top: 0;
}
.roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(4n) {
  display: inline-block;
  width: calc((100% - 50px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(4) {
  margin-top: 0;
}
.roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(4n) {
  margin-right: 0;
}
@media only screen and (max-width: 1199px) {
  .roadshow-proposals-container > .content .proposals-box .proposals-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (4 - 1)) / 4 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (4 - 1)) / 4 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 30px * (4 - 1)) / 4 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(4n) {
    display: inline-block;
    width: calc((100% - 30px * (4 - 1)) / 4 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(4) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(4n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-proposals-container > .content .proposals-box .proposals-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .roadshow-proposals-container > .content .proposals-box .proposals-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 799px) {
  .roadshow-proposals-container > .content .proposals-box .proposals-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .roadshow-proposals-container > .content .proposals-box .proposals-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-wide > div:nth-child(2n) {
    margin-right: 0;
  }
}
.roadshow-proposals-container > .content .proposals-box .proposals-collapse {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(1) {
  margin-top: 0;
}
.roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(2) {
  margin-top: 0;
}
.roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 50px;
  margin-top: 50px;
}
.roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(3) {
  margin-top: 0;
}
.roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(3n) {
  margin-right: 0;
}
@media only screen and (max-width: 1249px) {
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1149px) {
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 999px) {
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 30px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 799px) {
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 30px;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-proposals-container > .content .proposals-box .proposals-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}

.roadshow-offers-supplier-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .roadshow-offers-supplier-container {
    padding: 0px 20px;
  }
}
.roadshow-offers-supplier-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .roadshow-offers-supplier-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .roadshow-offers-supplier-container .header .title-box {
    margin-bottom: 20px;
  }
}
.roadshow-offers-supplier-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.roadshow-offers-supplier-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .roadshow-offers-supplier-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-offers-supplier-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-offers-supplier-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.roadshow-offers-supplier-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-offers-supplier-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-offers-supplier-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-offers-supplier-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.roadshow-offers-supplier-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-offers-supplier-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-offers-supplier-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-offers-supplier-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.roadshow-offers-supplier-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.roadshow-offers-supplier-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .roadshow-offers-supplier-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-offers-supplier-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-offers-supplier-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.roadshow-offers-supplier-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.roadshow-offers-supplier-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .roadshow-offers-supplier-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .roadshow-offers-supplier-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.roadshow-offers-supplier-container > .content {
  display: flex;
}
.roadshow-offers-supplier-container > .content .filter_box {
  width: 300px;
  margin-right: 50px;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal {
  width: 100%;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_header {
  display: none;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content {
  width: 100%;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword {
  margin-top: 3px;
  display: flex;
  height: 48px;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  border: none;
  border-radius: 26px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_0_icon.svg") no-repeat center;
  background-size: 17px auto;
  margin: 0 15px 0 0;
  cursor: pointer;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon:hover {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_1_icon.svg") no-repeat center;
  background-size: 17px auto;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input {
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 1.3rem;
  border: none;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input::placeholder {
  line-height: 1.3rem !important;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_0_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
  border-bottom: 1px solid #c5c5c5;
  padding: 20px 0;
  cursor: pointer;
}
@media only screen and (max-width: 1339px) {
  .roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.0625rem;
  }
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header:hover {
  color: #0047ba;
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_2_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options {
  display: none;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar {
  width: 6px;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option {
  padding: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox {
  margin: 0;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label:hover {
  color: #0047ba;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  width: 78px;
  height: 14px;
  background: url("/assets/images/icons/stars_empty_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars > .stars-positive {
  position: absolute;
  width: 0%;
  height: 14px;
  background: url("/assets/images/icons/stars_full_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .counter {
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option:first-of-type {
  padding: 0;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion:first-of-type > .criterion-header {
  padding-top: 0;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_1_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  border: none;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header:hover {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_3_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-options {
  display: block;
  max-height: 190px;
  overflow-y: auto;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset {
  margin-top: 10px;
  text-align: right;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn {
  display: inline-block;
  padding-top: 3px;
  margin-right: 7px;
  padding-right: 7px;
  border-right: 2px solid #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn:hover {
  opacity: 0.8;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn {
  display: inline-block;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn:hover {
  opacity: 0.8;
}
.roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_footer {
  display: none;
}
@media only screen and (max-width: 999px) {
  .roadshow-offers-supplier-container > .content .filter_box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(236, 241, 250, 0.7);
  }
  .roadshow-offers-supplier-container > .content .filter_box .filter_box_modal {
    max-width: 600px;
    min-width: 500px;
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 30px);
    min-height: 150px;
    background-color: #fff;
    padding: 0 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  }
  .roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_header {
    display: block;
    font-family: "ADNOC Sans Bold";
    color: #0047ba;
    font-size: 1rem;
    padding: 20px 0 15px 0;
    border-bottom: 1px solid #c5c5c5;
  }
  .roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content {
    margin: 30px 0;
    overflow-x: auto;
    padding-right: 10px;
    padding-left: 3px;
    max-height: calc(50vh - 50px);
  }
  .roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
    width: 6px;
  }
  .roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
    background: #f8f8f8;
  }
  .roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
    background: #c0cace;
    border-radius: 5px;
    cursor: pointer;
  }
  .roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
    background: #0047ba;
  }
  .roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .criterion-options {
    display: block;
    max-height: 1000px !important;
    overflow-y: auto;
  }
  .roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset {
    display: none;
  }
  .roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_footer {
    display: block;
    padding: 0 0 20px 0;
    display: flex;
    justify-content: flex-end;
  }
  .roadshow-offers-supplier-container > .content .filter_box .filter_box_modal .filter_box_modal_footer > * {
    margin: 0 0 0 10px;
  }
}
.roadshow-offers-supplier-container > .content .filter_box-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(236, 241, 250, 0.7);
}
.roadshow-offers-supplier-container > .content .filter_box-modal .filter_box_modal {
  max-width: 600px;
  min-width: 500px;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 30px);
  min-height: 150px;
  background-color: #fff;
  padding: 0 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}
.roadshow-offers-supplier-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_header {
  display: block;
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  padding: 20px 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.roadshow-offers-supplier-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content {
  margin: 30px 0;
  overflow-x: auto;
  padding-right: 10px;
  padding-left: 3px;
  max-height: calc(50vh - 50px);
}
.roadshow-offers-supplier-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
  width: 6px;
}
.roadshow-offers-supplier-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.roadshow-offers-supplier-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.roadshow-offers-supplier-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.roadshow-offers-supplier-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content .reset {
  display: none;
}
.roadshow-offers-supplier-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_footer {
  display: block;
  padding: 0 0 20px 0;
  display: flex;
  justify-content: flex-end;
}
.roadshow-offers-supplier-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_footer > * {
  margin: 0 0 0 10px;
}
.roadshow-offers-supplier-container > .content .offers-box {
  width: 100%;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card {
  position: relative;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .cover {
  position: relative;
  border-radius: 10px;
  height: 12.2rem;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .cover > .img-cover {
  top: 0;
  left: 0;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
  height: 100%;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .cover > .img-cover:hover {
  opacity: 0.8;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .cover > .img-placeholder {
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .cover > .details {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  background-color: #8b8b8b;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1rem;
  text-align: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2705882353);
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .cover > .details .roadshow-title {
  display: none;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .cover:hover .duration {
  display: none;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .cover:hover .roadshow-title {
  display: inline-block;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .cover > .checked {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/checker_1_icon.svg") no-repeat center;
  background-size: auto 16px;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > img {
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > img:hover {
  opacity: 0.8;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .data {
  padding: 10px 10px 0 10px;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .data > .title {
  display: inline-block;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1.125rem;
  word-break: break-word;
  cursor: pointer;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .data > .title:hover {
  color: #0047ba;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .data > .sub-title {
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .data > .sub-title > .category {
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
  cursor: pointer;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .data > .sub-title > .location {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/location_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 12px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 17px;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .data > .sub-title > .locations {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/locations_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 12px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 17px;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .data > .sub-title > .proposals {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/proposals_0_icon.svg") no-repeat center left;
  background-size: auto 11px;
  padding-left: 19px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 12px;
  cursor: pointer;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .data > .sub-title > .proposals:hover {
  width: auto;
  height: 12px;
  background: url("/assets/images/icons/proposals_1_icon.svg") no-repeat center left;
  background-size: auto 11px;
  color: #0047ba;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .data > .description {
  margin: 10px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 18px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 36px;
  word-break: break-word;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .data > .price {
  display: flex;
  align-items: center;
  margin: 15px 0 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .data > .price .line-through {
  margin-right: 5px;
  font-family: "ADNOC Sans Medium";
  color: #8b8b8b;
  font-size: 1rem;
  text-decoration: line-through;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .data > .price .bold {
  color: #000000;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .data > .price .discount {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  margin: 0 5px;
  padding: 7px 5px 3px 5px;
  border-radius: 3px;
  background-color: #efefef;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .tag {
  position: absolute;
  top: 0;
  left: 0;
  padding: 7px 15px;
  background-color: #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 0.875rem;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 8px;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card:hover > .settings {
  display: flex;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .settings {
  top: -15px;
  right: -15px;
  position: absolute;
  display: none;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0px 5px;
  border-radius: 5px;
  z-index: 1;
  cursor: initial;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .settings > .edit {
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/edit_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.roadshow-offers-supplier-container > .content .offers-box .offers .offer-roadshow-card > .settings > .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-wide {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(1) {
  margin-top: 0;
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(2) {
  margin-top: 0;
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(3) {
  margin-top: 0;
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(4n) {
  display: inline-block;
  width: calc((100% - 25px * (4 - 1)) / 4 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(4) {
  margin-top: 0;
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(4n) {
  margin-right: 0;
}
@media only screen and (max-width: 1199px) {
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 50px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1099px) {
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 799px) {
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 40px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 40px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-wide > div:nth-child(2n) {
    margin-right: 0;
  }
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-collapse {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(1) {
  margin-top: 0;
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2) {
  margin-top: 0;
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(3n) {
  display: inline-block;
  width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
  height: max-content;
  margin-right: 25px;
  margin-top: 50px;
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(3) {
  margin-top: 0;
}
.roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(3n) {
  margin-right: 0;
}
@media only screen and (max-width: 1199px) {
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1099px) {
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 999px) {
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(3n) {
    display: inline-block;
    width: calc((100% - 25px * (3 - 1)) / 3 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(3) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 50px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 50px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 799px) {
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 40px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 40px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 40px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 699px) {
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(1) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    display: inline-block;
    width: calc((100% - 25px * (2 - 1)) / 2 - 1px);
    height: max-content;
    margin-right: 25px;
    margin-top: 50px;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2) {
    margin-top: 0;
  }
  .roadshow-offers-supplier-container > .content .offers-box > .offers-collapse > div:nth-child(2n) {
    margin-right: 0;
  }
}

.offer-agreement-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .offer-agreement-container {
    padding: 0px 20px;
  }
}
.offer-agreement-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .offer-agreement-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .offer-agreement-container .header .title-box {
    margin-bottom: 20px;
  }
}
.offer-agreement-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.offer-agreement-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .offer-agreement-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offer-agreement-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .offer-agreement-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.offer-agreement-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .offer-agreement-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offer-agreement-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .offer-agreement-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.offer-agreement-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .offer-agreement-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offer-agreement-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .offer-agreement-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.offer-agreement-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.offer-agreement-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .offer-agreement-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offer-agreement-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .offer-agreement-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.offer-agreement-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.offer-agreement-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .offer-agreement-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .offer-agreement-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.offer-agreement-container > .content .progress {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.offer-agreement-container > .content .progress > .data {
  position: absolute;
  left: 0;
  top: 0;
}
.offer-agreement-container > .content .progress > .data > .date {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .offer-agreement-container > .content .progress > .data > .date {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offer-agreement-container > .content .progress > .data > .date {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .offer-agreement-container > .content .progress > .data > .date {
    font-size: 1.4875rem;
  }
}
.offer-agreement-container > .content .progress > .data > .id {
  margin-top: 5px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .offer-agreement-container > .content .progress > .data > .id {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offer-agreement-container > .content .progress > .data > .id {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .offer-agreement-container > .content .progress > .data > .id {
    font-size: 1.4875rem;
  }
}
.offer-agreement-container > .content .progress > .draft {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/draft_progress_0_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 1rem;
}
.offer-agreement-container > .content .progress > .draft-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/draft_progress_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
.offer-agreement-container > .content .progress > .review {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/review_progress_0_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 1rem;
}
.offer-agreement-container > .content .progress > .review-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/review_progress_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
.offer-agreement-container > .content .progress > .approved {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/approved_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 1rem;
}
.offer-agreement-container > .content .progress > .approved-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/approved_progress_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #42ad53;
}
.offer-agreement-container > .content .progress > .rejected {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/rejected_progress_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  font-family: "ADNOC Sans Regular";
  color: #f55757;
  font-size: 1rem;
}
.offer-agreement-container > .content .progress > .rejected-active {
  width: 95px;
  height: 125px;
  background: url("/assets/images/icons/rejected_progress_1_icon.svg") no-repeat top center;
  background-size: auto 94px;
  color: #f55757;
}
.offer-agreement-container > .content .progress > .union {
  width: 180px;
  height: 125px;
  background: url("/assets/images/icons/union_progress_0_icon.svg") no-repeat top 40px center;
  background-size: auto 14px;
}
.offer-agreement-container > .content .progress > .union-active {
  width: 180px;
  height: 125px;
  background: url("/assets/images/icons/union_progress_1_icon.svg") no-repeat top 40px center;
  background-size: auto 14px;
}
.offer-agreement-container > .content .location-choice > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1rem;
  margin-bottom: 20px;
}
.offer-agreement-container > .content .location-choice > .f-checkbox {
  margin: 0 0 10px 0;
}
.offer-agreement-container > .content .location-choice > .f-basic-btn {
  margin: 15px 0 0 0;
}
.offer-agreement-container > .content .terms .terms-conditions {
  max-height: 350px;
  overflow: auto;
  margin: 0 0 30px 0;
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
  line-height: 1.3rem;
}
.offer-agreement-container > .content .terms .terms-conditions p {
  margin: 0 0 15px 0;
}
.offer-agreement-container > .content .terms .terms-conditions p:last-of-type {
  margin: 0;
}
.offer-agreement-container > .content .terms .terms-conditions::-webkit-scrollbar {
  width: 6px;
}
.offer-agreement-container > .content .terms .terms-conditions::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.offer-agreement-container > .content .terms .terms-conditions::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.offer-agreement-container > .content .terms .terms-conditions::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.offer-agreement-container > .content .date-signature .info {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1rem;
  margin: 0 0 30px 0;
}
.offer-agreement-container > .content .date-signature .stamp-c {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.offer-agreement-container > .content .date-signature .stamp-c .f-basic {
  width: calc(100% - 430px);
}
.offer-agreement-container > .content .date-signature .stamp-c .f-recreation-services label {
  width: 470px;
}
.offer-agreement-container > .content .date-signature .stamp-c .f-recreation-services input {
  width: calc(100% - 470px);
}
.offer-agreement-container > .content .date-signature .stamp-c .stamp {
  width: 400px;
  padding: 10px 0 0 30px;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
}
.offer-agreement-container > .content .attachments .step-verifications > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1rem;
  margin-bottom: 20px;
}
.offer-agreement-container > .content .attachments .step-verifications .step {
  margin-bottom: 15px;
}
.offer-agreement-container > .content .attachments .step-verifications .step .text {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.95rem;
  margin-bottom: 15px;
}
.offer-agreement-container > .content .attachments .step-verifications .step .actions {
  margin: 15px 10px 0 10px;
}
.offer-agreement-container > .content .attachments .attachments-list .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1rem;
  margin-top: 15px;
  margin-bottom: 10px;
}
.offer-agreement-container > .content .attachments .attachments-list .attachment {
  margin-bottom: 7px;
  display: flex;
}
.offer-agreement-container > .content .attachments .attachments-list .attachment .file-name {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 0.95rem;
  cursor: pointer;
}
.offer-agreement-container > .content .attachments .attachments-list .attachment .file-name:hover {
  color: #0047ba;
}
.offer-agreement-container > .content .attachments .attachments-list .attachment .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin-left: 6px;
  cursor: pointer;
}
.offer-agreement-container > .content .attachments .attachments-list .attachment .delete:hover {
  opacity: 0.8;
}
.offer-agreement-container > .content .attachments > .attachment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 20px 0;
}
.offer-agreement-container > .content .attachments > .attachment > .file-name {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  margin: 0 20px 0 0;
  cursor: pointer;
}
.offer-agreement-container > .content .attachments > .attachment > .btn-download {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/download_0_icon.svg") no-repeat center right 15px;
  background-size: auto 17px;
  padding: 6px 40px 6px 20px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 1rem;
  border: 1px solid #0047ba;
  border-radius: 16px;
  cursor: pointer;
}
.offer-agreement-container > .content .attachments > .attachment > .btn-download:hover {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/download_1_icon.svg") no-repeat center right 15px;
  background-size: auto 17px;
  padding: 6px 40px 6px 20px;
  color: #fff;
  background-color: #0047ba;
}
.offer-agreement-container > .content .attachments .note {
  margin-top: 20px;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 1rem;
}
.offer-agreement-container > .content .attachments .error {
  margin: 10px 0 0 0;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.offer-agreement-container > .content .attachments .error:before {
  content: "! ";
}
.offer-agreement-container > .footer {
  margin: 30px 0 0 0;
}
.offer-agreement-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.offer-agreement-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.banners-edit-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .banners-edit-container {
    padding: 0px 20px;
  }
}
.banners-edit-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .banners-edit-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .banners-edit-container .header .title-box {
    margin-bottom: 20px;
  }
}
.banners-edit-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.banners-edit-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .banners-edit-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .banners-edit-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .banners-edit-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.banners-edit-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .banners-edit-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .banners-edit-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .banners-edit-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.banners-edit-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .banners-edit-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .banners-edit-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .banners-edit-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.banners-edit-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.banners-edit-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .banners-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .banners-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .banners-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.banners-edit-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.banners-edit-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .banners-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .banners-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.banners-edit-container > .footer {
  margin: 30px 0 0 0;
}
.banners-edit-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.banners-edit-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.banners-edit-container .banners {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.banners-edit-container .banners > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
  height: max-content;
  margin-right: 30px;
  margin-top: 50px;
}
.banners-edit-container .banners > div:nth-child(1) {
  margin-top: 0;
}
.banners-edit-container .banners > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
  height: max-content;
  margin-right: 30px;
  margin-top: 50px;
}
.banners-edit-container .banners > div:nth-child(2) {
  margin-top: 0;
}
.banners-edit-container .banners > div:nth-child(2n) {
  margin-right: 0;
}
.banners-edit-container .banners .banner-card {
  cursor: pointer;
  display: flex;
  border-radius: calc(1.2vw / 2);
  overflow: hidden;
  position: relative;
}
.banners-edit-container .banners .banner-card > .text-section {
  background-color: #3f6285;
  border-right: 4px solid white;
  width: 20%;
  position: relative;
}
.banners-edit-container .banners .banner-card > .text-section > .banner__tag {
  position: absolute;
  left: 0;
  top: 0;
  padding: calc(1vw / 2) calc(1vw / 2) calc(0.6vw / 2) calc(1vw / 2);
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: calc(1vw / 2);
  background-color: #0047ba;
  border-bottom-right-radius: calc(1vw / 2);
}
@media only screen and (max-width: 1199px) {
  .banners-edit-container .banners .banner-card > .text-section > .banner__tag {
    font-size: calc(1.2vw / 2);
    padding: calc(1.4vw / 2) calc(1.2vw / 2) calc(0.8vw / 2) calc(1.2vw / 2);
  }
}
@media only screen and (max-width: 799px) {
  .banners-edit-container .banners .banner-card > .text-section > .banner__tag {
    display: none;
  }
}
.banners-edit-container .banners .banner-card > .text-section > .banner__content {
  position: absolute;
  left: calc(1vw / 2);
  top: calc(4vw / 2);
  width: calc(100% - (2vw / 2));
}
.banners-edit-container .banners .banner-card > .text-section > .banner__content > .banner__content__title {
  margin-top: calc(0.8vw / 2);
  font-family: "ADNOC Sans Bold";
  color: #fff;
  font-size: calc(1.8vw / 2);
  line-height: calc(2.3vw / 2);
  word-break: all;
}
.banners-edit-container .banners .banner-card > .text-section > .banner__content > .banner__content__discount {
  font-family: "ADNOC Sans Bold";
  color: #fff;
  font-size: calc(1.1vw / 2);
  line-height: calc(1.6vw / 2);
}
.banners-edit-container .banners .banner-card > .text-section > .banner__content > .banner__content__discount .discount--new .old {
  text-decoration: line-through;
}
.banners-edit-container .banners .banner-card > .text-section > .banner__content > .banner__content__discount .discount--new .new {
  margin-left: 5px;
}
.banners-edit-container .banners .banner-card > .text-section > .banner__content > .banner__content__company {
  margin-top: calc(1.2vw / 2);
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: calc(1.2vw / 2);
  line-height: calc(1.5vw / 2);
}
.banners-edit-container .banners .banner-card > .text-section > .banner__btn_c {
  position: absolute;
  bottom: calc(1.5vw / 2);
  width: 100%;
  display: flex;
  justify-content: center;
}
.banners-edit-container .banners .banner-card > .text-section > .banner__btn_c > .banner__btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: calc(0.5vw / 2) calc(1.3vw / 2);
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: calc(0.8vw / 2);
  border: 1px solid #fff;
  border-radius: calc(1vw / 2);
  cursor: pointer;
}
.banners-edit-container .banners .banner-card > .text-section > .banner__btn_c > .banner__btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.banners-edit-container .banners .banner-card > .big-imgs-section {
  width: 40%;
  position: relative;
  border-right: 4px solid white;
}
.banners-edit-container .banners .banner-card > .big-imgs-section > img {
  width: 100%;
}
.banners-edit-container .banners .banner-card > .big-imgs-section .img {
  position: absolute;
  top: 0;
  left: 0;
}
.banners-edit-container .banners .banner-card > .small-imgs-section {
  display: inline-block;
  width: calc((80% - 3px) / 2);
  display: flex;
  flex-wrap: wrap;
}
.banners-edit-container .banners .banner-card > .small-imgs-section .small-img {
  width: calc(50% - 3px);
  position: relative;
}
.banners-edit-container .banners .banner-card > .small-imgs-section .small-img:nth-child(1) {
  border-right: 4px solid white;
}
.banners-edit-container .banners .banner-card > .small-imgs-section .small-img:nth-child(3) {
  border-top: 4px solid white;
  border-right: 4px solid white;
}
.banners-edit-container .banners .banner-card > .small-imgs-section .small-img:nth-child(4) {
  border-top: 4px solid white;
}
.banners-edit-container .banners .banner-card > .small-imgs-section .small-img > img {
  width: 100%;
}
.banners-edit-container .banners .banner-card > .small-imgs-section .small-img .img {
  position: absolute;
  left: 0;
}
.banners-edit-container .banners .banner-card .website-btn {
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/website_0_icon.svg") no-repeat center;
  background-size: auto 25px;
  position: absolute;
  right: 14px;
  top: 14px;
  z-index: 10;
  cursor: pointer;
}
.banners-edit-container .banners .banner-card .website-btn:hover {
  opacity: 0.8;
}
.banners-edit-container .banners .banner-c {
  position: relative;
}
.banners-edit-container .banners .banner-c:hover > .settings {
  display: flex;
}
.banners-edit-container .banners .banner-c .settings {
  top: -15px;
  right: -15px;
  position: absolute;
  display: hidden;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0px 5px;
  border-radius: 5px;
  z-index: 1;
  cursor: initial;
}
.banners-edit-container .banners .banner-c .settings > .edit {
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/edit_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.banners-edit-container .banners .banner-c .settings > .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
@media only screen and (max-width: 899px) {
  .banners-edit-container .banners {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .banners-edit-container .banners > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (1 - 1)) / 1 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 50px;
  }
  .banners-edit-container .banners > div:nth-child(1) {
    margin-top: 0;
  }
  .banners-edit-container .banners > div:nth-child(1n) {
    margin-right: 0;
  }
  .banners-edit-container .banners .banner-card {
    cursor: pointer;
    display: flex;
    border-radius: calc(1.2vw / 1);
    overflow: hidden;
    position: relative;
  }
  .banners-edit-container .banners .banner-card > .text-section {
    background-color: #3f6285;
    border-right: 4px solid white;
    width: 20%;
    position: relative;
  }
  .banners-edit-container .banners .banner-card > .text-section > .banner__tag {
    position: absolute;
    left: 0;
    top: 0;
    padding: calc(1vw / 1) calc(1vw / 1) calc(0.6vw / 1) calc(1vw / 1);
    font-family: "ADNOC Sans Medium";
    color: #fff;
    font-size: calc(1vw / 1);
    background-color: #0047ba;
    border-bottom-right-radius: calc(1vw / 1);
  }
}
@media only screen and (max-width: 899px) and (max-width: 1199px) {
  .banners-edit-container .banners .banner-card > .text-section > .banner__tag {
    font-size: calc(1.2vw / 1);
    padding: calc(1.4vw / 1) calc(1.2vw / 1) calc(0.8vw / 1) calc(1.2vw / 1);
  }
}
@media only screen and (max-width: 899px) and (max-width: 799px) {
  .banners-edit-container .banners .banner-card > .text-section > .banner__tag {
    display: none;
  }
}
@media only screen and (max-width: 899px) {
  .banners-edit-container .banners .banner-card > .text-section > .banner__content {
    position: absolute;
    left: calc(1vw / 1);
    top: calc(4vw / 1);
    width: calc(100% - (2vw / 1));
  }
  .banners-edit-container .banners .banner-card > .text-section > .banner__content > .banner__content__title {
    margin-top: calc(0.8vw / 1);
    font-family: "ADNOC Sans Bold";
    color: #fff;
    font-size: calc(1.8vw / 1);
    line-height: calc(2.3vw / 1);
    word-break: all;
  }
  .banners-edit-container .banners .banner-card > .text-section > .banner__content > .banner__content__discount {
    font-family: "ADNOC Sans Bold";
    color: #fff;
    font-size: calc(1.1vw / 1);
    line-height: calc(1.6vw / 1);
  }
  .banners-edit-container .banners .banner-card > .text-section > .banner__content > .banner__content__discount .discount--new .old {
    text-decoration: line-through;
  }
  .banners-edit-container .banners .banner-card > .text-section > .banner__content > .banner__content__discount .discount--new .new {
    margin-left: 5px;
  }
  .banners-edit-container .banners .banner-card > .text-section > .banner__content > .banner__content__company {
    margin-top: calc(1.2vw / 1);
    font-family: "ADNOC Sans Medium";
    color: #fff;
    font-size: calc(1.2vw / 1);
    line-height: calc(1.5vw / 1);
  }
}
@media only screen and (max-width: 899px) {
  .banners-edit-container .banners .banner-card > .text-section > .banner__btn_c {
    position: absolute;
    bottom: calc(1.5vw / 1);
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .banners-edit-container .banners .banner-card > .text-section > .banner__btn_c > .banner__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: calc(0.5vw / 1) calc(1.3vw / 1);
    font-family: "ADNOC Sans Regular";
    color: #fff;
    font-size: calc(0.8vw / 1);
    border: 1px solid #fff;
    border-radius: calc(1vw / 1);
    cursor: pointer;
  }
  .banners-edit-container .banners .banner-card > .text-section > .banner__btn_c > .banner__btn:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
@media only screen and (max-width: 899px) {
  .banners-edit-container .banners .banner-card > .big-imgs-section {
    width: 40%;
    position: relative;
    border-right: 4px solid white;
  }
  .banners-edit-container .banners .banner-card > .big-imgs-section > img {
    width: 100%;
  }
  .banners-edit-container .banners .banner-card > .big-imgs-section .img {
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media only screen and (max-width: 899px) {
  .banners-edit-container .banners .banner-card > .small-imgs-section {
    display: inline-block;
    width: calc((80% - 3px) / 2);
    display: flex;
    flex-wrap: wrap;
  }
  .banners-edit-container .banners .banner-card > .small-imgs-section .small-img {
    width: calc(50% - 3px);
    position: relative;
  }
  .banners-edit-container .banners .banner-card > .small-imgs-section .small-img:nth-child(1) {
    border-right: 4px solid white;
  }
  .banners-edit-container .banners .banner-card > .small-imgs-section .small-img:nth-child(3) {
    border-top: 4px solid white;
    border-right: 4px solid white;
  }
  .banners-edit-container .banners .banner-card > .small-imgs-section .small-img:nth-child(4) {
    border-top: 4px solid white;
  }
  .banners-edit-container .banners .banner-card > .small-imgs-section .small-img > img {
    width: 100%;
  }
  .banners-edit-container .banners .banner-card > .small-imgs-section .small-img .img {
    position: absolute;
    left: 0;
  }
}
@media only screen and (max-width: 899px) {
  .banners-edit-container .banners .banner-card .website-btn {
    width: 30px;
    height: 30px;
    background: url("/assets/images/icons/website_0_icon.svg") no-repeat center;
    background-size: auto 25px;
    position: absolute;
    right: 14px;
    top: 14px;
    z-index: 10;
    cursor: pointer;
  }
  .banners-edit-container .banners .banner-card .website-btn:hover {
    opacity: 0.8;
  }
}

.supplier-edit-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .supplier-edit-container {
    padding: 0px 20px;
  }
}
.supplier-edit-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .supplier-edit-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .supplier-edit-container .header .title-box {
    margin-bottom: 20px;
  }
}
.supplier-edit-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.supplier-edit-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .supplier-edit-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .supplier-edit-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .supplier-edit-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.supplier-edit-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .supplier-edit-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .supplier-edit-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .supplier-edit-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.supplier-edit-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .supplier-edit-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .supplier-edit-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .supplier-edit-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.supplier-edit-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.supplier-edit-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .supplier-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .supplier-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .supplier-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.supplier-edit-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.supplier-edit-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .supplier-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .supplier-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.supplier-edit-container > .footer {
  margin: 30px 0 0 0;
}
.supplier-edit-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.supplier-edit-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.login-container {
  background: url("/assets/images/backgrounds/login_background.jpg") no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  margin-bottom: -50px;
  position: relative;
}
.login-container > .box {
  background-color: #fff;
  border-radius: 13px;
  padding: 35px 50px 30px 50px;
  width: 530px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-container > .box > .header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 40px 0;
  height: 80px;
}
.login-container > .box > .header > .title-box > .title {
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 2rem;
}
@media only screen and (max-width: 1339px) {
  .login-container > .box > .header > .title-box > .title {
    font-size: 1.9rem;
  }
}
@media only screen and (max-width: 1049px) {
  .login-container > .box > .header > .title-box > .title {
    font-size: 1.8rem;
  }
}
@media only screen and (max-width: 849px) {
  .login-container > .box > .header > .title-box > .title {
    font-size: 1.7rem;
  }
}
.login-container > .box > .header > .title-box > .title > u {
  text-decoration: none;
  border-bottom: 5px solid #0047ba;
  padding: 0 0 5px 0;
}
.login-container > .box > .header > .title-box > .subtitle {
  margin-top: 25px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 1.5rem;
}
@media only screen and (max-width: 1339px) {
  .login-container > .box > .header > .title-box > .subtitle {
    font-size: 1.425rem;
  }
}
@media only screen and (max-width: 1049px) {
  .login-container > .box > .header > .title-box > .subtitle {
    font-size: 1.35rem;
  }
}
@media only screen and (max-width: 849px) {
  .login-container > .box > .header > .title-box > .subtitle {
    font-size: 1.275rem;
  }
}
.login-container > .box > .header .logo-mazaya {
  width: 114px;
  height: 80px;
  background: url("/assets/images/logos/mazaya_1_logo.svg") no-repeat bottom 2px left;
  background-size: auto 66px;
}
.login-container > .box > .header > .logo {
  width: 200px;
  height: 100px;
  background: url("/assets/images/logos/adnoc_h_logo.svg") no-repeat center;
  background-size: 200px auto;
}
.login-container > .box > .header > .subtitle {
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.login-container > .box > .header > .subtitle .subtitle_ar {
  font-family: "ADNOC Sans Bold";
  color: #002e6d;
  font-size: 1.1rem;
  margin-bottom: 5px;
}
.login-container > .box > .header > .subtitle .subtitle_eng {
  font-family: "ADNOC Sans Bold";
  color: #002e6d;
  font-size: 1.1rem;
}
.login-container > .box > .content > .text {
  font-family: "ADNOC Sans Regular";
  color: #717171;
  font-size: 0.9rem;
  line-height: 1.3rem;
}
.login-container > .box > .content > .text > p {
  margin: 0 0 10px 0;
}
.login-container > .box > .content > .text b {
  font-family: "ADNOC Sans Medium";
  color: #002e6d;
  font-size: 0.9rem;
}
.login-container > .box > .content > .text .link {
  cursor: pointer;
}
.login-container > .box > .content > .text .link:hover {
  opacity: 0.8;
}
.login-container > .box > .content > .text .bold-blue {
  font-family: "ADNOC Sans Medium";
  color: #002e6d;
  font-size: 0.9rem;
  cursor: pointer;
}
.login-container > .box > .content > .text .bold-blue:hover {
  opacity: 0.8;
}
.login-container > .box > .content > .options {
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login-container > .box > .content > .options .btn-blue {
  min-width: 160px;
}
.login-container > .box > .content .options--centar {
  justify-content: center;
}
.login-container > .box > .content .options--centar .btn-blue {
  margin: 0 15px;
}
.login-container > .box > .footer {
  margin: 50px 0 0 0;
  text-align: center;
}
.login-container > .box > .footer > .text {
  font-family: "ADNOC Sans Regular";
  color: #717171;
  font-size: 0.875rem;
}
.login-container > .box > .footer > .text > b {
  color: #002e6d;
  cursor: pointer;
}
.login-container > .box > .footer > .text > b:hover {
  opacity: 0.8;
}
.login-container > .box > .footer > .btn-blue {
  margin-top: 20px;
  padding: 6px 70px;
  padding-top: 10px;
}
.login-container > .box .copyright {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  text-align: center;
  font-family: "ADNOC Sans Regular";
  color: white;
  font-size: 0.875rem;
}
.login-container > .box .user-counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
}
.login-container > .box .user-counter--text {
  font-family: "ADNOC Sans Regular";
  color: #717171;
  font-size: 0.9rem;
}
.login-container > .box .user-counter--text span {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 0.9rem;
}
.login-info-container {
  background: url("/assets/images/backgrounds/register_background.png") no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  margin-bottom: -50px;
  position: relative;
}
.login-info-container > .box {
  background-color: #fff;
  border-radius: 13px;
  padding: 50px 50px 30px 50px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-info-container > .box > .header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 40px 0;
  height: 80px;
}
.login-info-container > .box > .header > .title-box > .title {
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 2rem;
}
@media only screen and (max-width: 1339px) {
  .login-info-container > .box > .header > .title-box > .title {
    font-size: 1.9rem;
  }
}
@media only screen and (max-width: 1049px) {
  .login-info-container > .box > .header > .title-box > .title {
    font-size: 1.8rem;
  }
}
@media only screen and (max-width: 849px) {
  .login-info-container > .box > .header > .title-box > .title {
    font-size: 1.7rem;
  }
}
.login-info-container > .box > .header > .title-box > .title > u {
  text-decoration: none;
  border-bottom: 5px solid #0047ba;
  padding: 0 0 5px 0;
}
.login-info-container > .box > .header > .title-box > .subtitle {
  margin-top: 25px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 1.5rem;
}
@media only screen and (max-width: 1339px) {
  .login-info-container > .box > .header > .title-box > .subtitle {
    font-size: 1.425rem;
  }
}
@media only screen and (max-width: 1049px) {
  .login-info-container > .box > .header > .title-box > .subtitle {
    font-size: 1.35rem;
  }
}
@media only screen and (max-width: 849px) {
  .login-info-container > .box > .header > .title-box > .subtitle {
    font-size: 1.275rem;
  }
}
.login-info-container > .box > .header .logo-mazaya {
  width: 114px;
  height: 80px;
  background: url("/assets/images/logos/mazaya_1_logo.svg") no-repeat bottom 2px left;
  background-size: auto 66px;
}
.login-info-container > .box > .header > .logo {
  width: 200px;
  height: 100px;
  background: url("/assets/images/logos/adnoc_h_logo.svg") no-repeat center;
  background-size: 200px auto;
}
.login-info-container > .box > .header > .subtitle {
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.login-info-container > .box > .header > .subtitle .subtitle_ar {
  font-family: "ADNOC Sans Bold";
  color: #002e6d;
  font-size: 1.1rem;
  margin-bottom: 5px;
}
.login-info-container > .box > .header > .subtitle .subtitle_eng {
  font-family: "ADNOC Sans Bold";
  color: #002e6d;
  font-size: 1.1rem;
}
.login-info-container > .box > .content > .text {
  font-family: "ADNOC Sans Regular";
  color: #717171;
  font-size: 0.875rem;
  line-height: 1.1rem;
}
.login-info-container > .box > .content > .text > p {
  margin: 0 0 10px 0;
}
.login-info-container > .box > .content > .text > b {
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.875rem;
}
.login-info-container > .box > .content > .text .bold-blue {
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.875rem;
  cursor: pointer;
}
.login-info-container > .box > .content > .text .bold-blue:hover {
  opacity: 0.8;
}
.login-info-container > .box > .footer {
  margin: 50px 0 0 0;
  text-align: center;
}
.login-info-container > .box > .footer > .back {
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.875rem;
  cursor: pointer;
}
.login-info-container > .box > .footer > .back:hover {
  opacity: 0.7;
}

.company-register {
  background: url("/assets/images/backgrounds/register_background.png") no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  margin-bottom: -50px;
  position: relative;
}
.company-register .box {
  background-color: #fff;
  width: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 20px 0;
  min-height: 590px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 25px;
  border-radius: 4px;
}
.company-register .box .cancel-button {
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
}
.company-register .box .cancel-button .cancel-button-arrow {
  width: 14px;
  height: 14px;
  background: url("/assets/images/icons/arrowLeft_navbar_subbar_icon.svg") no-repeat center;
  background-size: 14px auto;
}
.company-register .box .logo {
  width: 114px;
  height: 80px;
  background: url("/assets/images/logos/mazaya_1_logo.svg") no-repeat bottom 2px left;
  background-size: auto 66px;
}
.company-register .box .input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 10rem;
}
.company-register .box .input-array {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.company-register .box .input-array .password-array {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}
.company-register .box .input-array .password-array input {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #8b8b8b;
  font-size: 14px;
  width: 60%;
}
.company-register .box .input-array .password-array input::placeholder {
  font-size: 14px;
}
.company-register .box .input-array .password-array input:focus {
  border: 1px solid #0047ba;
}
.company-register .box .input-array .error-message {
  position: absolute;
  bottom: -20px;
  font-size: 14px;
  color: #f55757;
}
.company-register .box .input-array .error-message--upload {
  bottom: -25px;
}
.company-register .box .input-array .error-message--date {
  left: 90px;
  top: 66px;
}
.company-register .box .input-array .custom-file-upload {
  border: 2px dashed #8b8b8b;
  border-radius: 10px;
  padding: 20px;
  width: 60%;
  background-color: #fff;
  position: relative;
}
.company-register .box .input-array .custom-file-upload input {
  opacity: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.company-register .box .input-array .custom-file-upload label {
  font-size: 14px;
  color: #8b8b8b;
}
.company-register .box .input-array input {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #8b8b8b;
  font-size: 14px;
  width: 60%;
}
.company-register .box .input-array input::placeholder {
  font-size: 14px;
}
.company-register .box .input-array input:focus {
  border: 1px solid #0047ba;
}
.company-register .box .input-array button {
  background-color: #0047ba;
  color: #fff;
  font-size: 14px;
  padding: 20px;
  border-radius: 10px;
  border: none;
  width: 55%;
}
.company-register .sublabel {
  font-size: 12px;
  font-weight: bold;
  color: #8b8b8b;
}

.license-agreement-container {
  background: url("/assets/images/backgrounds/register_background.png") no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  margin-bottom: -50px;
  position: relative;
}
.license-agreement-container .box {
  background-color: #fff;
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 20px;
  border-radius: 10px;
}
.license-agreement-container .box .cancel-button {
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
}
.license-agreement-container .box .cancel-button .cancel-button-arrow {
  width: 14px;
  height: 14px;
  background: url("/assets/images/icons/arrowLeft_navbar_subbar_icon.svg") no-repeat center;
  background-size: 14px auto;
}
.license-agreement-container .box .logo {
  width: 114px;
  height: 80px;
  background: url("/assets/images/logos/mazaya_1_logo.svg") no-repeat bottom 2px left;
  background-size: auto 66px;
}
.license-agreement-container .box ul li {
  font-size: 12px;
  padding: 4px 0;
}
.license-agreement-container .box .terms-agreement {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.license-agreement-container .box .button-wrapper {
  display: flex;
  justify-content: center;
}
.license-agreement-container .box .button-wrapper button {
  background-color: #0047ba;
  color: #fff;
  font-size: 16px;
  padding: 20px;
  border-radius: 10px;
  border: none;
  width: 35%;
}

.register-container {
  background: url("/assets/images/backgrounds/login_background.jpg") no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  margin-bottom: -50px;
  position: relative;
}
.register-container .box {
  width: 1050px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 20px 0;
  min-height: 610px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.register-container .box .form-container {
  position: relative;
  width: 470px;
  background-color: #fff;
  border-radius: 25px;
  padding: 20px;
}
.register-container .box .form-container .title {
  margin-top: 20px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 1.5rem;
  text-align: center;
}
@media only screen and (max-width: 1339px) {
  .register-container .box .form-container .title {
    font-size: 1.425rem;
  }
}
@media only screen and (max-width: 1049px) {
  .register-container .box .form-container .title {
    font-size: 1.35rem;
  }
}
@media only screen and (max-width: 849px) {
  .register-container .box .form-container .title {
    font-size: 1.275rem;
  }
}
.register-container .box .form-container .tabs {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.register-container .box .form-container .tabs .tab {
  font-family: "ADNOC Sans Medium";
  color: #8b8b8b;
  font-size: 1rem;
  margin: 0 13px;
  cursor: pointer;
  padding: 0 0 10px 0;
}
.register-container .box .form-container .tabs .tab:hover {
  color: #0047ba;
}
.register-container .box .form-container .tabs .tab-active {
  color: #0047ba;
  border-bottom: 2px solid #0047ba;
}
.register-container .box .form-container form {
  margin-top: 30px;
}
.register-container .box .form-container form .f-basic,
.register-container .box .form-container form .f-textarea {
  margin: 0 0 20px 0;
}
.register-container .box .form-container form .f-basic label,
.register-container .box .form-container form .f-basic .label,
.register-container .box .form-container form .f-textarea label,
.register-container .box .form-container form .f-textarea .label {
  font-family: "ADNOC Sans Medium";
  color: #6e6e6e;
  font-size: 0.9rem;
  width: 220px;
}
.register-container .box .form-container form .f-basic input,
.register-container .box .form-container form .f-basic textarea,
.register-container .box .form-container form .f-textarea input,
.register-container .box .form-container form .f-textarea textarea {
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.9rem;
}
.register-container .box .form-container form .f-basic textarea,
.register-container .box .form-container form .f-textarea textarea {
  height: 75px;
}
.register-container .box .form-container form .f-basic textarea::placeholder,
.register-container .box .form-container form .f-textarea textarea::placeholder {
  color: #c4c4c4 !important;
}
.register-container .box .form-container .conf {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 6px 35px;
}
.register-container .box .form-container .register-footer {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 15px;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.register-container .box .form-container .register-footer > b {
  color: #0047ba;
  cursor: pointer;
}
.register-container .box .right {
  width: 470px;
  padding: 40px 0;
}
.register-container .box .right .logo {
  width: 200px;
  height: 100px;
  background: url("/assets/images/logos/adnoc_h_logo.svg") no-repeat center;
  background-size: 200px auto;
}
.register-container .box .right .welcome {
  margin-top: 20px;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.9rem;
}
@media only screen and (max-width: 1339px) {
  .register-container .box .right .welcome {
    font-size: 1.33rem;
  }
}
@media only screen and (max-width: 1049px) {
  .register-container .box .right .welcome {
    font-size: 1.26rem;
  }
}
@media only screen and (max-width: 849px) {
  .register-container .box .right .welcome {
    font-size: 1.19rem;
  }
}

.banners-home-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .banners-home-container {
    padding: 0px 20px;
  }
}
.banners-home-container .banner-card {
  cursor: pointer;
  display: flex;
  border-radius: calc(1.2vw / 1);
  overflow: hidden;
  position: relative;
}
.banners-home-container .banner-card > .text-section {
  background-color: #3f6285;
  border-right: 4px solid white;
  width: 20%;
  position: relative;
}
.banners-home-container .banner-card > .text-section > .banner__tag {
  position: absolute;
  left: 0;
  top: 0;
  padding: calc(1vw / 1) calc(1vw / 1) calc(0.6vw / 1) calc(1vw / 1);
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: calc(1vw / 1);
  background-color: #0047ba;
  border-bottom-right-radius: calc(1vw / 1);
}
@media only screen and (max-width: 1199px) {
  .banners-home-container .banner-card > .text-section > .banner__tag {
    font-size: calc(1.2vw / 1);
    padding: calc(1.4vw / 1) calc(1.2vw / 1) calc(0.8vw / 1) calc(1.2vw / 1);
  }
}
@media only screen and (max-width: 799px) {
  .banners-home-container .banner-card > .text-section > .banner__tag {
    display: none;
  }
}
.banners-home-container .banner-card > .text-section > .banner__content {
  position: absolute;
  left: calc(1vw / 1);
  top: calc(4vw / 1);
  width: calc(100% - (2vw / 1));
}
.banners-home-container .banner-card > .text-section > .banner__content > .banner__content__title {
  margin-top: calc(0.8vw / 1);
  font-family: "ADNOC Sans Bold";
  color: #fff;
  font-size: calc(1.8vw / 1);
  line-height: calc(2.3vw / 1);
  word-break: all;
}
.banners-home-container .banner-card > .text-section > .banner__content > .banner__content__discount {
  font-family: "ADNOC Sans Bold";
  color: #fff;
  font-size: calc(1.1vw / 1);
  line-height: calc(1.6vw / 1);
}
.banners-home-container .banner-card > .text-section > .banner__content > .banner__content__discount .discount--new .old {
  text-decoration: line-through;
}
.banners-home-container .banner-card > .text-section > .banner__content > .banner__content__discount .discount--new .new {
  margin-left: 5px;
}
.banners-home-container .banner-card > .text-section > .banner__content > .banner__content__company {
  margin-top: calc(1.2vw / 1);
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: calc(1.2vw / 1);
  line-height: calc(1.5vw / 1);
}
.banners-home-container .banner-card > .text-section > .banner__btn_c {
  position: absolute;
  bottom: calc(1.5vw / 1);
  width: 100%;
  display: flex;
  justify-content: center;
}
.banners-home-container .banner-card > .text-section > .banner__btn_c > .banner__btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: calc(0.5vw / 1) calc(1.3vw / 1);
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: calc(0.8vw / 1);
  border: 1px solid #fff;
  border-radius: calc(1vw / 1);
  cursor: pointer;
}
.banners-home-container .banner-card > .text-section > .banner__btn_c > .banner__btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.banners-home-container .banner-card > .big-imgs-section {
  width: 40%;
  position: relative;
  border-right: 4px solid white;
}
.banners-home-container .banner-card > .big-imgs-section > img {
  width: 100%;
}
.banners-home-container .banner-card > .big-imgs-section .img {
  position: absolute;
  top: 0;
  left: 0;
}
.banners-home-container .banner-card > .small-imgs-section {
  display: inline-block;
  width: calc((80% - 3px) / 2);
  display: flex;
  flex-wrap: wrap;
}
.banners-home-container .banner-card > .small-imgs-section .small-img {
  width: calc(50% - 3px);
  position: relative;
}
.banners-home-container .banner-card > .small-imgs-section .small-img:nth-child(1) {
  border-right: 4px solid white;
}
.banners-home-container .banner-card > .small-imgs-section .small-img:nth-child(3) {
  border-top: 4px solid white;
  border-right: 4px solid white;
}
.banners-home-container .banner-card > .small-imgs-section .small-img:nth-child(4) {
  border-top: 4px solid white;
}
.banners-home-container .banner-card > .small-imgs-section .small-img > img {
  width: 100%;
}
.banners-home-container .banner-card > .small-imgs-section .small-img .img {
  position: absolute;
  left: 0;
}
.banners-home-container .banner-card .website-btn {
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/website_0_icon.svg") no-repeat center;
  background-size: auto 25px;
  position: absolute;
  right: 14px;
  top: 14px;
  z-index: 10;
  cursor: pointer;
}
.banners-home-container .banner-card .website-btn:hover {
  opacity: 0.8;
}

.banners-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .banners-container {
    padding: 0px 20px;
  }
}
.banners-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .banners-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .banners-container .header .title-box {
    margin-bottom: 20px;
  }
}
.banners-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.banners-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .banners-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .banners-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .banners-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.banners-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .banners-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .banners-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .banners-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.banners-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .banners-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .banners-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .banners-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.banners-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.banners-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .banners-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .banners-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .banners-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.banners-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.banners-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .banners-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .banners-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.banners-container .content {
  display: flex;
  flex-wrap: wrap;
  height: auto;
}
.banners-container .content > div:nth-child(1n) {
  display: inline-block;
  width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
  height: max-content;
  margin-right: 30px;
  margin-top: 50px;
}
.banners-container .content > div:nth-child(1) {
  margin-top: 0;
}
.banners-container .content > div:nth-child(2n) {
  display: inline-block;
  width: calc((100% - 30px * (2 - 1)) / 2 - 1px);
  height: max-content;
  margin-right: 30px;
  margin-top: 50px;
}
.banners-container .content > div:nth-child(2) {
  margin-top: 0;
}
.banners-container .content > div:nth-child(2n) {
  margin-right: 0;
}
.banners-container .content .banner-card {
  cursor: pointer;
  display: flex;
  border-radius: calc(1.2vw / 2);
  overflow: hidden;
  position: relative;
}
.banners-container .content .banner-card > .text-section {
  background-color: #3f6285;
  border-right: 4px solid white;
  width: 20%;
  position: relative;
}
.banners-container .content .banner-card > .text-section > .banner__tag {
  position: absolute;
  left: 0;
  top: 0;
  padding: calc(1vw / 2) calc(1vw / 2) calc(0.6vw / 2) calc(1vw / 2);
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: calc(1vw / 2);
  background-color: #0047ba;
  border-bottom-right-radius: calc(1vw / 2);
}
@media only screen and (max-width: 1199px) {
  .banners-container .content .banner-card > .text-section > .banner__tag {
    font-size: calc(1.2vw / 2);
    padding: calc(1.4vw / 2) calc(1.2vw / 2) calc(0.8vw / 2) calc(1.2vw / 2);
  }
}
@media only screen and (max-width: 799px) {
  .banners-container .content .banner-card > .text-section > .banner__tag {
    display: none;
  }
}
.banners-container .content .banner-card > .text-section > .banner__content {
  position: absolute;
  left: calc(1vw / 2);
  top: calc(4vw / 2);
  width: calc(100% - (2vw / 2));
}
.banners-container .content .banner-card > .text-section > .banner__content > .banner__content__title {
  margin-top: calc(0.8vw / 2);
  font-family: "ADNOC Sans Bold";
  color: #fff;
  font-size: calc(1.8vw / 2);
  line-height: calc(2.3vw / 2);
  word-break: all;
}
.banners-container .content .banner-card > .text-section > .banner__content > .banner__content__discount {
  font-family: "ADNOC Sans Bold";
  color: #fff;
  font-size: calc(1.1vw / 2);
  line-height: calc(1.6vw / 2);
}
.banners-container .content .banner-card > .text-section > .banner__content > .banner__content__discount .discount--new .old {
  text-decoration: line-through;
}
.banners-container .content .banner-card > .text-section > .banner__content > .banner__content__discount .discount--new .new {
  margin-left: 5px;
}
.banners-container .content .banner-card > .text-section > .banner__content > .banner__content__company {
  margin-top: calc(1.2vw / 2);
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: calc(1.2vw / 2);
  line-height: calc(1.5vw / 2);
}
.banners-container .content .banner-card > .text-section > .banner__btn_c {
  position: absolute;
  bottom: calc(1.5vw / 2);
  width: 100%;
  display: flex;
  justify-content: center;
}
.banners-container .content .banner-card > .text-section > .banner__btn_c > .banner__btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: calc(0.5vw / 2) calc(1.3vw / 2);
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: calc(0.8vw / 2);
  border: 1px solid #fff;
  border-radius: calc(1vw / 2);
  cursor: pointer;
}
.banners-container .content .banner-card > .text-section > .banner__btn_c > .banner__btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.banners-container .content .banner-card > .big-imgs-section {
  width: 40%;
  position: relative;
  border-right: 4px solid white;
}
.banners-container .content .banner-card > .big-imgs-section > img {
  width: 100%;
}
.banners-container .content .banner-card > .big-imgs-section .img {
  position: absolute;
  top: 0;
  left: 0;
}
.banners-container .content .banner-card > .small-imgs-section {
  display: inline-block;
  width: calc((80% - 3px) / 2);
  display: flex;
  flex-wrap: wrap;
}
.banners-container .content .banner-card > .small-imgs-section .small-img {
  width: calc(50% - 3px);
  position: relative;
}
.banners-container .content .banner-card > .small-imgs-section .small-img:nth-child(1) {
  border-right: 4px solid white;
}
.banners-container .content .banner-card > .small-imgs-section .small-img:nth-child(3) {
  border-top: 4px solid white;
  border-right: 4px solid white;
}
.banners-container .content .banner-card > .small-imgs-section .small-img:nth-child(4) {
  border-top: 4px solid white;
}
.banners-container .content .banner-card > .small-imgs-section .small-img > img {
  width: 100%;
}
.banners-container .content .banner-card > .small-imgs-section .small-img .img {
  position: absolute;
  left: 0;
}
.banners-container .content .banner-card .website-btn {
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/website_0_icon.svg") no-repeat center;
  background-size: auto 25px;
  position: absolute;
  right: 14px;
  top: 14px;
  z-index: 10;
  cursor: pointer;
}
.banners-container .content .banner-card .website-btn:hover {
  opacity: 0.8;
}
@media only screen and (max-width: 899px) {
  .banners-container .content {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .banners-container .content > div:nth-child(1n) {
    display: inline-block;
    width: calc((100% - 30px * (1 - 1)) / 1 - 1px);
    height: max-content;
    margin-right: 30px;
    margin-top: 50px;
  }
  .banners-container .content > div:nth-child(1) {
    margin-top: 0;
  }
  .banners-container .content > div:nth-child(1n) {
    margin-right: 0;
  }
  .banners-container .content .banner-card {
    cursor: pointer;
    display: flex;
    border-radius: calc(1.2vw / 1);
    overflow: hidden;
    position: relative;
  }
  .banners-container .content .banner-card > .text-section {
    background-color: #3f6285;
    border-right: 4px solid white;
    width: 20%;
    position: relative;
  }
  .banners-container .content .banner-card > .text-section > .banner__tag {
    position: absolute;
    left: 0;
    top: 0;
    padding: calc(1vw / 1) calc(1vw / 1) calc(0.6vw / 1) calc(1vw / 1);
    font-family: "ADNOC Sans Medium";
    color: #fff;
    font-size: calc(1vw / 1);
    background-color: #0047ba;
    border-bottom-right-radius: calc(1vw / 1);
  }
}
@media only screen and (max-width: 899px) and (max-width: 1199px) {
  .banners-container .content .banner-card > .text-section > .banner__tag {
    font-size: calc(1.2vw / 1);
    padding: calc(1.4vw / 1) calc(1.2vw / 1) calc(0.8vw / 1) calc(1.2vw / 1);
  }
}
@media only screen and (max-width: 899px) and (max-width: 799px) {
  .banners-container .content .banner-card > .text-section > .banner__tag {
    display: none;
  }
}
@media only screen and (max-width: 899px) {
  .banners-container .content .banner-card > .text-section > .banner__content {
    position: absolute;
    left: calc(1vw / 1);
    top: calc(4vw / 1);
    width: calc(100% - (2vw / 1));
  }
  .banners-container .content .banner-card > .text-section > .banner__content > .banner__content__title {
    margin-top: calc(0.8vw / 1);
    font-family: "ADNOC Sans Bold";
    color: #fff;
    font-size: calc(1.8vw / 1);
    line-height: calc(2.3vw / 1);
    word-break: all;
  }
  .banners-container .content .banner-card > .text-section > .banner__content > .banner__content__discount {
    font-family: "ADNOC Sans Bold";
    color: #fff;
    font-size: calc(1.1vw / 1);
    line-height: calc(1.6vw / 1);
  }
  .banners-container .content .banner-card > .text-section > .banner__content > .banner__content__discount .discount--new .old {
    text-decoration: line-through;
  }
  .banners-container .content .banner-card > .text-section > .banner__content > .banner__content__discount .discount--new .new {
    margin-left: 5px;
  }
  .banners-container .content .banner-card > .text-section > .banner__content > .banner__content__company {
    margin-top: calc(1.2vw / 1);
    font-family: "ADNOC Sans Medium";
    color: #fff;
    font-size: calc(1.2vw / 1);
    line-height: calc(1.5vw / 1);
  }
}
@media only screen and (max-width: 899px) {
  .banners-container .content .banner-card > .text-section > .banner__btn_c {
    position: absolute;
    bottom: calc(1.5vw / 1);
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .banners-container .content .banner-card > .text-section > .banner__btn_c > .banner__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: calc(0.5vw / 1) calc(1.3vw / 1);
    font-family: "ADNOC Sans Regular";
    color: #fff;
    font-size: calc(0.8vw / 1);
    border: 1px solid #fff;
    border-radius: calc(1vw / 1);
    cursor: pointer;
  }
  .banners-container .content .banner-card > .text-section > .banner__btn_c > .banner__btn:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
@media only screen and (max-width: 899px) {
  .banners-container .content .banner-card > .big-imgs-section {
    width: 40%;
    position: relative;
    border-right: 4px solid white;
  }
  .banners-container .content .banner-card > .big-imgs-section > img {
    width: 100%;
  }
  .banners-container .content .banner-card > .big-imgs-section .img {
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media only screen and (max-width: 899px) {
  .banners-container .content .banner-card > .small-imgs-section {
    display: inline-block;
    width: calc((80% - 3px) / 2);
    display: flex;
    flex-wrap: wrap;
  }
  .banners-container .content .banner-card > .small-imgs-section .small-img {
    width: calc(50% - 3px);
    position: relative;
  }
  .banners-container .content .banner-card > .small-imgs-section .small-img:nth-child(1) {
    border-right: 4px solid white;
  }
  .banners-container .content .banner-card > .small-imgs-section .small-img:nth-child(3) {
    border-top: 4px solid white;
    border-right: 4px solid white;
  }
  .banners-container .content .banner-card > .small-imgs-section .small-img:nth-child(4) {
    border-top: 4px solid white;
  }
  .banners-container .content .banner-card > .small-imgs-section .small-img > img {
    width: 100%;
  }
  .banners-container .content .banner-card > .small-imgs-section .small-img .img {
    position: absolute;
    left: 0;
  }
}
@media only screen and (max-width: 899px) {
  .banners-container .content .banner-card .website-btn {
    width: 30px;
    height: 30px;
    background: url("/assets/images/icons/website_0_icon.svg") no-repeat center;
    background-size: auto 25px;
    position: absolute;
    right: 14px;
    top: 14px;
    z-index: 10;
    cursor: pointer;
  }
  .banners-container .content .banner-card .website-btn:hover {
    opacity: 0.8;
  }
}

.user-invitation-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .user-invitation-container {
    padding: 0px 20px;
  }
}
.user-invitation-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .user-invitation-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .user-invitation-container .header .title-box {
    margin-bottom: 20px;
  }
}
.user-invitation-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.user-invitation-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .user-invitation-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .user-invitation-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .user-invitation-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.user-invitation-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .user-invitation-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .user-invitation-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .user-invitation-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.user-invitation-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .user-invitation-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .user-invitation-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .user-invitation-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.user-invitation-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.user-invitation-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .user-invitation-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .user-invitation-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .user-invitation-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.user-invitation-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.user-invitation-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .user-invitation-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .user-invitation-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.user-invitation-container .approve_btn {
  width: 19px;
  height: 30px;
  background: url("/assets/images/icons/approve_0_icon.svg") no-repeat center;
  background-size: auto 18px;
  cursor: pointer;
}
.user-invitation-container .approve_btn:hover {
  width: 19px;
  height: 30px;
  background: url("/assets/images/icons/approve_1_icon.svg") no-repeat center;
  background-size: auto 18px;
}
.user-invitation-container .content .table-user-invitation .table-body .col-user {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 0.9rem;
  line-height: 1rem;
}
.user-invitation-container .content .table-user-invitation .table-body .col-type {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
}
.user-invitation-container .content .table-user-invitation .table-body .col-options {
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.9rem;
}
.user-invitation-container .content .table-user-invitation .table-body .col-options > * {
  margin-left: 10px;
}
.user-invitation-container .content .table-user-invitation .table-body .col-options .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.user-invitation-container .content .table-user-invitation .table-body .col-options .delete:hover {
  opacity: 0.8;
}
.user-invitation-container .content .table-user-invitation .col-user {
  flex-grow: 1;
}
.user-invitation-container .content .table-user-invitation .col-type {
  width: 300px;
}
.user-invitation-container .content .table-user-invitation .col-options {
  width: 100px;
}
.user-invitation-container .content .table-user-invitation .col-options-btn {
  width: 75px;
  display: flex;
  justify-content: center;
}
.user-invitation-container .content .table-user-invitation .col-date {
  width: 200px;
}
.user-invitation-container .content .table-user-invitation .col-suggestion {
  width: 580px;
  margin-right: 25px;
}
.user-invitation-container .content .table-user-invitation .col-suggestion--wider {
  width: 669px;
}
.user-invitation-container .content .table-user-invitation .col-buyer {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
}
.user-invitation-container .content .table-user-invitation .col-buyer .buyer {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
  line-height: 1.3rem;
}
.user-invitation-container .content .table-user-invitation .col-buyer .buyer:hover {
  color: #0047ba;
}
@media only screen and (max-width: 899px) {
  .user-invitation-container .content .table-user-invitation .col-buyer {
    display: none;
  }
}
.user-invitation-container .content .table-user-invitation .col-data-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.mail-storage-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .mail-storage-container {
    padding: 0px 20px;
  }
}
.mail-storage-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .mail-storage-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .mail-storage-container .header .title-box {
    margin-bottom: 20px;
  }
}
.mail-storage-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.mail-storage-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .mail-storage-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .mail-storage-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .mail-storage-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.mail-storage-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .mail-storage-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .mail-storage-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .mail-storage-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.mail-storage-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .mail-storage-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .mail-storage-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .mail-storage-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.mail-storage-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.mail-storage-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .mail-storage-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .mail-storage-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .mail-storage-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.mail-storage-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.mail-storage-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .mail-storage-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .mail-storage-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.mail-storage-container .content .table-mail-storage .table-body .col-userEmail {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 0.9rem;
  line-height: 1rem;
}
.mail-storage-container .content .table-mail-storage .table-body .col-subject {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
}
.mail-storage-container .content .table-mail-storage .table-body .col-date {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
}
.mail-storage-container .content .table-mail-storage .table-body .col-status {
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.9rem;
}
.mail-storage-container .content .table-mail-storage .col-userEmail {
  width: 300px;
}
.mail-storage-container .content .table-mail-storage .col-subject {
  flex-grow: 1;
}
.mail-storage-container .content .table-mail-storage .col-date {
  width: 200px;
}
.mail-storage-container .content .table-mail-storage .col-status {
  width: 150px;
}

.domains-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .domains-container {
    padding: 0px 20px;
  }
}
.domains-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .domains-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .domains-container .header .title-box {
    margin-bottom: 20px;
  }
}
.domains-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.domains-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .domains-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .domains-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .domains-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.domains-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .domains-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .domains-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .domains-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.domains-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .domains-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .domains-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .domains-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.domains-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.domains-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .domains-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .domains-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .domains-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.domains-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.domains-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .domains-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .domains-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.domains-container .content .table-domains .table-body .col-type {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 0.9rem;
}
.domains-container .content .table-domains .table-body .col-key {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
}
.domains-container .content .table-domains .table-body .col-domains {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
}
.domains-container .content .table-domains .table-body .col-options {
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.9rem;
  display: flex;
}
.domains-container .content .table-domains .table-body .col-options > * {
  margin-left: 10px;
}
.domains-container .content .table-domains .table-body .col-options .edit {
  width: 20px;
  height: 15px;
  background: url("/assets/images/icons/edit_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.domains-container .content .table-domains .table-body .col-options .edit:hover {
  opacity: 0.8;
}
.domains-container .content .table-domains .table-body .col-options .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.domains-container .content .table-domains .table-body .col-options .delete:hover {
  opacity: 0.8;
}
.domains-container .content .table-domains .col-type {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.domains-container .content .table-domains .col-key {
  width: 300px;
}
.domains-container .content .table-domains .col-domains {
  flex-grow: 1;
}
.domains-container .content .table-domains .col-options {
  width: 50px;
}

.suppliers-pending-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .suppliers-pending-container {
    padding: 0px 20px;
  }
}
.suppliers-pending-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .suppliers-pending-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .suppliers-pending-container .header .title-box {
    margin-bottom: 20px;
  }
}
.suppliers-pending-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.suppliers-pending-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .suppliers-pending-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .suppliers-pending-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .suppliers-pending-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.suppliers-pending-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .suppliers-pending-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .suppliers-pending-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .suppliers-pending-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.suppliers-pending-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .suppliers-pending-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .suppliers-pending-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .suppliers-pending-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.suppliers-pending-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.suppliers-pending-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .suppliers-pending-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .suppliers-pending-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .suppliers-pending-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.suppliers-pending-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.suppliers-pending-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .suppliers-pending-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .suppliers-pending-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.suppliers-pending-container .content .table-suppliers-pending .table-body .col-suppliers {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 0.9rem;
}
.suppliers-pending-container .content .table-suppliers-pending .table-body .col-contact {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
  line-height: 1.3rem;
  word-break: break-word;
}
.suppliers-pending-container .content .table-suppliers-pending .table-body .col-contact .contact_mail {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
}
.suppliers-pending-container .content .table-suppliers-pending .table-body .col-contact .contact_mail:hover {
  color: #0047ba;
}
.suppliers-pending-container .content .table-suppliers-pending .table-body .col-contact .contact_phone {
  cursor: pointer;
}
.suppliers-pending-container .content .table-suppliers-pending .table-body .col-contact .contact_phone:hover {
  color: #0047ba;
}
.suppliers-pending-container .content .table-suppliers-pending .table-body .col-description {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
  line-height: 1.3rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
}
@media only screen and (max-width: 1299px) {
  .suppliers-pending-container .content .table-suppliers-pending .table-body .col-description {
    display: none;
  }
}
.suppliers-pending-container .content .table-suppliers-pending .table-body .col-status {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
  line-height: 1.3rem;
  display: -webkit-box;
  overflow: hidden;
  cursor: pointer;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
@media only screen and (max-width: 1299px) {
  .suppliers-pending-container .content .table-suppliers-pending .table-body .col-status {
    display: none;
  }
}
.suppliers-pending-container .content .table-suppliers-pending .table-body .col-options {
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-end;
}
.suppliers-pending-container .content .table-suppliers-pending .table-body .col-options > * {
  margin-left: 10px;
}
.suppliers-pending-container .content .table-suppliers-pending .table-body .col-options .approve_btn {
  width: 19px;
  height: 30px;
  background: url("/assets/images/icons/approve_0_icon.svg") no-repeat center;
  background-size: auto 18px;
  cursor: pointer;
}
.suppliers-pending-container .content .table-suppliers-pending .table-body .col-options .approve_btn:hover {
  width: 19px;
  height: 30px;
  background: url("/assets/images/icons/approve_1_icon.svg") no-repeat center;
  background-size: auto 18px;
}
.suppliers-pending-container .content .table-suppliers-pending .table-body .col-options .reject_btn {
  width: 20px;
  height: 30px;
  background: url("/assets/images/icons/reject_0_icon.svg") no-repeat center;
  background-size: auto 19px;
  cursor: pointer;
}
.suppliers-pending-container .content .table-suppliers-pending .table-body .col-options .reject_btn:hover {
  width: 20px;
  height: 30px;
  background: url("/assets/images/icons/reject_1_icon.svg") no-repeat center;
  background-size: auto 19px;
}
.suppliers-pending-container .content .table-suppliers-pending .table-body .col-options .description_btn {
  display: none;
  width: 16px;
  height: 30px;
  background: url("/assets/images/icons/comment_0_icon.svg") no-repeat center;
  background-size: auto 15px;
  cursor: pointer;
}
.suppliers-pending-container .content .table-suppliers-pending .table-body .col-options .description_btn:hover {
  width: 16px;
  height: 30px;
  background: url("/assets/images/icons/comment_1_icon.svg") no-repeat center;
  background-size: auto 15px;
}
@media only screen and (max-width: 1299px) {
  .suppliers-pending-container .content .table-suppliers-pending .table-body .col-options .description_btn {
    display: block;
  }
}
.suppliers-pending-container .content .table-suppliers-pending .col-suppliers {
  flex-grow: 1;
}
.suppliers-pending-container .content .table-suppliers-pending .col-contact {
  width: 250px;
}
.suppliers-pending-container .content .table-suppliers-pending .col-status {
  width: 250px;
}
.suppliers-pending-container .content .table-suppliers-pending .col-description {
  width: 400px;
}
@media only screen and (max-width: 1299px) {
  .suppliers-pending-container .content .table-suppliers-pending .col-description {
    display: none;
  }
}
.suppliers-pending-container .content .table-suppliers-pending .col-options {
  width: 100px;
}

.suppliers-edit-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .suppliers-edit-container {
    padding: 0px 20px;
  }
}
.suppliers-edit-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .suppliers-edit-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .suppliers-edit-container .header .title-box {
    margin-bottom: 20px;
  }
}
.suppliers-edit-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.suppliers-edit-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .suppliers-edit-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .suppliers-edit-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .suppliers-edit-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.suppliers-edit-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .suppliers-edit-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .suppliers-edit-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .suppliers-edit-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.suppliers-edit-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .suppliers-edit-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .suppliers-edit-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .suppliers-edit-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.suppliers-edit-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.suppliers-edit-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .suppliers-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .suppliers-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .suppliers-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.suppliers-edit-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.suppliers-edit-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .suppliers-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .suppliers-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.suppliers-edit-container > .footer {
  margin: 30px 0 0 0;
}
.suppliers-edit-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.suppliers-edit-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.offers-rating-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .offers-rating-container {
    padding: 0px 20px;
  }
}
.offers-rating-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .offers-rating-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .offers-rating-container .header .title-box {
    margin-bottom: 20px;
  }
}
.offers-rating-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.offers-rating-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .offers-rating-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-rating-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-rating-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.offers-rating-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .offers-rating-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-rating-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-rating-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.offers-rating-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .offers-rating-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-rating-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-rating-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.offers-rating-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.offers-rating-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .offers-rating-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .offers-rating-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .offers-rating-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.offers-rating-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.offers-rating-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .offers-rating-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .offers-rating-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.offers-rating-container > .footer {
  margin: 30px 0 0 0;
}
.offers-rating-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.offers-rating-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.offers-rating-container .content .table-rating .table-body .col-buyer {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
}
.offers-rating-container .content .table-rating .table-body .col-buyer .buyer {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
  line-height: 1.3rem;
}
.offers-rating-container .content .table-rating .table-body .col-buyer .buyer:hover {
  color: #0047ba;
}
@media only screen and (max-width: 899px) {
  .offers-rating-container .content .table-rating .table-body .col-buyer {
    display: none;
  }
}
.offers-rating-container .content .table-rating .table-body .col-offer {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
  line-height: 1.3rem;
  word-break: break-word;
}
.offers-rating-container .content .table-rating .table-body .col-offer .offer__data {
  display: inline-block;
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 0.9rem;
  cursor: pointer;
}
.offers-rating-container .content .table-rating .table-body .col-offer .offer__data:hover {
  color: #0047ba;
}
.offers-rating-container .content .table-rating .table-body .col-rate {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.offers-rating-container .content .table-rating .table-body .col-rate .rate__date {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
  line-height: 1.3rem;
}
.offers-rating-container .content .table-rating .table-body .col-rate > .rate__rating {
  position: relative;
  display: inline-block;
  margin-bottom: 5px;
  width: 78px;
  height: 14px;
  background: url("/assets/images/icons/stars_empty_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.offers-rating-container .content .table-rating .table-body .col-rate > .rate__rating > .rating-positive {
  position: absolute;
  width: 0%;
  height: 14px;
  background: url("/assets/images/icons/stars_full_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.offers-rating-container .content .table-rating .table-body .col-comment {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
  line-height: 1.3rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
}
@media only screen and (max-width: 1299px) {
  .offers-rating-container .content .table-rating .table-body .col-comment {
    display: none;
  }
}
@media only screen and (max-width: 1299px) {
  .offers-rating-container .content .table-rating .table-body .col-visible {
    display: none;
  }
}
.offers-rating-container .content .table-rating .table-body .col-visible > .visible__btn {
  width: 50px;
  height: 30px;
  background: url("/assets/images/icons/visible_0_icon.svg") no-repeat center;
  background-size: auto 10px;
  cursor: pointer;
}
.offers-rating-container .content .table-rating .table-body .col-visible > .visible__btn--active {
  width: 50px;
  height: 30px;
  background: url("/assets/images/icons/visible_1_icon.svg") no-repeat center;
  background-size: auto 10px;
  cursor: pointer;
}
.offers-rating-container .content .table-rating .table-body .col-options {
  align-items: center;
  justify-content: flex-end;
}
.offers-rating-container .content .table-rating .table-body .col-options > .visible_btn {
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/visible_0_icon.svg") no-repeat center;
  background-size: auto 10px;
  cursor: pointer;
}
.offers-rating-container .content .table-rating .table-body .col-options > .visible_btn--active {
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/visible_1_icon.svg") no-repeat center;
  background-size: auto 10px;
  cursor: pointer;
}
.offers-rating-container .content .table-rating .table-body .col-options .comment_btn {
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/comment_0_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.offers-rating-container .content .table-rating .table-body .col-options .comment_btn:hover {
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/comment_1_icon.svg") no-repeat center;
  background-size: auto 14px;
}
.offers-rating-container .content .table-rating .col-buyer {
  min-width: 250px;
  max-width: 250px;
}
@media only screen and (max-width: 899px) {
  .offers-rating-container .content .table-rating .col-buyer {
    display: none;
  }
}
.offers-rating-container .content .table-rating .col-offer {
  flex-grow: 1;
}
.offers-rating-container .content .table-rating .col-rate {
  min-width: 150px;
  max-width: 150px;
}
.offers-rating-container .content .table-rating .col-comment {
  min-width: 500px;
  max-width: 500px;
}
@media only screen and (max-width: 1299px) {
  .offers-rating-container .content .table-rating .col-comment {
    display: none;
  }
}
.offers-rating-container .content .table-rating .col-visible {
  width: 50px;
}
@media only screen and (max-width: 1299px) {
  .offers-rating-container .content .table-rating .col-visible {
    display: none;
  }
}
.offers-rating-container .content .table-rating .col-options {
  width: 100px;
  display: none;
}
@media only screen and (max-width: 1299px) {
  .offers-rating-container .content .table-rating .col-options {
    display: flex;
  }
}

.email-templates-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .email-templates-container {
    padding: 0px 20px;
  }
}
.email-templates-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .email-templates-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .email-templates-container .header .title-box {
    margin-bottom: 20px;
  }
}
.email-templates-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.email-templates-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .email-templates-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .email-templates-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .email-templates-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.email-templates-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .email-templates-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .email-templates-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .email-templates-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.email-templates-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .email-templates-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .email-templates-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .email-templates-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.email-templates-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.email-templates-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .email-templates-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .email-templates-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .email-templates-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.email-templates-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.email-templates-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .email-templates-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .email-templates-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.email-templates-container > .footer {
  margin: 30px 0 0 0;
}
.email-templates-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.email-templates-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.email-templates-container .content .table-rating .table-body .col-name {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 0.9rem;
  line-height: 1.3rem;
  cursor: pointer;
}
.email-templates-container .content .table-rating .table-body .col-name:hover {
  color: #0047ba;
}
.email-templates-container .content .table-rating .table-body .col-message,
.email-templates-container .content .table-rating .table-body .col-notification,
.email-templates-container .content .table-rating .table-body .col-sms {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
  line-height: 1.3rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media only screen and (max-width: 1299px) {
  .email-templates-container .content .table-rating .table-body .col-message,
  .email-templates-container .content .table-rating .table-body .col-notification,
  .email-templates-container .content .table-rating .table-body .col-sms {
    display: none;
  }
}
.email-templates-container .content .table-rating .col-name {
  flex-grow: 1;
}
.email-templates-container .content .table-rating .col-message {
  min-width: 200px;
  max-width: 200px;
}
@media only screen and (max-width: 1299px) {
  .email-templates-container .content .table-rating .col-message {
    display: none;
  }
}
.email-templates-container .content .table-rating .col-notification {
  min-width: 200px;
  max-width: 200px;
}
@media only screen and (max-width: 1299px) {
  .email-templates-container .content .table-rating .col-notification {
    display: none;
  }
}
.email-templates-container .content .table-rating .col-sms {
  min-width: 200px;
  max-width: 200px;
}
@media only screen and (max-width: 1299px) {
  .email-templates-container .content .table-rating .col-sms {
    display: none;
  }
}
.email-templates-container .content .table-rating .col-mailTemplateType {
  width: 100px;
}
.email-templates-container .content .table-rating .col-notificationType {
  width: 100px;
}

.email-template-edit-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .email-template-edit-container {
    padding: 0px 20px;
  }
}
.email-template-edit-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .email-template-edit-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .email-template-edit-container .header .title-box {
    margin-bottom: 20px;
  }
}
.email-template-edit-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.email-template-edit-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .email-template-edit-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .email-template-edit-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .email-template-edit-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.email-template-edit-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .email-template-edit-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .email-template-edit-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .email-template-edit-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.email-template-edit-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .email-template-edit-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .email-template-edit-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .email-template-edit-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.email-template-edit-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.email-template-edit-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .email-template-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .email-template-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .email-template-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.email-template-edit-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.email-template-edit-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .email-template-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .email-template-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.email-template-edit-container > .footer {
  margin: 30px 0 0 0;
}
.email-template-edit-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.email-template-edit-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.supplier-announcement-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .supplier-announcement-container {
    padding: 0px 20px;
  }
}
.supplier-announcement-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .supplier-announcement-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .supplier-announcement-container .header .title-box {
    margin-bottom: 20px;
  }
}
.supplier-announcement-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.supplier-announcement-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .supplier-announcement-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .supplier-announcement-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .supplier-announcement-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.supplier-announcement-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .supplier-announcement-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .supplier-announcement-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .supplier-announcement-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.supplier-announcement-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .supplier-announcement-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .supplier-announcement-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .supplier-announcement-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.supplier-announcement-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.supplier-announcement-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .supplier-announcement-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .supplier-announcement-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .supplier-announcement-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.supplier-announcement-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.supplier-announcement-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .supplier-announcement-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .supplier-announcement-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.supplier-announcement-container > .footer {
  margin: 30px 0 0 0;
}
.supplier-announcement-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.supplier-announcement-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.supplier-announcement-container > .content {
  display: flex;
}
.supplier-announcement-container > .content .f-select-multiple {
  margin-left: 4.5%;
  display: inline-block;
  width: 100%;
}
.supplier-announcement-container > .content .f-select-multiple > .error {
  bottom: -20px;
  right: 0;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.supplier-announcement-container > .content .f-select-multiple > .error:before {
  content: "! ";
}
.supplier-announcement-container > .content .req {
  display: inline-block;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  margin-right: 2%;
  margin-bottom: 1%;
}
.supplier-announcement-container > .content .req::after {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  content: "*";
  margin-left: 5px;
  left: 1px !important;
}
.supplier-announcement-container > .content .f-checkbox {
  display: inline-block;
  margin-right: 2%;
}
.supplier-announcement-container > .content .f-checkbox label {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 0.9rem;
}
.supplier-announcement-container > .content .attachments > .attachment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 20px 0;
}
.supplier-announcement-container > .content .attachments > .attachment > .file-name {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  margin: 0 20px 0 0;
  cursor: pointer;
}
.supplier-announcement-container > .content .attachments > .attachment > .file-name:hover {
  color: #0047ba;
}
.supplier-announcement-container > .content .attachments > .no-attachments {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
}
.supplier-announcement-container > .content .attachments .note {
  margin-top: 20px;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 1rem;
}
.supplier-announcement-container > .content .filter_box {
  width: 300px;
  margin-right: 50px;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal {
  width: 100%;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_header {
  display: none;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content {
  width: 100%;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword {
  margin-top: 3px;
  display: flex;
  height: 48px;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  border: none;
  border-radius: 26px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_0_icon.svg") no-repeat center;
  background-size: 17px auto;
  margin: 0 15px 0 0;
  cursor: pointer;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-icon:hover {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_1_icon.svg") no-repeat center;
  background-size: 17px auto;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input {
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 1.3rem;
  border: none;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-keyword > .search-input::placeholder {
  line-height: 1.3rem !important;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_0_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
  border-bottom: 1px solid #c5c5c5;
  padding: 20px 0;
  cursor: pointer;
}
@media only screen and (max-width: 1339px) {
  .supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header {
    font-size: 1.0625rem;
  }
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-header:hover {
  color: #0047ba;
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_2_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options {
  display: none;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar {
  width: 6px;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option {
  padding: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox {
  margin: 0;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label:hover {
  color: #0047ba;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  width: 78px;
  height: 14px;
  background: url("/assets/images/icons/stars_empty_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .f-checkbox label > .stars > .stars-positive {
  position: absolute;
  width: 0%;
  height: 14px;
  background: url("/assets/images/icons/stars_full_icon.svg") no-repeat center left;
  background-size: auto 13px;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option > .counter {
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion > .criterion-options > .option:first-of-type {
  padding: 0;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion:first-of-type > .criterion-header {
  padding-top: 0;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_1_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
  border: none;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-header:hover {
  width: 100%;
  height: 100%;
  background: url("/assets/images/icons/arrow_search_3_icon.svg") no-repeat center right 3px;
  background-size: auto 9px;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .search-criterion-extended > .criterion-options {
  display: block;
  max-height: 190px;
  overflow-y: auto;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset {
  margin-top: 10px;
  text-align: right;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn {
  display: inline-block;
  padding-top: 3px;
  margin-right: 7px;
  padding-right: 7px;
  border-right: 2px solid #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .collapse-btn:hover {
  opacity: 0.8;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn {
  display: inline-block;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset > .reset-btn:hover {
  opacity: 0.8;
}
.supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_footer {
  display: none;
}
@media only screen and (max-width: 999px) {
  .supplier-announcement-container > .content .filter_box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(236, 241, 250, 0.7);
  }
  .supplier-announcement-container > .content .filter_box .filter_box_modal {
    max-width: 600px;
    min-width: 500px;
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 30px);
    min-height: 150px;
    background-color: #fff;
    padding: 0 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  }
  .supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_header {
    display: block;
    font-family: "ADNOC Sans Bold";
    color: #0047ba;
    font-size: 1rem;
    padding: 20px 0 15px 0;
    border-bottom: 1px solid #c5c5c5;
  }
  .supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content {
    margin: 30px 0;
    overflow-x: auto;
    padding-right: 10px;
    padding-left: 3px;
    max-height: calc(50vh - 50px);
  }
  .supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
    width: 6px;
  }
  .supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
    background: #f8f8f8;
  }
  .supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
    background: #c0cace;
    border-radius: 5px;
    cursor: pointer;
  }
  .supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
    background: #0047ba;
  }
  .supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .criterion-options {
    display: block;
    max-height: 1000px !important;
    overflow-y: auto;
  }
  .supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_content .reset {
    display: none;
  }
  .supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_footer {
    display: block;
    padding: 0 0 20px 0;
    display: flex;
    justify-content: flex-end;
  }
  .supplier-announcement-container > .content .filter_box .filter_box_modal .filter_box_modal_footer > * {
    margin: 0 0 0 10px;
  }
}
.supplier-announcement-container > .content .filter_box-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(236, 241, 250, 0.7);
}
.supplier-announcement-container > .content .filter_box-modal .filter_box_modal {
  max-width: 600px;
  min-width: 500px;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 30px);
  min-height: 150px;
  background-color: #fff;
  padding: 0 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}
.supplier-announcement-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_header {
  display: block;
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  padding: 20px 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.supplier-announcement-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content {
  margin: 30px 0;
  overflow-x: auto;
  padding-right: 10px;
  padding-left: 3px;
  max-height: calc(50vh - 50px);
}
.supplier-announcement-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar {
  width: 6px;
}
.supplier-announcement-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.supplier-announcement-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.supplier-announcement-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.supplier-announcement-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_content .reset {
  display: none;
}
.supplier-announcement-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_footer {
  display: block;
  padding: 0 0 20px 0;
  display: flex;
  justify-content: flex-end;
}
.supplier-announcement-container > .content .filter_box-modal .filter_box_modal .filter_box_modal_footer > * {
  margin: 0 0 0 10px;
}
.companies-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .companies-container {
    padding: 0px 20px;
  }
}
.companies-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .companies-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .companies-container .header .title-box {
    margin-bottom: 20px;
  }
}
.companies-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.companies-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .companies-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .companies-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .companies-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.companies-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .companies-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .companies-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .companies-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.companies-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .companies-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .companies-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .companies-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.companies-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.companies-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .companies-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .companies-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .companies-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.companies-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.companies-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .companies-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .companies-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.companies-container .content .table-company .table-body .col-company {
  display: flex;
  flex-basis: 0;
  align-items: center;
}
.companies-container .content .table-company .table-body .col-company .logo {
  width: 60px;
  margin: 0 10px 0 0;
  cursor: pointer;
}
.companies-container .content .table-company .table-body .col-company .logo:hover {
  opacity: 0.8;
}
.companies-container .content .table-company .table-body .col-company > .name {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  line-height: 1.2rem;
  cursor: pointer;
}
.companies-container .content .table-company .table-body .col-company > .name:hover {
  color: #0047ba;
}
.companies-container .content .table-company .table-body .col-contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.companies-container .content .table-company .table-body .col-contacts > .contact {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
  line-height: 1.3rem;
  word-break: break-word;
  cursor: pointer;
}
.companies-container .content .table-company .table-body .col-contacts > .contact:hover {
  color: #0047ba;
}
@media only screen and (max-width: 899px) {
  .companies-container .content .table-company .table-body .col-contacts {
    display: none;
  }
}
.companies-container .content .table-company .table-body .col-options {
  display: flex;
  justify-content: flex-end;
}
.companies-container .content .table-company .table-body .col-options .edit {
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/edit_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.companies-container .content .table-company .table-body .col-options .edit:hover {
  opacity: 0.8;
}
.companies-container .content .table-company .table-body .col-options .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.companies-container .content .table-company .table-body .col-options .delete:hover {
  opacity: 0.8;
}
.companies-container .content .table-company .col-company {
  flex-grow: 1;
}
.companies-container .content .table-company .col-contacts {
  width: 300px;
}
@media only screen and (max-width: 899px) {
  .companies-container .content .table-company .col-contacts {
    display: none;
  }
}
.companies-container .content .table-company .col-options {
  width: 100px;
}

.company-edit-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .company-edit-container {
    padding: 0px 20px;
  }
}
.company-edit-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .company-edit-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .company-edit-container .header .title-box {
    margin-bottom: 20px;
  }
}
.company-edit-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.company-edit-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .company-edit-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .company-edit-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .company-edit-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.company-edit-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .company-edit-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .company-edit-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .company-edit-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.company-edit-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .company-edit-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .company-edit-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .company-edit-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.company-edit-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.company-edit-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .company-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .company-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .company-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.company-edit-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.company-edit-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .company-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .company-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.company-edit-container > .footer {
  margin: 30px 0 0 0;
}
.company-edit-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.company-edit-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.company-edit-container .content .location .map {
  width: 100%;
}
.company-edit-container .content .location .location-choice > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.company-edit-container .content .location .location-choice > .f-checkbox {
  margin: 0 0 10px 0;
}
.company-edit-container .content .location .location-choice > .f-basic-btn {
  margin: 15px 0 0 0;
}
@media only screen and (max-width: 1099px) {
  .company-edit-container .content .location .col-2 {
    width: 100%;
  }
  .company-edit-container .content .location .location-choice {
    margin-top: 30px;
  }
}
.company-edit-container .content .focal-points {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  padding-bottom: 80px;
}
.company-edit-container .content .focal-points .user-card {
  position: relative;
  display: flex;
  text-align: center;
}
.company-edit-container .content .focal-points .user-card > img {
  width: 70%;
  border-radius: 50%;
  border: 1px solid #c5c5c5;
  cursor: pointer;
}
.company-edit-container .content .focal-points .user-card > img:hover {
  opacity: 0.8;
}
.company-edit-container .content .focal-points .user-card > .data {
  position: absolute;
  width: 100%;
}
.company-edit-container .content .focal-points .user-card > .data > .name {
  margin: 15px 0 0 0;
  text-align: center;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  line-height: 1.2rem;
}
.company-edit-container .content .focal-points .user-card > .data > .title {
  margin: 8px 0 0 0;
  text-align: center;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
}
.company-edit-container .content .focal-points .user-card > .data > .mail {
  margin: 7px 0 0 0;
  text-align: center;
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  line-height: 1.2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: copy;
}
.company-edit-container .content .focal-points .user-card > .data > .mail:hover {
  color: #0047ba;
}
.company-edit-container .content .focal-points .user-card:hover > .settings {
  display: flex;
}
.company-edit-container .content .focal-points .user-card > .settings {
  top: 0px;
  right: 7px;
  position: absolute;
  display: none;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0px 5px;
  border-radius: 5px;
  z-index: 1;
  cursor: initial;
}
.company-edit-container .content .focal-points .user-card > .settings > .edit {
  width: 15px;
  height: 15px;
  background: url("/assets/images/icons/edit_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.company-edit-container .content .focal-points .user-card > .settings > .delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.company-edit-container .content .focal-points .user-card-inactive > img {
  opacity: 0.5;
}
.company-edit-container .content .focal-points > div:nth-child(1n) {
  display: inline-block;
  width: 180px;
  height: max-content;
  margin-right: calc((100% - 180px * 6) / (6 - 1) - 1px);
  margin-top: 115px;
}
.company-edit-container .content .focal-points > div:nth-child(1) {
  margin-top: 0;
}
.company-edit-container .content .focal-points > div:nth-child(2n) {
  display: inline-block;
  width: 180px;
  height: max-content;
  margin-right: calc((100% - 180px * 6) / (6 - 1) - 1px);
  margin-top: 115px;
}
.company-edit-container .content .focal-points > div:nth-child(2) {
  margin-top: 0;
}
.company-edit-container .content .focal-points > div:nth-child(3n) {
  display: inline-block;
  width: 180px;
  height: max-content;
  margin-right: calc((100% - 180px * 6) / (6 - 1) - 1px);
  margin-top: 115px;
}
.company-edit-container .content .focal-points > div:nth-child(3) {
  margin-top: 0;
}
.company-edit-container .content .focal-points > div:nth-child(4n) {
  display: inline-block;
  width: 180px;
  height: max-content;
  margin-right: calc((100% - 180px * 6) / (6 - 1) - 1px);
  margin-top: 115px;
}
.company-edit-container .content .focal-points > div:nth-child(4) {
  margin-top: 0;
}
.company-edit-container .content .focal-points > div:nth-child(5n) {
  display: inline-block;
  width: 180px;
  height: max-content;
  margin-right: calc((100% - 180px * 6) / (6 - 1) - 1px);
  margin-top: 115px;
}
.company-edit-container .content .focal-points > div:nth-child(5) {
  margin-top: 0;
}
.company-edit-container .content .focal-points > div:nth-child(6n) {
  display: inline-block;
  width: 180px;
  height: max-content;
  margin-right: calc((100% - 180px * 6) / (6 - 1) - 1px);
  margin-top: 115px;
}
.company-edit-container .content .focal-points > div:nth-child(6) {
  margin-top: 0;
}
.company-edit-container .content .focal-points > div:nth-child(6n) {
  margin-right: 0;
}
@media only screen and (max-width: 1099px) {
  .company-edit-container .content .focal-points {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .company-edit-container .content .focal-points > div:nth-child(1n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 6) / (6 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(1) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(2n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 6) / (6 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(2) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(3n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 6) / (6 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(3) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(4n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 6) / (6 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(4) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(5n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 6) / (6 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(5) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(6n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 6) / (6 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(6) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(6n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 999px) {
  .company-edit-container .content .focal-points {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .company-edit-container .content .focal-points > div:nth-child(1n) {
    display: inline-block;
    width: 180px;
    height: max-content;
    margin-right: calc((100% - 180px * 5) / (5 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(1) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(2n) {
    display: inline-block;
    width: 180px;
    height: max-content;
    margin-right: calc((100% - 180px * 5) / (5 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(2) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(3n) {
    display: inline-block;
    width: 180px;
    height: max-content;
    margin-right: calc((100% - 180px * 5) / (5 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(3) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(4n) {
    display: inline-block;
    width: 180px;
    height: max-content;
    margin-right: calc((100% - 180px * 5) / (5 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(4) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(5n) {
    display: inline-block;
    width: 180px;
    height: max-content;
    margin-right: calc((100% - 180px * 5) / (5 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(5) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(5n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 899px) {
  .company-edit-container .content .focal-points {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .company-edit-container .content .focal-points > div:nth-child(1n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 5) / (5 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(1) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(2n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 5) / (5 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(2) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(3n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 5) / (5 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(3) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(4n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 5) / (5 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(4) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(5n) {
    display: inline-block;
    width: 150px;
    height: max-content;
    margin-right: calc((100% - 150px * 5) / (5 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(5) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(5n) {
    margin-right: 0;
  }
}
@media only screen and (max-width: 749px) {
  .company-edit-container .content .focal-points {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .company-edit-container .content .focal-points > div:nth-child(1n) {
    display: inline-block;
    width: 160px;
    height: max-content;
    margin-right: calc((100% - 160px * 4) / (4 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(1) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(2n) {
    display: inline-block;
    width: 160px;
    height: max-content;
    margin-right: calc((100% - 160px * 4) / (4 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(2) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(3n) {
    display: inline-block;
    width: 160px;
    height: max-content;
    margin-right: calc((100% - 160px * 4) / (4 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(3) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(4n) {
    display: inline-block;
    width: 160px;
    height: max-content;
    margin-right: calc((100% - 160px * 4) / (4 - 1) - 1px);
    margin-top: 115px;
  }
  .company-edit-container .content .focal-points > div:nth-child(4) {
    margin-top: 0;
  }
  .company-edit-container .content .focal-points > div:nth-child(4n) {
    margin-right: 0;
  }
}

.survey-edit-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .survey-edit-container {
    padding: 0px 20px;
  }
}
.survey-edit-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .survey-edit-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .survey-edit-container .header .title-box {
    margin-bottom: 20px;
  }
}
.survey-edit-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.survey-edit-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .survey-edit-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .survey-edit-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .survey-edit-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.survey-edit-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .survey-edit-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .survey-edit-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .survey-edit-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.survey-edit-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .survey-edit-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .survey-edit-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .survey-edit-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.survey-edit-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.survey-edit-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .survey-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .survey-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .survey-edit-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.survey-edit-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.survey-edit-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .survey-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .survey-edit-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.survey-edit-container > .footer {
  margin: 30px 0 0 0;
}
.survey-edit-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.survey-edit-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.survey-edit-container .questions .question {
  margin-bottom: 20px;
  position: relative;
}
.survey-edit-container .questions .question__delete {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  cursor: pointer;
}
.survey-edit-container .questions .question__delete:hover {
  opacity: 0.8;
}
.survey-edit-container .multiplechoice .options {
  margin-bottom: 0;
}
.survey-edit-container .multiplechoice .option__settings {
  top: -20px;
  right: -30px;
  position: absolute;
  display: none;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0px 5px;
  border-radius: 5px;
  z-index: 1;
  cursor: initial;
}
.survey-edit-container .multiplechoice .option__delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin: 7px;
  cursor: pointer;
}
.survey-edit-container .multiplechoice .option:hover .option__settings {
  display: block;
}
.survey-edit-container .multiplechoice .option-add {
  font-family: "ADNOC Sans Medium";
  color: #6e6e6e;
  font-size: 1rem;
  cursor: pointer;
}
.survey-edit-container .multiplechoice .option-add:hover {
  color: #0047ba;
}

.survey-results-container {
  max-width: 1300px;
  margin: 50px auto 0 auto;
}
@media only screen and (max-width: 1339px) {
  .survey-results-container {
    padding: 0px 20px;
  }
}
.survey-results-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
@media only screen and (max-width: 999px) {
  .survey-results-container .header {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 30px 0;
  }
  .survey-results-container .header .title-box {
    margin-bottom: 20px;
  }
}
.survey-results-container .header > .title-box > .back {
  display: inline-block;
  margin: 0 0 7px 0;
  width: 52px;
  height: 14px;
  background: url("/assets/images/icons/back_icon.svg") no-repeat center left;
  background-size: auto 11px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  text-align: right;
  cursor: pointer;
}
.survey-results-container .header > .title-box > .title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 2.25rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1339px) {
  .survey-results-container .header > .title-box > .title {
    font-size: 2.1375rem;
  }
}
@media only screen and (max-width: 1049px) {
  .survey-results-container .header > .title-box > .title {
    font-size: 2.025rem;
  }
}
@media only screen and (max-width: 849px) {
  .survey-results-container .header > .title-box > .title {
    font-size: 1.9125rem;
  }
}
.survey-results-container .header > .title-box > .title--section {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1339px) {
  .survey-results-container .header > .title-box > .title--section {
    font-size: 1.6625rem;
  }
}
@media only screen and (max-width: 1049px) {
  .survey-results-container .header > .title-box > .title--section {
    font-size: 1.575rem;
  }
}
@media only screen and (max-width: 849px) {
  .survey-results-container .header > .title-box > .title--section {
    font-size: 1.4875rem;
  }
}
.survey-results-container .header > .title-box > .sub-title {
  margin: 5px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .survey-results-container .header > .title-box > .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .survey-results-container .header > .title-box > .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .survey-results-container .header > .title-box > .sub-title {
    font-size: 1.0625rem;
  }
}
.survey-results-container .header > .title-box > .sub-title > * {
  margin-right: 10px;
}
.survey-results-container .header > .title-box > .sub-title > .bold {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .survey-results-container .header > .title-box > .sub-title > .bold {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .survey-results-container .header > .title-box > .sub-title > .bold {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .survey-results-container .header > .title-box > .sub-title > .bold {
    font-size: 1.0625rem;
  }
}
.survey-results-container .header > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.survey-results-container .header > .tools-box > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 999px) {
  .survey-results-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-width: 799px) {
  .survey-results-container .header > .tools-box > * {
    margin: 0 10px 0 0;
  }
}
.survey-results-container > .footer {
  margin: 30px 0 0 0;
}
.survey-results-container > .footer > .tools-box {
  display: flex;
  justify-content: flex-end;
}
.survey-results-container > .footer > .tools-box > * {
  margin: 0 0 0 10px;
}
.survey-results-container .question {
  margin-bottom: 20px;
}
.survey-results-container .question__numeration {
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.9rem;
}
.survey-results-container .question__text {
  margin-top: 10px;
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.8rem;
  line-height: 1.1rem;
  overflow-wrap: break-word;
}
.survey-results-container .question__answer {
  margin-top: 30px;
}
.survey-results-container .qap-p-multiplechoice {
  width: 100%;
  display: flex;
  align-items: stretch;
}
.survey-results-container .qap-p-multiplechoice .multiplechoice-chart-labels {
  width: 100px;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 30px;
  padding-top: 10px;
}
.survey-results-container .qap-p-multiplechoice .multiplechoice-chart-labels .bar-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  text-align: right;
}
.survey-results-container .qap-p-basic .table .col-answer-hideContent {
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-word;
}
.survey-results-container .qap-p-basic .show-more {
  display: flex;
  align-items: center;
  justify-content: center;
}
.survey-results-container .qap-p-basic .show-more__btn {
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.9rem;
  cursor: pointer;
}
.survey-results-container .qap-p-basic .show-more__btn:hover {
  opacity: 0.8;
}
.survey-results-container .empty-results {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.survey-results-container .empty-results .sub-title {
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1339px) {
  .survey-results-container .empty-results .sub-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .survey-results-container .empty-results .sub-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .survey-results-container .empty-results .sub-title {
    font-size: 1.0625rem;
  }
}

.survey-answers {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  background: url("/assets/images/backgrounds/survey_background.svg") no-repeat center;
  background-size: auto 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.survey-answers__close {
  position: absolute;
  top: 20px;
  right: 20px;
}
.survey-answers__box {
  width: 75%;
}
.survey-answers__header {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
}
.survey-answers__logo {
  width: 100px;
  height: 60px;
  background: url("/assets/images/logos/mazaya_3_logo.svg") no-repeat center;
  background-size: 80px auto;
}
.survey-answers .start__title {
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
}
.survey-answers .start__description {
  margin-top: 20px;
  font-family: "ADNOC Sans Medium";
  color: #fff;
  font-size: 1rem;
  line-height: 1.5rem;
  word-break: break-word;
  text-align: justify;
  padding: 10px;
  max-height: 340px;
  overflow-y: scroll;
  padding-right: 15px;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.survey-answers .start__description:hover {
  padding-right: 10px;
}
.survey-answers .start__description::-webkit-scrollbar {
  width: 5px;
  display: none;
}
.survey-answers .start__description:hover::-webkit-scrollbar {
  width: 5px;
  display: block;
}
.survey-answers .start__description::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.survey-answers .start__description::-webkit-scrollbar-thumb {
  background: #888;
}
.survey-answers .start__description::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.survey-answers .start__footer {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.survey-answers .question__box {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
}
.survey-answers .question__header {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 0.8rem;
}
.survey-answers .question__text {
  margin-top: 10px;
  word-break: break-word;
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.8rem;
  line-height: 1.1rem;
}
.survey-answers .question__content {
  margin-top: 20px;
  min-height: 170px;
}
.survey-answers .question__footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.f-section {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 20px 0;
}
.f-long {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 0 0 30px 0;
}
.f-long > label {
  display: inline-block;
  min-width: 115px;
  width: 115px;
  margin: 0 10px 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  line-height: 1.2rem;
}
.f-long > .help-btn {
  right: 0px;
  bottom: 5px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/help_0_icon.svg") no-repeat center;
  background-size: auto 16px;
  position: absolute;
  cursor: pointer;
}
.f-long > .help {
  position: absolute;
  background-color: #0047ba;
  font-family: "ADNOC Sans Regular";
  color: #f1f1f1;
  font-size: 0.875rem;
  line-height: 1.1rem;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  top: 37px;
  width: calc(100% - 175px);
  right: 0;
}
.f-long > .req::after {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  content: "*";
  margin-left: 5px;
  left: 1px !important;
}
.f-long > .limit {
  position: absolute;
  right: 0;
  bottom: 8px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.8rem;
  background-color: white;
}
.f-long input {
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 1rem;
  line-height: 1.7rem;
  padding: 2px 5px 0px 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  box-shadow: 0 1px 0 transparent;
}
.f-long input::-webkit-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-long input::-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-long input:-ms-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-long input:-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-long input[type=checkbox] {
  display: none;
}
.f-long input[type=checkbox] + label {
  display: block;
  position: relative;
  padding-left: 25px;
  line-height: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.f-long input[type=checkbox] + label:before {
  content: "";
  display: block;
  margin-top: 7px;
  width: 15px;
  height: 15px;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
}
.f-long input[type=checkbox]:checked + label:before {
  background-color: #0047ba !important;
  border: 1px solid #0047ba;
}
.f-long input[type=checkbox]:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 8px;
  width: 3px;
  height: 7px;
  border: 1.6px solid white;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}
.f-long input[type=checkbox]:disabled + label:before {
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.f-long input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  opacity: 0.7;
}
.f-long input[type=checkbox]:disabled + label:hover {
  color: #333333;
}
.f-long .error {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.f-long .error:before {
  content: "! ";
}
.f-long .error-left {
  right: auto;
}
.f-long input:hover,
.f-long textarea:hover,
.f-long select:hover {
  background-color: rgba(255, 255, 255, 0.0156862745);
}
.f-long input:focus,
.f-long textarea:focus,
.f-long select:focus {
  border-color: #0047ba;
  box-shadow: 0 1.5px 0px -0.5px #0047ba;
  transition: border-color 0.13s ease-in-out, box-shadow 0.13s ease-in-out, -webkit-box-shadow 0.13s ease-in-out;
}
.f-long input:read-only,
.f-long textarea:read-only,
.f-long select:read-only {
  background-color: rgba(255, 255, 255, 0.031372549);
}
.f-long input:disabled,
.f-long textarea:disabled,
.f-long select:disabled {
  background-color: #f8f8f8;
  border-radius: 5px;
  border: none;
  cursor: not-allowed;
}
.f-long label {
  min-width: 250px;
  width: 250px;
}

.f-basic {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 0 0 30px 0;
}
.f-basic > label {
  display: inline-block;
  min-width: 115px;
  width: 115px;
  margin: 0 10px 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  line-height: 1.2rem;
}
.f-basic > .help-btn {
  right: 0px;
  bottom: 5px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/help_0_icon.svg") no-repeat center;
  background-size: auto 16px;
  position: absolute;
  cursor: pointer;
}
.f-basic > .help {
  position: absolute;
  background-color: #0047ba;
  font-family: "ADNOC Sans Regular";
  color: #f1f1f1;
  font-size: 0.875rem;
  line-height: 1.1rem;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  top: 37px;
  width: calc(100% - 175px);
  right: 0;
}
.f-basic > .req::after {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  content: "*";
  margin-left: 5px;
  left: 1px !important;
}
.f-basic > .limit {
  position: absolute;
  right: 0;
  bottom: 8px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.8rem;
  background-color: white;
}
.f-basic input {
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 1rem;
  line-height: 1.7rem;
  padding: 2px 5px 0px 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  box-shadow: 0 1px 0 transparent;
}
.f-basic input::-webkit-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-basic input::-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-basic input:-ms-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-basic input:-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-basic input[type=checkbox] {
  display: none;
}
.f-basic input[type=checkbox] + label {
  display: block;
  position: relative;
  padding-left: 25px;
  line-height: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.f-basic input[type=checkbox] + label:before {
  content: "";
  display: block;
  margin-top: 7px;
  width: 15px;
  height: 15px;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
}
.f-basic input[type=checkbox]:checked + label:before {
  background-color: #0047ba !important;
  border: 1px solid #0047ba;
}
.f-basic input[type=checkbox]:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 8px;
  width: 3px;
  height: 7px;
  border: 1.6px solid white;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}
.f-basic input[type=checkbox]:disabled + label:before {
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.f-basic input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  opacity: 0.7;
}
.f-basic input[type=checkbox]:disabled + label:hover {
  color: #333333;
}
.f-basic .error {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.f-basic .error:before {
  content: "! ";
}
.f-basic .error-left {
  right: auto;
}
.f-basic input:hover,
.f-basic textarea:hover,
.f-basic select:hover {
  background-color: rgba(255, 255, 255, 0.0156862745);
}
.f-basic input:focus,
.f-basic textarea:focus,
.f-basic select:focus {
  border-color: #0047ba;
  box-shadow: 0 1.5px 0px -0.5px #0047ba;
  transition: border-color 0.13s ease-in-out, box-shadow 0.13s ease-in-out, -webkit-box-shadow 0.13s ease-in-out;
}
.f-basic input:read-only,
.f-basic textarea:read-only,
.f-basic select:read-only {
  background-color: rgba(255, 255, 255, 0.031372549);
}
.f-basic input:disabled,
.f-basic textarea:disabled,
.f-basic select:disabled {
  background-color: #f8f8f8;
  border-radius: 5px;
  border: none;
  cursor: not-allowed;
}
.f-basic .invalid,
.f-basic .unresponsive,
.f-basic .other {
  white-space: nowrap !important;
}

.report-basic {
  margin: 0 0 10px 0;
}

.f-phone {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 0 0 30px 0;
}
.f-phone > label {
  display: inline-block;
  min-width: 115px;
  width: 115px;
  margin: 0 10px 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  line-height: 1.2rem;
}
.f-phone > .help-btn {
  right: 0px;
  bottom: 5px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/help_0_icon.svg") no-repeat center;
  background-size: auto 16px;
  position: absolute;
  cursor: pointer;
}
.f-phone > .help {
  position: absolute;
  background-color: #0047ba;
  font-family: "ADNOC Sans Regular";
  color: #f1f1f1;
  font-size: 0.875rem;
  line-height: 1.1rem;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  top: 37px;
  width: calc(100% - 175px);
  right: 0;
}
.f-phone > .req::after {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  content: "*";
  margin-left: 5px;
  left: 1px !important;
}
.f-phone > .limit {
  position: absolute;
  right: 0;
  bottom: 8px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.8rem;
  background-color: white;
}
.f-phone input {
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 1rem;
  line-height: 1.7rem;
  padding: 2px 5px 0px 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  box-shadow: 0 1px 0 transparent;
}
.f-phone input::-webkit-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-phone input::-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-phone input:-ms-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-phone input:-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-phone input[type=checkbox] {
  display: none;
}
.f-phone input[type=checkbox] + label {
  display: block;
  position: relative;
  padding-left: 25px;
  line-height: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.f-phone input[type=checkbox] + label:before {
  content: "";
  display: block;
  margin-top: 7px;
  width: 15px;
  height: 15px;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
}
.f-phone input[type=checkbox]:checked + label:before {
  background-color: #0047ba !important;
  border: 1px solid #0047ba;
}
.f-phone input[type=checkbox]:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 8px;
  width: 3px;
  height: 7px;
  border: 1.6px solid white;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}
.f-phone input[type=checkbox]:disabled + label:before {
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.f-phone input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  opacity: 0.7;
}
.f-phone input[type=checkbox]:disabled + label:hover {
  color: #333333;
}
.f-phone .error {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.f-phone .error:before {
  content: "! ";
}
.f-phone .error-left {
  right: auto;
}
.f-phone input:hover,
.f-phone textarea:hover,
.f-phone select:hover {
  background-color: rgba(255, 255, 255, 0.0156862745);
}
.f-phone input:focus,
.f-phone textarea:focus,
.f-phone select:focus {
  border-color: #0047ba;
  box-shadow: 0 1.5px 0px -0.5px #0047ba;
  transition: border-color 0.13s ease-in-out, box-shadow 0.13s ease-in-out, -webkit-box-shadow 0.13s ease-in-out;
}
.f-phone input:read-only,
.f-phone textarea:read-only,
.f-phone select:read-only {
  background-color: rgba(255, 255, 255, 0.031372549);
}
.f-phone input:disabled,
.f-phone textarea:disabled,
.f-phone select:disabled {
  background-color: #f8f8f8;
  border-radius: 5px;
  border: none;
  cursor: not-allowed;
}
.f-phone .intl-tel-wrapper {
  width: 100%;
}
.f-phone .intl-tel-wrapper .iti {
  display: block;
}
.f-phone input {
  padding-left: 88px !important;
}
.f-phone input::placeholder {
  color: #999999;
  font-style: normal;
}
.f-phone input[disabled] {
  padding-left: 81px !important;
  padding-top: 1px;
}
.f-phone .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent !important;
}
.f-phone #country-search-box {
  padding-left: 5px !important;
}
.f-phone .iti__arrow {
  margin-left: 4px;
}
.f-phone .selected-dial-code {
  text-align: right;
  min-width: 28px;
}
.f-phone .iti__country-list::-webkit-scrollbar {
  width: 6px;
}
.f-phone .iti__country-list::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.f-phone .iti__country-list::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.f-phone .iti__country-list::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.f-phone .dropdown-menu {
  margin: 9px 0 0 !important;
  border: none !important;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
}
.f-phone .iti__country-list {
  box-shadow: none;
}
.f-phone .iti__selected-flag[disabled=true] {
  width: 75px !important;
  background-color: #f1f1f1;
}
.f-phone .iti__selected-flag[disabled=true] .iti__arrow {
  display: none !important;
}

.f-basic-table {
  background: transparent;
  outline: none;
  border: none;
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 1rem;
  line-height: 1.1rem;
}
.f-basic-btn {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 0 0 30px 0;
}
.f-basic-btn > label {
  display: inline-block;
  min-width: 115px;
  width: 115px;
  margin: 0 10px 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  line-height: 1.2rem;
}
.f-basic-btn > .help-btn {
  right: 0px;
  bottom: 5px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/help_0_icon.svg") no-repeat center;
  background-size: auto 16px;
  position: absolute;
  cursor: pointer;
}
.f-basic-btn > .help {
  position: absolute;
  background-color: #0047ba;
  font-family: "ADNOC Sans Regular";
  color: #f1f1f1;
  font-size: 0.875rem;
  line-height: 1.1rem;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  top: 37px;
  width: calc(100% - 175px);
  right: 0;
}
.f-basic-btn > .req::after {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  content: "*";
  margin-left: 5px;
  left: 1px !important;
}
.f-basic-btn > .limit {
  position: absolute;
  right: 0;
  bottom: 8px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.8rem;
  background-color: white;
}
.f-basic-btn input {
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 1rem;
  line-height: 1.7rem;
  padding: 2px 5px 0px 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  box-shadow: 0 1px 0 transparent;
}
.f-basic-btn input::-webkit-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-basic-btn input::-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-basic-btn input:-ms-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-basic-btn input:-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-basic-btn input[type=checkbox] {
  display: none;
}
.f-basic-btn input[type=checkbox] + label {
  display: block;
  position: relative;
  padding-left: 25px;
  line-height: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.f-basic-btn input[type=checkbox] + label:before {
  content: "";
  display: block;
  margin-top: 7px;
  width: 15px;
  height: 15px;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
}
.f-basic-btn input[type=checkbox]:checked + label:before {
  background-color: #0047ba !important;
  border: 1px solid #0047ba;
}
.f-basic-btn input[type=checkbox]:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 8px;
  width: 3px;
  height: 7px;
  border: 1.6px solid white;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}
.f-basic-btn input[type=checkbox]:disabled + label:before {
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.f-basic-btn input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  opacity: 0.7;
}
.f-basic-btn input[type=checkbox]:disabled + label:hover {
  color: #333333;
}
.f-basic-btn .error {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.f-basic-btn .error:before {
  content: "! ";
}
.f-basic-btn .error-left {
  right: auto;
}
.f-basic-btn input:hover,
.f-basic-btn textarea:hover,
.f-basic-btn select:hover {
  background-color: rgba(255, 255, 255, 0.0156862745);
}
.f-basic-btn input:focus,
.f-basic-btn textarea:focus,
.f-basic-btn select:focus {
  border-color: #0047ba;
  box-shadow: 0 1.5px 0px -0.5px #0047ba;
  transition: border-color 0.13s ease-in-out, box-shadow 0.13s ease-in-out, -webkit-box-shadow 0.13s ease-in-out;
}
.f-basic-btn input:read-only,
.f-basic-btn textarea:read-only,
.f-basic-btn select:read-only {
  background-color: rgba(255, 255, 255, 0.031372549);
}
.f-basic-btn input:disabled,
.f-basic-btn textarea:disabled,
.f-basic-btn select:disabled {
  background-color: #f8f8f8;
  border-radius: 5px;
  border: none;
  cursor: not-allowed;
}
.f-basic-btn label {
  width: 160px;
}
.f-basic-btn input {
  width: calc(100% - 160px - 160px);
}
.f-basic-btn button {
  margin-left: 15px;
}

.f-select-row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 0 0 30px 0;
}
.f-select-row > label {
  display: inline-block;
  min-width: 115px;
  width: 115px;
  margin: 0 10px 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  line-height: 1.2rem;
}
.f-select-row > .help-btn {
  right: 0px;
  bottom: 5px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/help_0_icon.svg") no-repeat center;
  background-size: auto 16px;
  position: absolute;
  cursor: pointer;
}
.f-select-row > .help {
  position: absolute;
  background-color: #0047ba;
  font-family: "ADNOC Sans Regular";
  color: #f1f1f1;
  font-size: 0.875rem;
  line-height: 1.1rem;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  top: 37px;
  width: calc(100% - 175px);
  right: 0;
}
.f-select-row > .req::after {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  content: "*";
  margin-left: 5px;
  left: 1px !important;
}
.f-select-row > .limit {
  position: absolute;
  right: 0;
  bottom: 8px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.8rem;
  background-color: white;
}
.f-select-row input {
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 1rem;
  line-height: 1.7rem;
  padding: 2px 5px 0px 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  box-shadow: 0 1px 0 transparent;
}
.f-select-row input::-webkit-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-select-row input::-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-select-row input:-ms-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-select-row input:-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-select-row input[type=checkbox] {
  display: none;
}
.f-select-row input[type=checkbox] + label {
  display: block;
  position: relative;
  padding-left: 25px;
  line-height: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.f-select-row input[type=checkbox] + label:before {
  content: "";
  display: block;
  margin-top: 7px;
  width: 15px;
  height: 15px;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
}
.f-select-row input[type=checkbox]:checked + label:before {
  background-color: #0047ba !important;
  border: 1px solid #0047ba;
}
.f-select-row input[type=checkbox]:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 8px;
  width: 3px;
  height: 7px;
  border: 1.6px solid white;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}
.f-select-row input[type=checkbox]:disabled + label:before {
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.f-select-row input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  opacity: 0.7;
}
.f-select-row input[type=checkbox]:disabled + label:hover {
  color: #333333;
}
.f-select-row .error {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.f-select-row .error:before {
  content: "! ";
}
.f-select-row .error-left {
  right: auto;
}
.f-select-row input:hover,
.f-select-row textarea:hover,
.f-select-row select:hover {
  background-color: rgba(255, 255, 255, 0.0156862745);
}
.f-select-row input:focus,
.f-select-row textarea:focus,
.f-select-row select:focus {
  border-color: #0047ba;
  box-shadow: 0 1.5px 0px -0.5px #0047ba;
  transition: border-color 0.13s ease-in-out, box-shadow 0.13s ease-in-out, -webkit-box-shadow 0.13s ease-in-out;
}
.f-select-row input:read-only,
.f-select-row textarea:read-only,
.f-select-row select:read-only {
  background-color: rgba(255, 255, 255, 0.031372549);
}
.f-select-row input:disabled,
.f-select-row textarea:disabled,
.f-select-row select:disabled {
  background-color: #f8f8f8;
  border-radius: 5px;
  border: none;
  cursor: not-allowed;
}
.f-select-row label {
  width: 160px;
}
.f-select-row .select-area {
  width: calc(100% - 180px);
}

.f-checkbox {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 0 0 30px 0;
  justify-content: flex-start;
}
.f-checkbox > label {
  display: inline-block;
  min-width: 115px;
  width: 115px;
  margin: 0 10px 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  line-height: 1.2rem;
}
.f-checkbox > .help-btn {
  right: 0px;
  bottom: 5px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/help_0_icon.svg") no-repeat center;
  background-size: auto 16px;
  position: absolute;
  cursor: pointer;
}
.f-checkbox > .help {
  position: absolute;
  background-color: #0047ba;
  font-family: "ADNOC Sans Regular";
  color: #f1f1f1;
  font-size: 0.875rem;
  line-height: 1.1rem;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  top: 37px;
  width: calc(100% - 175px);
  right: 0;
}
.f-checkbox > .req::after {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  content: "*";
  margin-left: 5px;
  left: 1px !important;
}
.f-checkbox > .limit {
  position: absolute;
  right: 0;
  bottom: 8px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.8rem;
  background-color: white;
}
.f-checkbox input {
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 1rem;
  line-height: 1.7rem;
  padding: 2px 5px 0px 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  box-shadow: 0 1px 0 transparent;
}
.f-checkbox input::-webkit-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-checkbox input::-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-checkbox input:-ms-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-checkbox input:-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-checkbox input[type=checkbox] {
  display: none;
}
.f-checkbox input[type=checkbox] + label {
  display: block;
  position: relative;
  padding-left: 25px;
  line-height: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.f-checkbox input[type=checkbox] + label:before {
  content: "";
  display: block;
  margin-top: 7px;
  width: 15px;
  height: 15px;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
}
.f-checkbox input[type=checkbox]:checked + label:before {
  background-color: #0047ba !important;
  border: 1px solid #0047ba;
}
.f-checkbox input[type=checkbox]:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 8px;
  width: 3px;
  height: 7px;
  border: 1.6px solid white;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}
.f-checkbox input[type=checkbox]:disabled + label:before {
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.f-checkbox input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  opacity: 0.7;
}
.f-checkbox input[type=checkbox]:disabled + label:hover {
  color: #333333;
}
.f-checkbox .error {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.f-checkbox .error:before {
  content: "! ";
}
.f-checkbox .error-left {
  right: auto;
}
.f-checkbox input:hover,
.f-checkbox textarea:hover,
.f-checkbox select:hover {
  background-color: rgba(255, 255, 255, 0.0156862745);
}
.f-checkbox input:focus,
.f-checkbox textarea:focus,
.f-checkbox select:focus {
  border-color: #0047ba;
  box-shadow: 0 1.5px 0px -0.5px #0047ba;
  transition: border-color 0.13s ease-in-out, box-shadow 0.13s ease-in-out, -webkit-box-shadow 0.13s ease-in-out;
}
.f-checkbox input:read-only,
.f-checkbox textarea:read-only,
.f-checkbox select:read-only {
  background-color: rgba(255, 255, 255, 0.031372549);
}
.f-checkbox input:disabled,
.f-checkbox textarea:disabled,
.f-checkbox select:disabled {
  background-color: #f8f8f8;
  border-radius: 5px;
  border: none;
  cursor: not-allowed;
}
.f-checkbox label {
  min-width: auto;
  width: auto;
}
.f-checkbox label > .help {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 1rem;
}
.f-checkbox label:hover {
  color: #0047ba;
}

.f-radio-s {
  max-height: 160px;
  overflow-y: scroll;
}
.f-radio-s::-webkit-scrollbar {
  width: 6px;
}
.f-radio-s::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.f-radio-s::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.f-radio-s::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.f-radio-s__opt {
  margin-bottom: 10px;
}
.f-radio-s label {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.9rem;
}
.f-radio-s [type=radio]:checked,
.f-radio-s [type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.f-radio-s [type=radio]:checked + label,
.f-radio-s [type=radio]:not(:checked) + label {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.f-radio-s [type=radio]:checked + label:before,
.f-radio-s [type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 14px;
  height: 14px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.f-radio-s [type=radio]:checked + label:after,
.f-radio-s [type=radio]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #0047ba;
  position: absolute;
  top: 5px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.f-radio-s [type=radio]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.f-radio-s [type=radio]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.f-checkbox-s {
  max-height: 160px;
  overflow-y: scroll;
}
.f-checkbox-s::-webkit-scrollbar {
  width: 6px;
}
.f-checkbox-s::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.f-checkbox-s::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.f-checkbox-s::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.f-checkbox-s__opt {
  margin-bottom: 10px;
}
.f-checkbox-s label {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.9rem;
}
.f-checkbox-s [type=checkbox]:checked,
.f-checkbox-s [type=checkbox]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.f-checkbox-s [type=checkbox]:checked + label,
.f-checkbox-s [type=checkbox]:not(:checked) + label {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.f-checkbox-s [type=checkbox]:checked + label:before,
.f-checkbox-s [type=checkbox]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 14px;
  height: 14px;
  border: 1px solid #ddd;
  background: #fff;
}
.f-checkbox-s [type=checkbox]:checked + label:after,
.f-checkbox-s [type=checkbox]:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #0047ba;
  position: absolute;
  top: 6px;
  left: 4px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.f-checkbox-s [type=checkbox]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.f-checkbox-s [type=checkbox]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.f-stars {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.f-stars > .label {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  margin: 0 0 10px 0;
}
.f-stars .star {
  display: inline-block;
  margin: 0 5px;
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/star_0_icon.svg") no-repeat center;
  background-size: auto 28px;
  cursor: pointer;
}
.f-stars .star-active {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/star_1_icon.svg") no-repeat center;
  background-size: auto 28px;
}
.f-stars .star-prev {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/star_1_icon.svg") no-repeat center;
  background-size: auto 28px;
}
.f-stars > .error {
  margin: 10px 0 0 0;
  text-align: right;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.f-stars > .error:before {
  content: "! ";
}

.f-excel {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 0 0 30px 0;
  justify-content: flex-start;
  display: flex;
}
.f-excel > label {
  display: inline-block;
  min-width: 115px;
  width: 115px;
  margin: 0 10px 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  line-height: 1.2rem;
}
.f-excel > .help-btn {
  right: 0px;
  bottom: 5px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/help_0_icon.svg") no-repeat center;
  background-size: auto 16px;
  position: absolute;
  cursor: pointer;
}
.f-excel > .help {
  position: absolute;
  background-color: #0047ba;
  font-family: "ADNOC Sans Regular";
  color: #f1f1f1;
  font-size: 0.875rem;
  line-height: 1.1rem;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  top: 37px;
  width: calc(100% - 175px);
  right: 0;
}
.f-excel > .req::after {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  content: "*";
  margin-left: 5px;
  left: 1px !important;
}
.f-excel > .limit {
  position: absolute;
  right: 0;
  bottom: 8px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.8rem;
  background-color: white;
}
.f-excel input {
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 1rem;
  line-height: 1.7rem;
  padding: 2px 5px 0px 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  box-shadow: 0 1px 0 transparent;
}
.f-excel input::-webkit-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-excel input::-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-excel input:-ms-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-excel input:-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-excel input[type=checkbox] {
  display: none;
}
.f-excel input[type=checkbox] + label {
  display: block;
  position: relative;
  padding-left: 25px;
  line-height: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.f-excel input[type=checkbox] + label:before {
  content: "";
  display: block;
  margin-top: 7px;
  width: 15px;
  height: 15px;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
}
.f-excel input[type=checkbox]:checked + label:before {
  background-color: #0047ba !important;
  border: 1px solid #0047ba;
}
.f-excel input[type=checkbox]:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 8px;
  width: 3px;
  height: 7px;
  border: 1.6px solid white;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}
.f-excel input[type=checkbox]:disabled + label:before {
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.f-excel input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  opacity: 0.7;
}
.f-excel input[type=checkbox]:disabled + label:hover {
  color: #333333;
}
.f-excel .error {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.f-excel .error:before {
  content: "! ";
}
.f-excel .error-left {
  right: auto;
}
.f-excel input:hover,
.f-excel textarea:hover,
.f-excel select:hover {
  background-color: rgba(255, 255, 255, 0.0156862745);
}
.f-excel input:focus,
.f-excel textarea:focus,
.f-excel select:focus {
  border-color: #0047ba;
  box-shadow: 0 1.5px 0px -0.5px #0047ba;
  transition: border-color 0.13s ease-in-out, box-shadow 0.13s ease-in-out, -webkit-box-shadow 0.13s ease-in-out;
}
.f-excel input:read-only,
.f-excel textarea:read-only,
.f-excel select:read-only {
  background-color: rgba(255, 255, 255, 0.031372549);
}
.f-excel input:disabled,
.f-excel textarea:disabled,
.f-excel select:disabled {
  background-color: #f8f8f8;
  border-radius: 5px;
  border: none;
  cursor: not-allowed;
}
.f-excel .label {
  display: inline-block;
  min-width: 115px;
  width: 115px;
  margin: 0 10px 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
}
.f-excel .add-excel {
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 1rem;
  cursor: pointer;
}
.f-excel .add-excel:hover {
  opacity: 0.8;
}
.f-excel .excel .file {
  display: flex;
}
.f-excel .excel .file__name {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  cursor: pointer;
}
.f-excel .excel .file__name:hover {
  color: #0047ba;
}
.f-excel .excel .file__delete {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  margin-left: 6px;
  cursor: pointer;
}
.f-excel .excel .file__delete:hover {
  opacity: 0.8;
}

.f-checkbox-basic {
  margin-bottom: 10px;
}
.f-checkbox-basic input[type=checkbox] {
  display: none;
}
.f-checkbox-basic input[type=checkbox] + label {
  display: block;
  position: relative;
  padding-left: 25px;
  line-height: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.f-checkbox-basic input[type=checkbox] + label:before {
  content: "";
  display: block;
  margin-top: 5px;
  width: 15px;
  height: 15px;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
}
.f-checkbox-basic input[type=checkbox]:checked + label:before {
  background-color: #0047ba !important;
  border: 1px solid #0047ba;
}
.f-checkbox-basic input[type=checkbox]:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 6px;
  width: 3px;
  height: 7px;
  border: 1.6px solid white;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}
.f-checkbox-basic input[type=checkbox]:disabled + label:before {
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.f-checkbox-basic input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  opacity: 0.7;
}
.f-checkbox-basic input[type=checkbox]:disabled + label:hover {
  color: #333333;
}
.f-checkbox-basic > label {
  min-height: 30px;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  line-height: 1.2rem !important;
  display: flex !important;
  align-items: center !important;
}
.f-checkbox-simple input[type=checkbox] {
  display: none;
}
.f-checkbox-simple input[type=checkbox] + label {
  display: block;
  position: relative;
  padding-left: 25px;
  line-height: 3px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.f-checkbox-simple input[type=checkbox] + label:before {
  content: "";
  display: block;
  margin-top: -7px;
  width: 15px;
  height: 15px;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
}
.f-checkbox-simple input[type=checkbox]:checked + label:before {
  background-color: #0047ba !important;
  border: 1px solid #0047ba;
}
.f-checkbox-simple input[type=checkbox]:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: -6px;
  width: 3px;
  height: 7px;
  border: 1.6px solid white;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}
.f-checkbox-simple input[type=checkbox]:disabled + label:before {
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.f-checkbox-simple input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  opacity: 0.7;
}
.f-checkbox-simple input[type=checkbox]:disabled + label:hover {
  color: #333333;
}

.f-checkbox-list {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 0 0 30px 0;
  justify-content: flex-start;
  margin: 0 0 10px 0;
}
.f-checkbox-list > label {
  display: inline-block;
  min-width: 115px;
  width: 115px;
  margin: 0 10px 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  line-height: 1.2rem;
}
.f-checkbox-list > .help-btn {
  right: 0px;
  bottom: 5px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/help_0_icon.svg") no-repeat center;
  background-size: auto 16px;
  position: absolute;
  cursor: pointer;
}
.f-checkbox-list > .help {
  position: absolute;
  background-color: #0047ba;
  font-family: "ADNOC Sans Regular";
  color: #f1f1f1;
  font-size: 0.875rem;
  line-height: 1.1rem;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  top: 37px;
  width: calc(100% - 175px);
  right: 0;
}
.f-checkbox-list > .req::after {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  content: "*";
  margin-left: 5px;
  left: 1px !important;
}
.f-checkbox-list > .limit {
  position: absolute;
  right: 0;
  bottom: 8px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.8rem;
  background-color: white;
}
.f-checkbox-list input {
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 1rem;
  line-height: 1.7rem;
  padding: 2px 5px 0px 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  box-shadow: 0 1px 0 transparent;
}
.f-checkbox-list input::-webkit-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-checkbox-list input::-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-checkbox-list input:-ms-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-checkbox-list input:-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-checkbox-list input[type=checkbox] {
  display: none;
}
.f-checkbox-list input[type=checkbox] + label {
  display: block;
  position: relative;
  padding-left: 25px;
  line-height: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.f-checkbox-list input[type=checkbox] + label:before {
  content: "";
  display: block;
  margin-top: 7px;
  width: 15px;
  height: 15px;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
}
.f-checkbox-list input[type=checkbox]:checked + label:before {
  background-color: #0047ba !important;
  border: 1px solid #0047ba;
}
.f-checkbox-list input[type=checkbox]:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 8px;
  width: 3px;
  height: 7px;
  border: 1.6px solid white;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}
.f-checkbox-list input[type=checkbox]:disabled + label:before {
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.f-checkbox-list input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  opacity: 0.7;
}
.f-checkbox-list input[type=checkbox]:disabled + label:hover {
  color: #333333;
}
.f-checkbox-list .error {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.f-checkbox-list .error:before {
  content: "! ";
}
.f-checkbox-list .error-left {
  right: auto;
}
.f-checkbox-list input:hover,
.f-checkbox-list textarea:hover,
.f-checkbox-list select:hover {
  background-color: rgba(255, 255, 255, 0.0156862745);
}
.f-checkbox-list input:focus,
.f-checkbox-list textarea:focus,
.f-checkbox-list select:focus {
  border-color: #0047ba;
  box-shadow: 0 1.5px 0px -0.5px #0047ba;
  transition: border-color 0.13s ease-in-out, box-shadow 0.13s ease-in-out, -webkit-box-shadow 0.13s ease-in-out;
}
.f-checkbox-list input:read-only,
.f-checkbox-list textarea:read-only,
.f-checkbox-list select:read-only {
  background-color: rgba(255, 255, 255, 0.031372549);
}
.f-checkbox-list input:disabled,
.f-checkbox-list textarea:disabled,
.f-checkbox-list select:disabled {
  background-color: #f8f8f8;
  border-radius: 5px;
  border: none;
  cursor: not-allowed;
}
.f-checkbox-list label {
  min-width: auto;
  width: auto;
}
.f-checkbox-list label > .help {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 1rem;
}
.f-checkbox-list label:hover {
  color: #0047ba;
}

.f-select {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 0 0 30px 0;
}
.f-select > label {
  display: inline-block;
  min-width: 115px;
  width: 115px;
  margin: 0 10px 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  line-height: 1.2rem;
}
.f-select > .help-btn {
  right: 0px;
  bottom: 5px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/help_0_icon.svg") no-repeat center;
  background-size: auto 16px;
  position: absolute;
  cursor: pointer;
}
.f-select > .help {
  position: absolute;
  background-color: #0047ba;
  font-family: "ADNOC Sans Regular";
  color: #f1f1f1;
  font-size: 0.875rem;
  line-height: 1.1rem;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  top: 37px;
  width: calc(100% - 175px);
  right: 0;
}
.f-select > .req::after {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  content: "*";
  margin-left: 5px;
  left: 1px !important;
}
.f-select > .limit {
  position: absolute;
  right: 0;
  bottom: 8px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.8rem;
  background-color: white;
}
.f-select input {
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 1rem;
  line-height: 1.7rem;
  padding: 2px 5px 0px 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  box-shadow: 0 1px 0 transparent;
}
.f-select input::-webkit-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-select input::-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-select input:-ms-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-select input:-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-select input[type=checkbox] {
  display: none;
}
.f-select input[type=checkbox] + label {
  display: block;
  position: relative;
  padding-left: 25px;
  line-height: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.f-select input[type=checkbox] + label:before {
  content: "";
  display: block;
  margin-top: 7px;
  width: 15px;
  height: 15px;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
}
.f-select input[type=checkbox]:checked + label:before {
  background-color: #0047ba !important;
  border: 1px solid #0047ba;
}
.f-select input[type=checkbox]:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 8px;
  width: 3px;
  height: 7px;
  border: 1.6px solid white;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}
.f-select input[type=checkbox]:disabled + label:before {
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.f-select input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  opacity: 0.7;
}
.f-select input[type=checkbox]:disabled + label:hover {
  color: #333333;
}
.f-select .error {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.f-select .error:before {
  content: "! ";
}
.f-select .error-left {
  right: auto;
}
.f-select input:hover,
.f-select textarea:hover,
.f-select select:hover {
  background-color: rgba(255, 255, 255, 0.0156862745);
}
.f-select input:focus,
.f-select textarea:focus,
.f-select select:focus {
  border-color: #0047ba;
  box-shadow: 0 1.5px 0px -0.5px #0047ba;
  transition: border-color 0.13s ease-in-out, box-shadow 0.13s ease-in-out, -webkit-box-shadow 0.13s ease-in-out;
}
.f-select input:read-only,
.f-select textarea:read-only,
.f-select select:read-only {
  background-color: rgba(255, 255, 255, 0.031372549);
}
.f-select input:disabled,
.f-select textarea:disabled,
.f-select select:disabled {
  background-color: #f8f8f8;
  border-radius: 5px;
  border: none;
  cursor: not-allowed;
}
.f-select ng-select {
  width: 100%;
}

.f-select-multiple {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 0 0 30px 0;
  min-height: 30px;
  height: auto;
}
.f-select-multiple > label {
  display: inline-block;
  min-width: 115px;
  width: 115px;
  margin: 0 10px 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  line-height: 1.2rem;
}
.f-select-multiple > .help-btn {
  right: 0px;
  bottom: 5px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/help_0_icon.svg") no-repeat center;
  background-size: auto 16px;
  position: absolute;
  cursor: pointer;
}
.f-select-multiple > .help {
  position: absolute;
  background-color: #0047ba;
  font-family: "ADNOC Sans Regular";
  color: #f1f1f1;
  font-size: 0.875rem;
  line-height: 1.1rem;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  top: 37px;
  width: calc(100% - 175px);
  right: 0;
}
.f-select-multiple > .req::after {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  content: "*";
  margin-left: 5px;
  left: 1px !important;
}
.f-select-multiple > .limit {
  position: absolute;
  right: 0;
  bottom: 8px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.8rem;
  background-color: white;
}
.f-select-multiple input {
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 1rem;
  line-height: 1.7rem;
  padding: 2px 5px 0px 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  box-shadow: 0 1px 0 transparent;
}
.f-select-multiple input::-webkit-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-select-multiple input::-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-select-multiple input:-ms-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-select-multiple input:-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-select-multiple input[type=checkbox] {
  display: none;
}
.f-select-multiple input[type=checkbox] + label {
  display: block;
  position: relative;
  padding-left: 25px;
  line-height: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.f-select-multiple input[type=checkbox] + label:before {
  content: "";
  display: block;
  margin-top: 7px;
  width: 15px;
  height: 15px;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
}
.f-select-multiple input[type=checkbox]:checked + label:before {
  background-color: #0047ba !important;
  border: 1px solid #0047ba;
}
.f-select-multiple input[type=checkbox]:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 8px;
  width: 3px;
  height: 7px;
  border: 1.6px solid white;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}
.f-select-multiple input[type=checkbox]:disabled + label:before {
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.f-select-multiple input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  opacity: 0.7;
}
.f-select-multiple input[type=checkbox]:disabled + label:hover {
  color: #333333;
}
.f-select-multiple .error {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.f-select-multiple .error:before {
  content: "! ";
}
.f-select-multiple .error-left {
  right: auto;
}
.f-select-multiple input:hover,
.f-select-multiple textarea:hover,
.f-select-multiple select:hover {
  background-color: rgba(255, 255, 255, 0.0156862745);
}
.f-select-multiple input:focus,
.f-select-multiple textarea:focus,
.f-select-multiple select:focus {
  border-color: #0047ba;
  box-shadow: 0 1.5px 0px -0.5px #0047ba;
  transition: border-color 0.13s ease-in-out, box-shadow 0.13s ease-in-out, -webkit-box-shadow 0.13s ease-in-out;
}
.f-select-multiple input:read-only,
.f-select-multiple textarea:read-only,
.f-select-multiple select:read-only {
  background-color: rgba(255, 255, 255, 0.031372549);
}
.f-select-multiple input:disabled,
.f-select-multiple textarea:disabled,
.f-select-multiple select:disabled {
  background-color: #f8f8f8;
  border-radius: 5px;
  border: none;
  cursor: not-allowed;
}
.f-select-multiple ng-select {
  width: 100%;
}

.f-date {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 0 0 30px 0;
}
.f-date > label {
  display: inline-block;
  min-width: 115px;
  width: 115px;
  margin: 0 10px 0 0;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  line-height: 1.2rem;
}
.f-date > .help-btn {
  right: 0px;
  bottom: 5px;
  width: 17px;
  height: 17px;
  background: url("/assets/images/icons/help_0_icon.svg") no-repeat center;
  background-size: auto 16px;
  position: absolute;
  cursor: pointer;
}
.f-date > .help {
  position: absolute;
  background-color: #0047ba;
  font-family: "ADNOC Sans Regular";
  color: #f1f1f1;
  font-size: 0.875rem;
  line-height: 1.1rem;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  top: 37px;
  width: calc(100% - 175px);
  right: 0;
}
.f-date > .req::after {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  content: "*";
  margin-left: 5px;
  left: 1px !important;
}
.f-date > .limit {
  position: absolute;
  right: 0;
  bottom: 8px;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 0.8rem;
  background-color: white;
}
.f-date input {
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 1rem;
  line-height: 1.7rem;
  padding: 2px 5px 0px 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  box-shadow: 0 1px 0 transparent;
}
.f-date input::-webkit-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-date input::-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-date input:-ms-input-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-date input:-moz-placeholder {
  font-family: "ADNOC Sans Regular";
  color: #c4c4c4;
  font-size: 1rem;
  line-height: 1.7rem;
}
.f-date input[type=checkbox] {
  display: none;
}
.f-date input[type=checkbox] + label {
  display: block;
  position: relative;
  padding-left: 25px;
  line-height: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.f-date input[type=checkbox] + label:before {
  content: "";
  display: block;
  margin-top: 7px;
  width: 15px;
  height: 15px;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
}
.f-date input[type=checkbox]:checked + label:before {
  background-color: #0047ba !important;
  border: 1px solid #0047ba;
}
.f-date input[type=checkbox]:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 8px;
  width: 3px;
  height: 7px;
  border: 1.6px solid white;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}
.f-date input[type=checkbox]:disabled + label:before {
  background-color: #f8f8f8;
  cursor: not-allowed;
}
.f-date input[type=checkbox]:disabled + label {
  cursor: not-allowed;
  opacity: 0.7;
}
.f-date input[type=checkbox]:disabled + label:hover {
  color: #333333;
}
.f-date .error {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.f-date .error:before {
  content: "! ";
}
.f-date .error-left {
  right: auto;
}
.f-date input:hover,
.f-date textarea:hover,
.f-date select:hover {
  background-color: rgba(255, 255, 255, 0.0156862745);
}
.f-date input:focus,
.f-date textarea:focus,
.f-date select:focus {
  border-color: #0047ba;
  box-shadow: 0 1.5px 0px -0.5px #0047ba;
  transition: border-color 0.13s ease-in-out, box-shadow 0.13s ease-in-out, -webkit-box-shadow 0.13s ease-in-out;
}
.f-date input:read-only,
.f-date textarea:read-only,
.f-date select:read-only {
  background-color: rgba(255, 255, 255, 0.031372549);
}
.f-date input:disabled,
.f-date textarea:disabled,
.f-date select:disabled {
  background-color: #f8f8f8;
  border-radius: 5px;
  border: none;
  cursor: not-allowed;
}

.f-date-simple input {
  background-color: #efefef;
  border: none;
  border-radius: 16px;
  height: 34px;
  width: 165px;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
  padding: 0 10px;
  cursor: pointer;
}
.f-date-table {
  background: transparent;
  outline: none;
  border: none;
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 1rem;
}
.f-slider-s__labels {
  display: flex;
  justify-content: space-between;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 0.9rem;
}
.f-textarea-table {
  resize: none;
  width: 100%;
  height: 61px;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 1rem;
  line-height: 1.3rem;
  border: none;
  overflow-y: scroll;
}
.f-textarea-table::-webkit-scrollbar {
  width: 6px;
}
.f-textarea-table::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.f-textarea-table::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.f-textarea-table::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}

.f-textarea {
  width: 100% !important;
  position: relative;
  margin: 0 0 30px 0;
}
.f-textarea .label {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  margin: 0 0 10px 0;
}
.f-textarea .label.req::after {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  content: "*";
  margin-left: 1px;
}
.f-textarea textarea {
  resize: none;
  padding: 10px 16px;
  width: calc(100% - 32px);
  height: 129px;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 0.9rem;
  line-height: 1.3rem;
  border: 1px solid #c4c4c4;
  overflow-y: scroll;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow-x: hidden;
}
.f-textarea textarea::-webkit-scrollbar {
  width: 6px;
}
.f-textarea textarea::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.f-textarea textarea::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.f-textarea textarea::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.f-textarea textarea::placeholder {
  color: #c4c4c4 !important;
}
.f-textarea textarea:focus {
  border: 1px solid #0047ba;
  box-shadow: 0px 0px 0px 1px rgb(0, 115, 249);
  transition: border-color 0.13s ease-in-out, box-shadow 0.13s ease-in-out, -webkit-box-shadow 0.13s ease-in-out;
}
.f-textarea .error {
  position: absolute;
  bottom: -22px;
  right: 0;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.f-textarea .error:before {
  content: "! ";
}

.f-dropdown {
  display: block;
  position: relative;
}
.f-dropdown > .dropdown-btn {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/arrowDown_icon.svg") no-repeat center right 17px;
  background-size: auto 8px;
  padding: 0 40px 0 20px;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  background-color: #e2e2e2;
  border-radius: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  cursor: pointer;
}
.f-dropdown > .dropdown-btn .default {
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
}
.f-dropdown > .dropdown-btn .value {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.875rem;
}
.f-dropdown > .dropdown-btn .value b {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 0.875rem;
}
.f-dropdown > .dropdown-btn-blue {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/arrowDown_icon.svg") no-repeat center right 17px;
  background-size: auto 8px;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  border-radius: 16px;
  font-family: "ADNOC Sans Regular";
  color: #fff;
  font-size: 0.875rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  min-width: 110px;
  height: 35px;
  background-color: #0047ba;
  padding: 0 40px 0 20px;
  border-radius: 16px;
  align-items: center;
  height: 35px;
  min-width: 30px;
  cursor: pointer;
}
.f-dropdown > .dropdown-btn-blue:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #003487;
  transition: all 0.2s;
  border-radius: 16px;
  z-index: -1;
}
.f-dropdown > .dropdown-btn-blue:hover {
  color: #fff;
}
.f-dropdown > .dropdown-btn-blue:hover:before {
  width: 100%;
}
.f-dropdown > .dropdown-btn-active {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/arrowUp_icon.svg") no-repeat center right 17px;
  background-size: auto 8px;
  display: inline-flex;
  background-color: #e2e2e2;
  height: 35px;
}
.f-dropdown > .dropdown-btn-blue-active {
  width: auto;
  height: auto;
  background: url("/assets/images/icons/arrowUp_icon.svg") no-repeat center right 17px;
  background-size: auto 8px;
  display: inline-flex;
  background-color: #0047ba;
  height: 35px;
}
.f-dropdown > .dropdown-list {
  position: absolute;
  z-index: 20;
  top: 45px;
  background-color: #fff;
  box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
  padding: 0 25px;
  border-radius: 10px;
  right: 0;
}
.f-dropdown > .dropdown-list::after {
  position: absolute;
  content: "";
  top: -8px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 8px solid white;
  z-index: 0;
  right: 27px;
}
.f-dropdown > .dropdown-list::before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  background-color: white;
  top: -7px;
  transform: rotate(45deg);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  z-index: -1;
  right: 30px;
}
.f-dropdown > .dropdown-list > .dropdown-item {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.875rem;
  padding: 12px 5px 12px 5px;
  border-bottom: 1px solid #c5c5c5;
  width: auto;
  white-space: nowrap;
  background-color: #fff;
  cursor: pointer;
}
.f-dropdown > .dropdown-list > .dropdown-item b {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 0.875rem;
  margin-right: 5px;
}
.f-dropdown > .dropdown-list > .dropdown-item:hover {
  color: #0047ba;
}
.f-dropdown > .dropdown-list > .dropdown-item:hover b {
  color: #0047ba;
}
.f-dropdown > .dropdown-list > .dropdown-item:last-of-type {
  border: none;
}

.f-search {
  display: flex;
  height: 38px;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  border: none;
  border-radius: 26px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
}
.f-search > .search-icon {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_0_icon.svg") no-repeat center;
  background-size: 16px auto;
  margin: 0 15px 0 0;
  cursor: pointer;
}
.f-search > .search-icon:hover {
  width: 18px;
  height: 28px;
  background: url("/assets/images/icons/search_1_icon.svg") no-repeat center;
  background-size: 16px auto;
}
.f-search > .search-input {
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #6e6e6e;
  font-size: 0.875rem;
  border: none;
}
.f-editor {
  width: 100% !important;
  margin: 0 0 20px 0;
}
.f-editor .error {
  margin-top: 10px;
  text-align: right;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.f-editor .error:before {
  content: "! ";
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.f-image {
  display: flex;
  align-items: center;
  margin: 0 0 30px 0;
  position: relative;
  flex-wrap: wrap;
}
.f-image .label {
  display: inline-block;
  width: 100%;
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  line-height: 1.2rem;
  margin: 0 0 10px 0;
}
.f-image .req::after {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  content: "*";
  margin-left: 5px;
  left: 1px !important;
}
.f-image > .image {
  height: 68px;
  padding: 10px;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  background-color: #efefef;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.f-image > .image > img {
  height: 100%;
  width: auto;
}
.f-image > .image > .missing {
  font-family: "ADNOC Sans Medium";
  color: #6e6e6e;
  font-size: 1rem;
  margin-top: 5px;
}
.f-image > .image-active {
  background-color: transparent;
}
.f-image > button {
  margin-left: 10px;
}
.f-image .error {
  position: absolute;
  bottom: -20px;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.f-image .error:before {
  content: "! ";
}

.f-map {
  margin: 0 0 30px 0;
}
.f-map .map {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.f-image-crop .no-images-box {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.f-image-crop .no-images-box > .icon {
  width: 200px;
  height: 180px;
  background: url("/assets/images/icons/noImageUploder_icon.svg") no-repeat bottom center;
  background-size: auto 140px;
}
.f-image-crop .no-images-box > .btn {
  margin: 0 0 20px 0;
}
.f-image-crop .no-images-box > .note {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 1rem;
}
.f-image-crop .no-images-box > .note > span {
  color: #0047ba;
}
.f-image-crop .cropper-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.f-image-crop .cropper-box .cropper {
  width: 30%;
}
.f-image-crop .cropper-box .cropper > .cropper-subtitle {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
@media only screen and (max-width: 1099px) {
  .f-image-crop .cropper-box .cropper {
    width: calc(50% - 30px);
  }
}
.f-image-crop .cropper-box .prev {
  width: 30%;
}
.f-image-crop .cropper-box .prev > .cropper-subtitle {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 15px 0;
}
.f-image-crop .cropper-box .prev > img {
  width: 100%;
  background-color: #efefef;
  border: 1px solid #c5c5c5;
  border-radius: 15px;
}
@media only screen and (max-width: 1099px) {
  .f-image-crop .cropper-box .prev {
    width: calc(50% - 30px);
  }
}
.f-image-crop .cropper-box .note {
  width: 30%;
}
@media only screen and (max-width: 1099px) {
  .f-image-crop .cropper-box .note {
    width: 100%;
    margin-top: 30px;
  }
}
.f-image-crop .cropper-box .note .note__title {
  margin-bottom: 10px;
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
}
.f-image-crop .cropper-box .note .note__description {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
}
.f-image-crop .cropper-box .note .note__description div {
  margin-bottom: 7px;
}
.f-image-crop > .error {
  margin: 10px 0 0 0;
  text-align: right;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.f-image-crop > .error:before {
  content: "! ";
}

.f-video .no-video-box {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.f-video .no-video-box > .icon {
  width: 200px;
  height: 180px;
  background: url("/assets/images/icons/noVideoUploder_icon.svg") no-repeat bottom center;
  background-size: auto 140px;
}
.f-video .no-video-box > .btn {
  margin: 10px 0 20px 0;
}
.f-video .no-video-box > .note {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 1rem;
}
.f-video .no-video-box > .note > b {
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 1rem;
}
.f-video .video-box {
  display: flex;
  justify-content: space-between;
}
.f-video .video-box > .video {
  width: 50%;
}
.f-video .video-box > .video > video {
  border-radius: 15px;
}
.f-video .video-box > .note {
  width: 30%;
}
.f-video .video-box > .note .note__title {
  margin-bottom: 10px;
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
}
.f-video .video-box > .note .note__description {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.9rem;
}
.f-video .video-box > .note .note__description div {
  margin-bottom: 7px;
}

.f-select-simple {
  width: 185px;
  height: 34px;
  background-color: #efefef;
  border-radius: 16px;
  padding: 0 10px;
  display: flex !important;
  align-items: center;
}
.f-select-simple ng-select {
  width: 100%;
}
.f-select-simple ng-select .ng-select-container {
  background-color: transparent;
  border: none;
  box-shadow: none;
  height: 32px;
}
.f-select-simple .ng-select-focused {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.f-select-simple .ng-select-opened > .ng-select-container {
  background-color: transparent;
  border: none;
}
.f-select-simple .ng-select .ng-select-container:hover {
  box-shadow: none;
}
.f-select-simple .ng-input {
  top: 0 !important;
}
.f-select-simple .ng-input input {
  height: 27px;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 1rem;
}
.table {
  margin: 0 0 30px 0;
}
.table > .table-title {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 20px 0;
}
.table > .table-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.table > .table-header > .table-title {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1rem;
  margin: 0 0 20px 0;
}
.table > .table-header > .table-introduction {
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.9rem;
}
.table > .table-header > .table-tools > * {
  margin-left: 15px;
}
.table > table {
  width: 100%;
  font-family: "ADNOC Sans Regular";
  color: #505050;
  font-size: 0.85rem;
  line-height: 1.2rem;
}
.table > table > thead {
  background-color: #efefef;
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.8rem;
}
.table > table th,
.table > table td {
  padding: 12px 15px 9px 15px;
  text-align: left;
  border: 1px solid #c4c4c4;
}
.table > table .col-delete {
  width: 20px;
}
.table > table .col-delete > .delete-btn {
  width: 20px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  cursor: pointer;
}
.table > table .col-delete > .delete-btn:hover {
  opacity: 0.8;
}
.table > table .col-show-more {
  width: 20px;
}
.table > table .col-show-more > .show-more-btn {
  width: 20px;
  height: 15px;
  background: url("/assets/images/icons/visible_0_icon.svg") no-repeat center;
  background-size: auto 8px;
  cursor: pointer;
}
.table > table .col-show-more > .show-more-btn:hover {
  opacity: 0.8;
}
.table > table .req::after {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  content: "*";
  margin-left: 5px;
}
.table > table .no-data {
  height: 50px;
  text-align: center;
  vertical-align: middle;
}
.table > table .no-data > .action {
  cursor: pointer;
}
.table > table .no-data > .action:hover {
  color: blue;
}
.table > .error {
  margin: 10px 0 0 0;
  text-align: right;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.table > .error:before {
  content: "! ";
}
@media only screen and (max-width: 899px) {
  .table {
    overflow-x: auto !important;
  }
  .table::-webkit-scrollbar {
    height: 6px;
  }
  .table::-webkit-scrollbar-track {
    background: #f8f8f8;
  }
  .table::-webkit-scrollbar-thumb {
    background: #c0cace;
    border-radius: 5px;
    cursor: pointer;
  }
  .table::-webkit-scrollbar-thumb:hover {
    background: #0047ba;
  }
  .table table {
    min-width: 900px;
  }
  .table .no-data {
    text-align: left !important;
  }
}

.table-custom .table-options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 10px 0;
  padding: 0 10px;
}
.table-custom .table-options > .option {
  padding: 0;
  background-color: transparent;
  border: none;
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 1rem;
  margin: 0 0 0 15px;
  cursor: pointer;
}
.table-custom .table-options > .option:hover {
  opacity: 0.8;
}
.table-custom .table-options > .option:disabled {
  cursor: not-allowed;
}
.table-custom .table-header {
  display: flex;
  background-color: #efefef;
  border: 1px solid #cecece;
  border-radius: 10px;
  padding: 15px 15px;
  margin: 0 5px 10px 5px;
}
.table-custom .table-header > * {
  display: flex;
  align-items: center;
  justify-content: left;
  font-family: "ADNOC Sans Medium";
  color: #000000;
  font-size: 0.875rem;
}
.table-custom .table-body {
  overflow-y: auto;
  padding: 0 5px;
}
.table-custom .table-body::-webkit-scrollbar {
  width: 6px;
}
.table-custom .table-body::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.table-custom .table-body::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.table-custom .table-body::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.table-custom .table-body .table-row {
  display: flex;
  background-color: #fff;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 15px;
  margin: 0 0 7px 0;
  align-items: center;
  justify-content: space-between;
}
.table-custom .table-body .table-row-empty {
  height: 100%;
  display: flex;
  align-items: center;
}
.table-custom .table-footer {
  padding: 0px 15px;
}
.table-custom .col {
  margin: 0 7px;
}
.table-custom .col-flex {
  flex-grow: 1;
  margin: 0 7px;
}
.table-custom .col-checkbox {
  width: 40px;
  margin: 0 7px;
}
.table-custom .col-delete {
  width: 20px;
  margin: 0 7px;
}
.table-custom .col-delete > .delete-btn {
  width: 12px;
  height: 15px;
  background: url("/assets/images/icons/delete_icon.svg") no-repeat center;
  background-size: auto 14px;
  cursor: pointer;
}
.table-custom .col-delete > .delete-btn:hover {
  opacity: 0.8;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(236, 241, 250, 0.7);
}
.modal-container > .modal {
  display: flex;
  flex-direction: column;
  width: 500px;
  max-height: calc(100vh - 30px);
  min-height: 150px;
  background-color: #fff;
  padding: 0 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}
.modal-container > .modal > .modal-header {
  font-family: "ADNOC Sans Bold";
  color: #0047ba;
  font-size: 1rem;
  padding: 20px 0 15px 0;
  border-bottom: 1px solid #c5c5c5;
}
.modal-container > .modal > .modal-tabs {
  padding: 20px 0 0 0;
}
.modal-container > .modal > .modal-tabs > .tab {
  display: inline-block;
  margin: 0 40px 0 0;
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 1rem;
  line-height: 30px;
  border-bottom: 4px solid transparent;
  cursor: pointer;
}
.modal-container > .modal > .modal-tabs > .tab:hover {
  color: #0047ba;
}
.modal-container > .modal > .modal-tabs > .tab-active {
  border-bottom: 4px solid #0047ba;
  font-family: "ADNOC Sans Medium";
  color: #0047ba;
  font-size: 1rem;
}
.modal-container > .modal > .modal-content {
  margin: 20px 0;
  overflow-x: auto;
  padding-right: 5px;
  padding-left: 3px;
  max-height: calc(50vh - 50px);
}
.modal-container > .modal > .modal-content::-webkit-scrollbar {
  width: 6px;
}
.modal-container > .modal > .modal-content::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.modal-container > .modal > .modal-content::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.modal-container > .modal > .modal-content::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.modal-container > .modal > .modal-content-right {
  direction: rtl;
  padding-right: 0;
  padding-left: 5px;
  padding-right: 3px;
}
.modal-container > .modal > .modal-footer {
  padding: 0 0 20px 0;
  display: flex;
  justify-content: flex-end;
}
.modal-container > .modal > .modal-footer > * {
  margin: 0 0 0 10px;
}
.modal-container > .modal-complex {
  display: flex;
  flex-direction: column;
  width: 500px;
  max-height: calc(100vh - 30px);
  min-height: 150px;
  background-color: #fff;
  padding: 0 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}
.modal-container > .modal-complex .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0 15px 0;
  padding: 0 5px;
}
.modal-container > .modal-complex .modal-header .modal-header-title {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 1.5rem;
}
@media only screen and (max-width: 1339px) {
  .modal-container > .modal-complex .modal-header .modal-header-title {
    font-size: 1.425rem;
  }
}
@media only screen and (max-width: 1049px) {
  .modal-container > .modal-complex .modal-header .modal-header-title {
    font-size: 1.35rem;
  }
}
@media only screen and (max-width: 849px) {
  .modal-container > .modal-complex .modal-header .modal-header-title {
    font-size: 1.275rem;
  }
}
.modal-container > .modal-complex .modal-header .modal-header-tools {
  display: flex;
  justify-content: flex-end;
}
.modal-container > .modal-complex .modal-header .modal-header-tools > * {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 799px) {
  .modal-container > .modal-complex .modal-header .modal-header-tools {
    display: none !important;
  }
}
.modal-container > .modal-complex .modal-content {
  overflow: hidden;
  padding: 5px;
  max-height: calc(100vh - 140px);
}
.modal-container > .modal-complex .modal-content .table-body {
  height: calc(100vh - 250px);
}
.modal-container > .modal-complex .content-scroll {
  overflow-y: auto;
  max-height: inherit;
  margin-bottom: 5px;
}
.modal-container > .modal-complex .content-scroll::-webkit-scrollbar {
  width: 6px;
}
.modal-container > .modal-complex .content-scroll::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.modal-container > .modal-complex .content-scroll::-webkit-scrollbar-thumb {
  background: #c0cace;
  border-radius: 5px;
  cursor: pointer;
}
.modal-container > .modal-complex .content-scroll::-webkit-scrollbar-thumb:hover {
  background: #0047ba;
}
.modal-container > .modal-complex .modal-footer {
  display: flex;
  align-items: center;
  padding: 15px 5px;
  border-top: 1px solid #efefef;
}
.modal-container > .modal-complex .modal-footer > * {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
}
.modal-container > .modal-complex .modal-footer .modal-footer-options {
  justify-content: flex-start;
}
@media only screen and (max-width: 999px) {
  .modal-container > .modal-complex .modal-footer .modal-footer-options {
    display: none !important;
  }
}
.modal-container > .modal-complex .modal-footer .modal-footer-pagination {
  justify-content: center;
}
.modal-container > .modal-complex .modal-footer .modal-footer-pagination .pagination-container {
  margin: 0;
}
@media only screen and (max-width: 799px) {
  .modal-container > .modal-complex .modal-footer .modal-footer-pagination {
    display: none !important;
  }
}
.modal-container > .modal-complex .modal-footer .modal-footer-btns {
  justify-content: flex-end;
}
.modal-container > .modal-complex .modal-footer .modal-footer-btns > * {
  margin: 0 0 0 10px;
}
.modal-container > .modal-width-s {
  width: 300px;
}
.modal-container > .modal-width-m {
  width: 500px;
}
.modal-container > .modal-width-l {
  width: auto;
  max-width: 800px;
}
@media only screen and (max-width: 849px) {
  .modal-container > .modal-width-l {
    margin: 0 25px;
  }
}
.modal-container > .modal-width-xl {
  width: 1300px;
}
@media only screen and (max-width: 1349px) {
  .modal-container > .modal-width-xl {
    margin: 0 25px;
  }
}
.modal-container > .modal-width-max {
  width: calc(100vw - 100px);
  max-width: 1400px;
}

.modal-article > p {
  margin: 20px 0 0 0;
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
  line-height: 1.2rem;
}
.modal-article > p:first-of-type {
  margin-top: 0;
}
.modal-article > p > .color-blue {
  color: #0047ba;
}
.modal-article > p > .color-red {
  color: #f55757;
}
.modal-article > p > b {
  font-family: "ADNOC Sans Bold";
  color: #333333;
  font-size: 0.875rem;
}
.modal-article > p > i {
  font-style: italic;
}
.modal-article > p > a {
  color: #0047ba;
  cursor: pointer;
}

.article-right-orientation {
  text-align: right;
}

.modal-rate .stars {
  margin: 0 0 20px 0;
}
.modal-rate .stars > .label {
  font-family: "ADNOC Sans Medium";
  color: #333333;
  font-size: 1rem;
  margin: 0 0 10px 0;
}
.modal-rate .stars .star {
  display: inline-block;
  margin: 0 5px;
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/star_0_icon.svg") no-repeat center;
  background-size: auto 28px;
  cursor: pointer;
}
.modal-rate .stars .star-active {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/star_1_icon.svg") no-repeat center;
  background-size: auto 28px;
}
.modal-rate .stars .star-prev {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url("/assets/images/icons/star_1_icon.svg") no-repeat center;
  background-size: auto 28px;
}
.modal-rate .stars > .error {
  margin: 10px 0 0 0;
  text-align: right;
  font-family: "ADNOC Sans Medium";
  color: #f55757;
  font-size: 0.75rem;
}
.modal-rate .stars > .error:before {
  content: "! ";
}

.modal-comment {
  font-family: "ADNOC Sans Regular";
  color: #000000;
  font-size: 0.875rem;
  line-height: 1.2rem;
  padding: 5px 0;
}
.modal-qr-code {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-qr-code .img-qr {
  width: 200px;
}

.modal-change-event .events .event {
  display: flex;
}
.modal-change-event .events .event .f-date {
  width: 30%;
}
.modal-change-event .events .event .f-select {
  width: 30%;
}

.no {
  width: 100%;
  height: 175px;
  background: url("/assets/images/default/offers_no.svg") no-repeat top center;
  background-size: auto 160px;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  margin-bottom: 50px;
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 1rem;
}
.no > .btn {
  margin-top: 15px;
  position: absolute;
  bottom: -50px;
}

.no-offers {
  width: 100%;
  height: 175px;
  background: url("/assets/images/default/offers_no.svg") no-repeat top center;
  background-size: auto 145px;
}

.no-categories {
  width: 100%;
  height: 175px;
  background: url("/assets/images/default/categories_no.svg") no-repeat top center;
  background-size: auto 145px;
}

.no-roadshows {
  width: 100%;
  height: 175px;
  background: url("/assets/images/default/roadshows_no.svg") no-repeat top center;
  background-size: auto 145px;
}

.no-collections {
  width: 100%;
  height: 175px;
  background: url("/assets/images/default/collections_no.svg") no-repeat top center;
  background-size: auto 145px;
}

.no-users {
  width: 100%;
  height: 175px;
  background: url("/assets/images/default/adnocUsers_no.svg") no-repeat top center;
  background-size: auto 145px;
}

.no-agreements {
  width: 100%;
  height: 175px;
  background: url("/assets/images/default/agreements_no.svg") no-repeat top center;
  background-size: auto 145px;
}

.no-proposals {
  width: 100%;
  height: 175px;
  background: url("/assets/images/default/agreements_no.svg") no-repeat top center;
  background-size: auto 145px;
}

.no-suppliers {
  width: 100%;
  height: 175px;
  background: url("/assets/images/default/suppliers_no.svg") no-repeat top center;
  background-size: auto 145px;
}

.no-tags {
  width: 100%;
  height: 175px;
  background: url("/assets/images/default/tags_no.svg") no-repeat top center;
  background-size: auto 145px;
}

.no-banners {
  width: 100%;
  height: 175px;
  background: url("/assets/images/default/banners_no.svg") no-repeat top center;
  background-size: auto 145px;
}

.no-ratings {
  width: 100%;
  height: 175px;
  background: url("/assets/images/default/ratings_no.svg") no-repeat top center;
  background-size: auto 145px;
}

.no-terms {
  width: 100%;
  height: 175px;
  background: url("/assets/images/default/terms_no.svg") no-repeat top center;
  background-size: auto 145px;
}

.no-table {
  width: 100%;
  height: 110px;
  background: url("/assets/images/default/table_no.svg") no-repeat top center;
  background-size: auto 80px;
  margin-top: 30px;
}

.no-locations {
  width: 100%;
  height: 175px;
  background: url("/assets/images/default/locations_no.svg") no-repeat top center;
  background-size: auto 145px;
}

.no-date {
  width: 100%;
  height: 140px;
  background: url("/assets/images/default/date_no.svg") no-repeat top center;
  background-size: auto 110px;
  margin-bottom: 0;
}

.no-info {
  margin-top: 50px;
  width: 100%;
  height: 100px;
  background: url("/assets/images/default/info_no.svg") no-repeat top center;
  background-size: auto 70px;
}

.no-notifications {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 auto 42px auto;
  font-family: "ADNOC Sans Regular";
  color: #333333;
  font-size: 0.875rem;
  width: 100%;
  height: 130px;
  background: url("/assets/images/icons/no_notification_icon.svg") no-repeat center;
  background-size: 42px auto;
}
.sub-title-terms-and-conditions {
  margin: 10px 0 15px 0;
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 1.25rem;
  display: flex;
}
@media only screen and (max-width: 1339px) {
  .sub-title-terms-and-conditions {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .sub-title-terms-and-conditions {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .sub-title-terms-and-conditions {
    font-size: 1.0625rem;
  }
}

.no-ads-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.no-ads-container .no-ads-icon {
  width: 200px;
  height: 200px;
  background: url("/assets/images/icons/notifications_icon.svg") no-repeat center;
  background-size: 200px 200px;
}
.no-ads-container .no-ads-text {
  font-family: "ADNOC Sans Regular";
  color: #c9d1db;
  font-size: 0.875rem;
  margin: 10px 0;
}
.collections-available-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 50px 0;
}
.collections-available-container .collections-available-img {
  width: 700px;
  height: 175px;
  background: url("/assets/images/test/collection-img-test.png") no-repeat center;
  background-size: 700px 175px;
  border-radius: 10px;
  background-size: contain;
}
.collections-available-container .collections-available-info {
  margin: 0 260px 50px 10px;
}
.collections-available-container .collections-available-info .collections-available-title {
  font-family: "ADNOC Sans Bold";
  color: #000000;
  font-size: 1.25rem;
  padding: 0 0 5px 0;
}
@media only screen and (max-width: 1339px) {
  .collections-available-container .collections-available-info .collections-available-title {
    font-size: 1.1875rem;
  }
}
@media only screen and (max-width: 1049px) {
  .collections-available-container .collections-available-info .collections-available-title {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 849px) {
  .collections-available-container .collections-available-info .collections-available-title {
    font-size: 1.0625rem;
  }
}
.collections-available-container .collections-available-info .collections-available-date {
  font-family: "ADNOC Sans Regular";
  color: #0047ba;
  font-size: 0.875rem;
  padding: 0 0 10px 0;
}
.collections-available-container .collections-available-info .collections-available-description {
  text-align: justify;
}
.collections-available-container .tools-box {
  margin: 0 0 0 10px;
  display: flex;
}
.collections-available-container .tools-box .btn {
  width: 100px;
}

.user-content {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
  padding: 30px 20px 20px 20px;
}
.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 20%;
  padding: 30px 20px 0px 0px;
}
.user-info .user-image-container {
  width: 150px;
  height: 150px;
  background: url("/assets/images/default/Group 1545.svg") no-repeat center;
  background-size: 150px 150px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.user-info button {
  margin-bottom: 10px;
}
.user-info p {
  font-family: "ADNOC Sans Regular";
  color: #8b8b8b;
  font-size: 0.875rem;
  width: 160px;
  text-align: center;
}
.form-user {
  width: 80%;
}
.form-user .section-modern {
  margin: 0;
}
.form-user .section-modern .section-content {
  box-shadow: none;
}

.col-button .btn-blue-c {
  width: 100px;
}

.carousel {
  position: relative;
  overflow: hidden;
  display: flex;
  border-radius: 1.5rem;
}
.carousel .carousel_page {
  min-width: 100%;
}

.carousel-pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.carousel-pagination .page {
  position: relative;
  width: 12px;
  height: 12px;
  border: 1px solid #707070;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}
.carousel-pagination .page-active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #0073f9;
}

.tooltip {
  padding: 7px 5px;
  font-size: 14px;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #000000;
}

.mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-link .mdc-tab__text-label {
  color: #000000;
}

.mat-mdc-tab.mdc-tab {
  height: 80px !important;
}

.mat-mdc-tab.mdc-tab:hover {
  height: 80px !important;
}

.mat-tab-label:hover {
  background-color: red;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: #0047B9;
}

.mat-elevation-z4 {
  box-shadow: none;
}

.mdc-tab__ripple::before,
.mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-link .mat-ripple-element {
  background-color: none !important;
}

.analytics-container .mat-mdc-tab .mat-mdc-focus-indicator .mdc-tab--active .ng-star-inserted .mdc-tab-indicator--active {
  background-color: none !important;
}

.mdc-tab__ripple::before,
.mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-link .mat-ripple-element {
  background-color: #fff;
}

.mat-mdc-tab-label-container {
  border-bottom: 2px solid #b4b4b4 !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 30px !important;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 0px #595959;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #d9d9d9;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: rgba(255, 255, 255, 0) !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #000000;
  font-size: 14px;
  margin-left: 2px;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: #000000;
  margin-left: 3px;
  font-size: 14px;
}

.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: rgba(0, 0, 0, 0);
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
}

.mat-mdc-form-field-flex {
  display: inline-flex;
  align-items: baseline;
  box-sizing: border-box;
  margin-right: 2px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cec0c0;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: #000;
}

.mdc-line-ripple::before,
.mdc-line-ripple::after {
  border-bottom-style: none !important;
}

.mdc-text-field {
  border-top-left-radius: var(--mdc-shape-small, 0px) !important;
  border-top-right-radius: var(--mdc-shape-small, 0px) !important;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  display: inline-flex;
  align-items: baseline;
  padding: 0 0px !important;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  will-change: opacity, transform, color;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgb(0, 0, 0);
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::before {
  color: rgb(39, 9, 161);
}

.mdc-text-field .mdc-text-field__input {
  caret-color: #000000;
}

.mat-mdc-select-value {
  color: #000;
}

.mat-mdc-select-arrow {
  color: rgb(0, 0, 0);
}

.mat-mdc-select-arrow-wrapper {
  height: 24px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
}

.mdc-menu-surface {
  background-color: #ffffff;
}

.mdc-list-item__primary-text {
  color: #000000;
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: #000000;
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #0047B9;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #0047B9;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #0047B9 !important;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #ffffff !important;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
  border: 1px solid #b1b1b1;
}

.owl-theme .owl-dots {
  margin-right: 34px;
  -webkit-tap-highlight-color: transparent;
}

.mat-mdc-form-field-infix {
  min-height: 48px;
}

.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #ffffff;
}

.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mdc-floating-label--float-above {
  color: #000;
}

.mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgb(0, 0, 0);
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  line-height: normal;
  pointer-events: none !important;
}

.modal-body .owl-theme .owl-nav .owl-prev,
.owl-theme .owl-nav .owl-next {
  color: rgb(255, 255, 255) !important;
  background-color: #0047B9 !important;
  border-radius: 15px !important;
  font-size: 14px !important;
  margin: 5px 29px 5px 28px !important;
}

.member-registration .ng-select .ng-select-container {
  border-bottom: 0px !important;
}

.ng-select .ng-select-focused {
  box-shadow: 0 0 0 white !important;
}

.otp-input {
  width: 35px !important;
  height: 35px !important;
  font-size: 20px !important;
}

.mat-mdc-radio-button .mdc-form-field {
  color: black !important;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-icon-color: #0047b9;
  --mdc-radio-unselected-icon-color:#0047b9 ;
}

.registration-new .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #fff;
}

.registration-new .ng-select .ng-select-container {
  color: #fff;
}