@import "src/design/standard.scss";
@import "src/design/pattern.scss";


@mixin rtl {
    @at-root [dir="rtl"] #{&} {
        @content
    }
}

$ng-select-highlight: transparent !default;
$ng-select-primary-text: #333 !default;
$ng-select-disabled-text: #f9f9f9 !default;
$ng-select-border: #c4c4c4 !default;
$ng-select-border-radius: 0 !default;
$ng-select-bg: #ffffff !default;
$ng-select-selected: lighten($ng-select-highlight, 46) !default;
$ng-select-marked: lighten($ng-select-highlight, 48) !default;
$ng-select-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0),
0 0 0 3px rgba(0, 126, 255, 0.0) !default;
$ng-select-placeholder: #c4c4c4 !default;
$ng-select-height: 28.6px !default;
$ng-select-value-padding-left: 0px !default;
$ng-select-value-font-size: 0.9em !default;

.ng-select {
    @include font-basic(#505050, 1rem);

    &.ng-select-opened {
        >.ng-select-container {
            background: $ng-select-bg;
            border-color: darken($ng-select-border, 10) $ng-select-border lighten($ng-select-border, 5);
            background-color: transparent;

            &:hover {
                box-shadow: none;
            }

            .ng-arrow {
                top: -2px;
                border-color: transparent transparent darken($ng-select-border, 20);
                border-width: 0 5px 5px;

                &:hover {
                    border-color: transparent transparent darken($ng-select-border, 60);
                }
            }
        }

        &.ng-select-bottom {
            >.ng-select-container {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &.ng-select-top {
            >.ng-select-container {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }

        //test
        .ng-arrow-wrapper {
            width: 25px;
            padding-right: 5px;
    
            @include image($icon-arrow-up, 30px, 25px, 15px, auto, bottom 6px center);
    
        }
        
    }

    &.ng-select-focused {
        //border-bottom: 1px solid $ng-select-border;
        border-color: #0073f9;
        box-shadow: 0 1.5px 0 #0073f9;
        transition: border-color 0.13s ease-in-out, box-shadow 0.13s ease-in-out;
        &:not(.ng-select-opened)>.ng-select-container {
            border-color: $ng-select-highlight;
            box-shadow: $ng-select-box-shadow;
        }
    }

    &.ng-select-disabled {
        >.ng-select-container {
            background-color: #f8f8f8;
            border: none;
            border-radius: 5px;
            cursor: not-allowed;
            .ng-placeholder{
                color: transparent;
            }
        }
        .ng-arrow-wrapper{
            display: none;
        }
        .ng-value-container{
            background-color: #f8f8f8;
            cursor: not-allowed;
            .ng-placeholder{
                color: transparent;
            }
        }
    }

    .ng-has-value .ng-placeholder {
        display: none;
    }

    .ng-select-container {
        color: $ng-select-primary-text;
        background-color: $ng-select-bg;
        background-color: transparent;
        border-radius: $ng-select-border-radius;
        border-bottom: 1px solid $ng-select-border;
        box-shadow: 0 1px 0 transparent;
        min-height: $ng-select-height;
        align-items: center;

        &:hover {
            box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
        }

        .ng-value-container {
            align-items: center;
            padding-left: 0 !important; 
            cursor: pointer;

            @include rtl {
                padding-right: $ng-select-value-padding-left;
                padding-left: 0;
            }

            .ng-placeholder {
                color: $ng-select-placeholder;
            }
        }
    }

    &.ng-select-single {
        .ng-select-container {
            height: $ng-select-height;

            .ng-value-container {
                .ng-input {
                    left: 0;
                    padding-left: $ng-select-value-padding-left;
                    padding-right: 50px;
                    

                    @include rtl {
                        padding-right: $ng-select-value-padding-left;
                        padding-left: 50px;
                    }

                    input{
                        padding: 0;
                    }
                }
            }
        }
    }

    &.ng-select-multiple {
        &.ng-select-disabled {
            >.ng-select-container .ng-value-container .ng-value {
                background-color: $ng-select-disabled-text;
                border: 1px solid lighten($ng-select-border, 10);
                border: none;
                border-radius: 5px;
                background-color: #f8f8f8;
                cursor: not-allowed;

                

                .ng-value-label {
                    padding: 0 5px;
                }
            }
            .ng-placeholder{
                color: transparent;
            }
        }

        .ng-select-container {
            .ng-value-container {
                padding-left: 7px;

                @include rtl {
                    padding-right: 7px;
                    padding-left: 0
                }

                .ng-value {
                    @include font-basic(#505050, 0.9rem);
                    display: flex;
                    flex-direction: row-reverse;
                    border: none;
                    background-color: $c-cream;
                    padding: 5px 5px 5px 10px;
                    border-radius: 15px;
                    margin-bottom: 6px;
                    margin-right: 10px;

                    @include rtl {
                        margin-right: 0;
                        margin-left: 5px;
                    }

                    &.ng-value-disabled {
                        background-color: $ng-select-disabled-text;

                        .ng-value-label {
                            padding-left: 5px;

                            @include rtl {
                                padding-left: 0;
                                padding-right: 5px;
                            }
                        }

                        .ng-value-container{
                            background-color: #f8f8f8;
                            cursor: not-allowed;
                        }
                        .ng-placeholder{
                            color: transparent;
                        }
                    }

                    .ng-value-label {
                        display: inline-block;
                        padding: 1px 5px;
                    }

                    .ng-value-icon {
                        display: inline-block;
                        padding: 1px 5px;

                        &:hover {
                            background-color: darken($ng-select-selected, 5);
                        }

                        &.left {
                            border-right: 1px solid darken($ng-select-selected, 10);

                            @include rtl {
                                border-left: 1px solid darken($ng-select-selected, 10);
                                border-right: none;
                            }
                        }

                        &.right {
                            border-left: 1px solid darken($ng-select-selected, 10);

                            @include rtl {
                                border-left: 0;
                                border-right: 1px solid darken($ng-select-selected, 10);
                            }
                        }
                    }
                }

                .ng-input {
                    padding: 0 0 0px 3px;

                    @include rtl {
                        padding: 0 0px 3px 0;
                    }
                }

                .ng-placeholder {
                    padding-bottom: 0px;
                    padding-left: 3px;
                    position: absolute;
                    top: inherit;
                    z-index: 1;

                    @include rtl {
                        padding-right: 3px;
                        padding-left: 0;
                    }
                }
            }
        }
    }

    .ng-clear-wrapper {
        color: darken($ng-select-border, 20);

        &:hover .ng-clear {
            color: #D0021B;
        }
    }

    .ng-spinner-zone {
        padding: 5px 5px 0 0;

        @include rtl {
            padding: 5px 0 0 5px;
        }
    }

    .ng-arrow-wrapper {
        width: 25px;
        padding-right: 5px;

        @include image($icon-arrow-down, 30px, 25px, 15px, auto, bottom 6px center);

        @include rtl {
            padding-left: 5px;
            padding-right: 0;
        }

        &:hover {
            .ng-arrow {

                // border-top-color: darken($ng-select-border, 40);
            }
        }

        .ng-arrow {
            // border-color: darken($ng-select-border, 20) transparent transparent;
            // border-style: solid;
            // border-width: 5px 5px 2.5px;
        }
    }
}

.ng-dropdown-panel {
    background-color: $ng-select-bg;
    // border: 1px solid $ng-select-border;
    // box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    left: 0;

    &.ng-select-bottom {
        top: 100%;
        border-radius: 10px;
        border-top-color: lighten($ng-select-border, 10);
        margin-top: 10px;

        .ng-dropdown-panel-items {
            .ng-option {
                &:last-child {
                    border-bottom-right-radius: 10px;
                    border-bottom-left-radius: 10px;
                }

                &:hover{
                    color: $c-blue;
                }
            }
        }
    }

    &.ng-select-top {
        bottom: 100%;
        border-radius: 10px;
        border-bottom-color: lighten($ng-select-border, 10);
        margin-bottom: 5px;

        .ng-dropdown-panel-items {
            .ng-option {
                &:first-child {
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                }
            }
            &:hover{
                color: $c-blue;
            }
        }
    }

    .ng-dropdown-header {
        border-bottom: 1px solid $ng-select-border;
        padding: 5px 7px;
    }

    .ng-dropdown-footer {
        border-top: 1px solid $ng-select-border;
        padding: 5px 7px;
    }

    .ng-dropdown-panel-items {
        padding: 0 25px;
        border-radius: 10px;
        box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
        max-height: 203px !important;
        @include scroll-vertical();
        .ng-optgroup {
            user-select: none;
            padding: 8px 10px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.54);
            cursor: pointer;

            &.ng-option-disabled {
                cursor: default;
            }

            &.ng-option-marked {
                background-color: $ng-select-marked;
            }

            &.ng-option-selected,
            &.ng-option-selected.ng-option-marked {
                background-color: $ng-select-selected;
                font-weight: 600;
            }
        }

        .ng-option {
            background-color: $ng-select-bg;
            color: rgba(0, 0, 0, .87);
            padding: 21px 10px 15px 10px;
            border-bottom: 1px solid #C5C5C5;
            @include font-basic($c-black, $fs-basic);

            &:last-of-type{
                border: none;
            }

            &:hover{
                color: $c-blue !important;
            }

            &.ng-option-selected,
            &.ng-option-selected.ng-option-marked {
                color: $ng-select-primary-text;
                background-color: $ng-select-selected;

                .ng-option-label {
                    @include font-basic-semi-bold($c-black, $fs-basic);
                }
            }

            &.ng-option-marked {
                background-color: $ng-select-marked;
                color: $ng-select-primary-text;
            }

            &.ng-option-disabled {
                color: lighten($ng-select-primary-text, 60);
            }

            &.ng-option-child {
                padding-left: 22px;

                @include rtl {
                    padding-right: 22px;
                    padding-left: 0;
                }
            }

            .ng-tag-label {
                font-size: 80%;
                font-weight: 400;
                padding-right: 5px;

                @include rtl {
                    padding-left: 5px;
                    padding-right: 0;
                }
            }
        }

        
    }

    @include rtl {
        direction: rtl;
        text-align: right;
    }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value{
    line-height: 1.3rem !important;
}