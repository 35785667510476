@import "src/design/standard.scss";
@import "src/design/pattern.scss";

.full-calendar{

    full-calendar{
        height: calc(100vh - 150px)
    }
    

    .fc-toolbar{
        .fc-toolbar-title{
            @include font-basic-bold($c-black-light, 1.8rem);
        }
        .fc-toolbar-chunk{
            .fc-button-group{
                .fc-button{
                    background-color: transparent;
                    border: 1px solid $c-blue;
                    height: 34px;
                    .fc-icon{
                        color: $c-blue !important;
                    }
                }

                .fc-button:focus{
                    outline: none;
                    box-shadow: none;
                }

                .fc-prev-button{
                    width: 34px;
                    padding: 0 !important;
                    border-right: none;
                }
                .fc-next-button{
                    width: 34px;
                    padding: 0 !important;
                }
                .fc-dayGridMonth-button{
                    @include font-basic($c-blue, 1);
                    line-height: 0;
                }
                .fc-dayGridWeek-button{
                    @include font-basic($c-blue, 1);
                    line-height: 0;
                }

                .fc-button-active{
                    background-color: $c-blue;
                    color: white;
                }
            }
        }
    }

    table{
        background-color: white ;
        thead{
            .fc-scroller{
                overflow: hidden !important;
            }
        }
        tbody{
            .fc-scroller {
                @include scroll-vertical();
            }
        }
    }

    .fc-col-header-cell-cushion {
        @include font-basic-semi-bold($c-black, 0.9rem);
        padding: 5px 0 !important;
    }



    // Event




    .fc-event{
        min-height: 70px;
        .fc-daygrid-event{
            border: none !important;
        }
        .fc-event-title-container{
            display: none !important;
        }
        .event-card{
            position: relative;
            overflow: hidden;
            height: 70px;
            margin-right: 5px;
            position: relative;
            padding: 5px;
            .event-card-new{
                display: flex; 
                flex-direction: row; 
                justify-content: space-between; 
                width: 100%;
            }
            .event-card-title{
                @include font-basic-bold($c-black-light, 1rem);
                text-transform: capitalize;
                margin-right: 10px;
            }
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .event-card-content{
                .event-card-content-locations{
                    @include margin-top(7px);
                    opacity: 0.6;
                    @include image($icon-location, auto, 15px, auto, 14px, center left);
                    display: flex;
                    align-items: center;
                    padding-left: 15px;

                    .event-card-content-location{
                        margin-right: 5px;
                        @include font-basic($c-black, 0.9rem);
                    }
                }
                .event-card-content-roadshow{
                    @include margin-top(7px);
                    @include font-basic-semi-bold($c-black-light, 0.9rem);
                }
            }

            .event-card-lock{
                position: absolute;
                right: 0px;
                bottom: 5px;
                @include image($icon-roadshow-lock, 20px, 20px, auto, 19px, center);
            }

            .event-card-unlock{
                position: absolute;
                right: 0px;
                bottom: 5px;
                @include image($icon-roadshow-unlock, 20px, 20px, auto, 19px, center);
            }
           
            
        }
    }
}



.tippy-box {
    
   
  }


  .tippi-text{
      background-color: red;
      width: 500px;
      height: 300px;
  }



  .tippy-roadshow-invite{
    position: relative;
    width: 450px;
    background-color: $c-white;
    box-shadow: -1px 3px 8px rgba(0, 0, 0, 0.16);
    @include padding(20px 20px 20px 20px);
    border-radius: 10px;

    >.tippy-roadshow-invite-title { 
        @include font-basic-bold($c-black, 1rem);

	}

	>.tippy-roadshow-invite-locations { 
        @include margin-top(10px);
        @include image($icon-location, auto, 15px, auto, 14px, center left);
        display: flex;
        align-items: center;
        padding-left: 20px;
		>.tippy-roadshow-invite-location {
            display: inline-block;
            @include margin(0 5px 0 0);
            @include font-basic-semi-bold($c-black, 0.9rem); 

		}
	}

	>.tippy-roadshow-invite-offers {
        @include image($icon-offer, auto, 15px, auto, 13px, center left);
        display: flex;
        align-items: center;
        padding-left: 20px; 
        @include margin-top(10px);
        @include font-basic-semi-bold($c-black, 0.9rem); 
    }
    
	>.tippy-roadshow-invite-roadshow {
        @include image($icon-roadshow, auto, 15px, auto, 13px, center left);
        display: flex;
        align-items: center;
        padding-left: 20px; 
        @include margin-top(10px);
        @include font-basic-semi-bold($c-black, 0.9rem); 
	}

	>.tippy-roadshow-invite-description { 
        @include margin-top(10px);
		>.tippy-roadshow-invite-description-title { 
            @include font-basic-semi-bold($c-black, 0.9rem); 

		}

		>.tippy-roadshow-invite-description-content { 
            @include margin-top(5px);
            @include font-basic($c-grey, 0.9rem); 
		}
    }
    
    >.tippy-roadshow-invite-focal { 
        @include margin-top(10px);
		>.tippy-roadshow-invite-focal-title { 
            @include font-basic-semi-bold($c-black, 0.9rem); 

		}

		>.tippy-roadshow-invite-focal-content { 
            @include margin-top(5px);

            >.tippy-roadshow-invite-focal-content-contact{
                @include font-basic($c-grey, 0.9rem); 
            }
		}
	}

	>.tippy-roadshow-invite-statistics { 
        @include margin-top(10px);
		>.tippy-roadshow-invite-statistic {
            @include padding(0 5px);
            display: inline-block;
            @include font-basic($c-grey, 0.9rem); 
            border-left: 1px solid $c-grey;

            &:first-of-type{
                padding-left: 0 !important;
                border-left: none;
            }
        }
        
	}

	>.tippy-roadshow-invite-status { 
        position: absolute;
        top: 0;
        right: 0;
        @include padding(7px 15px);
        background-color: $c-blue;
        @include font-basic-semi-bold($c-white, $fs-basic);
        border-top-right-radius: 10px;
        border-bottom-left-radius: 8px;
        
	}
  }