@import "src/design/standard.scss";

image-cropper{
    >div{
        background-color: $c-cream;
        border-radius: 15px;
        .square{
            background-color: $c-blue !important;
            border: 1px solid $c-blue !important;
            border: 1px solid $c-grey-line;
            border-radius: 50%;
        }
        .source-image{
            border-radius: 15px;
        }
    }
}