// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

// STANDARD

// (colors, fonts, imgs, background, scroll)

// + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

// Colors
$c-blue: #0047ba;
$c-black: #000000;
$c-black-light: #333333;
$c-grey: #6e6e6e;
$c-grey-light: #8b8b8b;
$c-grey-line: #c5c5c5;
$c-cream: #efefef;
$c-green: #42ad53;
$c-red: #f55757;
$c-white: #fff;

//Line:
$c-line-grey: #c9d1db;
$c-line-grey-light: #eff0f1;

// Status:
$c-status-draft: #8b8b8b;
$c-status-invited: #0073f9;
$c-status-responses: #0073f9;
$c-status-published: #57d26b;
$c-status-accepted: #d76cf6;
$c-status-approved: #57d26b;
$c-status-review: #5ea8ff;
$c-status-rejected: #f00051;
$c-status-deactivated: #fac23d;
$c-status-blocked: #ff5f69;
$c-status-expired: #c2c2c2;
$c-status-renegotiation: #ffaf70;
$c-status-returned: #ffc815;

// Fonts
@font-face {
    font-family: "ADNOC Sans Regular";
    src: 
    url("/assets/fonts/ADNOCSansRegular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Segoe-UI-Italic";
    src: url("/assets/fonts/Segoe-UI-Italic.woff") format("woff");
}

@font-face {
    font-family: "ADNOC Sans Medium";
    src: 
    url("/assets/fonts/ADNOCSansMedium.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Segoe-UI-Semi-Bold-Italic";
    src: url("/assets/fonts/Segoe-UI-Semi-Bold-Italic.woff") format("woff");
}

@font-face {
    font-family: "ADNOC Sans Bold";
    src: 
    url("/assets/fonts/ADNOCSansBold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Segoe-UI-Bold-Italic";
    src: url("/assets/fonts/Segoe-UI-Bold-Italic.woff") format("woff");
}

@mixin font-basic($color, $font-size, $font-size-L: null, $font-size-M: null, $font-size-S: null) {
    font-family: "ADNOC Sans Regular";
    color: $color;
    @include font-size($font-size);
}

@mixin font-basic-italic($color, $font-size) {
    font-family: "Segoe-UI-Italic";
    color: $color;
    @include font-size($font-size);
}

@mixin font-basic-semi-bold($color, $font-size, $font-size-L: null, $font-size-M: null, $font-size-S: null) {
    font-family: "ADNOC Sans Medium";
    color: $color;
    @include font-size($font-size, $font-size-L: null, $font-size-M: null, $font-size-S: null);
}

@mixin font-basic-semi-bold-italic($color, $font-size, $font-size-L: null, $font-size-M: null, $font-size-S: null) {
    font-family: "Segoe-UI-Semi-Bold-Italic";
    color: $color;
    @include font-size($font-size, $font-size-L: null, $font-size-M: null, $font-size-S: null);
}

@mixin font-basic-bold($color, $font-size, $font-size-L: null, $font-size-M: null, $font-size-S: null) {
    font-family: "ADNOC Sans Bold";
    color: $color;
    @include font-size($font-size, $font-size-L: null, $font-size-M: null, $font-size-S: null);
}

@mixin font-basic-bold-italic($color, $font-size) {
    font-family: "Segoe-UI-Bold-Italic";
    color: $color;
    @include font-size($font-size);
}

@mixin font-navbar() {
    font-family: "ADNOC Sans Regular";
}

@mixin font-navbar-bold() {
    font-family: "Segoe-UI";
    font-weight: 600;
}

// Font-size
$fs-basic: 0.875rem;
$fs-section-title: 2.25rem;
$fs-section-subtitle: 1.25rem;
$fs-footer: 0.9375rem;
$fs-collections-title: 1.25rem;

// Radius
$radius: 15px;
$radius-img: 15px;
$radius-btn: 16px;
$radius-navbar-searchbar: 25px;
$radius-label-input: 25px;
$radius-box: 10px;

// Shadows
$shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
$shadow-navbar: 0 0 10px 0 rgba(0, 0, 0, 0.16);
$shadow-card: 0 0 6px 0 rgba(0, 0, 0, 0.07);
$shadow-dropdown: 0 0 5px 0 rgba(0, 0, 0, 0.16);

// Screen
$screen-container: 1300px;

$break-small: 850px;
$break-medium: 1050px;
$break-large: 1340px;

@mixin container() {
    max-width: $screen-container;
    margin: 0 auto;

    @include resp(L) {
        padding: 0px 20px;
    }
}

@mixin container-section() {
    max-width: $screen-container;
    margin: 50px auto 0 auto;

    @include resp(L) {
        padding: 0px 20px;
    }
}

// Imgs
$img-root: "/assets/images";

$icon-search-0: "icons/search_0_icon.svg";
$icon-search-1: "icons/search_1_icon.svg";
$icon-searchReset-0: "icons/searchReset_0_icon.svg";
$icon-searchReset-1: "icons/searchReset_1_icon.svg";
$icon-notifications: "icons/notifications_icon.svg";
$icon-cart: "mazayaPlus/cart.svg";
$icon-home: "icons/home_icon.svg";
$icon-back: "icons/back_icon.svg";
$icon-offer: "icons/offer_icon.svg";
$icon-roadshow: "icons/roadshow_icon.svg";
$icon-roadshow-lock: "icons/lock_roadshow_icon.svg";
$icon-roadshow-unlock: "icons/unlock_roadshow_icon.svg";

$icon-location: "icons/location_icon.svg";
$icon-location-0: "icons/location_0_icon.svg";
$icon-location-1: "icons/location_1_icon.svg";
$icon-locations: "icons/locations_icon.svg";
$icon-locations-0: "icons/locations_0_icon.svg";
$icon-locations-1: "icons/locations_1_icon.svg";

$icon-proposals-0: "icons/proposals_0_icon.svg";
$icon-proposals-1: "icons/proposals_1_icon.svg";

$icon-qr-0: "icons/qr_0_icon.svg";
$icon-qr-1: "icons/qr_1_icon.svg";

$icon-attachment: "icons/attachment_icon.svg";
$icon-checker-1: "icons/checker_1_icon.svg";

$icon-visible-0: "icons/visible_0_icon.svg";
$icon-visible-1: "icons/visible_1_icon.svg";

$icon-reject-0: "icons/reject_0_icon.svg";
$icon-reject-1: "icons/reject_1_icon.svg";

$icon-approve-0: "icons/approve_0_icon.svg";
$icon-approve-1: "icons/approve_1_icon.svg";

$icon-comment-0: "icons/comment_0_icon.svg";
$icon-comment-1: "icons/comment_1_icon.svg";

$icon-add-0: "icons/add_0_icon.svg";
$icon-add-1: "icons/add_1_icon.svg";

$icon-stars-empty: "icons/stars_empty_icon.svg";
$icon-stars-full: "icons/stars_full_icon.svg";

$icon-stars-0: "icons/stars_0_icon.svg";
$icon-stars-1: "icons/stars_1_icon.svg";
$icon-star-0: "icons/star_0_icon.svg";
$icon-star-1: "icons/star_1_icon.svg";

$icon-download-0: "icons/download_0_icon.svg";
$icon-download-1: "icons/download_1_icon.svg";

$icon-arrow-up: "icons/arrowUp_icon.svg";
$icon-arrow-down: "icons/arrowDown_icon.svg";

$icon-search-arrow-0: "icons/arrow_search_0_icon.svg";
$icon-search-arrow-1: "icons/arrow_search_1_icon.svg";
$icon-search-arrow-2: "icons/arrow_search_2_icon.svg";
$icon-search-arrow-3: "icons/arrow_search_3_icon.svg";

$icon-roadshow-location: "icons/location_roadshow_icon.svg";

$icon-edit: "icons/edit_icon.svg";
$icon-delete: "icons/delete_icon.svg";

$icon-no-ads: "icons/no_ads.svg";

$icon-no-image-uploder: "icons/noImageUploder_icon.svg";
$icon-no-video-uploder: "icons/noVideoUploder_icon.svg";

$icon-btn-favourites-0: "icons/favourites_btn_0_icon.svg";
$icon-btn-favourites-1: "icons/favourites_btn_1_icon.svg";

$icon-btn-share-0: "icons/share_btn_0_icon.svg";
$icon-btn-share-1: "icons/share_btn_1_icon.svg";

$icon-share-mail: "icons/share_mail_icon.svg";
$icon-share-fb: "icons/share_fb_icon.svg";
$icon-share-twiter: "icons/share_twiter_icon.svg";

$icon-btn-filter-0: "icons/filter_0_icon.svg";
$icon-btn-filter-1: "icons/filter_1_icon.svg";

$icon-gallery-arrowUp: "icons/arrowUp_gallery_icon.svg";
$icon-gallery-arrowUp-1: "icons/arrowUp_gallery_1_icon.svg";
$icon-gallery-arrowDown: "icons/arrowDown_gallery_icon.svg";
$icon-gallery-arrowDown-1: "icons/arrowDown_gallery_1_icon.svg";

$icon-offer-coupon: "icons/coupon_offer_icon.svg";
$icon-offer-collection: "icons/collection_offer_icon.svg";
$icon-offer-roadshow: "icons/roadshow_offer_icon.svg";

$icon-dashboard-banners: "icons/banners_dashboard_icon.svg";
$icon-dashboard-tags: "icons/tags_dashboard_icon.svg";
$icon-dashboard-users: "icons/users_dashboard_icon.svg";
$icon-dashboard-roadshow: "icons/roadshow_dashboard_icon.svg";
$icon-dashboard-offers: "icons/offers_dashboard_icon.svg";
$icon-dashboard-proposals: "icons/proposals_dashboard_icon.svg";
$icon-dashboard-agreements: "icons/agreements_dashboard_icon.svg";
$icon-dashboard-categories: "icons/categories_dashboard_icon.svg";
$icon-dashboard-collections: "icons/collections_dashboard_icon.svg";
$icon-dashboard-supplies: "icons/companies_dashboard_icon.svg";
$icon-dashboard-offers-rating: "icons/offers-rating_dashboard_icon.svg";

$icon-progress-draft-0: "icons/draft_progress_0_icon.svg";
$icon-progress-draft-1: "icons/draft_progress_1_icon.svg";
$icon-progress-invited-0: "icons/invited_progress_0_icon.svg";
$icon-progress-invited-1: "icons/invited_progress_1_icon.svg";
$icon-progress-review-0: "icons/review_progress_0_icon.svg";
$icon-progress-review-1: "icons/review_progress_1_icon.svg";
$icon-progress-approved-0: "icons/approved_progress_0_icon.svg";
$icon-progress-approved-1: "icons/approved_progress_1_icon.svg";
$icon-progress-rejected-1: "icons/rejected_progress_1_icon.svg";
$icon-progress-returned-0: "icons/returned_progress_0_icon.svg";
$icon-progress-returned-1: "icons/returned_progress_1_icon.svg";
$icon-progress-union-0: "icons/union_progress_0_icon.svg";
$icon-progress-union-1: "icons/union_progress_1_icon.svg";
$icon-progress-accepted-0: "icons/accepted_progress_0_icon.svg";
$icon-progress-accepted-1: "icons/accepted_progress_1_icon.svg";
$icon-progress-blocked-0: "icons/blocked_progress_0_icon.svg";
$icon-progress-blocked-1: "icons/blocked_progress_1_icon.svg";
$icon-progress-expired-0: "icons/expired_progress_0_icon.svg";
$icon-progress-expired-1: "icons/expired_progress_1_icon.svg";
$icon-progress-deactivated-0: "icons/deactivated_progress_0_icon.svg";
$icon-progress-deactivated-1: "icons/deactivated_progress_1_icon.svg";
$icon-scheduled-progres-0: "icons/scheduled_progres_0_icon.svg";
$icon-scheduled-progres-1: "icons/scheduled_progres_1_icon.svg";
$icon-progress-closed-0: "icons/closed_progress_0_icon.svg";
$icon-progress-closed-1: "icons/closed_progress_1_icon.svg";

$icon-progress-submitted-0: "icons/susubmitted_icon.svg";
$icon-progress-submitted-1: "icons/submitted_progress_icon.svg";
$icon-progress-approved-0: "icons/approved_icon.svg";
$icon-progress-approved-1: "icons/approved_progress_icon.svg";
$icon-progress-confirmed-0: "icons/confirmed_icon.svg";
$icon-progress-confirmed-1: "icons/confirmed_progress_icon.svg";
$icon-progress-published-0: "icons/published_icon.svg";
$icon-progress-published-1: "icons/published_progress_icon.svg";

$icon-section-resize-0: "icons/resize_section_0_icon.svg";
$icon-section-resize-1: "icons/resize_section_1_icon.svg";

$icon-no-notification: "icons/no_notification_icon.svg";
$icon-cropper-cover: "icons/coverStart_cropper_icon.svg";
$icon-help-0: "icons/help_0_icon.svg";

$icon-pagination-firstPage-0: "icons/firstPage_pagination_0_icon.svg";
$icon-pagination-prevPage-0: "icons/prevPage_pagination_0_icon.svg";
$icon-pagination-nextPage-0: "icons/nextPage_pagination_0_icon.svg";
$icon-pagination-lastPage-0: "icons/lastPage_pagination_0_icon.svg";
$icon-pagination-firstPage-1: "icons/firstPage_pagination_1_icon.svg";
$icon-pagination-prevPage-1: "icons/prevPage_pagination_1_icon.svg";
$icon-pagination-nextPage-1: "icons/nextPage_pagination_1_icon.svg";
$icon-pagination-lastPage-1: "icons/lastPage_pagination_1_icon.svg";

$img-logo-offers: "logos/offers_logo.svg";
$img-logo-mazaya-0: "logos/mazaya_0_logo.svg";
$img-logo-mazaya-1: "logos/mazaya_1_logo.svg";
$img-logo-mazaya-3: "logos/mazaya_3_logo.svg";
$img-logo-adnoc-v: "logos/adnoc_v_logo.svg";
$img-logo-adnoc-h: "logos/adnoc_h_logo.svg";

$icon-notif-sucess: "icons/success_notif_icon.svg";
$icon-notif-info: "icons/info_notif_icon.svg";
$icon-notif-error: "icons/error_notif_icon.svg";
$icon-notif-warning: "icons/warning_notif_icon.svg";

$icon-website-0: "icons/website_0_icon.svg";
$icon-report-0: "icons/report_0_icon.svg";
// Placeholders
$default-user-icon: "default/Group 1545.svg";
$no-offers: "default/offers_no.svg";
$no-adnocUsers: "default/adnocUsers_no.svg";
$no-suppliers: "default/suppliers_no.svg";
$no-tags: "default/tags_no.svg";
$no-banners: "default/banners_no.svg";
$no-ratings: "default/ratings_no.svg";
$no-terms: "default/terms_no.svg";
$no-locations: "default/locations_no.svg";
$no-agreements: "default/agreements_no.svg";
$no-categories: "default/categories_no.svg";
$no-collections: "default/collections_no.svg";
$no-roadshows: "default/roadshows_no.svg";
$no-date: "default/date_no.svg";
$no-info: "default/info_no.svg";
$no-table: "default/table_no.svg";

$img-navbar-hamb-0: "icons/hamburger_nav_0_icon.svg";
$img-navbar-hamb-1: "icons/hamburger_nav_1_icon.svg";
$img-navbar-sub-arrowLeft: "icons/arrowLeft_navbar_subbar_icon.svg";
$img-navbar-sub-arrowRight: "icons/arrowRight_navbar_subbar_icon.svg";
$icon-collections: "icons/collections.svg";

// Background
$bc-roadshow: "backgrounds/roadshow_background.svg";
$bc-survey: "backgrounds/survey_background.svg";

$test-collection-img: "test/collection-img-test.png";

// Background
@mixin background {
    background-image: url("/assets/images/backgrounds/shape_background.svg");
    background-size: 100% auto;
    background-repeat: repeat-y;
}

// Scroll
@mixin scroll-vertical() {
    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: #f8f8f8;
    }

    &::-webkit-scrollbar-thumb {
        background: #c0cace;
        border-radius: 5px;
        cursor: pointer;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $c-blue;
    }
}

@mixin scroll-horizontal() {
    &::-webkit-scrollbar {
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background: #f8f8f8;
    }

    &::-webkit-scrollbar-thumb {
        background: #c0cace;
        border-radius: 5px;
        cursor: pointer;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $c-blue;
    }
}
